import { Service } from '../service';
import { createSlice } from '@reduxjs/toolkit';
import {
  SUCCESS_STATUS, ERROR_STATUS, UPLOAD_CHECK, CUSTOMER, UPLOAD_CUSTOMER_FILE, CHECK_CUSTOMER_NUMBER,
  DELETE_CUSTOMERS, CUSTOMER_GROUP_NAME, CUSTOMER_SEARCH_FORMULARITY_URL, ASSIGN_FORMULARY_CUSTOMER, BASE_URL, SIGNIN_TOKEN, API_KEY, UPLOAD_CHECK_CUSTOMER, REG_ORG_TYPE, FACILITY_CHECK_BILLING, OPTION_TYPES
} from '../constants';
import { setError, setLoading, deleteAsync,setAccessError } from './appSlice';
import { constructEndpoint } from '../utils/commonHelper'
import { SimplePersistence } from '../utils';
// import { hashHistory } from 'react-router';
const { request } = Service;

const storage = new SimplePersistence();

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    detailViewData: {}, // individual customer details 
    facilitiesData: {}, // Facility(s)
    uploading: false, // Someone else is uploading?
    uploadStatus: null, // Upload success or failed?
    reasonForVisit: [],
    comorbidConditions: [],
    insurancePayer: [],
    woundAssessments: [],
    customers: {},
    assessmentHistory: {},
    tempPassword: '',
    facilitiesDataById: {},// Specific Facility(s)
    listOption:{},//ListOption SalesOffice Customer
    customerGroupType:{},//list option Customer Group
    resMsgState: '',
    editState: false,
    formularySearchData: {},
    customerAssignedFormulary: {},
    responseAssignState: false,
    priceListOption:{},
    quantityAcrossFacilitiesStatus: null //check facility for billing tab
  },
  reducers: {
    /* Data for detailed customer view */
    setDetailViewData: (state, action) => {
      state.detailViewData = action.payload
    },
    /* Reducer for "someone else is uploading?" */
    setUploading: (state, action) => {
      state.uploading = action.payload
    },
    /* CSV file upload success or failed */
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload
    },
    /* Fetch facilities */
    setFacilitiesData: (state, action) => {
      state.facilitiesData = action.payload
    },
    /* Get Customers Details*/
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    resetCustomers: (state, action) => {
      state.customers = {}
    },
    setTempPassword: (state, action) => {
      state.tempPassword = action.payload;
    },
    resetFacilitiesData: (state, action) => {
      state.facilitiesData = {}
    },
    /* Fetch facilities by id */
    setIdByFacilitiesData: (state, action) => {
      state.facilitiesDataById = action.payload
    },
    /* Reset facilities by id */
    resetFacilitiesDataById: (state, action) => {
      state.facilitiesDataById = {}
    },
    setResMsgState: (state, action) => {
      state.resMsgState = action.payload;
    },
    setEditState: (state, action) => {
      state.editState = action.payload;
    },
    setFormularySearchData: (state, action) => {
      state.formularySearchData = action.payload;
    },
    setCustomerAssignedFormulary: (state, action) => {
      state.customerAssignedFormulary = action.payload;
    },
    resetCustomerAssignedFormulary: (state, action) => {
      state.customerAssignedFormulary = {}
    },
    setResponseAssignState: (state, action) => {
      state.responseAssignState = action.payload;
    },
    setListOption : (state, action) => {
      state.listOption = action.payload
    },
    /* Customer Group List based on Sale Office Value */
    setCustomerGroupType: (state, action) => {
      state.customerGroupType = action.payload;
    },
    setPriceListOption: (state, action) => {
      state.priceListOption = action.payload;
    }, /* Check facility for billing tab */
    SetQuantityAcrossFacilitiesStatus: (state, action) => {
      state.quantityAcrossFacilitiesStatus = action.payload;
    }
  }
});

export const {
  setDetailViewData,
  setFileSelected,
  setUploading,
  setUploadStatus,
  setFacilitiesData,
  setCustomers,
  resetCustomers,
  setTempPassword,
  resetFacilitiesData,
  setIdByFacilitiesData,
  resetFacilitiesDataById,
  setResMsgState,
  setEditState,
  setFormularySearchData,
  setCustomerAssignedFormulary,
  resetCustomerAssignedFormulary,
  setResponseAssignState,
  setListOption,
  setCustomerGroupType,
  setPriceListOption,
  SetQuantityAcrossFacilitiesStatus,
} = customerSlice.actions;

// Customer details  
export const customerDetailsAsync = (customerId, history) => async dispatch => {
  dispatch(setDetailViewData({}))
  try {
    dispatch(setLoading(true));
    const data = await request(`${CUSTOMER}/${customerId}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDetailViewData(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }

    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    if(obj?.response?.status === 405 || obj?.response?.status === 404){
      history.push('/unAuthorized')
      //dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

/**************** */
/* CUSTOMER UPDATE */
/**************** */

export const customerUpdateAsync = (payload, history) => async  (dispatch, getState) => {
  try {
    const state = getState();
    let loginCustomerNumber=state.user?.customerNumber
    dispatch(setLoading(true));
    const data = await request(`${CUSTOMER}/${payload.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(customerDetailsAsync(payload.id))

      dispatch(checkCustomerAsync({ customer: payload.customerNumber }))

      dispatch(setEditState(data.status))
      let payloadCus = {}
      payloadCus['url'] = '/customers'
      payloadCus['method'] = 'GET'
      payloadCus['queryParam'] = { limit: 50, offSet: 0}
      //payloadCus['queryParam'] = { limit: 50, offSet: 0, search:'customerNumber:'+loginCustomerNumber}     
      //dispatch(getCallBackListAsync(payloadCus))
      
      // if (payload.settings.customerStatus === 0) {
      //   history.push('/admin/customers')
      // }
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setError(data.message))
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setError(obj.errorMessage))
    }

  }
  finally {
    dispatch(setLoading(false));
  }
}


// ========================= 


// Upload checker - If someone else is uploading right now?
export const uploadingCheckAsync = (payload) => async dispatch => {
  try {
    const resp = await request(UPLOAD_CHECK_CUSTOMER, {
      method: 'GET',
    });
    /* If someone is uploading, set uploading state to true */
    if (resp && resp.status === ERROR_STATUS) {
      dispatch(setUploading(true))
    }
    /* Else set it as false */
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setUploading(false))
    }
  }
  catch (error) {
    const obj = { ...error }
    if (obj.errorMessage === "Import in progress") {
      dispatch(setUploading(true))
    }
  }
}

// Upload CSV files 
export const uploadFile = ({ payload, signal, getCustomerList }) => async dispatch => {
  const signin_token = storage.getItem(SIGNIN_TOKEN);
  try {
    dispatch(setLoading(true));
    const resp = await fetch(BASE_URL + UPLOAD_CUSTOMER_FILE, {
      method: 'POST',
      headers: new Headers({
        'Authorization': signin_token ? `Bearer ${signin_token}` : '',
        "medline-authorization": signin_token ? signin_token : '',
        'Ocp-Apim-Subscription-Key': API_KEY,
      }),
      body: payload,
      /* Signal for aborting */
      signal: signal
    });
    const data = await resp.json();
    /* If success */
    if (data.status==='success') {
      dispatch(setUploadStatus(data.data))
      getCustomerList();
    }
    if(resp.status===405){
      const res = { status: 'error', message: data.message,accessStatus:405}
      dispatch(setUploadStatus(res))
    }

    if(resp.status===409){
      const res = { status: 'error', message: data.message,statuscode:409}
      dispatch(setUploadStatus(res))
    }
  }
  catch (error) {
    const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      const res = { status: 'error', message: obj.errorMessage }
     // dispatch(setUploadStatus(res)) 
  }
  finally {
    dispatch(setLoading(false));
  }
}

// facility based Aggregate quantity for Billing Tab
export const facilitiesCheckAsyn = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${FACILITY_CHECK_BILLING}/${payload}`, {
      method: 'GET',
    });
    /* If this facility belongs to in a Primary group and those facilities in which any one does have 'Success' Option */
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(SetQuantityAcrossFacilitiesStatus(data.message))
    }
    /* If this facility belongs to in a Primary group and those facilities in which any one does have 'Error' Option */
    if (data && data.status === ERROR_STATUS) {
      dispatch(SetQuantityAcrossFacilitiesStatus(data.message))
    }
  }
  catch (error) {  
    const obj = { ...error }  
    dispatch(setError(obj.errorMessage))
    dispatch(SetQuantityAcrossFacilitiesStatus(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// ========================= 

// create customer
export const createCustomerAsyn = (payload, history) => async (dispatch, getState) => {
  try {

    const state = getState();
    let loginCustomerNumber=state.user?.customerNumber
    dispatch(setLoading(true));
    const data = await request(CUSTOMER, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      const cust_payload={
        url:CUSTOMER,
        queryParam:{ limit: '50', offSet: '0', search:'customerNumber:'},
       // queryParam:{ limit: '50', offSet: '0'},
        method:'GET'
      }
      await dispatch(setResMsgState(data.status))
      await dispatch(getCallBackListAsync(cust_payload))
      await dispatch(setLoading(true));
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}


//ListOption Customer SaleOffice Dropdown
export const listOptionSalesCustomer = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(      
      '/listoptions' + 
      "/" + payload
      , {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setListOption(resp.data.options));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

//Customer Group Dropdown list Based on Sale Office Value
export const listOptionCustomerGroup = (payload) => async dispatch => {
  try {   
    dispatch(setLoading(true));
    const data = await request(`${OPTION_TYPES}/${payload}`, {
      method: 'GET',
    });
   
    if (data && data.status === SUCCESS_STATUS && data.status === 'success') {
      dispatch(setCustomerGroupType(data.data.options))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}


// getCustomersDetails
export const getCallBackListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
  
    if (data && data.status === SUCCESS_STATUS) {
      data.data.customers.map((value) => {
        dispatch(setLoading(true));
        // Combining address line 1 and address line 2
        let streetName = ''
        if (value['address']?.addressLine1 !== null) {
          streetName = `${streetName}' '${value['address'].addressLine1}`
        }
        if (value['address']?.addressLine2 !== null) {
          streetName = `${streetName}' '${value['address']?.addressLine2}`
        }
        //isUserMapped
          value['type']=(value?.isUserMapped===1)||(value?.isCustomerDeleted===1)?"isListDisable":''
          value['strike_through']=value?.isCustomerDeleted&&value?.isCustomerDeleted===1?1:''      
        //customerStatus
        value['streetName'] = value['address'].addressLine1
        //  value['streetName'] = (value['address'].addressLine2 !== null) && value['address'].addressLine2
        value['city'] = value['address'].city
        value['state'] = value['address'].state
        value['zipCode'] = value['address'].zipCode
        value['region'] = value['address'].region
        value['district'] = value['address'].district
        value['country'] = value['address'].country
        value['domain'] = (value['domain']&&Array.isArray(value['domain']))?value['domain'].join(',') :''
        value['customerGroup'] = [CUSTOMER_GROUP_NAME?.map((item) => {
          let customerGroupName;
          if (item.key === value?.customerGroup) {
            customerGroupName = item.value;
          }
          return customerGroupName;
        })]

      })
      dispatch(setCustomers(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
      dispatch(setCustomers({"data":{"customers":[],"metadata":{"total":0,"limit":50,"offset":0,"prev":"","next":""}}}))
      window.location='/unAuthorized';
    }else{
      dispatch(setCustomers(obj))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const checkCustomerAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${CHECK_CUSTOMER_NUMBER}?customerNumber=${payload.customer}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setFacilitiesData(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setFacilitiesData(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}
/*Get Specific facility by id and check available or not*/
export const getFacilityByIdAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${CHECK_CUSTOMER_NUMBER}/${payload.id}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setIdByFacilitiesData(data))
      return data;
    } else if (data && data.status) {

      dispatch(setIdByFacilitiesData(data))
      resetFacilitiesDataById()
      return data;
    }

  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setIdByFacilitiesData(obj))
  }
  finally {
    dispatch(setLoading(false));
  }


}


// delete customer list
export const deleteCustomerListAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    payload.map((value) => {
      dispatch(deleteAsync(DELETE_CUSTOMERS, value.id))
    })
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */

     if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError())
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
//Filter By formulary id & name 
export const getFormularyFilterByNameId = (payload, history) => async dispatch => {
  //const datas={"status":"success","data":{options:[{"key":"1027545","value":"1027545 - fortis",},{"key":"1027546","value":"1027546 - Fortis Des",},{"key":"1027547","value":"1027547 - Fortis Asc",}]},"message":null}

  try {
    dispatch(setLoading(true));
    const data = await request(`${CUSTOMER_SEARCH_FORMULARITY_URL}?search=${payload}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setFormularySearchData(data.data.options))
    }

  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setFormularySearchData(obj))

  }
  finally {
    dispatch(setLoading(false));
  }
}

// create or Assign Formulary 
export const assignFormularyAsyn = (payload, customerId, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    // const data = await request(ASSIGN_FORMULARY_CUSTOMER, {
    const data = await request(constructEndpoint(ASSIGN_FORMULARY_CUSTOMER, customerId), {
      method: 'PUT',
      body: JSON.stringify(payload)
    });

    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setResponseAssignState(data.status))
      dispatch(customerDetailsAsync(customerId))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setResponseAssignState('error'))
    }

  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setResponseAssignState(obj));
    }
    
  }
  finally {
    dispatch(setLoading(false));
  }
}


// get customer Assigned Formulary  list Details
export const getCallBackAssignedFormularyListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  // const datas={"status":"success","data":{"id":123,"formularyName":"WOD2610-Medigrip Elastic Latex Tubular Support Bandages","division":"advancedwoundcare71","productCategoryDesc":"skincare","productCategoryCode":"1027545","productFamilyDesc":"advancedwoundcare71","productFamilyCode":"productfamilycode","materials":[{"division":"Nutrition and Pharmaceuticals(14)","productCategoryDesc":"Skin Care","materialNumber":"MSC09254414","materialName":"Paste, Protectant, ZGuard, Remedy Phyto, 4OZ","title":"MSC09254414 - Paste, Protectant, ZGuard, Remedy Phyto, 4OZ"},{"division":"Nutrition and Pharmaceuticals(15)","productCategoryDesc":"Skin CareTS","materialNumber":"MSC09254415","materialName":"Paste, Protectant, ZGuard, Remedy Phyto, 4OZ1","title":"MSC09254414 - Paste, Protectant, ZGuard, Remedy Phyto, 4OZ1"},{"division":"Nutrition and Pharmaceuticals(14)","productCategoryDesc":"Skin Care DS","materialNumber":"MSC09254416","materialName":"Paste, Protectant, ZGuard, Remedy Phyto, 4OZ2","title":"MSC09254414 - Paste, Protectant, ZGuard, Remedy Phyto, 4OZ2"}],"metadata":{"total":100,"limit":50,"offset":50,},"createdDate":"","createdBy":"Oliva","updatedDate":"","updatedBy":"Oliva"},"message":null}
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCustomerAssignedFormulary(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    //dispatch(setCustomerAssignedFormulary(obj))

  }
  finally {
    dispatch(setLoading(false));
  }
}

//priceListOption Customer Billing Tab
export const getPriceListOption = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const resp = await request(      
      '/listoptions' + 
      "/pricelist" 
      , {
      method: "GET",
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setPriceListOption(resp.data.options));
    }
  } catch (error) {
    const obj = { ...error };
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

export default customerSlice.reducer;
