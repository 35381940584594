import React, { useState, useEffect,useRef } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { VerticalTab } from '../../secondary'
import { CharacteristicCustomForm } from './characteristicCustomForm';
import { CharacteristicProgress } from './characteristicProgress';
import { CharacteristicHistory } from './characteristicHistory';
import { CharacteristicAnatomy } from './characteristicAnatomy';
import { CharacteristicTissue } from './characteristicTissue';
import { CharacteristicTouch } from './characteristicTouch';
import { CharacteristicClassification } from './characteristicClassification';
import { optionTypeAsync, setCharStatus, detailAssessmentAsync, setTissueTypesData, patientAssessmentAsyncOverride,ne1getwoundassessmentcharacteristicsAsync } from '../../../app/patientSlice';
import { getCustomFormAsync } from '../../../app/customFormSlice';
import styles from './characteristic.module.scss'
import { useParams, withRouter } from 'react-router-dom'
import Loader from '../../../static/images/spinner.svg';	

export let Characteristic = ({ location, hideIt }) => {
  const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)
  const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
  const characteristicTabsData = useSelector(state => state.patient.characteristicTabs)
  const[tabListNew, setTabListNew] = useState([])
  const [defaultIndex, setDefaultIndex] = useState(0) 
  const prevDefaultIndex = useRef("");
  const [tinyDelay, setTinyDelay] = useState(false)
  /* Dispatch */
  const dispatch = useDispatch()

  // Getting details about wound assessment
  // Get values from url
  const { patientId, assessmentId } = useParams();
  // Assign it to payload
  const payload = { patientId, assessmentId }

  // This is for re-rendering
  const getCharDetails = () => { 
    // Dispatching get assessment details call
    dispatch(detailAssessmentAsync(payload))
  }

  useEffect(() => {
    setTabListNew([])
    dispatch(detailAssessmentAsync(payload))
  }, [location])

  useEffect(() => {
    setTabListNew([])
    setDefaultIndex(0)
    dispatch(setCharStatus([]))
    prevDefaultIndex.current = 0;
    getCharDetails();
    dispatch(setTissueTypesData({}))
  
  }, [])

  useEffect(() => {
    if (assessmentId) {
      // Dispatching list options
      setTabListNew([])
      setDefaultIndex(0)
      dispatch(setCharStatus([]))
      prevDefaultIndex.current = 0;
     
      dispatch(optionTypeAsync('humananatomy'));
      dispatch(optionTypeAsync('historycause'));
      dispatch(optionTypeAsync('pihistory'));
      dispatch(optionTypeAsync('woundage'));
      dispatch(optionTypeAsync('phototype'));
      dispatch(optionTypeAsync('documentationtype'));
      dispatch(optionTypeAsync('tissuetemperature'));
      dispatch(optionTypeAsync('tissuefirmness'));
      dispatch(optionTypeAsync('painlevel'));
      dispatch(optionTypeAsync('blanchtest'));
      dispatch(optionTypeAsync('exudatetype'));
      dispatch(optionTypeAsync('exudateamount'));
      dispatch(optionTypeAsync('woundedges'));
      dispatch(optionTypeAsync('skincolorarounwound'));
      dispatch(optionTypeAsync('induration'));
      dispatch(optionTypeAsync('edema'));
      dispatch(optionTypeAsync('granulationtissue'));
      dispatch(optionTypeAsync('epithelialization'));
      dispatch(optionTypeAsync('woundprogress'));
      dispatch(optionTypeAsync('periwoundcondition'));
    }
  }, [assessmentId, dispatch])


  // Get values of assessment from state 
  const assessmentDetails = useSelector(state => state.patient.detailAssessment)


  //added wound photo for T&v and Tissue Type
  const [showwound, setShowWound] = useState(false) 

  // get Overrideinfo status from TT
  const tissueOverrideStatus = useSelector(state => state.patient.tissueOverrideStatus)
  useEffect(()=>{
      let payload = { patientId, assessmentId, type: 'tissuetypes', assessmentDetails, getCharDetails} 
      dispatch(patientAssessmentAsyncOverride(payload))
  },[dispatch])

  const[overrideToggle, setOverrideToggle] = useState(false)
  useEffect(()=>{
    if(tissueOverrideStatus===1){
      setOverrideToggle(true)
    }
    else{
      setOverrideToggle(false)
    }
  },[tissueOverrideStatus])
// get Overrideinfo status from TT
  
 useEffect(() => {
  //setDefaultIndex(0)
  if(characteristicTabsData?.length>0){
    const finalTabNew = characteristicTabsData.map((data, i) => {
      if(data?.id===0&&data.category==="History/Cause"){
        return {
          completed: data.isCompleted,
          name: 'History/Cause',
          content: <CharacteristicHistory
            styles={styles}
            patientId={patientId}
            assessmentId={assessmentId}
            assessmentDetails={assessmentDetails}
            getCharDetails={getCharDetails}
            location={location}
            hideIt={hideIt}
            setShowWound={false}
            overrideToggle={overrideToggle}
            isCompleted= {data?.isCompleted}
            characteristicTabsData={characteristicTabsData}
          />
        }
      }
      else if(data?.id===0&&data.category==="Tissue Type"){
        return {
          completed: data.isCompleted,
          name: 'Tissue Types',
          content: <CharacteristicTissue
            styles={styles}
            assessmentDetails={assessmentDetails}
            patientId={patientId}
            assessmentId={assessmentId}
            getCharDetails={getCharDetails}
            hideIt={hideIt}
            setShowWound={false}
            overrideToggle={overrideToggle}
            isCompleted= {data?.isCompleted}
            characteristicTabsData={characteristicTabsData}
           
          />
        }
      }
      else if(data?.id===0&&data.category==="Touch & View Details"){
        return {
          completed: data.isCompleted,
          name: 'Touch & View Details',
          content: <CharacteristicTouch
          styles={styles}
          patientId={patientId}
          assessmentId={assessmentId}
          assessmentDetails={assessmentDetails}
          getCharDetails={getCharDetails}
          updateCharStatus={updateCharStatus}
          hideIt={hideIt}
          setShowWound={setShowWound}
          overrideToggle={overrideToggle}
          isCompleted= {data?.isCompleted}
          characteristicTabsData={characteristicTabsData}
        />
        }
      }
      else if(data?.id!=0){
      return {
        completed: data.isCompleted,
        name: data.category,
        /* Container component for custom form */
        content: <CharacteristicCustomForm name={data.category} formId={data.id} queryParam={{ ne1Id: patientId, assessmentId: assessmentId }} hideIt={hideIt} assessmentDetails={assessmentDetails}  isCompleted= {data?.isCompleted} characteristicTabsData={characteristicTabsData} />
       
      }
    }
    else if(data?.id===0&&data.category==="Classification"){
      return {
        completed: data.isCompleted,
        name: 'Classification',
        content: <CharacteristicClassification
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        hideIt={hideIt}
        setShowWound={setShowWound}
        overrideToggle={overrideToggle}
        isCompleted= {data?.isCompleted}
        characteristicTabsData={characteristicTabsData}
      />
      }
    }
    else if(data?.id===0&&data.category==="Set Progress"){
      return {
        completed: data.isCompleted,
        name: 'Set Progress',
        content: <CharacteristicProgress
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        hideIt={hideIt}
        setShowWound={setShowWound}
        overrideToggle={overrideToggle}
        isCompleted= {data?.isCompleted}
        characteristicTabsData={characteristicTabsData}
      />  
      }
    }
    })
if(finalTabNew){
setTabListNew(finalTabNew)
}

    }
    }, [ne1getWounAssessmentDetailData,characteristicTabsData,assessmentDetails,hideIt])
  // Get Customform
  let formsName = useSelector(state => state.forms.setForm)
  useEffect(() => {
    const queryParam = {
      feature: "NE1 WMS", subFeature: "Characteristics", ne1Id: patientId, assessmentId: assessmentId
    }
    setDefaultIndex(0)
    setTabListNew([])
   // dispatch(getCustomFormAsync(queryParam))
  }, [dispatch])



  const charStatus = useSelector(state => state.patient.charStatus)
 
  var updateCharStatus = () => {
    const temp = [...charStatus]
    if(characteristicTabsData?.length>0){
      let finalChar=characteristicTabsData.map((data, i) => {
     return (data.isCompleted)
      })
      /*let chekAllCom=finalChar.some(e => e === 0)
      if(chekAllCom===false){
        setDefaultIndex(0)
      }*/

      dispatch(setCharStatus(finalChar))
    }
  }

  useEffect(() => {
    updateCharStatus();
  }, [dispatch, characteristicTabsData])

  useEffect(() => {
    if(tabListNew){
     // setDefaultIndex(0)
      for (let i = 0; i < tabListNew.length; i++) {
        if(tabListNew[i].completed === 0){
        setDefaultIndex(i)
           break;
        }
      }
    }
    
  }, [dispatch,assessmentDetails,tabListNew])
 

      
    useEffect(() => {
      setTinyDelay(false)
      prevDefaultIndex.current = defaultIndex;
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    },[defaultIndex]);

  return (
    <div>
      <span className="d-none">{assessmentDetails?.anatomy}</span>
      {((!tinyDelay)||(tabListNew.length===0)||(charStatus.length===0)) ?
    
    <div className={`${styles.main_loader}`}>
    <img src={Loader} alt="loader" />
</div>:
      <>{prevDefaultIndex.current!==defaultIndex&&<VerticalTab tabList={tabListNew} dispatch={dispatch} defaultIndex={defaultIndex} showwound={showwound} setShowWound={setShowWound}/>} 
        {prevDefaultIndex.current===defaultIndex&&<VerticalTab tabList={tabListNew} dispatch={dispatch} defaultIndex={defaultIndex} showwound={showwound} setShowWound={setShowWound}/>}          
        </>
        }
   
        </div>
  )
}

Characteristic = connect()(withRouter(Characteristic));
