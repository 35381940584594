import React, { useEffect, useState } from 'react'
import { format } from "date-fns";
import { AccessErrorModal, Button } from '../../primary'
import { Modal, ActionModal } from '../../secondary'
import { linkAssessmentUpdateAsync, setHistoryCauseFormData, woundAssessmentUpdateAsync, patientAssessmentAsyncOverride, characteristicsImagesAsync,woundAssessmentReEvalAsync,woundClassificationAsync,setWoundAssessmentsAnatomyList,updateAnatomyListCheck,getcauseAsync,gethistoryAsync,ne1updateassessmentHistoryCauseAsync} from '../../../app/patientSlice'
import editIcon from '../../../static/images/edit-icon.png'
import { HistoricalView } from './historicalView'
import { CharacteristicHistoryEdit } from './characteristicHistoryEdit'
import { AnatomyListPopUp } from "./anatomyListPopUp";
import { CloseWarning } from '../../secondary'
import { useForm } from 'react-hook-form'
import { useSelector, shallowEqual } from 'react-redux'
import { allNullCheck, signedLockedAccess ,daysAgo } from '../../../utils'
import Loader from '../../../static/images/spinner.svg';

export function CharacteristicHistory({ index, location, patientId, updateDone, assessmentId, dispatch, selectedIndex, styles, assessmentDetails,
    getCharDetails, hideIt, overrideToggle,isCompleted, characteristicTabsData }) {
        
    const causeData = useSelector((state) => state.patient.causeDataByAssessID);
    const historyData = useSelector((state) => state.patient.historyDataByAssessID);
    const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)
   // ne1getWounAssessmentDetailData?.isCauseDetailDone===1||ne1getWounAssessmentDetailData?.isHistoryDetailDone===1
    const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
    const [assessmentDetailsNew, setAssessmentDetailsNew] = useState('');
   // let assessmentDetailsNew =assessmentDetails
   useEffect(() => {
    if((ne1getWounAssessmentDetailData?.isCauseDetailDone===0||ne1getWounAssessmentDetailData?.isHistoryDetailDone===0)){
        setTimeout(() => {
            if (getUserRole?.role!=='ExternalStandardUser'&&isCompleted===0) {
                handleStartNew()
             }           
        }, 700)
    }
}, [causeData,ne1getWounAssessmentDetailData,isCompleted])
    
    useEffect(() => {
        if(causeData&&historyData){
            let assessmentDetailsChck={...causeData?.cause,...historyData?.history,conditionPOA:historyData?.conditionalPOA,cause:causeData?.cause?.cause?causeData?.cause?.cause:null}
            setAssessmentDetailsNew({'historyCause':assessmentDetailsChck,assessmentStage:assessmentDetails?.assessmentStage})

        }
 
    }, [causeData,historyData])
    const charStatus = useSelector(state => state.patient.charStatus)
    const [anatomyPopUPList, setAnatomyPopUPList] = useState(false);
    const [chckDocNewExtAssess, setChckDocNewExtAssess] = useState(false);
    const [updateExtAssesmentuserData, setUpdateExtAssesmentuserData] = useState('');
    const anatomyModalClose = () => {
        setAnatomyPopUPList(false)
    }
    /* State for start button */
    const [fresh, setFresh] = useState(true)
    // Edit state
    const [editable, setEditable] = useState(false)
    // Handle Save (state for moving from historical view to history cause page)
    const [save, setSave] = useState(false)
    /* State for catgory */
    const [category, setCategory] = useState()
    /* Selected cause */
    const [selectedCause, setSelectedCause] = useState()
    /* Warning state for closing modal */
    const [closeWarning, setCloseWarning] = useState(false)
    /* State to display hide history view */
    const [historyState, setHistoryState] = useState(false)
  
    /* State to handle current or new wound info from history view */
    const [newWound, setNewWound] = useState(false)
    const [currentWound, setCurrentWound] = useState(false)
    /* Current wound id (we get this id when user clicks 'same as current wound') */
    const [currentId, setCurrentId] = useState(null)
    /*Error check HistCause*/
    const [errChkHistCause, setErrChkHistCause] = useState(false)
    // Getting data 
    const historyCauseFormData = useSelector(state => state.patient.historyCauseFormData, shallowEqual);
    const historyCause = useSelector(state => state.patient.historyCause);
    const piHistory = useSelector(state => state.patient.piHistory);
    const woundAge = useSelector(state => state.patient.woundAge);
    const photoType = useSelector(state => state.patient.photoType);
    const documentProvidedBy = useSelector(state => state.patient.documentProvidedBy);
    /* get user role*/
    const getUserRole = useSelector(state => state.user);
    const { register, errors, handleSubmit, watch } = useForm({
        defaultValues: historyCauseFormData
    })

    useEffect(() => {
        dispatch(setHistoryCauseFormData({ cause: '' }))
    }, [location])

    /* Warning functions Yes and no */
    const handleYes = () => {
        setHistoryState(false)
        setEditable(false)
        setCloseWarning(false)
        //setEditable(false)
       // setSave(false)
       // dispatch(setHistoryCauseFormData({ cause: '' }))
    }
    /* No fn */
    const handleNo = () => {
        setCloseWarning(false)
    }
//handleStartNew

    // On click start 
    const handleStart = () => {
       //  setHistoryState(true)
        setEditable(false)
        //setEditable(true)
    }

    // Next screen historical view 
    const handleNext = () => {
       // setHistoryState(true)
        setEditable(false)
    }

    // We should not show 'historical view' when user revisits the history/cause.
    // So I am checking wheather newWound has value or not. If there is value, then I am assuming 
    // user is editing 2nd time. If newWound don't have value, it is first time.

    useEffect(() => {
        if(!newWound && !currentWound){
            if (assessmentDetailsNew?.historyCause?.newWound) {
                setSave(true)
            }else{
                setSave(false)
            }
        }

    }, [assessmentDetailsNew, editable])


    //  call the API for Update the data
    useEffect(()=>{
        dispatch(setWoundAssessmentsAnatomyList({}));
        let payload = { patientId, assessmentId, type: 'tissuetypes'} 
        dispatch(patientAssessmentAsyncOverride(payload))  
        
        dispatch(getcauseAsync(payload))  
        dispatch(gethistoryAsync(payload))  
        const classificationPayload = {
            patientId,
            assessmentId,
            data: {
                woundBedIdentifiable: 0,
                configurationRequest: 1
            }
        }
       // dispatch(woundClassificationAsync(classificationPayload))
    },[dispatch])

    const handleSave = (data, reset) => {       
        setUpdateExtAssesmentuserData('')
        if((category)&&(selectedCause)){
            setErrChkHistCause(false)
        let documentProvidedByPost= data?.documentProvidedBy ? data?.documentProvidedBy : assessmentDetailsNew?.historyCause?.documentProvidedBy?assessmentDetailsNew?.historyCause?.documentProvidedBy.replace(/\s/g, '')?.toLowerCase():assessmentDetailsNew?.historyCause?.documentProvidedBy;
        if(data?.documentProvidedBy){
            if(data?.documentProvidedBy&&data?.documentProvidedBy.replace(/\s/g, '')?.toLowerCase()==='other'&&data?.documentProvidedByOther){
                documentProvidedByPost=data?.documentProvidedByOther
            }
        }
        if(data?.documentProvidedBy===''){
            documentProvidedByPost=''
        }        
        function navigate() {
           
            setSave(false)
            updateDone(index)
            if(assessmentDetailsNew?.historyCause?.newWound==1&&assessmentDetailsNew?.tissueTypes === null){
                setSelectedCause('')
                setCategory('')
                selectedIndex(index + 1)
            }
            else if(Array.isArray(charStatus)&&charStatus[index]===1){
                setEditable(false)
            }
            // else{
            //     setSelectedCause('')
            //     setCategory('')
            //     selectedIndex(index + 1)
               
            // }
        }
        //update history cause options details updateHistoryCauseDetailsOption
        if(data?.otherNamecause){
            let chckExitOtheropt=false
            historyCause && historyCause.length > 0 && historyCause.map((histDataOpt) => {
              if(histDataOpt.key==='other'){
                     return chckExitOtheropt =Array.isArray(histDataOpt.options)&&histDataOpt.options.some((val) => val.key?.toLowerCase() === data?.otherNamecause?.toLowerCase())
              }
            })
            if(!chckExitOtheropt){
                const payloadOptUpdate = {
                    ne1ID:patientId,
                    assessmentId:assessmentId,
                    historyCauseType: "other",
                    historyCauseTypeValue: data?.otherNamecause,
                    KeyUpdated: null 
                    }
            }
        }
     
     let selPhotoType=''
     if(data?.photoType){
        Array.isArray(photoType) && photoType.map((optionVal) => {
            if(optionVal.value===data?.photoType){
                selPhotoType=optionVal.key
            }
    
         });
     }
        // Temp assessment with submitted data
        const tempAssessment = {
            anatomy:assessmentDetails?.anatomy,
            isWoundWithoutPhoto:assessmentDetails?.isWoundWithoutPhoto,
            historyCause: {
                cause: selectedCause ? selectedCause : assessmentDetailsNew?.historyCause?.cause,
                causeCategory: category ? category : assessmentDetailsNew?.historyCause?.causeCategory,
                woundAge: data?.woundAge ? data?.woundAge.replace(/\s/g, '')?.toLowerCase() : null,
                conditionPOA: data?.conditionPOA ? data?.conditionPOA === 'Yes' ? 1 : 0 : assessmentDetailsNew?.historyCause?.conditionPOA,
                photoType: data?.photoType && selPhotoType? selPhotoType :assessmentDetailsNew?.historyCause?.photoType,
                documentProvidedBy: documentProvidedByPost?documentProvidedByPost.replace(/\s/g, '')?.toLowerCase() : null,
                piHistory: pi ? data?.piHistory ? data?.piHistory.replace(/\s/g, '')?.toLowerCase() : null : null,
                newWound: 1
            }
        }
        // Merging our temp assessment with assessment details and dispatching
        const assessment = { ...assessmentDetailsNew, ...tempAssessment }
        const payload = {
            patientId,
            assessmentId,
            assessment,
            navigate,
            getCharDetails,
            reset,
            overrideenable : overrideToggle ? true : false
        }
        // if users clicks 'same as current wound' the below linking API call will get triggerd 
        if (currentWound) {
            const linkPayload = {
                patientId,
                assessmentId,
                currentId
            }
            /* Linking assessment when user clicks 'same as current wound' */
            dispatch(linkAssessmentUpdateAsync(linkPayload))
           
        }
        // Saving history cause data 
        if(ne1getWounAssessmentDetailData?.isCauseDetailDone===0||ne1getWounAssessmentDetailData?.isHistoryDetailDone===0||assessmentDetailsNew?.historyCause?.cause===null){
            setUpdateExtAssesmentuserData(payload)
          dispatch(updateAnatomyListCheck(payload,setAnatomyPopUPList))
        }else{
            //dispatch(woundAssessmentUpdateAsync(payload))
            dispatch(ne1updateassessmentHistoryCauseAsync(payload))
            
        }


        }
        else{
            setErrChkHistCause(true)
        }

    }

    /* Pressure Injury PI */
    const [pi, setPi] = useState()
    /* POA or Non POA */
    const [poa, setPoa] = useState(false)

    /* POA for form handling */
    const [formPoa, setFormPoa] = useState(false)

    /* PDocumentation provided by: other */
    const [otherDocType, setOtherDocType] = useState(false)
    const [otherDocTypeVal, setOtherDocTypeVal] = useState('')
    const [viewDocType, setViewDocType] = useState('')
    const [viewDocTypeSelect, setViewDocTypeSelect] = useState('')

    useEffect(() => {
        let docView=documentProvidedBy && documentProvidedBy.length > 0 && documentProvidedBy.map((data) => {
            if (assessmentDetailsNew?.historyCause?.documentProvidedBy&&assessmentDetailsNew?.historyCause?.documentProvidedBy.replace(/\s/g, '')?.toLowerCase() === data.key) {
                return data.value
            }
        })
        docView = docView && Object.values(docView).filter(function (el) {
            return el != null;
          });
        if(docView){
            let docViews=docView;
            setViewDocType(docViews)
            setViewDocTypeSelect(Array.isArray(docViews)&&docViews.length>0?docViews[0].replace(/\s/g, '')?.toLowerCase():'')
        }
        if(docView.length===0){
            setViewDocType(`Other - ${assessmentDetailsNew?.historyCause?.documentProvidedBy}`)
            if(assessmentDetailsNew?.historyCause?.documentProvidedBy)
            setViewDocTypeSelect('other')
        }
        const chkDfalDoc=Array.isArray(documentProvidedBy)&&documentProvidedBy.some((val) => ((assessmentDetailsNew?.historyCause?.documentProvidedBy)&&(val.key === assessmentDetailsNew?.historyCause?.documentProvidedBy.replace(/\s/g, '')?.toLowerCase())))
        if (assessmentDetailsNew?.historyCause?.documentProvidedBy&&assessmentDetailsNew?.historyCause?.documentProvidedBy.replace(/\s/g, '')?.toLowerCase() === 'other' || !chkDfalDoc) {
            if(assessmentDetailsNew?.historyCause&&assessmentDetailsNew?.historyCause?.documentProvidedBy){
                setOtherDocType(true)
                setOtherDocTypeVal(assessmentDetailsNew?.historyCause?.documentProvidedBy)
            }
        } 
        // Set fresh form 
        if (assessmentDetailsNew?.historyCause?.newWound) {
            setFresh(false)
        } else {
            setFresh(true)
        }
        // Setting PI
        if (assessmentDetailsNew?.historyCause?.causeCategory === 'pi') {
            setPi(true)
        } else {
            setPi(false)
        }
        // Setting POA
        if (assessmentDetailsNew?.woundAcquired === 'POA') {
            setPoa(true)
            setFormPoa(false)
        } else {
            setPoa(false)
            setFormPoa(true)
        }
        if (assessmentDetailsNew?.historyCause?.conditionPOA === 1 && assessmentDetailsNew?.woundAcquired === 'POA') {
            setPoa(false)
        }
        if((getUserRole?.role!=='ExternalStandardUser'&&assessmentDetailsNew?.historyCause?.cause===null)||assessmentDetailsNew?.historyCause?.newWound===0||assessmentDetailsNew?.historyCause?.cause===null){
            //setEditable(true)
         }
    }, [assessmentDetailsNew])
/*new flow*/
const [historyStateNew, setHistoryStateNew] = useState(false)

const patientData = useSelector(state => state.patient.detailViewData)
const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions) // Get wound classification select box options

 //   const historyCause = useSelector(state => state.patient.historyCause);

    const handleStartNew = () => {
        setHistoryState(false)
        setEditable(true)
        setNewWound(true)
        setSave(true)
        setHistoryStateNew(false)
       /* if( sameAnotomy && sameAnotomy.length > 0){
          setHistoryStateNew(true)
          
        }*/
        
    }
    const handleReEval = (e) => {
        e.preventDefault();
        const payload = {
            patientId,
            assessmentId,
        }
        //woundAssessmentReEvalAsync
        dispatch(woundAssessmentReEvalAsync(payload))
        setHistoryStateNew(false)
    }
    const handleNew = () => {
        setHistoryState(false)
        setEditable(true)
        setNewWound(true)
        setSave(true)
        setHistoryStateNew(false)
    }

    useEffect(() => {
    
       
  //assessmentDetailsNew?.historyCause?.newWound
}, [hideIt,fresh ,assessmentDetailsNew])
useEffect(() => {
if(chckDocNewExtAssess){
    setChckDocNewExtAssess(false)
    //dispatch(woundAssessmentUpdateAsync(updateExtAssesmentuserData))
    dispatch(ne1updateassessmentHistoryCauseAsync(updateExtAssesmentuserData))

}
}, [chckDocNewExtAssess])
    return (
        <div className={`${styles.mesurement_blk} pt-1 pb-0`}>
 {anatomyPopUPList&&<AnatomyListPopUp 
      patientId={patientId}
      //history={history}
      setAnatomyPopUPList={setAnatomyPopUPList}
    modalClose={anatomyModalClose} 
      dispatch={dispatch}
      setChckDocNewExtAssess={setChckDocNewExtAssess}
      />}
            <AccessErrorModal />

            {assessmentDetailsNew === null ? 
            
            <div className={`${styles.main_loader}`}>
                <img src={Loader} alt="loader" />
            </div>
            
            :

            <>
                {/* New  */}

                {fresh && !editable && <div>
                    <div>
                        {/* Edit button  */}
                        {signedLockedAccess(assessmentDetailsNew?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={() => setEditable(true)}>
                            <img src={editIcon} alt="edit" />Edit
                        </div>}
                        </div>
                    {/* If there aren't any data saved already, show below with start button */}
                    {/* Display history cause  */}
                    <p>
                    {getUserRole?.role==='ExternalStandardUser'&&ne1getWounAssessmentDetailData?.isHistoryDetailDone===0&&assessmentDetailsNew?.historyCause?.cause===null?
 <><p>History/Cause is not selected for this wound</p></>:<><label>History/Cause:</label></>}
                        <span>
                            {assessmentDetailsNew?.historyCause?.causeCategory!=='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0) {
                                    data.options.map((sub) => {
                                        if (assessmentDetailsNew?.historyCause?.cause&&sub.key=== assessmentDetailsNew?.historyCause?.cause?.toLowerCase()) {
                                            value = `${data.value}-${sub.value}`
                                            value=`${assessmentDetailsNew?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${data.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (assessmentDetailsNew?.historyCause?.cause&&data.key === assessmentDetailsNew?.historyCause?.cause?.toLowerCase()) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                            
                            {assessmentDetailsNew?.historyCause?.causeCategory==='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0 ) {
                                    data?.options?.map((sub) => {
                                        if (sub.key === assessmentDetailsNew?.historyCause?.cause) {
                                            value=`${assessmentDetailsNew?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetailsNew?.historyCause?.cause) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                            
                           {assessmentDetailsNew?.historyCause?.causeCategory==='others'&&`${assessmentDetailsNew?.historyCause?.cause}`}
                        </span>
                    </p>
                    {/* Button to start  */}
                    {/* {hideIt && <p>
                        <Button
                            type="button"
                            value="Start"
                            classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom} ${styles.start_hist}` }}
                            id="startHistroy"
                           // onClick={() => handleStart()} old flow
                            onClick={() => handleStartNew()} //new work flow
                        />
                    </p>} */}

                </div>}
                {/* <>
                <p>
                        <Button
                            type="button"
                            value="New Changes"
                            classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom}` }}
                            id="startHistroyNew"
                            onClick={() => handleStartNew()}
                        />
                    </p></> */}

                {/* Already saved */}


                {!editable ?

                    // Readable field
                    // 'fresh' - if the data is already saved, we just need to display. 
                    !fresh  && <div>
                        {/* Edit button  */}
                        {signedLockedAccess(assessmentDetailsNew?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={() => setEditable(true)}>
                            <img src={editIcon} alt="edit" />Edit
                        </div>}

                        <div>
                            {/* History cause */}
                            <p><label>History/Cause:</label><span>{assessmentDetailsNew?.historyCause?.causeCategory!=='others'&&assessmentDetailsNew?.historyCause?.causeCategory!=='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0) {
                                    data.options.map((sub) => {
                                        if (sub.key === assessmentDetailsNew?.historyCause?.cause?.toLowerCase()) {
                                            value=`${assessmentDetailsNew?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${data.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetailsNew?.historyCause?.cause?.toLowerCase()) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                                
                                
                            {assessmentDetailsNew?.historyCause?.causeCategory==='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0 ) {
                                    data?.options?.map((sub) => {
                                        if (sub.key === assessmentDetailsNew?.historyCause?.cause) {
                                            value=`${assessmentDetailsNew?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetailsNew?.historyCause?.cause) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                            
                             {assessmentDetailsNew?.historyCause?.causeCategory==='others'&&`${assessmentDetailsNew?.historyCause?.cause}`}
                            </span></p>

                            {/* Wound age */}
                            {allNullCheck(assessmentDetailsNew?.historyCause?.woundAge) ?
                                <p><label>Wound Age:</label><span>
                                    {woundAge && woundAge.length > 0 && woundAge.map((data) => {
                                        if (assessmentDetailsNew?.historyCause?.woundAge === data.key) {
                                            return data.value
                                        }
                                    })}
                                </span></p>
                                : ''}


                            {/* If non-POA */}
                            {!poa && <>
                                {/* Condition POA */}
                                <p><label> Condition present on admission?</label><span>{assessmentDetailsNew?.historyCause?.conditionPOA === 1 ? 'Yes' : 'No'}</span></p>


                                {assessmentDetailsNew?.historyCause?.photoType && <>
                                    {/* Photo Type */}
                                    <p><label>Photo Type:</label><span>
                                        {photoType && photoType.length > 0 && photoType.map((data) => {
                                            if (assessmentDetailsNew?.historyCause?.photoType === data.key) {
                                                return data.value
                                            }
                                        })}
                                    </span></p></>}

                                {assessmentDetailsNew?.historyCause?.documentProvidedBy && <>
                                    {/* Dcoument */}
                                    <p><label>Documentation type:</label><span>
                                        {
                                            viewDocType
                                            }
                                    </span></p></>}

                            </>}

                            {/* If PI is true  */}
                            {pi && <div>
                                {/* Does the patient has PI? */}
                                {/* <p><label>Does the Patient has the history of Pressure Injury/Ulcer for the same wound?:</label><span>Yes</span></p> */}
                                {/* PI history */}
                                {allNullCheck(assessmentDetailsNew?.historyCause?.piHistory) ? <p><label>Does the patient has a past history of Pressure Injury/Ulcer for the same wound?</label><span>
                                    {piHistory && piHistory.length > 0 && piHistory.map((data) => {
                                        if (assessmentDetailsNew?.historyCause?.piHistory === data.key) {
                                            return data.value
                                        }
                                    })}
                                </span></p>
                                    :
                                    ""}
                            </div>}

                        </div>
                    </div>

                    :

                    <>

                        {/* On close warning */}
                        {closeWarning && <CloseWarning
                            message="All progress in this section will be lost. Are you sure want to cancel?"
                            yesFn={handleYes}
                            noFn={handleNo}
                        />
                        }
 {getUserRole?.role==='ExternalStandardUser'&&ne1getWounAssessmentDetailData?.isHistoryDetailDone===0?
 <><p>History/Cause is not selected for this wound</p></>:<>
  {/* Edit history/cause component  */}
  <CharacteristicHistoryEdit
                            poa={poa}
                            pi={pi}
                            setPi={setPi}
                            styles={styles}
                            save={save}
                            piHistory={piHistory}
                            setCategory={setCategory}
                            category={category}
                            setSelectedCause={setSelectedCause}
                            selectedCause={selectedCause}
                            historyCause={historyCause}
                            errChkHistCause={errChkHistCause}
                            woundAge={woundAge}
                            photoType={photoType}
                            documentProvidedBy={documentProvidedBy}
                            handleNext={handleNext}
                            handleSave={handleSave}
                            setCloseWarning={setCloseWarning}
                            formPoa={formPoa}
                            //handleFormPoa={handleFormPoa}
                            setFormPoa={setFormPoa}
                            assessmentDetails={assessmentDetailsNew}
                            dispatch={dispatch}
                            register={register}
                            errors={errors}
                            handleSubmit={handleSubmit}
                            watch={watch}
                            historyCauseFormData={historyCauseFormData}
                            hideIt={hideIt}
                            setOtherDocType={setOtherDocType}
                            otherDocType={otherDocType}
                            otherDocTypeVal={otherDocTypeVal}
                            viewDocTypeSelect={viewDocTypeSelect}
                            setViewDocTypeSelect={setViewDocTypeSelect}
                        />
 </>
 }
                       

                    </>

                }

                {/* Local historical view componet */}
                {/* {historyState&&hideIt  && <HistoricalView
                    modalOpen={true}
                    setHistoryState={setHistoryState}
                    setSave={setSave}
                    setNewWound={setNewWound}
                    setCurrentWound={setCurrentWound}
                    setEditable={setEditable}
                    setCurrentId={setCurrentId}
                />} */}
            </>}

        </div>
    )
}
