import React, { useEffect, useState, useRef } from 'react';
import { Tab as TabComponent } from '../../secondary';
import { Button } from '../../primary';
import PropTypes from 'prop-types';
import styles from './detailView.module.scss';
import PatientCard from '../../core/Patients/patientCard';
import { Header } from '../Header';
import { MobileAppRegisterForm } from '../../core/MobileAppRegisterForm/mobileAppRegisterForm';
import { CreateAssessment, CreateRiskAssessment } from '../../core/Patients';
import { setCreateAssessmentData, setDetailAssessment, getRiskAssessmentOptionsAsync, setTemporaryPassword, createAssessMentWithoutPhoto } from '../../../app/patientSlice';
import PlusIcon from '../../../static/images/plus-icon.svg';
import { LayoutsDetail } from '../../core/Layouts/layoutsDetail';
import { ContextualSupport } from '../../core/ContextualSupport/contextualSupport';
import { DISCHARGED, PATIENT_GENDER, FACILITY_HEAD} from '../../../constants';
import { useSelector } from 'react-redux';
import { CreateWoundPhotoAssessment } from '../../core/Patients/createWoundPhotoAssessment';
import debounce from "debounce";

let prevScrollY = 0;

export function DetailView({
    title,
    tabList,
    history,
    defaultIndex,
    handleBack,
    detailsRow,
    subHeading,
    backLink,
    dispatch,
    patientData,
    layouts = false,
    layoutRowData,
    supportView,
    supportViewRowData,
    status,
    customerSettings,
    detailType,
    otherHeading,
    tabRemove
}) {

    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const imagePlusIcon = <img src={PlusIcon} className={`pr-1`} alt="plus" />

    /* New Wound Assessment state */
    const [assessmentModal, setAssessmentModal] = useState(false)

    /* New Risk Assessment state */
    const [riskAssessmentModal, setRiskAssessmentModal] = useState(false)

    /****************************************** */
        /*  DO NOT REMOVE THE BELOW COMMENTED CODE  */
    /******************************************* */

    /* State to check drop down assw */
    //const [viewDrop, setDrop] = useState(false)

    /* Function to toggle drop assessment*/
    // const handleDrop = (e) => {
    //     setDrop(!viewDrop)
    // }

    /* Trigger Drop Down Items close */
    // const dropRef = useRef();

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // });
    // const handleClickOutside = (e) => {
    //     if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
    //         setDrop(false);
    //     }
    // };

    /* New assessment modal */
    /* Trigger opening the modal */
    // const newAssessmentOpen = () => {
    //     setDetailAssessment(null);
    //     /* Set state to true */
    //     setAssessmentModal(true);
    // }
    
    /****************************************** */
        /*  DO NOT REMOVE THE ABOVE COMMENTED CODE  */
    /******************************************* */

    /* Register mobile app modal */
    const [showModal, setShowModal] = useState(false)
    const [checkTempPass, setCheckTempPass] = useState(false)
    const modalOpen = () => {
        setShowModal(true);
        setCheckTempPass(true)
    }
    const modalClose = () => {
        setShowModal(false);
        setCheckTempPass(false)
    }

    /* For closing the modal */
    const newAssessmentClose = () => {
        /* Set state to false */
        setAssessmentModal(false);
        dispatch(setCreateAssessmentData("reset"))
    }

    /* New Risk assessment modal open */
    const newRiskAssessmentOpen = () => {
        setRiskAssessmentModal(true);
    }
    /* For closing the Risk modal */
    const newRiskAssessmentClose = () => {
        setRiskAssessmentModal(false);
        // dispatch(setCreateRiskAssessmentData("reset"))
    }
    //customerName

   const checkDetails = useSelector(state => state.user.userDetails);
    const checkRiskAssessmentScale = (checkDetails && checkDetails.customerSettings &&
      checkDetails.customerSettings.riskAssessmentScale ? checkDetails.customerSettings.riskAssessmentScale : 'norton')
    
    useEffect(() => {

        if (detailType==='Patients') {
            dispatch(setCreateAssessmentData('reset'));
           /* if (checkRiskAssessmentScale && checkRiskAssessmentScale === 'norton') {
                dispatch(getRiskAssessmentOptionsAsync('nortonphysicalcondition'));
                dispatch(getRiskAssessmentOptionsAsync('nortonmentalcondition'));
                dispatch(getRiskAssessmentOptionsAsync('nortonactivity'));
                dispatch(getRiskAssessmentOptionsAsync('nortonmobility'));
                dispatch(getRiskAssessmentOptionsAsync('nortonincontinence'));
            }
            else {
                dispatch(getRiskAssessmentOptionsAsync('bradensensoryperception'));
                dispatch(getRiskAssessmentOptionsAsync('bradenmoisture'));
                dispatch(getRiskAssessmentOptionsAsync('bradenactivity'));
                dispatch(getRiskAssessmentOptionsAsync('bradenmobility'));
                dispatch(getRiskAssessmentOptionsAsync('bradennutrition'));
                dispatch(getRiskAssessmentOptionsAsync('bradenfrictionshear'));
            }*/

        }
    }, [detailType, dispatch])
    
    //witout wound creation
    const [createWoundPhotoWithoutModal, setCreateWoundPhotoWithoutModal] = useState(false);
    const callAssessMentWithoutPhoto = () => {
        let payload={
            "uuId":null,
            "picture":null,
            "isWoundWithoutPhoto":1 
        }
        dispatch(setCreateAssessmentData(payload));
        setCreateWoundPhotoWithoutModal(true)
       /* let payload={
            "cause": "poormobilitypressure",
            "causeCategory": "pi",
            "notes":"",
            "anatomy": "armupperrightanterior",
            "uuid":null,
            "picture":null,
            "isWoundWithoutPhoto":1 
        }
        dispatch(createAssessMentWithoutPhoto(patientData?.ne1Id,payload,history))*/

    }

    const [goingUp, setGoingUp] = useState(false);
    const [scrollDiv, setScrollDiv] = useState(0)

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (prevScrollY < currentScrollY && goingUp) {
      debounce(() => {
        setGoingUp(false);
      }, 1000);
    }
    if (prevScrollY > currentScrollY && !goingUp) {
      debounce(() => {
        setGoingUp(true);
      }, 1000);
    }
    prevScrollY = currentScrollY;
    setScrollDiv(currentScrollY)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [divHeight, setDivHeight] = useState(false)  
  useEffect(()=>{   
    if(scrollDiv > 20){
        setDivHeight(true)
    }
    else{
        setDivHeight(false)
    }
  })


    return (
        <div className=''>
            {/* Tabs */}
            <div className={`${styles.back_btn}`}>
                <Header
                    heading={isAdminView ? 'Admin' : ''}
                    subHeading={isAdminView ? subHeading : ''}
                    detailsRow={detailsRow}
                    handleBack={handleBack}
                    backLink={isAdminView ? backLink : '/patients'}
                    
                />
            </div>
            {/* Patient header only on Patient Management Screen */}
            {subHeading==="Patient Management" &&                
                <div className={`col-12 px-0 ${styles.patient_details_blk} pb-3`} style={{position:'sticky', top:'0',zIndex:'999'}}>
                    <PatientCard modalOpen={modalOpen} divHeight={divHeight} history={history} dispatch={dispatch} />
                </div>
            }
            {/* Patient header only on wound assessment and wound profile pages */}
            <div className='col-12 px-0' style={{position:'sticky', top:'0',zIndex:'999'}}>
                {!isAdminView &&<PatientCard modalOpen={modalOpen} divHeight={divHeight} history={history} dispatch={dispatch} />}
            </div>
            
            <div className={`col-12 ${styles.wound_address} d-flex flex-row-reverse`}>
                {/* Register Patient for Mobile and New Assessment */}
                {
                    !isAdminView &&
                    [
                    // <PatientCard modalOpen={modalOpen}/>,
                    <div className={`pt-3  ${styles.ipad_wound}`}>
                        
                        <div className="text-center">
                            <div className="d-inline mr-2">
                                {patientData && patientData?.skipPhoto===1&&patientData?.moreInfo?.ne1WMSStatus === 1 && patientData?.statusInfo?.status !== 'discharged' && <Button
                                    type={"button"}
                                    value={"Wound assessment without photo"}
                                    classes={{ className: `btn ${styles.btn_custom} ${styles.btn_mobile}` }}
                                  
                                    onClick={() => callAssessMentWithoutPhoto()}
                                />}
                                 {createWoundPhotoWithoutModal && <CreateWoundPhotoAssessment modalClose={setCreateWoundPhotoWithoutModal} reEval={false} assessmentId={''} currentWoundPhoto={false} existingAssessment={false} patientId={patientData?.ne1Id} isWoundWithoutPhoto={1} />}
                            </div>
                            {status && status !== DISCHARGED && <div className="d-inline">
                                <div className="btn-group">
                                    {patientData?.moreInfo?.ne1WMSStatus === 1 && <Button
                                        type={"button"}
                                        classes={{ className: `btn ${styles.btn_new_risk}` }}
                                        id="newRiskAssess"
                                        onClick={() => newRiskAssessmentOpen()}
                                    >
                                        Risk assessment
                                    </Button>}
                                    {/****************************************** */
                                     /*  DO NOT REMOVE THE BELOW COMMENTED CODE  */
                                    /******************************************* */}
                                    {/* <Button
                                        type={"button"}
                                        classes={{ className: `btn ${styles.btn_add}` }}
                                        id="assessment"
                                        onClick={handleDrop}
                                        ref={dropRef}
                                    >
                                        {imagePlusIcon}
                                        New Assessment
                                    </Button> */}
                                    {/*viewDrop &&
                                        <div className={`dropdown-menu dropdown-menu-right show pb-0 ${styles.dropdown_select_align}`} ref={dropRef} >
                                            {/*<p className={`mb-0 ${styles.dropdown_heading2}`}>Choose Assessment</p>
                                            <div className="dropdown-divider mb-0"></div>*/}
                                            {/*<p className={`dropdown-item mb-0 ${styles.p_link}`} onClick={() => newAssessmentOpen()}>Wound Assessment</p>
                                            <p className={`dropdown-item mb-0 ${styles.p_link}`} id={'newRiskAssess'} onClick={() => newRiskAssessmentOpen()} >Risk Assessment</p>
                                        </div>
                                    */}
                                    {/****************************************** */
                                     /*  DO NOT REMOVE THE ABOVE COMMENTED CODE  */
                                    /******************************************* */}
                                </div>
                            </div>}
                        </div>
                    </div>
                    ]
                }
            </div>
            {
                !layouts && !supportView && <TabComponent tabList={tabList} defaultIndex={defaultIndex} tabRemove={tabRemove}/>
            }
            {showModal&&<MobileAppRegisterForm modalOpen={showModal} modalClose={modalClose} detailsRow={detailsRow} checkTempPass={checkTempPass} setCheckTempPass={setCheckTempPass} />}
            {/* If assessment is true, display create assessment modal  */}
            {assessmentModal && <CreateAssessment modalClose={newAssessmentClose} />}
            {
                layouts && <LayoutsDetail layoutRowData={layoutRowData} history={history} />
            }
            {
                supportView && <ContextualSupport supportViewRowData={supportViewRowData} />
            }
            {/* Create Risk Assessment modal */}
            {riskAssessmentModal && <CreateRiskAssessment  checkRiskAssessmentScale={checkRiskAssessmentScale}  modalClose={newRiskAssessmentClose} dispatch={dispatch}/>}
            
        </div>
    )
}

DetailView.propTypes = {
    title: PropTypes.string,
    tabList: PropTypes.array,
    history: PropTypes.object,
    defaultIndex: PropTypes.number,
    handleBack: PropTypes.func,
    layouts: PropTypes.bool,
    layoutRowData: PropTypes.object,
    supportView: PropTypes.bool,
    otherHeading : PropTypes.bool
}
