  import React, { useEffect, useRef, useState, useCallback } from "react";
  import PropTypes from "prop-types";
  import { connect, useSelector, useDispatch } from "react-redux";
  import { withRouter } from "react-router";
  import {getQualityAssuranceOverViewPages, postOverviewDownloadCsvReports, setQualityReportPayload} from "../../../app/woundReportSlice";
  import { DataTable, Pagination } from "../../secondary";
  import { Header } from "../../secondary/Header";
  import styles from "./overviewRowData.module.scss";
  import { DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT_OVERVIEW, LIST_VIEW_LIMIT } from '../../../constants';

  import Spreadsheet from "../../../static/images/ExportSpredsheet.svg";
  import { CSVLink } from "react-csv";
  import Loader from '../../../static/images/spinner.svg';	
  import { format } from "date-fns";
  
  export const PendingPhotos = ({ history }) => {

    const dispatch = useDispatch();      
    
    const qualityReportPayload = useSelector((state) => state.woundreport.qualityReportPayload)

    //when onload payload passing to API
    useEffect(() => {
      const listPayLoad = {};
      listPayLoad["url"] = `${DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT_OVERVIEW}/pendingphotos`;
      listPayLoad["method"] = "Post";
      listPayLoad["payload"] = qualityReportPayload;
      listPayLoad["queryParam"] = { offSet: 0, limit: LIST_VIEW_LIMIT };
      if (
          qualityReportPayload && qualityReportPayload.facility && qualityReportPayload.facility.length > 0
      ) {
        dispatch(getQualityAssuranceOverViewPages(listPayLoad));
      }
    }, []);     
    
    // For scrolling the page to top
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []) 
    
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [sortType, setSortType] = useState('')   
  
  //Getting data from Clicked rows in Overview table through the API
  const qaOverViewPagesData = useSelector((state) => state.woundreport.qaOverViewPagesData);

  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(()=>{
    let getloadValue = qaOverViewPagesData?.woundAssessment !== undefined ? true : false
    if(getloadValue){
      setTimeout(()=>{
        setTinyDelay(true)
      }, 1000)    
    }    
   })
  
  const columns = [
      {
        Header: " Wound photo",
        accessor: "woundImage",
        imageData: "picture",
        restWidth: "110px",
        disableSortBy: true,
      },
      {
        Header: "Wound number",
        accessor: "woundNumber",
        restWidth: "150px",
      },
      {
        Header: "MRN #",
        accessor: "mrn",
        restWidth: "100px",
      },
      {
        Header: "Patient\'s name",
        accessor: "patientName",
        restWidth: "180px",
      },
      {
        Header: "Photo taken by",
        accessor: "photoTakenBy",
        restWidth: "180px",
      },
      {
        Header: "Photo taken on",
        accessor: "photoTakenOn",
        restWidth: "200px",
      },
      {
        Header: "History/Cause",
        accessor: "historyCause",
        restWidth: "200px",
      },
      {
        Header: "Anatomy",
        accessor: "anatomy",
        restWidth: "200px",
      },
      {
        Header: "Last assessed",
        accessor: "lastAccessed",
        restWidth: "200px",
      },
      {
        Header: "Admission date",
        accessor: "admissionDate",
        restWidth: "200px",
      },
      {
        Header: "Signed by",
        accessor: "signedBy",
        restWidth: "200px",
      },
      {
        Header: "Reviewed by",
        accessor: "reviewedBy",
        restWidth: "200px",
      },
    ];
  
  
    //redirect to patient-> assessments page 
    const getSelectedRowData = (data) => {
      if (data) {
        history.push(
          `/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`
        );
      }
    };
  
    const deleteDataList = () => {};
  
    const selectRowCount = () => {};  
  
    //sorting API call
    const handleSort = useCallback(
      (data) => {
        if (data && data.sortBy[0] && data.sortBy[0].id) {
          const woundAssessmentPayload = {
            ...qualityReportPayload,
            section: qualityReportPayload?.section,
            subsection: qualityReportPayload?.subsection,
          };
          const sortingColumn = data.sortBy[0];
          const sortingType = sortingColumn
            ? sortingColumn.desc
              ? ":DESC"
              : ":ASC"
            : "";
          let sortParam = sortingColumn.id + sortingType;
          const listPayLoad = {};
          listPayLoad["url"] = `${DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT_OVERVIEW}/pendingphotos`;
          listPayLoad["method"] = "Post";
          listPayLoad["payload"] = woundAssessmentPayload;
          listPayLoad["queryParam"] = {
            offSet: isNaN(offSet)===true?0:offSet,
            limit: LIST_VIEW_LIMIT,
            sort: sortParam,
          };
          setSortType(sortParam)
          if (
            woundAssessmentPayload &&
            woundAssessmentPayload.facility &&
            woundAssessmentPayload.facility.length > 0
          ) {
            dispatch(getQualityAssuranceOverViewPages(listPayLoad));
          }
        }
      },
      [dispatch, qualityReportPayload]
    );
  
    //active Page API Call
    const getActivePage = (data) => {
      setActivePage(data);
      const woundAssessmentPayload = {
        ...qualityReportPayload,
        section: qualityReportPayload?.section,
        subsection: qualityReportPayload?.subsection,
      };
      const listPayLoad = {};
      listPayLoad["url"] = `${DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT_OVERVIEW}/pendingphotos`;
      listPayLoad["method"] = "Post";
      listPayLoad["payload"] = woundAssessmentPayload;
      listPayLoad["queryParam"] = {
        limit: LIST_VIEW_LIMIT,
        offSet:
          data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT < LIST_VIEW_LIMIT
            ? 0
            : data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT,
            sort: (sortType)?sortType:''
      };
      setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
      if (
        woundAssessmentPayload &&
        woundAssessmentPayload.facility &&
        woundAssessmentPayload.facility.length > 0
      ) {
        dispatch(getQualityAssuranceOverViewPages(listPayLoad));
      }
    };

    const downloadDate = format(new Date(), "MM-dd-yyyy");

    const [csvHeader, setCsvHeader] = useState([
      { label: "Wound number", key: "woundNumber" },
      { label: "MRN #", key: "mrn" },
      { label: "Patient\'s name", key: "patientName" },
      { label: "Photo taken by", key: "photoTakenBy" },
      { label: "Photo taken on", key: "photoTakenOn" },
      { label: "History/Cause", key: "historyCause" },
      { label: "Anatomy", key: "anatomy" },
      { label: "Admission date", key: "admissionDate" },
      { label: "Signed by", key: "signedBy" },
      { label: "Reviewed by", key: "reviewedBy" },
      { label: "Last assessed", key: "lastAccessed" },
    ]);

    const overviewDownloadCsvfile = useSelector((state) => state.woundreport.overviewDownloadCsvfile)   
    
    return (
      <>
          <div className={`${styles.back_btn}`}>
              <Header 
                  // heading="Admin Portal"
                  // subHeading="Pending Assessments"  
                  reportHeading={"Pending Photos"} 
                  backLink={'/qualityReport'} 
                  handleBack={() => history.goBack()} 
              />
          </div>
          {!tinyDelay ?    
          <div className={`${styles.loader} col-12`}>
            <img src={Loader} alt="loading" />
          </div>:
          <div className="col-12">
          {qaOverViewPagesData && Array.isArray(qaOverViewPagesData?.woundAssessment) && (
            <>
              <div className="col-sm-12 mb-2 justify-content-end d-flex">
                <span className={styles.down_spread}>
                  <img src={Spreadsheet} alt="" />
                  <label>
                    <CSVLink                      
                      headers={csvHeader}
                      data={
                        overviewDownloadCsvfile &&
                        Array.isArray(overviewDownloadCsvfile?.reports)
                          ? overviewDownloadCsvfile?.reports
                          : []
                      }
                      filename={`NE1-QA-Pending-Photos-Report-${downloadDate}.csv`}
                      target="_blank"
                    >
                      Download
                    </CSVLink>
                  </label>
                </span>
              </div>

              <div className="col-12 px-0">
                <DataTable
                    columns={columns}
                    data={
                      qaOverViewPagesData &&
                      Array.isArray(qaOverViewPagesData?.woundAssessment)
                        ? qaOverViewPagesData?.woundAssessment
                        : []
                    }
                    sorting={true}
                    getSelectedRowData={getSelectedRowData}
                    setSelectedRows={selectRowCount}
                    onSort={handleSort}
                    detailView={true}
                    deleteDataList={deleteDataList}
                    showFixedColumn={false}
                    workListView={true}
                    reportView={true}
                    sortType={(sortType)?sortType:''}
                  />
              </div>
            </>
          )}
          {Array.isArray(qaOverViewPagesData.woundAssessment) &&
            qaOverViewPagesData.woundAssessment.length > 1 && (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={
                  qaOverViewPagesData?.metadata
                    ? qaOverViewPagesData?.metadata?.limit
                    : 50
                }
                totalItemsCount={
                  qaOverViewPagesData?.metadata
                    ? qaOverViewPagesData?.metadata?.total
                    : 0
                }
                getActivePage={getActivePage}
                reportView={true}
              />
          )}
        </div>}
      </>
    );
  }//NOSONAR
  
  PendingPhotos.propTypes = {
      dispatch: PropTypes.func,
      history: PropTypes.object,
  };