import { Service } from '../service';
import { createSlice } from '@reduxjs/toolkit';
import crypto from "crypto";
import {
  AUTHENTICATE, USER_DETAIL, SIGNIN_TOKEN, FORGOT_PASSWORD, REGISTRATION, RESETPASSWORD, USER_ACTIVATION, SUCCESS_STATUS,
  RESEND_USER_ACTIVATION,
  OTP_CODE,
  USER,
  ERROR_STATUS,
  APP_DOWNLOAD_INVITE,
  CHECK_CUSTOMER_NUMBER, DELETE_USERS, UPLOAD_USER_FILE, ROLES,
  NOTIFICATION,
  DELETE_NOTIFICATION,
  NOTIFICATION_MARK_AS_READ, NOTIFICATION_MARK_AS_UNREAD, REGISTER_TEMP_PASSWORD, UPDATE_PASSWORD, UPDATE_PROFILE_UPDATE, BASE_URL, API_KEY,
  OPTION_TYPES,
  CUSTOMER,
  REG_PRIRIMARY_CUSTOMER,
  REG_USER_WITH_FACILITY,
  REG_ORG_TYPE,
  SSO_CLIENT_ID,
  OAUTH_HOST_URL,
  OAUTH_TOKEN_URL,
  DELETE_SSO ,//sso
  VERIFYUSEREMAIL,
  UPLOAD_CHECK_USER
} from '../constants';
import { SimplePersistence } from '../utils';
import { setError, setLoading, deleteAsync, setAccessError } from './appSlice';
import { constructEndpoint } from '../utils/commonHelper'
import format from 'date-fns/format';
import { browserName, osName, osVersion  } from 'react-device-detect';
const { request } = Service;

const storage = new SimplePersistence();

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isSignedIn: false,
    username: '',
    registrationFormData: {},
    activationDetails: {
      email: '',
      token: ''
    },
    users: {},
    ssoUser:{},//sso
    userFacilities: {},
    customerNumberSearch : {},
    emailSearch: {},//sso
    customerFacilities: {},
    detailViewData: {},
    detailSsoViewData: {},//ssp
    appdownloadinvite: {},
    forgotPasswordinvite:{},
    uploading: false, // Someone else is uploading?
    uploadStatus: null,
    userRole: {},
    resMsgState: '',
    editState: false,
    notificationData: {},
    temporaryPassword: '',
    updatePassword: {},
    userProfileUpdates: {},
    userDetails: {},
    loginUser: {},
    loginUserId: {},
    roleListoption: [],
    customerName: null,
    userLoading : false,
    passUpdateState:'',
    verificationLinkErr:false,
    VerificationOtpCode : {},
    errorOtpMsg: '',
    resendEmailMsg:{},    
    primaryCustomerFacility:[],
    organizationType:{},
    zipFacilitySearchDatas:[],
    locationByCountry:{},
    regResMatchCustomerNumZip:{},
    regResCheckAllFlag:{},
    sessionTimeOutData:{},
    sessionTempTime:'',
    sSOAuthData:{},
    isSSOLoading:{},
    patientByCustomerData:{},
    notificationCount:{}
  },
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isSignedIn = true;
      state.username = action.payload;
    },
    logout: state => {
      state.isSignedIn = false;
      state.username = '';
      storage.removeItem(SIGNIN_TOKEN);
    },
    setRegistrationFormData: (state, action) => {
      state.registrationFormData = action.payload;
    },
    setActivationDetails: (state, action) => {
      state.activationDetails = {
        email: action.payload.email,
        token: action.payload.token,
        code: action.payload.code
      }
    },
    setUserDetail: (state, action) => {
      return {
        ...state, ...action.payload
      }
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSsoUser: (state, action) => {
      state.ssoUser = action.payload;
    },
    resetUsers: (state, action) => {
      state.users = {}
    },
    setUserFacilities: (state, action) => {
      state.userFacilities = action.payload
    },
    setCustomerNumberSearch : (state, action) => {
      state.customerNumberSearch = action.payload
    },
    setEmailSearch : (state, action) => { //sso
      state.emailSearch = action.payload
    },    
    setCustomerFacilities: (state, action) => {
      state.customerFacilities = action.payload
    },
    resetFacilitiesUser: (state, action) => {
      state.userFacilities = action.payload
    },
    setDetailViewData: (state, action) => {
      state.detailViewData = action.payload
    },
    setDetailSsoViewData: (state, action) => { //sso
      state.detailSsoViewData = action.payload
    },    
    setAppdownloadinvite: (state, action) => {
      state.appdownloadinvite = state
    },
    setForgotPasswordinvite: (state, action) => {
      state.forgotPasswordinvite = state
    },
     /* Reducer for "someone else is uploading?" */
     setUploading: (state, action) => {
      state.uploading = action.payload
    },
     /* CSV file upload success or failed */
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload
    },
    setResMsgState: (state, action) => {
      state.resMsgState = action.payload;
    },
    setEditState: (state, action) => {
      state.editState = action.payload;
    },
    setNotificationData: (state, action) => {
      state.notificationData = action.payload
    },
    setTemporaryPassword: (state, action) => {
      state.temporaryPassword = action.payload;
    },
    setUpdatePassword: (state, action) => {
      state.updatePassword = action.payload;
    },
    setUserProfileUpdates: (state, action) => {
      state.userProfileUpdates = action.payload;
    },
    setuserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setloginUser: (state, action) => {
      state.loginUser = action.payload;
    },
    setLoginUserId: (state, action) => {
      state.loginUserId = action.payload;
    },
    // roleListoption
    setroleListoption: (state, action) => {
      state.roleListoption = action.payload;
    },
    setUserLoading  :(state, action) => {
      state.userLoading = action.payload;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload
    },setPassUpdateState: (state, action) => {
      state.passUpdateState = action.payload;
    },
    setVerificationLinkErr: (state, action) => {
      state.verificationLinkErr = action.payload;
    },
    setVerificationOtpCode: (state, action) => {
      state.VerificationOtpCode = action.payload;
    },
    setErrorOtpMsg: (state, action) => {
      state.errorOtpMsg = action.payload;
    },
    setResendEmailMsg: (state, action) => {
      state.resendEmailMsg = action.payload;
    },
    setPrimaryCustomerFacility: (state, action) => {
      state.primaryCustomerFacility = action.payload;
    },
    setyOrganizationType: (state, action) => {
      state.organizationType = action.payload;
    },    
     setZipFacilitySearchDatas: (state, action) => {
      state.zipFacilitySearchDatas = action.payload;
    }, 
    setLocationByCountry: (state, action) => {
      state.locationByCountry = action.payload;
    }, 
    setRegResMatchCustomerNumZip: (state, action) => {
      state.regResMatchCustomerNumZip = action.payload;
    }, 
    setRegResCheckAllFlag: (state, action) => {
      state.regResCheckAllFlag = action.payload;
    },
    setSessionTimeOutData: (state, action) => {
      state.sessionTimeOutData = action.payload;
    },
    setSessionTempTime: (state, action) => {
      state.sessionTempTime = action.payload;
    },
    setSSOAuthData: (state, action) => {
      state.sSOAuthData = action.payload;
    },
    //isSSOLoading
    setIsSSOLoading: (state, action) => {
      state.isSSOLoading = action.payload;
    },
    setPatientByCustomerData: (state, action) => {
      state.patientByCustomerData = action.payload
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload
    },
  }
});

export const {
  login,
  setUserDetail,
  logout,
  forgotPassword,
  setRegistrationFormData,
  setActivationDetails,
  setUsers,
  setSsoUser,
  resetUsers,
  setUserFacilities,
  setCustomerNumberSearch,
  setEmailSearch, //sso
  setCustomerFacilities,
  resetFacilitiesUser,
  setDetailViewData,
  setDetailSsoViewData,//sso
  setAppdownloadinvite,
  setForgotPasswordinvite,
  setUploading,
  setUploadStatus,
  setUserRole,
  setResMsgState,
  setEditState,
  setNotificationData,
  setTemporaryPassword,
  setUpdatePassword,
  setUserProfileUpdates,
  setuserDetails,
  setloginUser,
  setLoginUserId,
  setroleListoption,
  setUserLoading,
  setCustomerName,
  setPassUpdateState,
  setVerificationLinkErr,
  setVerificationOtpCode,
  setErrorOtpMsg,
  setResendEmailMsg,
  setPrimaryCustomerFacility,
  setyOrganizationType,
  setZipFacilitySearchDatas,
  setLocationByCountry,
  setRegResMatchCustomerNumZip,
  setRegResCheckAllFlag,
  setSessionTimeOutData,
  setSessionTempTime,
  setSSOAuthData,
  setIsSSOLoading,
  setPatientByCustomerData,
  setNotificationCount
} = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(loginAsync(data))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const loginAsync = (payload, history, localIP) => async dispatch => {
  try {
    dispatch(setUserLoading(true));
    dispatch(setLoading(true));
    let resp = await request(AUTHENTICATE, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email.toLowerCase(),
        password: payload.password,
        sourcename:'Web',
        osversion:osName&&osVersion?osName+' '+osVersion:'',
        ipaddress:localIP?localIP:'',
        macaddress: "",
        skinappversion:"",
        location:"",
        devicename:""
      })
    });
    /* Set token in local storage */
    if (resp && resp.data && resp.status === SUCCESS_STATUS) {
      
      if(resp?.data?.isPatient === 1){
        dispatch(setError("User is not authorized"))
      }else{
        storage.setItem(SIGNIN_TOKEN, resp.data.token);
        dispatch(login(payload.email));
        dispatch(setSessionTimeOutData(''))
        dispatch(setSessionTempTime(''))
        dispatch(getSessionTimeOutAsyn(payload.email.toLowerCase()));
        dispatch(setLoginUserId(resp));
        if (resp.data.isTemporaryPassword === 0) {
          history.push('/')
          resp = await request(USER_DETAIL);
          dispatch(setloginUser(resp));
          if (resp && resp.data && resp.status === SUCCESS_STATUS) {
            dispatch(setUserDetail(resp.data));
          }
        } else if (resp.data.isTemporaryPassword === 1) {
          history.push('/updatepassword');
        }else if (resp.data.isEmailVerified === 0) {
           history.push('/status/registrationsuccess',{'dynParam':{email:payload.email.toLowerCase()}});
        } 
      }
         
    }
  }
  catch (error) {
    const obj = { ...error }
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    if(errCk?.data&&errCk?.data?.isPasswordConfigured===0){
      history.push('/createpassword',{'dynParam':{email:payload.email,acct_status:'isPasswordConfigured'}});
    }
    else if(errCk?.data&&errCk?.data?.isCustomerActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payload.email,acct_status:'customerInActive'}});
    }
    else if(errCk?.data&&errCk?.data?.isEmailVerified===0){
      history.push('/status/registrationsuccess',{'dynParam':{email:payload.email}});
    }
   else if(errCk?.data&&errCk?.data?.isUserActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payload.email,acct_status:'userInActive'}});
    }
    else{
      dispatch(setError(obj.errorMessage))
    }
  } finally {
    dispatch(setUserLoading(false));
    dispatch(setLoading(false));
  }
};

//SSO User login
export const createSSOenableUser = (payloadSSO, history) => async dispatch => {
  try {
    dispatch(setUserLoading(true));
    dispatch(setLoading(true));
    let resp = await request('/createssoenableuser', {
      method: 'POST',
      body: JSON.stringify({
        email: payloadSSO.email,
        clinicalRepoUserAuthInfo: payloadSSO?.clinicalRepoUserAuthInfo
      })
    });
    /* Set token in local storage */
    if (resp && resp.data && resp.status === SUCCESS_STATUS) {
      if(resp?.data?.isPatient === 1){
        dispatch(setError("User is not authorized"))
      }else{
        storage.setItem(SIGNIN_TOKEN, resp.data.token);
        dispatch(login(payloadSSO.email));
        dispatch(setSessionTimeOutData(''))
        dispatch(setSessionTempTime(''))
        dispatch(getSessionTimeOutAsyn(payloadSSO.email));
        dispatch(setLoginUserId(resp));
        if (resp.data.isTemporaryPassword === 0) {
          history.push('/')
          resp = await request(USER_DETAIL);
          dispatch(setloginUser(resp));
          if (resp && resp.data && resp.status === SUCCESS_STATUS) {
            dispatch(setUserDetail(resp.data));
          }
        } else if (resp.data.isTemporaryPassword === 1) {
          history.push('/updatepassword');
        }else if (resp.data.isEmailVerified === 0) {
           history.push('/status/registrationsuccess',{'dynParam':{email:payloadSSO.email}});
        } 
      }
         
    }
  }
  catch (error) {
    const obj = { ...error }
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    if(errCk?.data&&errCk?.data?.isCustomerActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payloadSSO.email,acct_status:'customerInActive'}});
    }
    else if(errCk?.data&&errCk?.data?.isEmailVerified===0){
      history.push('/status/registrationsuccess',{'dynParam':{email:payloadSSO.email}});
    }
   else if(errCk?.data&&errCk?.data?.isUserActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payloadSSO.email,acct_status:'userInActive'}});
    }
    else{
      dispatch(setError(obj.errorMessage))
    }
  } finally {
    dispatch(setUserLoading(false));
    dispatch(setLoading(false));
  }
};
// getSSOTokaen
export const getSSOTokaen = ({ payload , history}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    // const formData = new FormData();
  /*const formData = new URLSearchParams();
  formData.append('grant_type', payload?.grant_type);
  formData.append('code',  payload?.code);
  formData.append('redirect_uri', payload?.redirect_uri);
  formData.append('code_verifier', payload?.code_verifier);
  formData.append('client_id', SSO_CLIENT_ID);
  console.log(' payload?.code_verifier', payload?.code_verifier)*/
  //return false; createssoenableuser OAUTH_TOKEN_URL
   /* const data = await fetch(`${'/createssoenableuser'}`, {
     // mode: 'no-cors',
      method: "POST",
     /* headers: new Headers({
       'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: formData,
        body: JSON.stringify({
        email: payload?.email,
        code: payload?.code,
        verifier: payload?.code_verifier
      })    
    });*/
    /*console.log('resp',JSON.stringify({
      email: payload?.email,
      code: payload?.code,
      verifier: payload?.code_verifier
    }))
    return false*/
    const resp = await request('/createssoenableuser', {
      method: 'POST',
      body: JSON.stringify({
        email: payload?.email,
        code: payload?.code,
        verifier: payload?.code_verifier
      })
    });
    //const resp = await data.json();
    /* If success */
    if (resp) {
      //data
      if (resp && resp.data && resp.status === SUCCESS_STATUS) {
        if(document?.querySelector("body")){
          document.querySelector("body").style.display = "block";
        }
        if(resp?.data?.isPatient === 1){
          dispatch(setError("User is not authorized"))
        }else{
          storage.setItem(SIGNIN_TOKEN, resp.data.token);
          
          dispatch(login(payload.email));
          dispatch(setSessionTimeOutData(''))
          dispatch(setSessionTempTime(''))
          dispatch(getSessionTimeOutAsyn(payload.email));
          dispatch(setLoginUserId(resp));
          if (resp?.data?.isTemporaryPassword === 0) {
             // window.location = '/worklist'
             
           let respss = await request(USER_DETAIL);
            dispatch(setloginUser(resp));
            
            if (respss && respss?.data && respss.status === SUCCESS_STATUS) {
              dispatch(setUserDetail(respss.data));
            }
            history.push('/')
          } else if (resp?.data?.isTemporaryPassword === 1) {
            history.push('/updatepassword');
          }else if (resp?.data.isEmailVerified === 0) {
             history.push('/status/registrationsuccess',{'dynParam':{email:payload.email}});
          } 
        }
      }
     // dispatch(createSSOenableUser(payloadSSO, history))
    }
  } catch (error) {
    if(document?.querySelector("body")){
      document.querySelector("body").style.display = "block";
    }
    const obj = { ...error }
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    if(errCk?.data&&errCk?.data?.isCustomerActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payload.email,acct_status:'customerInActive'}});
    }
    else if(errCk?.data&&errCk?.data?.isEmailVerified===0){
      history.push('/status/registrationsuccess',{'dynParam':{email:payload.email}});
    }
   else if(errCk?.data&&errCk?.data?.isUserActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payload.email,acct_status:'userInActive'}});
    }
    else{
      dispatch(setError(obj.errorMessage))
    }
  } finally {
    dispatch(setUserLoading(false));
    dispatch(setLoading(false));
  }
};

//LoginEmailCheck
function getRanCode(){

  function base64URLEncode(str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
  }
  let verifier = base64URLEncode(crypto.randomBytes(48));
  let challenge=''
  if(verifier){
     challenge = base64URLEncode(sha256(verifier));
  }
  
  function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
  }

  return {challenge:challenge,verifier:verifier}

}
export const loginEmailAsync = (payload, history,setIsEmailValid) => async dispatch => {

  try {
    //SSO code ignore or commended for october release
  /*if(payload?.email==='photon@lanternsandbox.onmicrosoft.com'){
    const oauthHost = OAUTH_HOST_URL; //Should be pulled from your server (stored as config in your database)
    const clientId = SSO_CLIENT_ID  //This value comes from the oauth console for your application.  It should be pulled from your server (stored as config in your database)
    //let redirectUri =`${window.location.origin}/status/ssosuccess?email=${payload.email}`; //This can be hard-coded and must match the intent filter in your activity
    const  redirectUri=encodeURIComponent(`${window.location.origin}/login`)
    //let code_challenge="sha256-Xj7O0zUcSSSgumEShX6kqyjYSm510v2xE+EL2bbKW9E="
    const state="dcbfe1eb"
    const ranData= getRanCode()
    let storeSSOData={...ranData,email:payload?.email}
    dispatch(setSSOAuthData(storeSSOData))
    const code_challenge=ranData?.challenge
    const code_challenge_method="S256"
    const payload_email=payload?.email
    const renderQueryURL =`${oauthHost}?response_type=${'code'}&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&email=${payload_email}&state=${state}`
     //window.location = renderQueryURL
     dispatch(setIsSSOLoading({renderQueryURL:renderQueryURL}))
  }*/
//else{
    dispatch(setUserLoading(true));
    dispatch(setLoading(true));
    //verifyuseremail version
    let resp = await request(VERIFYUSEREMAIL, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email?.toLowerCase(),
        //password: payload.password
      })
    });
   /* resp ={
      "status": "success",
      "data": {
          "isSSOUser": 1,
          //"redirect_ur":"https://clinrepodev.medline.com/"
          "redirect_ur":renderQueryURL
      },
      "message": ""
  }*/
    /* Set token in local storage */
    if (resp && resp.data && resp.status === SUCCESS_STATUS) {
      if (resp.data?.isSSOEnableForDomain === 1) {
        const oauthHost = OAUTH_HOST_URL; //Should be pulled from your server (stored as config in your database)
        const clientId = SSO_CLIENT_ID  //This value comes from the oauth console for your application.  It should be pulled from your server (stored as config in your database)
        //let redirectUri =`${window.location.origin}/status/ssosuccess?email=${payload.email}`; //This can be hard-coded and must match the intent filter in your activity
        const  redirectUri=encodeURIComponent(`${window.location.origin}/login`)
        //let code_challenge="sha256-Xj7O0zUcSSSgumEShX6kqyjYSm510v2xE+EL2bbKW9E="
        const state="dcbfe1eb"
        const ranData= getRanCode()
        let storeSSOData={...ranData,email:payload?.email}
        dispatch(setSSOAuthData(storeSSOData))
        const code_challenge=ranData?.challenge
        const code_challenge_method="S256"
        const payload_email=payload?.email.toLowerCase()
        const renderQueryURL =`${oauthHost}?response_type=${'code'}&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&email=${payload_email}&state=${state}`
        dispatch(setIsSSOLoading({renderQueryURL:renderQueryURL}))
        // window.location = renderQueryURL
      }
      else if (resp.data?.isValidRegisteredUser === 0) {
        history.push('/registration',{'dynParam':{email:payload.email}}) 
      } 
      else if (resp.data.isValidRegisteredUser === 1) {
        if(resp.data?.isPasswordConfigured===0){
          history.push('/createpassword',{'dynParam':{email:payload.email,acct_status:'isPasswordConfigured'}});
        } 
       else if(setIsEmailValid&&resp.data?.isPasswordConfigured===1){
        setIsEmailValid(true) //Go to password page
       }
      }
        
    }
  //}
  }
  catch (error) {
    const obj = { ...error }
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
   // dispatch(setError(obj.errorMessage))
   /* if(errCk?.data&&errCk?.data?.isCustomerActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payload.email,acct_status:'customerInActive'}});
    }*/
    if (errCk?.data&&errCk?.data?.isSSOEnableForDomain === 1) {
      const oauthHost = OAUTH_HOST_URL; //Should be pulled from your server (stored as config in your database)
      const clientId = SSO_CLIENT_ID  //This value comes from the oauth console for your application.  It should be pulled from your server (stored as config in your database)
      //let redirectUri =`${window.location.origin}/status/ssosuccess?email=${payload.email}`; //This can be hard-coded and must match the intent filter in your activity
      const  redirectUri=encodeURIComponent(`${window.location.origin}/login`)
      //let code_challenge="sha256-Xj7O0zUcSSSgumEShX6kqyjYSm510v2xE+EL2bbKW9E="
      const state="dcbfe1eb"
      const ranData= getRanCode()
      let storeSSOData={...ranData,email:payload?.email}
      dispatch(setSSOAuthData(storeSSOData))
      const code_challenge=ranData?.challenge
      const code_challenge_method="S256"
      const payload_email=payload?.email.toLowerCase()
      const renderQueryURL =`${oauthHost}?response_type=${'code'}&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&email=${payload_email}&state=${state}`
      dispatch(setIsSSOLoading({renderQueryURL:renderQueryURL}))
      // window.location = renderQueryURL
    }
   else if(errCk?.data&&errCk?.data?.isEmailVerified===0){
      history.push('/status/registrationsuccess',{'dynParam':{email:payload.email}});
    }
   else if(errCk?.data&&errCk?.data?.isUserActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:payload.email,acct_status:'userInActive'}});
    }
    else{
      dispatch(setError(obj.errorMessage))
    }
  } finally {
    dispatch(setUserLoading(false));
    dispatch(setLoading(false));
  }
};
export const registerAsync = (payload, history, reset) => async dispatch => {
  try {
    dispatch(setLoading(true));
    let dynPayload;
    let regLink=REGISTRATION;
    if(payload.customerNumber){
      dynPayload={
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        customerNumber: payload.customerNumber,
        password: payload.confirmPassword,
        userType: 'MedlineAdministrator',
        isPrivacyPolicyAgreed: 1,
        isTermsAndConditionAgreed: 1,
        /* Send user activation page URL also */
        userActivationURL: `${window.location.origin}/useractivation`,
        isDomainNameMatchWithMultipleCustomerNumbers:payload?.isDomainNameMatchWithMultipleCustomerNumbers?Number(payload?.isDomainNameMatchWithMultipleCustomerNumbers):0,
        isCustomerNumberMatchWithExcludedCustomerNumbers:payload?.isCustomerNumberMatchWithExcludedCustomerNumbers?Number(payload?.isCustomerNumberMatchWithExcludedCustomerNumbers):0,
        isDomainMatchWithExcludedCustomerNumbers:payload?.isDomainMatchWithExcludedCustomerNumbers?Number(payload?.isDomainMatchWithExcludedCustomerNumbers):0,
        isValidZipCodeWithNoCustomerNumber:payload?.isValidZipCodeWithNoCustomerNumber?Number(payload?.isValidZipCodeWithNoCustomerNumber):0,
        isAllPrimaryGroupNumberFacilitiesAssign:payload?.isAllPrimaryGroupNumberFacilitiesAssign?Number(payload?.isAllPrimaryGroupNumberFacilitiesAssign):0,
        iDonotHaveMedlineAccountNumber:payload?.iDonotHaveMedlineAccountNumber?Number(payload?.iDonotHaveMedlineAccountNumber):0,
        primaryCustomerNumber:payload?.primaryCustomerNumber?payload?.primaryCustomerNumber:''
      }
    }else{
      regLink=REG_USER_WITH_FACILITY;

      dynPayload={
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        password: payload.confirmPassword,
        userType: 'MedlineAdministrator',
        facilityName:payload.facilityName,
        facilityAddress:payload.facilityAddress,
        facilityCity:payload.facilityCity,
        facilityState:payload.facilityState,
        facilityZipCode:payload.facilityZipCode,
        organizationType:payload.organizationType,
        isPrivacyPolicyAgreed: 1,
        isTermsAndConditionAgreed: 1,
        /* Send user activation page URL also */
        userActivationURL: `${window.location.origin}/useractivation`,
        facilityCountry:payload.country,
        isDomainNameMatchWithMultipleCustomerNumbers:payload?.isDomainNameMatchWithMultipleCustomerNumbers?Number(payload?.isDomainNameMatchWithMultipleCustomerNumbers):0,
        isCustomerNumberMatchWithExcludedCustomerNumbers:payload?.isCustomerNumberMatchWithExcludedCustomerNumbers?Number(payload?.isCustomerNumberMatchWithExcludedCustomerNumbers):0,
        isDomainMatchWithExcludedCustomerNumbers:payload?.isDomainMatchWithExcludedCustomerNumbers?Number(payload?.isDomainMatchWithExcludedCustomerNumbers):0,
        isValidZipCodeWithNoCustomerNumber:payload?.isValidZipCodeWithNoCustomerNumber?Number(payload?.isValidZipCodeWithNoCustomerNumber):0,
        isAllPrimaryGroupNumberFacilitiesAssign:payload?.isAllPrimaryGroupNumberFacilitiesAssign?Number(payload?.isAllPrimaryGroupNumberFacilitiesAssign):0,
        iDonotHaveMedlineAccountNumber:payload?.iDonotHaveMedlineAccountNumber?Number(payload?.iDonotHaveMedlineAccountNumber):0,
       
      }
    }
    const data = await request(regLink, {
      method: 'POST',
      body: JSON.stringify(dynPayload)
    });
    /* If no error, dispatch action to reset the form and redirect to registration success page */
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setRegistrationFormData({}))
      reset({});
     // history.push('/status/registrationsuccess');
      history.push('/status/registrationsuccess',{'dynParam':{email:payload.email}});
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
   
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const userActivationAsync = (email, token, history) => async dispatch => {
  try {
    dispatch(setVerificationLinkErr(''))
    dispatch(setLoading(true));
    const data = await request(USER_ACTIVATION, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        token: token?token:''
      })
    });
    if (data && data.status === SUCCESS_STATUS) {
      history.push('/status/activationsuccess');
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    const payload = { email, token }
    /* Store email and token in a state */
    //dispatch(setActivationDetails(payload))
    dispatch(setVerificationLinkErr(obj.errorMessage))
    
    /* Link expried error page */
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    if(errCk?.data&&errCk?.data?.isCustomerActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:email,acct_status:'customerInActive'}});
    }

   else if(errCk?.data&&errCk?.data?.isUserActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:email,acct_status:'userInActive'}});
    }else{
      history.push('/status/verificationlinkexpired',{'dynParam':{email:email}});
    }
      /*if(errCk?.data&&errCk?.data?.isCustomerActive===0 || errCk?.data&&errCk?.data?.isUserActive===0){
      history.push('/status/accountstatus',{'dynParam':{email:email,acct_status:errCk?.data}});
      }
      else if(errCk?.data&&errCk?.data?.isEmailVerified===0){
      history.push('/status/verificationlinkexpired',{'dynParam':{email:email}});
      }*/    
  }
  finally {
    dispatch(setLoading(false));
  }
};

//User re activation
export const resendUserActivationAsync = (email, token, history,page) => async dispatch => {
  try {
    dispatch(setLoading(true));
    dispatch(setVerificationLinkErr(''))
    const data = await request(RESEND_USER_ACTIVATION, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        token: token?token:'',
        userReActivationURL: `${window.location.origin}/useractivation`
      })
    });
    /* If success, clear local state and forward to registration success page */
    if (data && data.status === SUCCESS_STATUS) {
      const payload = { email: '', token: '' }
      /* Set activation details state empty */
      dispatch(setActivationDetails(payload))
      if(page&&page==='expired'){
        dispatch(setVerificationLinkErr('Email sent, please check inbox.'))
        history.push('/status/verificationlinkexpired',{'dynParam':{email:email}});
      }else{
        history.push('/status/registrationsuccess',{'dynParam':{email:email}});
      }
      //history.push('/status/registrationsuccess');
    }
    dispatch(setResendEmailMsg(data))
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setResendEmailMsg({"status":"error","data":null,"message":obj.errorMessage}))
    dispatch(setVerificationLinkErr(obj.errorMessage))
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    /*if(obj.errorMessage==='Account is already activated, Please login'){
      history.push('/')
    }*/
    /* Link expried error message */
  }
  finally {
    dispatch(setLoading(false));
  }
};

// User Otp activation
export const resendOtpCodeAsync = (email, code, history) => async dispatch => {
  try {
    dispatch(setErrorOtpMsg(''));
    dispatch(setLoading(true));
    dispatch(setVerificationOtpCode(''))
    const data = await request(OTP_CODE, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        code: code?code:'',
        userReActivationURL: `${window.location.origin}/useractivation`
      })
    });
    /* If success, clear local state and forward to registration success page */
    if (data && data.status === SUCCESS_STATUS) {
      const payload = { email: '', code: '' }
      //history.push('/');
      history.push('/status/activationsuccess');
    }
   
  }
  catch (error) {
    const obj = { ...error }    
    dispatch(setErrorOtpMsg(obj.errorMessage))  
  }
  finally {
    dispatch(setLoading(false));
  }
};
// User Otp activation
export const resendOtpCodeAsyncForgetPass = (email, code, history) => async dispatch => {
  try {
    dispatch(setErrorOtpMsg(''));
    dispatch(setLoading(true));
    dispatch(setVerificationOtpCode(''))   
    const data = await request('/verifyresetpasswordcode', {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        code: code?code:'',
        userReActivationURL: `${window.location.origin}/useractivation`
      })
    });
    /* If success, clear local state and forward to registration success page */
    if (data && data.status === SUCCESS_STATUS) {
      history.push('/resetpassword?username='+`${email}`+'&token='+`${data?data?.data?.resetpasswordtoken:''}`);
    }
   
  }
  catch (error) {
    const obj = { ...error }    
    dispatch(setErrorOtpMsg(obj.errorMessage))  
  }
  finally {
    dispatch(setLoading(false));
  }
};
//resend email for forget password
export const resendForgetPassAsync = (email, token, history,page) => async dispatch => {
  try {
    dispatch(setLoading(true));
    dispatch(setVerificationLinkErr(''))
    const data = await request(FORGOT_PASSWORD, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        browser : browserName,
        oS : osName,
        userPasswordResetURL: `${window.location.origin}/resetpassword`,
        isResendRequest: 0
      })
    });
    /* If success, clear local state and forward to registration success page */
    if (data && data.status === SUCCESS_STATUS) {
      const payload = { email: '', token: '' }
      dispatch(setVerificationLinkErr(data?.message))
      history.push('/status/forgotpasswordsuccess',{'dynParam':{email:email}});
     // history.push('/status/forgotpasswordsuccess',{'dynParam':{email:payload.email}});
      /* Set activation details state empty */
      /*if(page&&page==='expired'){
        dispatch(setVerificationLinkErr('Email sent, please check inbox.'))
        history.push('/status/verificationlinkexpired',{'dynParam':{email:email}});
      }else{
        history.push('/status/registrationsuccess',{'dynParam':{email:email}});
      }*/
    }
    dispatch(setResendEmailMsg(data))
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setResendEmailMsg({"status":"error","data":null,"message":obj.errorMessage}))
    dispatch(setVerificationLinkErr(obj.errorMessage))
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    /*if(obj.errorMessage==='Account is already activated, Please login'){
      history.push('/')
    }*/
    /* Link expried error message */
  }
  finally {
    dispatch(setLoading(false));
  }
};
//User setings user sendre activation
export const resendUserSettingsActivationAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(RESEND_USER_ACTIVATION, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email,
        token: payload?.token?payload.token:'',
        userReActivationURL: `${window.location.origin}/useractivation`
      })
    });
    /* If success, clear local state and forward to registration success page */
    if (data && data.status === SUCCESS_STATUS) {
        dispatch(userDetailsAsync(payload.id))
    }

  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  }
};
// Forgot password 
export const forgotPasswordAsync = (payload, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(FORGOT_PASSWORD, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email,
        browser : payload.browser,
        oS : payload.oS,
        /* Send reset password URL also */
        userPasswordResetURL: `${window.location.origin}/resetpassword`,
        isResendRequest: 0
      })
    });
    if (data && data.status === SUCCESS_STATUS) {
     // history.push('/status/forgotpasswordsuccess');
      history.push('/status/forgotpasswordsuccess',{'dynParam':{email:payload.email}});
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  } finally {
    dispatch(setLoading(false));
  }
}

//Reset Password
export const resetPasswordAsync = (email, token, payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(RESETPASSWORD, {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
        password: payload.confirmPassword,
        token: token
      })
    });
    if (data && data.status === SUCCESS_STATUS) {
      history.push('/status/resetpasswordsuccess');
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  } finally {
    dispatch(setLoading(false));
  }
}
//Create Password updatessouserpassword
export const updatesSSOuserPasswordAsync = (email, payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request('/updatessouserpassword', {
      method: 'POST',
      body: JSON.stringify({
        useremail: email.toLowerCase()?.toLowerCase(),
        password: payload.confirmPassword,
      })
    });
    if (data && data.status === SUCCESS_STATUS) {
      history.push('/status/createpasswordsuccess');
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  } finally {
    dispatch(setLoading(false));
  }
}

/* Temporaray password */

export const temporaryPasswordAsync = (payload) => async dispatch => {
  try {
    dispatch(setError(''))
    dispatch(setLoading(true));
    const resp = await request(REGISTER_TEMP_PASSWORD, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setTemporaryPassword(resp.data.password))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// create Users
export const createUserAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(USER, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setResMsgState(data.status))
      let user_payload = {
        url: '/users',
        queryParam: { limit: '50', offSet: '0' },
        method: 'GET'
      }
      dispatch(getCallBackListAsync(user_payload))
    }
  }
  catch (error) {
    const obj = { ...error }
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
    }
    else if (obj?.response?.status === 409) {
      dispatch(setResMsgState(obj))
    }
    else {
      dispatch(setResMsgState(obj.errorMessage))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// getUsersDetails
export const getCallBackListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  dispatch(setEditState(false))
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.users.map((value)=>{
        value['lastLogin'] = (value.lastLogin) ? format(new Date(value.lastLogin), 'MMMM dd, yyyy h:mm aa') : '';
        value['strike_through']=value?.isUserDeleted&&value?.isUserDeleted===1?1:''
        value['type']=value?.isUserDeleted===1?"isListDisable":''
        //value['userStatus']=value?.userStatus&&value?.userStatus===1?'Active':'Inactive'
        value['facility'] = (value['facility']&&Array.isArray(value['facility']))?value['facility'].join(`, `) :''
      })
      dispatch(setUsers(data))
      dispatch(setEditState(false))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
      dispatch(setUsers({ "data": { "users": [], "metadata": { "total": 0, "limit": 50, "offset": 0, "prev": "", "next": "" } } }))
      window.location='/unAuthorized';
    } else {
      dispatch(setUsers(obj))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}


// create Sso
export const createSsoAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request('/createssotenantmapping', {
      method: 'POST',
      body: JSON.stringify(payload)
    });    
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setResMsgState(data.status))
      let user_payload = {
        url: '/getssotenantmappinglist',
        queryParam: { limit: '50', offSet: '0' },
        method: 'GET'
      }
      dispatch(getSsoCallBackListAsync(user_payload))
    }
  }
  catch (error) {
    const obj = { ...error }
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
    }
    else if (obj?.response?.status === 409) {
      dispatch(setResMsgState(obj))
    }
    else {
      dispatch(setResMsgState(obj.errorMessage))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// getSsoUsersListDetails
export const getSsoCallBackListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  dispatch(setEditState(false))
  try {
    dispatch(setLoading(true));    
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });  
    if (data && data.status === SUCCESS_STATUS) {
      data.data.ssoTenantMappingList.map((value)=>{
        value['isActive'] = value?.isActive===1 ? "Yes" : "No";
        value['isSSOEnable'] = value?.isSSOEnable=== 1 ? "Yes" : "No";
        value['isTokenizedFacility'] = value?.isTokenizedFacility=== 1 ? "Yes" : "No";
        value['assignToAllEmails'] = value?.assignToAllEmails===1 ? "Yes" : "No";
        value['department'] = value?.department !== null ? value?.department?.charAt(0)?.toUpperCase() + value?.department?.slice(1)?.toLowerCase() : "";
        value['title'] = value?.title !== null ? value?.title?.charAt(0)?.toUpperCase() + value?.title?.slice(1)?.toLowerCase() : "";
        value['primaryGroup'] = (value?.primaryGroup !== ""||value?.primaryGroup !== null) ? value?.primaryGroup?.charAt(0)?.toUpperCase() + value?.primaryGroup?.slice(1)?.toLowerCase() : "";
        value['assignToSpecificEmail'] = (value?.excludeToSpecificEmail === null) ? value?.assignToSpecificEmail : value?.excludeToSpecificEmail;
        value['strike_through']=value?.isDeleted&&value?.isDeleted===1?1:''
        value['dateCreated'] = (value?.dateCreated) ? format(new Date(value.dateCreated), 'MMMM dd, yyyy h:mm aa') : '';
        value['dateUpdated']= (value?.dateUpdated) ? format(new Date(value.dateUpdated), 'MMMM dd, yyyy h:mm aa') : '';
        value['facilities'] = (value['facilities']&&Array.isArray(value['facilities']))?value['facilities'].join(`, `) :''
        value['excludeToSpecificEmail'] = value?.excludeToSpecificEmail === null ? "Include" : "Exclude";
        //value['userStatus']=value?.userStatus&&value?.userStatus===1?'Active':'Inactive'
        // value['facility'] = (value['facility']&&Array.isArray(value['facility']))?value['facility'].join(`, `) :''
      })
      dispatch(setSsoUser(data))
      dispatch(setEditState(false))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
      dispatch(setSsoUser({ "data": { "ssoTenantMappingList": [], "metadata": { "total": 0, "limit": 50, "offset": 0, "prev": "", "next": "" } } }))
      window.location='/unAuthorized';
    } else {
      dispatch(setSsoUser(obj))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}



// Sso details by Id  
export const ssoDetailsAsync = (payload, history, isTravelNurse) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/getssotenantmapping/${payload}`, {
      method: 'GET',
    });

    if (data && data.status === SUCCESS_STATUS) {      
      // let dataGett = data?.data?.sSODomainTenantMappingInfo;
      let dataMerge = data?.data?.ssoTenantInfo;
      // let dataMerge = data?.data?.ssoTenantInfo;
      // dataMerge.facilities = [];     
      // dataMerge?.customer?.map((item)=>{
      //     return (item?.facilities?.map((list)=>{dataMerge.facilities.push(list.facilityNumber)}))
      // })
      dispatch(setDetailSsoViewData(dataMerge))
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    if(obj?.response?.status === 405 || obj?.response?.status === 404){
      history.push('/unAuthorized')
      //dispatch(setAccessError(obj.errorMessage))
    }else {
      dispatch(setError(obj.errorMessage))
    }

  }
  finally {
    dispatch(setLoading(false));
  }
}

export const ssoUpdateAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/updatessotenantmapping`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(ssoDetailsAsync(payload?.ssoTenantMappingId))
      dispatch(setEditState(data.status))
      dispatch(setDetailSsoViewData(data?.data))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    dispatch(setEditState('error'))
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
    } else {
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}


// delete sso list
export const deleteSsoListAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    payload.map((value) => {
      dispatch(deleteAsync(DELETE_SSO, value?.ssoTenantMappingId))
    })
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//SSO Email Search Suggestions
export const getEmailSuggestions = (payload, setCheckOrgDrop) => async (dispatch, getState) => {
  const state = getState();
  // dispatch(setUserFacilities([]))
  try {
    dispatch(setLoading(true));     
    // const data = await request(`/getemailsforsso?email=`, {
    //   method: 'GET',
    //   body: JSON.stringify({payload})
    // });
    const data = await request(`/getemailsforsso?email=${payload}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setEmailSearch(data.data))
      if(setCheckOrgDrop){
        setCheckOrgDrop(false)
      }      
    }
  }
  
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setEmailSearch(obj))
  }
  finally {
    dispatch(setLoading(false));
  } 
}





// Raja
export const getFaciltiestUserAsyn = (payload, setCheckOrgDrop, setErrorMsg) => async (dispatch, getState) => {
  const state = getState();
  // dispatch(setUserFacilities([]))
  try {
    dispatch(setLoading(true));     
    const data = await request(`${CHECK_CUSTOMER_NUMBER}?customerNumber=${payload.customer}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setUserFacilities(data))
      if(setCheckOrgDrop){
        setCheckOrgDrop(false)
      }
      let customerId = null;
      data?.data?.facilities && data?.data?.facilities?.length > 0 && data?.data?.facilities?.map((item) => {//NOSONAR
        if(item.facilityNumber === state.user.customerNumber){
          customerId = item.id
        }
      })//NOSONAR
      if(customerId!==null){
        dispatch(customerNameById(customerId))
      }
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setUserFacilities(obj))
    if(setErrorMsg){
      setErrorMsg(obj.errorMessage)
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

//Customer Number Search Suggestions
export const getCustomerNumberSuggestions = (payload, setCheckOrgDrop) => async (dispatch, getState) => {
  const state = getState();
  // dispatch(setUserFacilities([]))
  try {
    dispatch(setLoading(true));     
    const data = await request(`/customerbykeyword`, {
      method: 'POST',
      body: JSON.stringify({customerNumber:payload,salesOfficeType:''})
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCustomerNumberSearch(data.data))
      if(setCheckOrgDrop){
        setCheckOrgDrop(false)
      }      
    }
  }
  
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setCustomerNumberSearch(obj))
  }
  finally {
    dispatch(setLoading(false));
  } 
}

// Customer number

export const customerNameById = (customerId, history) => async (dispatch, getState) => {
  const state = getState();
  dispatch(setPatientByCustomerData({}))
  try {
    dispatch(setLoading(true));
    const data = await request(`${CUSTOMER}/${customerId}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCustomerName(data.data.customerName))
      dispatch(setPatientByCustomerData(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if(obj?.response?.status !== 405){
      dispatch(setError(obj.errorMessage))    
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const getFaciltiestCustomerAsyn = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${CHECK_CUSTOMER_NUMBER}?customerNumber=${payload.customer}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCustomerFacilities(data))
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setCustomerFacilities(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// User details by Id  
export const userDetailsAsync = (payload, history, isTravelNurse) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/usernurse/${payload}`, {
      method: 'GET',
    });

    if (data && data.status === SUCCESS_STATUS) {
      let dataMerge = data.data;
      dataMerge.facilities = [];     
      dataMerge?.customer?.map((item)=>{
          return (item?.facilities?.map((list)=>{dataMerge.facilities.push(list.facilityNumber)}))
      })
      dispatch(setDetailViewData(dataMerge))
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    if(obj?.response?.status === 405 || obj?.response?.status === 404){
      history.push('/unAuthorized')
      //dispatch(setAccessError(obj.errorMessage))
    }else {
      dispatch(setError(obj.errorMessage))
    }

  }
  finally {
    dispatch(setLoading(false));
  }
}

export const userUpdateAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${USER}/${payload.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(userDetailsAsync(payload.id))
      dispatch(setEditState(data.status))
      dispatch(setDetailViewData(data.data))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    dispatch(setEditState('error'))
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
    } else {
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const userUpdateLockAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/user/${payload.id}`, {
      method: 'PATCH',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(userDetailsAsync(payload.id))
      dispatch(setEditState(data.status))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    dispatch(setEditState('error'))
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
    } else {
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
//mobile app invite
export const mobileAppInviteAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setError(''))
    dispatch(setLoading(true));
    const data = await request(APP_DOWNLOAD_INVITE, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setAppdownloadinvite(data))

    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
// ========================= 

// Forgot password invite from user settings
export const forgotPasswordInviteAsync = (payload) => async (dispatch) => {
  try {
    dispatch(setError(''))
    dispatch(setLoading(true));
    const data = await request(FORGOT_PASSWORD, {
      method: 'POST',
      body: JSON.stringify({
        id: payload.id,
        email: payload.email,
        userPasswordResetURL: `${window.location.origin}/resetpassword`,
        isResendRequest: 0
      })
    });
    //dispatch(setForgotPasswordinvite(data))
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setForgotPasswordinvite(data))
    }
    if (data && data.status === 'error') {
      dispatch(setForgotPasswordinvite(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  } finally {
    dispatch(setLoading(false));
  }
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectUserName = state => state.user.username;

// delete user list
export const deleteUserListAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    payload.map((value) => {
      dispatch(deleteAsync(DELETE_USERS, value.id))
    })
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Upload checker - If someone else is uploading right now?
export const uploadingCheckAsync = (payload) => async dispatch => {
  try {
    const resp = await request(UPLOAD_CHECK_USER, {
      method: 'GET',
    });
    /* If someone is uploading, set uploading state to true */
    if (resp && resp.status === ERROR_STATUS) {
      dispatch(setUploading(true))
    }
    /* Else set it as false */
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setUploading(false))
    }
  }
  catch (error) {
    const obj = { ...error }
    if (obj.errorMessage === "Import in progress") {
      dispatch(setUploading(true))
    }
  }
}

// Upload CSV file
export const uploadFile = ({ payload, signal, getUserList }) => async dispatch => {
  const signin_token = storage.getItem(SIGNIN_TOKEN);
  try {
    dispatch(setLoading(true));
    const resp = await fetch(BASE_URL + UPLOAD_USER_FILE, {
      method: 'POST',
      headers: new Headers({
        'Authorization': signin_token ? `Bearer ${signin_token}` : '',
        "medline-authorization": signin_token ? signin_token : '',
        'Ocp-Apim-Subscription-Key': API_KEY,
      }),
      body: payload,
      /* Signal for aborting */
      signal: signal
    });
    const data = await resp.json();
    /* If success */
    if (data) {
      dispatch(setUploadStatus(data.data))
      getUserList();
    }
    if (resp.status === 405) {
      const res = { status: 'error', message: data.message, accessStatus: 405 }
      dispatch(setUploadStatus(res))
    }
    if(resp.status===409){
      const res = { status: 'error', message: data.message,statuscode:409}
      dispatch(setUploadStatus(res))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    const res = { status: 'error', message: obj.errorMessage }
    dispatch(setUploadStatus(res))
    //dispatch(setUploadStatus('success'))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Get Roles  
export const getUserRoleAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(ROLES, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setUserRole(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  }
}
// get  Notification Count 
export const getnotificationcountAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request('/getnotificationcount', {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setNotificationCount(data.data))
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  }
}
// get All Notification Details
export const userNotificationAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(NOTIFICATION, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setNotificationData(data.data))
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  }
}

// delete Notification
export const deleteNotificationAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(DELETE_NOTIFICATION, payload), {
      method: 'DELETE',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getnotificationcountAsync())
      dispatch(userNotificationAsync())
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Read Notification

export const readNotificationAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const postData = { notifications: [payload.id] }
    let endPoint;
    if (payload && payload.unread === 0) {
      endPoint = NOTIFICATION_MARK_AS_UNREAD;
    }
    else if (payload && payload.unread === 1) {
      endPoint = NOTIFICATION_MARK_AS_READ;
    }
    const data = await request(endPoint, {
      method: 'POST',
      body: JSON.stringify(postData)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getnotificationcountAsync())
      dispatch(userNotificationAsync())
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Update user password
export const updatePasswordAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(UPDATE_PASSWORD, {
      method: 'POST',
      body: JSON.stringify({
        'id': payload.id,
        "currentpassword": payload.currentpassword,
        "newpassword": payload.newpassword
      })
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setUpdatePassword(data.status))
      dispatch(setPassUpdateState('success'))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setPassUpdateState(obj))
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Update user profile
export const userProfileUpdateAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(UPDATE_PROFILE_UPDATE, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setUserProfileUpdates(data.status))
      dispatch(updateUserDetailsAsync());
    }
  }
  catch (error) {
    dispatch(setUserProfileUpdates('error'))
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// User details by Id  
export const updateUserDetailsAsync = (facilityNumber,setScoreType) => async dispatch => {
  let queryString=''
  if(facilityNumber){
    queryString='?facilityNumber='+facilityNumber;
  }
  try {
    dispatch(setLoading(true));
    const data = await request(USER_DETAIL+queryString, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setuserDetails(data.data))
      dispatch(setUserDetail(data?.data))
      //setUserDetails
      //updateUserDetailsAsync
      if(facilityNumber&&setScoreType){
        setScoreType(data?.data?.customerSettings?.woundAssessmentScore)
      }
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  }
}

export const optionRoleAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(OPTION_TYPES + '/' + 'role', {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setroleListoption(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//setPrimaryCustomerFacility
export const getPrimaryCustomerFacility = (payload,setRregType,regType,setCustomerNumberCheck) => async dispatch => {
  try {
    dispatch(setRegResCheckAllFlag({}))
    dispatch(setRegResMatchCustomerNumZip({}))
    dispatch(setPrimaryCustomerFacility([]))
    dispatch(setLoading(true));
    const data = await request(REG_PRIRIMARY_CUSTOMER  + payload, {
      method: 'GET',
    });
    if(data && data?.status){
      dispatch(getOrganizationType())
      if (data && data.status === SUCCESS_STATUS){
        let optionData = [];
      if( Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length<=50){
        data.data?.customerFacilitiesInfo?.facilitiesInfo.map((value, index) => {//NOSONARoptionData
          return optionData.push({
            value: `${value?.facilityNumber ? ` ${value?.facilityNumber}` : ''} ${value?.facilityName ? `| ${value?.facilityName}` : ''} ${value?.address?.addressLine1 ? `| ${value?.address?.addressLine1}` : ''} ${value?.address?.addressLine2 ? `| ${value?.address?.addressLine2}` : ''} ${value?.address?.city ? `| ${value?.address?.city}` : ''}${value?.address?.state ? `| ${value?.address?.state}` : ''}`,
            key: value.facilityNumber,
            item: value
          });
        }); //NOSONAR
      }
        dispatch(setRegResCheckAllFlag(data?.data))
        if (data.data?.customerFacilitiesInfo?.isPrimaryCustomerNumber===1) {
          if(data.data?.customerFacilitiesInfo&&regType==='custFacilityEmail'){
            setCustomerNumberCheck('')
            dispatch(setRegResMatchCustomerNumZip(data.data?.customerFacilitiesInfo))
          }
          if((Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length===1)){
            dispatch(setPrimaryCustomerFacility(data.data?.customerFacilitiesInfo?.facilitiesInfo))
            dispatch(setRregType(''))
          }
          else if(data.data?.customerFacilitiesInfo?.isPostalCodeScreenRequiredShow===1){
            dispatch(setRregType('isPrimaryGroup')) //navigated to zipcode screen
           }
          else if(data.data?.customerFacilitiesInfo?.isPostalCodeScreenRequiredShow===0&&(Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length<50)){
               dispatch(setZipFacilitySearchDatas(optionData)) //navigated to facility dropdown screen
               dispatch(setRregType('isPrimaryGroup')) 
          }
          else if(!Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.customerNumber){
            dispatch(setPrimaryCustomerFacility([{facilityNumber:data.data?.customerFacilitiesInfo?.customerNumber,facilityName:''}]))
            dispatch(setRregType(''))
          }
          else{
            dispatch(setRregType('isPrimaryGroup'))
          }
         
        }
        else if (data.data?.customerFacilitiesInfo?.soldToOrShipToCustomerNumberType==='soldto'&&regType==='custFacilityNumber') {
         
          if((Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length===1)){
            dispatch(setPrimaryCustomerFacility(data.data?.customerFacilitiesInfo?.facilitiesInfo))
            dispatch(setRregType(''))
          }
          else if(data.data?.customerFacilitiesInfo?.isPostalCodeScreenRequiredShow===1){
            dispatch(setRregType('isPrimaryGroup')) //navigated to zipcode screen
           }
          else if(data.data?.customerFacilitiesInfo?.isPostalCodeScreenRequiredShow===0&&(Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length<50)){
               dispatch(setZipFacilitySearchDatas(optionData)) //navigated to facility dropdown screen
               dispatch(setRregType('isPrimaryGroup'))
          }
          else{
            dispatch(setRregType('isPrimaryGroup'))
          }
        }
        else if (data.data?.customerFacilitiesInfo?.soldToOrShipToEmailType==='soldto'&&regType==='custFacilityEmail') {
          if(data.data?.customerFacilitiesInfo?.customerNumber){
            setCustomerNumberCheck('')
            dispatch(setRegResMatchCustomerNumZip(data.data?.customerFacilitiesInfo))
          }
          if((Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length===1)){
            dispatch(setPrimaryCustomerFacility(data.data?.customerFacilitiesInfo?.facilitiesInfo))
            dispatch(setRregType(''))
          }
          else if(data.data?.customerFacilitiesInfo?.isPostalCodeScreenRequiredShow===1){
            dispatch(setRregType('isPrimaryGroup')) //navigated to zipcode screen
           }
          else if(data.data?.customerFacilitiesInfo?.isPostalCodeScreenRequiredShow===0&&(Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length<50)){
               dispatch(setZipFacilitySearchDatas(optionData)) //navigated to facility dropdown screen
               dispatch(setRregType('isPrimaryGroup'))
          }
          else{
            dispatch(setRregType('isPrimaryGroup'))
          }
        }
        else if(data.data?.customerFacilitiesInfo?.isPrimaryCustomerNumber===0){
          if(regType==='custFacilityEmail'&&data.data?.customerFacilitiesInfo?.customerNumber){
            setCustomerNumberCheck('')
            dispatch(setRegResMatchCustomerNumZip(data.data?.customerFacilitiesInfo))
          }
          if((regType==='custFacilityEmail'&&Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length===1)||(regType==='custFacilityNumber'&&Array.isArray(data.data?.customerFacilitiesInfo?.facilitiesInfo)&&data.data?.customerFacilitiesInfo?.facilitiesInfo.length===1)){
            dispatch(setPrimaryCustomerFacility(data.data?.customerFacilitiesInfo?.facilitiesInfo))
          }
          dispatch(setRregType(''))
        }
        else if(data.data?.customerFacilitiesInfo===''&&data?.message==='Multiple Primary Group Customer Number found for given domain name'&&regType==='custFacilityEmail'){
          const urlParams = new URLSearchParams(payload);
          const getEmail = urlParams.get('Email')
          const first_split = getEmail?.split("@")[1];
          const finalDomainEmail = first_split?.split(".")[0]
          dispatch(setPrimaryCustomerFacility([{facilityNumber:finalDomainEmail,facilityName:'',isMultiplePrimaryCustomerExists:1}]))
          dispatch(setRregType(''))
        }
      }
    }   
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    //isValidZipCodeWithNoCustomerNumber
    if(errCk?.data&&errCk?.data?.isValidZipCodeWithNoCustomerNumber===1&&regType==='custFacilityEmail'){
      dispatch(setRegResCheckAllFlag(errCk?.data))
      dispatch(getOrganizationType())
      if(setRregType){
        setRregType('')
      }
    }else{
      dispatch(setError(obj.errorMessage))
    }
    /*
     if(errCk?.data&&errCk?.data?.isValidEmail){
      dispatch(getOrganizationType())
    }
    if (regType!=='custFacilityNumber') {
      if(errCk?.data&&errCk?.data?.isValidEmail===1){
        if(setRregType){
          setRregType('')
        }
      }else{
        dispatch(setError(obj.errorMessage))
      }
      
    }else{
      dispatch(setError(obj.errorMessage))
    }*/
  }
  finally {
    dispatch(setLoading(false));
  }
}
//getZipByFacility
export const getZipByFacility = (payload,customerNumberCheck,setRregType) => async dispatch => {
  try {
    dispatch(setRegResCheckAllFlag({}))
    dispatch(setPrimaryCustomerFacility({}))
    dispatch(setLoading(true));
    const data = await request(`/facilitiesbyzipcode`, {
      method: 'POST',
      body: JSON.stringify({
        'CustomerNumber': customerNumberCheck,
        "zipCode": payload
      })
    });
      //dispatch(setZipFacilitySearchDatas([{key:'photon', value: 'chennai'}, {key: 'medline', value : 'usa'}]))
  if (data && data.status === SUCCESS_STATUS) {
    dispatch(setRegResCheckAllFlag(data?.data))
    let optionData = [];
    data.data.facilities &&
      Array.isArray(data.data.facilities) &&
      data.data.facilities.map((value, index) => {//NOSONARoptionData
        return optionData.push({
          value: `${value?.facilityNumber ? ` ${value?.facilityNumber}` : ''} ${value?.facilityName ? `| ${value?.facilityName}` : ''} ${value?.address?.addressLine1 ? `| ${value?.address?.addressLine1}` : ''} ${value?.address?.addressLine2 ? `| ${value?.address?.addressLine2}` : ''} ${value?.address?.city ? `| ${value?.address?.city}` : ''}${value?.address?.state ? `| ${value?.address?.state}` : ''}`,
          key: value.facilityNumber,
          item: value
        });
      }); //NOSONAR
      dispatch(setZipFacilitySearchDatas(optionData))
      if( Array.isArray(data.data.facilities) &&data.data.facilities.length===0){
        dispatch(setError('Facilities not found for entered postalcode'))
      }
      if(setRregType && data?.data?.isAllPrimaryGroupNumberFacilitiesAssign===1){
        dispatch(setPrimaryCustomerFacility([{facilityNumber:customerNumberCheck,facilityName:''}]))
        dispatch(setRregType(''))
      }
    }
   
  }
  catch (error) {
    const obj = { ...error }
    //dispatch(setZipFacilitySearchDatas([{key:'photon', value: 'chennai'}, {key: 'medline', value : 'usa'}]))
    /* If there are error, dispatch setError action */
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
   
      dispatch(setError(obj.errorMessage))
  
  }
  finally {
    dispatch(setLoading(false));
  }
}

//getLocationByCountry
export const getLocationByCountry = (postalcode,country) => async dispatch => {
  try {
    dispatch(setLocationByCountry({}))
    dispatch(setLoading(true));
    const data = await request(`/addressbypostalcode?postalcode=${postalcode}&country=${country}`, {
      method: 'GET',
    });
    //dispatch(setLocationByCountry({"country":"Canada","state":"Ontario","city":"Grimsby","postalCode":"L3M","localities":["Grimsby","Lincoln"]}))
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setLocationByCountry(data.data.pinCodeDetails))
    }
  }
  catch (error) {
    const obj = { ...error }
 /* If there are error, dispatch setError action */
    let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
//setyOrganizationType
export const getOrganizationType = (payload) => async dispatch => {
  try {
   
    dispatch(setLoading(true));
    const data = await request(REG_ORG_TYPE, {
      method: 'GET',
    });
   
    if (data && data.status === SUCCESS_STATUS && data.status === 'success') {
      dispatch(setyOrganizationType(data.data.options))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// getUsersDetails for customer
export const getUsersCallBackListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  dispatch(setEditState(false))
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.users.map((value)=>{
        value['lastLogin'] = (value.lastLogin) ? format(new Date(value.lastLogin), 'MMMM dd, yyyy h:mm aa') : '';
        value['strike_through']=value?.isUserDeleted&&value?.isUserDeleted===1?1:''
      })
      dispatch(setUsers(data))
      dispatch(setEditState(false))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    if (obj?.response?.status === 405) {
      dispatch(setAccessError(obj.errorMessage))
      dispatch(setUsers({ "data": { "users": [], "metadata": { "total": 0, "limit": 50, "offset": 0, "prev": "", "next": "" } } }))
      window.location='/unAuthorized';
    } else {
      dispatch(setUsers(obj))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
//getSessionTimeOutAsyn
export const getSessionTimeOutAsyn = (payload) => async dispatch => {
  try {
   
    dispatch(setLoading(true));
    let data = await request(`/validatesessiontimeout`, {
      method: 'POST',
      body: JSON.stringify({
        "email": payload
      })
    });
     
    if (data && data.status === SUCCESS_STATUS && data.status === 'success') {
      let  sessData={
        lastLogOn:data?.data?.lastLogOn,
        sessionTimeout: data?.data?.remainderSeessionTime,
        sessionTimeoutType: data?.data?.sessionTimeoutType
      }  
      let sessionTimeOutData=sessData
      /*let chckFinalMinutes =
      sessionTimeOutData?.sessionTimeoutType === "hours" 
        ? sessionTimeOutData?.sessionTimeout * 60
        : sessionTimeOutData?.sessionTimeoutType === "minutes"
        ? sessionTimeOutData?.sessionTimeout
        : sessionTimeOutData?.sessionTimeoutType === "days"
        ?sessionTimeOutData?.sessionTimeout *1000 * 60 * 60 * 24
        : sessionTimeOutData?.sessionTimeoutType === "weeks"
        ?sessionTimeOutData?.sessionTimeout *1000 * 60 * 60 * 24
        : sessionTimeOutData?.sessionTimeoutType === "months"
        ?sessionTimeOutData?.sessionTimeout *1000 * 60 * 60 * 24
        : 15 * 60; //15 minutes*/
     let chckFinalMinutes =sessionTimeOutData?.sessionTimeout?sessionTimeOutData?.sessionTimeout:15 * 60; //15 minutes*/
      sessData={
        lastLogOn: sessionTimeOutData?.lastLogOn,
        sessionTimeout:sessionTimeOutData?.sessionTimeout,
        sessionTimeoutType: sessionTimeOutData?.sessionTimeoutType,
        dynTime: chckFinalMinutes,
      }    
      //setSessionTempTime
      dispatch(setSessionTempTime(chckFinalMinutes))
      dispatch(setSessionTimeOutData(sessData))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  
  }
  finally {
    dispatch(setLoading(false));
  }
}

//continuesessiontimeout
export const continueSessionTimeoutAsyn = (payload) => async dispatch => {
  try {
   
    dispatch(setLoading(true));
    const data = await request(`/continuesessiontimeout`, {
      method: 'POST',
      body: JSON.stringify({
        "email": payload
      })
    });
   
    if (data && data.status === SUCCESS_STATUS && data.status === 'success') {
      if(data?.data?.token){
        storage.setItem(SIGNIN_TOKEN, data?.data?.token);
      }
      
        dispatch(getSessionTimeOutAsyn(payload))
     // dispatch(setSessionTimeOutData(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
export default userSlice.reducer;
