import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import style from './mergeWoundAssessment.module.scss'
import { useParams } from 'react-router-dom';
import {patientAssessmentAsync, detailAssessmentAsync, mergeWoundSuccessReeval, setMergeWoundSuccess, updateAnatomyListCheck, optionTypeAsync, setWoundAssessmentsAnatomyList, patientAssessmentHistoryAsync} from '../../../app/patientSlice'
import { fullMonthFormatDate, allNullCheck, assessmentStageGetterMove, dateOfBirthDate } from '../../../utils';
import { Header } from '../../secondary/Header';
import { format } from 'date-fns'
import { toBeRequired } from '@testing-library/jest-dom/matchers';
import { id } from 'date-fns/locale';
import { Button } from '../../primary';
import {PATIENT_GENDER } from '../../../constants'
import Loader from '../../../static/images/spinner.svg';
import LogoImage from '../../../static/images/measurmenttest.jpg';
import pictureIcon from '../../../static/images/picture-icon-default.png'

export const MergeWoundAssessment = ({history}) => {
const { patientId } = useParams();
const { assessmentId } = useParams();
const dispatch = useDispatch();
const adminPageYes = history.location.state;
window.onload = function() {
    dispatch(detailAssessmentAsync({ patientId, assessmentId })) 
    // dispatch(patientAssessmentAsync(patientId))   
    dispatch(setMergeWoundSuccess(null));  
    dispatch(optionTypeAsync('historycause'))
}
useEffect(()=>{
    dispatch(detailAssessmentAsync({ patientId, assessmentId }))
    // dispatch(patientAssessmentAsync(patientId))   
    dispatch(setMergeWoundSuccess(null));  
    dispatch(optionTypeAsync('historycause'))
},[])

/* Get state with the Individual patient data */
const detailAssessment = useSelector(state => state.patient.detailAssessment);
const [allAssessmentState, setAllAssessmentState] = useState(false)
//For right side list call the API
useEffect(()=>{  
        rightSideListFectch();
        if(adminPageYes && detailAssessment?.reEvalSequence > 0){
            dispatch(patientAssessmentHistoryAsync(patientId, assessmentId));
            setAllAssessmentState(true)
            setTinyDelyLeft(true)
        }
        else{
            setAllAssessmentState(false)
            setTinyDelyLeftAllAssessment(true)        
        }          
},[detailAssessment])

//For right side list call the API
const [anatomyPopUPList, setAnatomyPopUPList] = useState(false);
const rightSideListFectch= () => {
    const assessment = {
        'anatomy': detailAssessment?.anatomy,
        'historyCause': {
            'cause': detailAssessment?.historyCause !== null ? detailAssessment?.historyCause?.cause : null
        }
    }      
    const payload = {
        assessmentId,
        patientId,
        assessment
    }
    const mergeFunction = true;      
    dispatch(updateAnatomyListCheck(payload,setAnatomyPopUPList, adminPageYes, mergeFunction))    
}


const patientData = useSelector(state => state.patient.detailViewData)
// const woundAssessments = useSelector(state => state.patient.woundAssessments);
const assessmentHistoryData = useSelector(state => state.patient.assessmentHistory);
const historyCause = useSelector(state => state.patient.historyCause);
const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions)
const woundAssessmentsAnatomyList = useSelector(state => state.patient.woundAssessmentsAnatomyList);//right side card list

const[assessmentHistoryState, setAssessmentHistoryState] = useState([])
useEffect(()=>{
    let assessmentLists = [];  
        assessmentHistoryData?.followuphistory?.map((item)=>{               
            return(
                setAssessmentHistoryState(item)
            )
        }) 
},[assessmentHistoryData, allAssessmentState])

//loader when singale assessments
const [tinyDelyLeft, setTinyDelyLeft] = useState(false)
useEffect(()=>{
    let getloadValue = detailAssessment !== undefined ? true : false;
    if(getloadValue && !allAssessmentState){
        setTimeout(()=>{
            setTinyDelyLeft(true)
        }, 2500)    
      } 
},[!allAssessmentState, detailAssessment])
//loader when multiple assessment
const [tinyDelyLeftAllAssessment, setTinyDelyLeftAllAssessment] = useState(false)
useEffect(()=>{
    let getloadValue = assessmentHistoryState?.assessments !== undefined ? true : false;
    if(getloadValue && allAssessmentState){
        setTimeout(()=>{
            setTinyDelyLeftAllAssessment(true)
        }, 2500)    
      }
},[allAssessmentState, assessmentHistoryState])

//Loader set to until api getting response for right side card
const [tinyDelay, setTinyDelay] = useState(false)
useEffect(()=>{
  let getloadValue = woundAssessmentsAnatomyList?.assessments !== undefined ? true : false;
  if(getloadValue){
    setTimeout(()=>{
      setTinyDelay(true)
    }, 3500)    
  } 
  else{
    if(detailAssessment !== undefined){
    setTimeout(()=>{
        setTinyDelay(true)
      }, 2000) 
    }
  }   
// setTinyDelay(true)
 })

const [mergeAssessment, setMergeAssessment] = useState([])
window.onload = function() {
    setMergeAssessment([detailAssessment])
    setCountMoved(0)
    setDropHereState(true) 
    setGetMergingAssessmentId(null)     
}
useEffect(()=>{
    setMergeAssessment([detailAssessment])
    setCountMoved(0)  
    setDropHereState(true)
    setGetMergingAssessmentId(null)
},[])

//Drag functionality
const [dropHereState, setDropHereState] = useState(true)
const drag = (ev) => {
    ev.dataTransfer.setData("div", ev.target.id);  
    setDropHereState(true)     
}

const allowDrop = (ev, id) => {  
    ev.preventDefault();
    setDropHereState(true)
}
  
const [movedSuccess, setMovedSuccess] = useState(false)
const [countMoved, setCountMoved] = useState(0)
const [firstConent, setFirstContent] = useState(false)
const [removeFlag, setremoveFlag] = useState(false)
const [getMergingAssessmentId, setGetMergingAssessmentId] = useState(null);
//Drop Success functionality
const drop = (ev, id) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("div");
    if(id !== undefined){
        ev.target.appendChild(document.getElementById(data));
        setGetMergingAssessmentId(id)
        if(undoChangeClick){
            ev.target.removeChild(document.getElementById(data));
        }                
        setLeaveHover(false)
        setMovedSuccess(true)
        if(firstConent===true){
            setremoveFlag(true)
        }
        if(firstConent===false){
            setremoveFlag(false)
        }             
    }  
    if(id === undefined){
        setDropHereState(false)
        setGetMergingAssessmentId(null)
    } 
}

// hover list functionality
const [onHoverList, setOnHoverList] = useState()
const hoverRightList = (e, index) => {
    setOnHoverList(index)
    setLeaveHover(true)
}
const [leaveHover, setLeaveHover] = useState(false)
const hoverLeaveRIghtList = (index) => {
    setLeaveHover(false)
}
//after successfully merged got confirmation
const [giveConfirmation, setGiveConfirmation] = useState(false)
const clickConfirmChange = (ev) => {
    if(movedSuccess && getMergingAssessmentId !== null) {
        setGiveConfirmation(true)
    }
}
//after successfull merge process navigate to woundassessment screen
const [navigatePmtScreen, setNavigatePmtScreen] = useState(false)
useEffect(()=>{
    if(giveConfirmation && getMergingAssessmentId !== null) {
        setTimeout(()=>{
            setNavigatePmtScreen(true)
        }, 2500)        
    }      
},[giveConfirmation, getMergingAssessmentId !== null])

useEffect(()=>{
    if(navigatePmtScreen){
        let payload = {
            "ParentAssessmentId" : getMergingAssessmentId, 
            "MergingAssessmentId" : assessmentId,
            "patientId": patientId,
        }
        let adminWoundAssement = adminPageYes
        dispatch(mergeWoundSuccessReeval(payload, history, adminWoundAssement))
    }
},[navigatePmtScreen])
//Undo single assessment parent/child functionality
const [undoChangeClick, setUndoChangeClick] = useState(false)
const [undoChangeValue, setUndoChangeValue] = useState([])
const clickUndoChange = (ev) => {
    if(undoChangeClick){
        setUndoChangeClick(false)
    } 
    if(!undoChangeClick){
        setUndoChangeClick(true)
    }
    setremoveFlag(false)  
    setFirstContent(false)
    setGiveConfirmation(false)
    setNavigatePmtScreen(false)
    setCountMoved((prevValue) => prevValue + 1)
    setMovedSuccess(false)
    setGetMergingAssessmentId(null)
    setUndoChangeValue([detailAssessment])
}

//Undo more than one assessment functionality
const [undoChangeClickAllAssessment, setUndoChangeClickAllAssessment] = useState(false)
const [undoChangeValueAllAssessment, setUndoChangeValueAllAssessment] = useState([])
const clickUndoChangeAllAssessment = (ev) => {
    if(undoChangeClickAllAssessment){
        setUndoChangeClickAllAssessment(false)
    } 
    if(!undoChangeClickAllAssessment){
        setUndoChangeClickAllAssessment(true)
    }
    setremoveFlag(false)  
    setFirstContent(false)
    setGiveConfirmation(false)
    setNavigatePmtScreen(false)
    setCountMoved((prevValue) => prevValue + 1)
    setMovedSuccess(false)
    setGetMergingAssessmentId(null)
    setUndoChangeValueAllAssessment([assessmentHistoryState])
}

const statusBadg = {
    'pendingassessment': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_assess}`}>Pending Assessment</span>,
    'reviewed': <span className={`pt-2 pb-2  ${style.assess_status} ${style.reviewed}`}>Reviewed</span>,
    'pendingreview': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_review}`}>Pending Review</span>,
    'signedlocked': <span className={`pt-2 pb-2 ${style.assess_status} ${style.signed_locked}`}>Signed &#38; Locked</span>
}

const detaileView = (data) => {
    history.push('/patients/' + patientId + '/assessment/' + data);
// if (userRole.role !== 'ExternalStandardUser') {
//   history.push('/patients/' + patientId + '/assessment/' + data);
//   modalRefresh();
// }    
}
const assessmentStageGetter = (data) => {
    return assessmentStageGetterMove(data)
}

    return(
        <div className={`col-12 ${style.mergeWoundPage} px-0`}>
            <div className={`${style.back_btn}`}>
                <Header backLink={'admin/patients/' + patientId} />
            </div>
            <div className='col-12 px-0'>
                <div className={`${style.patientCardMain}`}>
                    <div className={`col-12 ${style.patient_details_blk_scroll}`}>
                        <div className={`${style.patient_image} mx-3`}>
                            <a href={`/admin/patients/${patientId}`} target="_self">
                                <img src={patientData?.moreInfo?.profilePicture || pictureIcon} alt={`${patientData?.firstName} ${patientData?.lastName}`}/>                    
                            </a>
                        </div>
                        <div className={`${style.patient_details}`}>
                            <div className='d-flex align-items-center pb-2'>
                                <div className={'d-flex align-items-center'} >
                                    <a href={`/admin/patients/${patientId}`} target="_self" className={'d-flex align-items-center'}>
                                        <h4 className="pr-1 mb-0">{`${patientData?.firstName} ${patientData?.lastName}`}</h4>
                                    </a>
                                </div>
                                <div className={`verticalCode  ${style.vertical_Code}`}>
                                </div>    
                                <p className={`${style.labelSize} pt-1`}><span>{patientData?.age}</span></p>
                                <div className={`verticalCode  ${style.vertical_Code}`}>
                                </div>
                                <p className={`${style.labelSize} pt-1`}><span>{PATIENT_GENDER[patientData.gender ? patientData.gender.toLowerCase() : '']}</span></p>                        
                                <div className={'mx-4 text-center'}>
                                    <Button
                                        type={"button"}
                                        value={"Diabetic"}
                                        classes={{ className: (patientData?.moreInfo?.diabetic === "1" || patientData?.moreInfo?.diabetic === 1) ?  `btn ${style.btn_custom_with_diabetic}` : 'd-none' }}
                                        id="diabetic"
                                    />
                                </div>                       
                            </div>
                            <div className='d-flex align-items-center'>
                                <p className={`${style.labelSize}`}><label>MRN #:</label><span>{patientData?.mrn !== null ? patientData?.mrn : "-"}</span></p>
                                <div className={`verticalCode  ${style.vertical_Code_one}`}>
                                </div>
                                <p className={`${style.labelSize}`}><label>DOB:</label><span>{dateOfBirthDate(patientData?.dob)}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`col-12 ${style.mergeLeftsideCard} d-flex align-items-center`}>
                <h3>Drag and drop to merge the wound assessment </h3>                        
                <div className={`${style.arrow}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={`${style.mergewoundWholeSection} col-12 d-flex justify-content-between align-items-center px-0`}>                
                <div className='col-6 pl-0'>                      
                    {!allAssessmentState && !undoChangeClick && <div className={`col-12 ${style.mergerWoundMain}`} onDrop={(e)=>{drop(e);setFirstContent(false);}} onDragOver={(e)=>allowDrop(e)} id="div1">                                      
                        {!tinyDelyLeft ? <div className={`${'loader'} col-12`}><img src={Loader} alt="loading" /></div>:
                            <div className={`${style.wholeDrag_Undo}`} draggable="true" onDragStart={(e)=>drag(e)} onDragOver={(e)=>allowDrop(e)} id={"drag1"}>
                                <div className={`col-12 ${style.woundTitle} d-flex py-3`}>
                                    <a href={`/patients/${patientData?.ne1Id}/assessment/${assessmentId}`}>
                                        {detailAssessment?.title}
                                    </a>
                                    <span className='ml-auto mr-4'>{statusBadg[detailAssessment?.assessmentStage]}</span>
                                    {/* <span className={`${style.adminPageYesBatch} ml-auto mr-4`}>{adminPageYes ? 'Parent' : 'Child'}</span> */}
                                    <i className={`fas`} style={{fontSize:'20px',color:'#787878',float:'right', marginTop:'-10px'}}>&#xf58e;</i>
                                </div>
                                <div className={`col-12 d-flex ${style.midContent} justify-content-center align-items-center`}>
                                    <div className='col-2 p-0'>
                                        <div className={`${style.woundAssessment_img_parent}`} draggable="false">
                                            <img src={detailAssessment?.picture} alt="wound" class="woundAssessment_wound_img" draggable="false"/>
                                        </div>
                                    </div>
                                    <div className='col-10'>
                                        <div className={`${style.assess_content} pb-3`}>  
                                            <span className={style.assess_content_label}>History/Cause: </span> 
                                            <span className={style.assess_label}>
                                            {detailAssessment?.historyCause?.causeCategory !== "unknown"  ? (allNullCheck(detailAssessment?.historyCause?.causeCategory) && allNullCheck(detailAssessment?.historyCause?.cause) ?
                                                (detailAssessment?.historyCause?.causeCategory !== "others" || detailAssessment?.historyCause?.causeCategory.toLowerCase() !== "others") ?
                                                <span className=''>                                               
                                                    {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                                    if(data.key===detailAssessment?.historyCause?.causeCategory.toLowerCase()){
                                                        return data?.options?.map((item)=>{
                                                            if(item.key!=='unabletodetermine'){
                                                            if(item.key===detailAssessment?.historyCause?.cause || item.value===detailAssessment?.historyCause?.cause){
                                                                if(detailAssessment?.historyCause?.causeCategory === "other"){
                                                                    return  item.value
                                                                  }else{
                                                                    return data.value + '-' + item.value
                                                                  }
                                                            }
                                                        }
                                                        else{
                                                            return data.value
                                                        }
                                                        })
                                                    }                      
                                                    })}
                                                </span> : <span className=''>{detailAssessment?.historyCause?.cause}</span>
                                                : <span className=''>{`-`}</span>)
                                                : <span className=''>{`Unknown`}</span>
                                            }
                                        </span>
                                        </div>
                                        <div className={`${style.assess_content} pb-3`}>  
                                            <span className={style.assess_content_label}>Labels: </span> 
                                            <span className={style.assess_label}>{detailAssessment?.injuryType !== "" && detailAssessment?.injuryType !== null && detailAssessment?.injuryType !== "null" && detailAssessment?.injuryType}{detailAssessment?.bodyType !== "" && detailAssessment?.bodyType !== null && detailAssessment?.bodyType !== "null" && ', '}{detailAssessment?.bodyType !== "" && detailAssessment?.bodyType !== null && detailAssessment?.bodyType !== 'null' && detailAssessment?.bodyType} {(detailAssessment?.injuryType === "" || detailAssessment?.injuryType === "null" || detailAssessment?.injuryType === null) && (detailAssessment?.bodyType === "" || detailAssessment?.bodyType === "null" || detailAssessment?.bodyType === null) && 'None'}</span>
                                        </div>
                                        <div className={`${style.assess_content} pb-3 d-flex align-items-center justify-content-between`}> 
                                            <div className=''> 
                                                <span className={style.assess_content_label}>Wound Acquired: </span> 
                                                <span className={style.assess_label}>{detailAssessment?.woundAcquired} </span>
                                            </div>
                                            {/* <div className='mr-5'>
                                                <span className=''>{statusBadg[detailAssessment?.assessmentStage]}</span>
                                            </div> */}
                                        </div>
                                        <div className={`${style.assess_content}`}>  
                                            <span className={style.assess_content_label}>Classification: </span> 
                                            <span className={style.assess_label}>
                                            {detailAssessment?.classification?.woundClassification !== "unknown" ? (allNullCheck(detailAssessment?.classification?.woundClassification) ?                                  
                                                <span className={`${style.classifi_content}`}>
                                                    {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                                                        if (data.key === detailAssessment?.classification?.woundClassification) {
                                                            return data.value
                                                        }
                                                    })
                                                    }
                                                </span> : <span className={`${style.classifi_content}`}>{`Unknown`}</span>) :
                                                <span className={`${style.classifi_content}`}>{`Unknown`}</span>}
                                            </span>
                                        </div>
                                    </div>

                                    
                                </div>
                                <div className={`col-12 ${style.lastContent}`}>
                                    <div className={`${style.assess_content} pb-1 pt-1`}>
                                    <span className={style.assess_content_label}>Photo taken by:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.photoTakenBy ? detailAssessment?.photoTakenBy : 'NA'}</span>  
                                    {` | `}
                                    <span className={style.assess_content_label}>Photo taken on:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.identifiedDate ? fullMonthFormatDate(detailAssessment?.identifiedDate) : 'NA'}</span>
                                    </div>

                                    <div className={`${style.assess_content} pb-1`}>
                                    <span className={style.assess_content_label}>Assessed by:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.assessedBy ? detailAssessment?.assessedBy : 'NA'}</span>
                                    {` | `}
                                    <span className={style.assess_content_label}>Last assessed: </span>
                                    <span className={style.assess_label_subDetail_labelout}>
                                        {detailAssessment?.lastAssessedDate ? fullMonthFormatDate(detailAssessment?.lastAssessedDate) : 'NA'}
                                    </span>                                    
                                    </div>

                                    <div className={`${style.assess_content} pb-1`}>
                                    <span className={style.assess_content_label}>Signed:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.signedBy ? detailAssessment?.signedBy : 'NA'}</span>
                                        {` | `}
                                    <span className={style.assess_content_label}>Reviewed & Locked:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.lockedBy ? detailAssessment?.lockedBy : 'NA'}</span>
                                    </div>
                                </div>

                            </div>            
                            }
                    
                        {/* )
                    })} */}
                </div>}
                
                {!allAssessmentState && undoChangeClick && <div className={`col-12 ${style.mergerWoundMain}`} onDrop={(e)=>{drop(e);setFirstContent(true);}} onDragOver={(e)=>{allowDrop(e);setFirstContent(true);}} id="div1">
                    {undoChangeValue && Array.isArray(undoChangeValue) && undoChangeValue?.map(itemValue=>{
                        return(                     
                            <div className={!removeFlag ? `${style.wholeDrag}` : 'd-none'} draggable="true" onDragStart={(e)=>drag(e)} onDragOver={(e)=>allowDrop(e)} id={"drag1"}>
                                <div className={`col-12 ${style.woundTitle} d-flex py-3`}>
                                    <a href={`/patients/${patientData?.ne1Id}/assessment/${assessmentId}`}>
                                        {itemValue?.title}
                                    </a>
                                    <span className='ml-auto mr-4'>{statusBadg[detailAssessment?.assessmentStage]}</span>
                                    {/* <span className={`${style.adminPageYesBatch} ml-auto mr-4`}>{adminPageYes ? 'Parent' : 'Child'}</span> */}
                                    <i className={`fas`} style={{fontSize:'20px',color:'#787878',float:'right', marginTop:'-10px'}}>&#xf58e;</i>
                                </div>
                                <div className={`col-12 d-flex ${style.midContent} justify-content-center align-items-center`}>
                                    <div className='col-2 p-0'>
                                        <div className={`${style.woundAssessment_img_parent}`} draggable="false">
                                            <img src={itemValue?.picture} alt="wound" class="woundAssessment_wound_img" draggable="false"/>
                                        </div>
                                    </div>
                                    <div className='col-10'>
                                        <div className={`${style.assess_content} pb-3`}>  
                                            <span className={style.assess_content_label}>History/Cause: </span> 
                                            <span className={style.assess_label}>
                                            {itemValue?.historyCause?.causeCategory !== "unknown"  ? (allNullCheck(itemValue?.historyCause?.causeCategory) && allNullCheck(itemValue?.historyCause?.cause) ?
                                                (itemValue?.historyCause?.causeCategory !== "others" || itemValue?.historyCause?.causeCategory.toLowerCase() !== "others") ?
                                                <span className=''>                                               
                                                    {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                                    if(data.key===itemValue?.historyCause?.causeCategory.toLowerCase()){
                                                        return data?.options?.map((item)=>{
                                                            if(item.key!=='unabletodetermine'){
                                                            if(item.key===itemValue?.historyCause?.cause || item.value===itemValue?.historyCause?.cause){
                                                                if(itemValue?.historyCause?.causeCategory === "other"){
                                                                    return  item.value
                                                                }else{
                                                                    return data.value + '-' + item.value
                                                                }
                                                             
                                                            }
                                                        }
                                                        else{
                                                            return data.value
                                                        }
                                                        })
                                                    }                      
                                                    })}
                                                </span> : <span className=''>{itemValue?.historyCause?.cause}</span>
                                                : <span className=''>{`-`}</span>)
                                                : <span className=''>{`Unknown`}</span>
                                                }
                                            </span>
                                        </div>
                                        <div className={`${style.assess_content} pb-3`}>  
                                            <span className={style.assess_content_label}>Labels: </span> 
                                            <span className={style.assess_label}>{itemValue?.injuryType !== "" && itemValue?.injuryType !== null && itemValue?.injuryType !== "null" && itemValue?.injuryType}{itemValue?.bodyType !== "" && itemValue?.bodyType !== null && itemValue?.bodyType !== "null" && ', '}{itemValue?.bodyType !== "" && itemValue?.bodyType !== null && itemValue?.bodyType !== 'null' && itemValue?.bodyType} {(itemValue?.injuryType === "" || itemValue?.injuryType === "null" || itemValue?.injuryType === null) && (itemValue?.bodyType === "" || itemValue?.bodyType === "null" || itemValue?.bodyType === null) && 'None'} </span>
                                        </div>
                                        <div className={`${style.assess_content} pb-3 d-flex align-items-center justify-content-between`}> 
                                            <div className=''> 
                                                <span className={style.assess_content_label}>Wound Acquired: </span> 
                                                <span className={style.assess_label}>{detailAssessment?.woundAcquired} </span>
                                            </div>
                                            {/* <div className='mr-5'>
                                                <span className=''>{statusBadg[detailAssessment?.assessmentStage]}</span>
                                            </div> */}
                                        </div>
                                        <div className={`${style.assess_content}`}>  
                                            <span className={style.assess_content_label}>Classification: </span> 
                                            <span className={style.assess_label}>
                                            {itemValue?.classification?.woundClassification !== "unknown" ? (allNullCheck(itemValue?.classification?.woundClassification) ?                                  
                                                <span className={`${style.classifi_content}`}>
                                                    {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                                                        if (data.key === itemValue?.classification?.woundClassification) {
                                                            return data.value
                                                        }
                                                    })
                                                    }
                                                </span> : <span className={`${style.classifi_content}`}>{`Unknown`}</span>) :
                                                <span className={`${style.classifi_content}`}>{`Unknown`}</span>}
                                            </span>
                                        </div>
                                    </div>

                                    
                                </div>
                                <div className={`col-12 ${style.lastContent}`}>
                                    <div className={`${style.assess_content} pb-1 pt-1`}>
                                    <span className={style.assess_content_label}>Photo taken by:</span> <span className={style.assess_label_subDetail_labelout}>{itemValue?.photoTakenBy ? itemValue?.photoTakenBy : 'NA'}</span>  
                                    {` | `}
                                    <span className={style.assess_content_label}>Photo taken on:</span> <span className={style.assess_label_subDetail_labelout}>{itemValue?.identifiedDate ? fullMonthFormatDate(itemValue?.identifiedDate) : 'NA'}</span>
                                    </div>

                                    <div className={`${style.assess_content} pb-1`}>
                                    <span className={style.assess_content_label}>Assessed by:</span> <span className={style.assess_label_subDetail_labelout}>{itemValue?.assessedBy ? itemValue?.assessedBy : 'NA'}</span>
                                    {` | `}
                                    <span className={style.assess_content_label}>Last assessed: </span>
                                    <span className={style.assess_label_subDetail_labelout}>
                                        {itemValue?.lastAssessedDate ? fullMonthFormatDate(itemValue?.lastAssessedDate) : 'NA'}
                                    </span>                                    
                                    </div>

                                    <div className={`${style.assess_content} pb-1`}>
                                    <span className={style.assess_content_label}>Signed:</span> <span className={style.assess_label_subDetail_labelout}>{itemValue?.signedBy ? itemValue?.signedBy : 'NA'}</span>
                                        {` | `}
                                    <span className={style.assess_content_label}>Reviewed & Locked:</span> <span className={style.assess_label_subDetail_labelout}>{itemValue?.lockedBy ? itemValue?.lockedBy : 'NA'}</span>
                                    </div>
                                </div>

                            </div>            
                    
                    
                        )
                    })}
                </div>}


                {/* {!tinyDelyLeftAllAssessment ? <div className={`${'loader'} col-12`}><img src={Loader} alt="loading" /></div>:
                <div> */}
                {allAssessmentState && !undoChangeClickAllAssessment && <div className={`col-12 ${!movedSuccess ? style.mergerWoundMainAllAssessment : style.keepDivHeight}`} onDrop={(e)=>{drop(e);setFirstContent(false);}} onDragOver={(e)=>allowDrop(e)} id="div1">
                    {!tinyDelyLeftAllAssessment ? <div className={`${'loader'} col-12`}><img src={Loader} alt="loading" /></div>:
                    <div className={`${style.wholeDrag_Undo}`} draggable="true" onDragStart={(e)=>drag(e)} onDragOver={(e)=>allowDrop(e)} id={"drag1"}>
                        <div className=''>
                             <div className={`pl-4 ${style.sub_heading}`}>                                
                                <a href={`/patients/${patientData?.ne1Id}/assessment/${assessmentId}`}>{`${assessmentHistoryState?.woundTitle}`}</a>
                            </div>
                            <div className={`pl-4 pr-4 mb-2 mt-1 pt-1 ${style.admission_status}`}>History/Cause: {' '} 
                                {assessmentHistoryState?.causeCategory !== "unknown"  ? (allNullCheck(assessmentHistoryState?.causeCategory) && allNullCheck(assessmentHistoryState?.cause) ?
                                    (assessmentHistoryState?.causeCategory !== "others" || assessmentHistoryState?.causeCategory.toLowerCase() !== "others") ?
                                    <span className=''>                                               
                                        {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                        if(data.key===assessmentHistoryState?.causeCategory.toLowerCase()){
                                            return data?.options?.map((item)=>{
                                                if(item.key!=='unabletodetermine'){
                                                if(item.key===assessmentHistoryState?.cause || item.value===assessmentHistoryState?.cause){
                                                    if(assessmentHistoryState.causeCategory === "other"){
                                                        return  item.value
                                                    }else{
                                                        return data.value + '-' + item.value
                                                    }
                                                  
                                                }
                                            }
                                            else{
                                                return data.value
                                            }
                                            })
                                        }                      
                                        })}
                                    </span> : <span className=''>{assessmentHistoryState?.cause}</span>
                                    : <span className=''>{`-`}</span>)
                                : <span className=''>{`Unknown`}</span>
                                }
                            </div>
                            <div className={`pl-4 pr-4 mb-2 pb-1 mt-1 ${style.admission_status}`}>Admission Date: {fullMonthFormatDate(assessmentHistoryState?.admissionDate)}</div>
                        </div>
                        <div className=''>
                            <table className="w-100" cellPadding='0' cellSpacing='0'>
                            <thead>
                                <tr className={style.complete_th}>
                                <th>Wound</th>
                                <th>Record Type</th>
                                <th>Photo Taken On</th>
                                <th>Wound Progress</th>                             
                                </tr>
                            </thead>
                            {assessmentHistoryState && Array.isArray(assessmentHistoryState?.assessments) && assessmentHistoryState?.assessments?.map(item=>{
                                return(
                                    <tbody>
                                        <tr className={`${style.complete_td} mb-2`}>
                                            <td onClick={() => detaileView(item.id)}>
                                                {
                                                item.picture ? <img
                                                    src={item.picture}
                                                    alt="wound"
                                                    className={style.modal_image}
                                                /> :
                                                    <img src={LogoImage} alt="wound image" className={style.modal_image} />
                                                }
                                            </td>
                                            <td onClick={() => detaileView(item.id)}>
                                                {
                                                item.recordType ? <span className={`${style.recordTypeBatch}`}>{item.recordType}</span>
                                                :
                                                '-'
                                                }
                                            </td>
                                            <td onClick={() => detaileView(item.id)}>{format(new Date(item.identifiedDate), 'LLLL d, yyyy hh:mmaa')}</td>
                                            <td onClick={() => detaileView(item.id)}>
                                                {assessmentStageGetter(item.assessmentStage)}
                                            </td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                            </table>
                        </div>
                    </div>
                    }
                </div>
                }
                {/* </div>} */}
                {allAssessmentState && undoChangeClickAllAssessment && <div className={`col-12 ${!movedSuccess ? style.mergerWoundMainAllAssessment : style.keepDivHeight}`} onDrop={(e)=>{drop(e);setFirstContent(false);}} onDragOver={(e)=>allowDrop(e)} id="div1">
                    {undoChangeValueAllAssessment && Array.isArray(undoChangeValueAllAssessment) && undoChangeValueAllAssessment?.map(itemValue=>{
                        return(
                            <div className={!removeFlag ? `${style.wholeDrag}` : 'd-none'} draggable="true" onDragStart={(e)=>drag(e)} onDragOver={(e)=>allowDrop(e)} id={"drag1"}>
                                <div className=''>
                                    <div className={`pl-4 ${style.sub_heading}`}>                                
                                        <a href={`/patients/${patientData?.ne1Id}/assessment/${assessmentId}`}>{`${itemValue?.woundTitle}`}</a>
                                    </div>
                                    <div className={`pl-4 pr-4 mb-2 mt-1 pt-1 ${style.admission_status}`}>History/Cause: {' '} 
                                        {itemValue?.causeCategory !== "unknown"  ? (allNullCheck(itemValue?.causeCategory) && allNullCheck(itemValue?.cause) ?
                                            (itemValue?.causeCategory !== "others" || itemValue?.causeCategory.toLowerCase() !== "others") ?
                                            <span className=''>                                               
                                                {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                                if(data.key===itemValue?.causeCategory.toLowerCase()){
                                                    return data?.options?.map((item)=>{
                                                        if(item.key!=='unabletodetermine'){
                                                        if(item.key===itemValue?.cause || item.value===itemValue?.cause){
                                                            if(itemValue.causeCategory === "other"){
                                                                return item.value
                                                            }
                                                            else{
                                                                return data.value + '-' + item.value
                                                            }
                                                       
                                                        }
                                                    }
                                                    else{
                                                        return data.value
                                                    }
                                                    })
                                                }                      
                                                })}
                                            </span> : <span className=''>{itemValue?.cause}</span>
                                            : <span className=''>{`-`}</span>)
                                        : <span className=''>{`Unknown`}</span>
                                        }
                                    </div>
                                    <div className={`pl-4 pr-4 mb-2 pb-1 mt-1 ${style.admission_status}`}>Admission Date: {fullMonthFormatDate(itemValue?.admissionDate)}</div>
                                </div>
                                <div className=''>
                                    <table className="w-100" cellPadding='0' cellSpacing='0'>
                                    <thead>
                                        <tr className={style.complete_th}>
                                        <th>Wound</th>
                                        <th>Record Type</th>
                                        <th>Photo Taken On</th>
                                        <th>Wound Progress</th>                             
                                        </tr>
                                    </thead>
                                    {itemValue && Array.isArray(itemValue?.assessments) && itemValue?.assessments?.map(item=>{
                                        return(
                                            <tbody>
                                                <tr className={`${style.complete_td} mb-2`}>
                                                    <td onClick={() => detaileView(item.id)}>
                                                        {
                                                        item.picture ? <img
                                                            src={item.picture}
                                                            alt="wound"
                                                            className={style.modal_image}
                                                        /> :
                                                            <img src={LogoImage} alt="wound image" className={style.modal_image} />
                                                        }
                                                    </td>
                                                    <td onClick={() => detaileView(item.id)}>
                                                        {
                                                        item.recordType ? <span className={`${style.recordTypeBatch}`}>{item.recordType}</span>
                                                        :
                                                        '-'
                                                        }
                                                    </td>
                                                    <td onClick={() => detaileView(item.id)}>{format(new Date(item.identifiedDate), 'LLLL d, yyyy hh:mmaa')}</td>
                                                    <td onClick={() => detaileView(item.id)}>
                                                        {assessmentStageGetter(item.assessmentStage)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                    </table>
                                </div>
                            </div>
                        )})}
                </div>
                }
            </div>

            <div className={`col-6 ${style.mergeListSection} ${leaveHover && !movedSuccess && dropHereState && style.mergeListSectionPaddingHeight}`}>
            <div className='col-12 d-flex align-items-start justify-content-between px-0 pt-1 pb-2'>
                <h3>Merge with...</h3>                
                {/* <i class="fa fa-times" aria-hidden="true" style={{color: 'rgb(102 102 102)', fontSize: '25px', marginRight: '10px', cursor: 'pointer', padding: '10px 5px 10px 10px'}} onClick={()=>closeClick()}></i> */}
                
                
            </div>
            {!tinyDelay ? <div className={`${'loader'} col-12`}><img src={Loader} alt="loading" /></div>:
            <div>    
            {
                woundAssessmentsAnatomyList && woundAssessmentsAnatomyList.assessments && Array.isArray(woundAssessmentsAnatomyList.assessments) && woundAssessmentsAnatomyList.assessments.map(data=>data.assessments.map((item,index)=>{
                    return(
                        <div className={`${style.mergeList} ${onHoverList===index && leaveHover && 'pb-2'} ${movedSuccess && onHoverList===index && !leaveHover && 'pb-3'}`} >
                            <div className='col-12 py-2 px-0' onDragEnter={(e)=>hoverRightList(e, index)} id="div2">                                
                                <div className='col-12'>
                                    <div className={`${style.woundTitle} pt-2 pb-3`}>
                                        <a href={`/patients/${patientData?.ne1Id}/assessment/${item?.id}`}>
                                            {item?.title}
                                        </a>
                                    </div>
                                </div>
                                <div className='col-12 d-flex justify-content-center align-items-center pb-1'>
                                    <div className={`col-2 ${style.imageSection}`}>
                                        <div className={`${style.woundAssessment_img_parent}`}>
                                            <img src={item?.picture} alt="wound" class="woundAssessment_wound_img" />
                                        </div>
                                    </div>
                                    <div className={`col-10 ${style.contentSection}`}>                                        
                                        <div className={`${style.assess_content} pb-1`}>  
                                            <span className={style.assess_content_label}>History/Cause: </span> 
                                            <span className={style.assess_label}>
                                                {item?.causeCategory !== "unknown"  ? (allNullCheck(item?.causeCategory) && allNullCheck(item?.cause) ?
                                                    (item?.causeCategory !== "others" || item?.causeCategory.toLowerCase() !== "others") ?
                                                    <span className=''>                                               
                                                        {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                                        if(data.key===item?.causeCategory.toLowerCase()){
                                                            return data?.options?.map((ele)=>{
                                                            if(ele.key!=='unabletodetermine'){
                                                                if(ele.key===item?.cause || ele.value===item?.cause){
                                                                    if(item?.causeCategory === "other"){
                                                                        return  ele.value
                                                                    }else{
                                                                        return data.value + '-' + ele.value
                                                                    }
                                                                }
                                                            }
                                                            else{
                                                                return data.value
                                                            }
                                                            })
                                                        }                      
                                                        })}
                                                    </span> : <span className=''>{item?.cause}</span>
                                                    : <span className=''>{`-`}</span>)
                                                : <span className=''>{`Unknown`}</span>
                                                }
                                            </span>
                                            </div>
                                        {/* <div className={`${style.assess_content} pb-3`}>  
                                            <span className={style.assess_content_label}>Admission Date: </span>
                                            <span>{format(new Date(item.identifiedDate.replace('Z', '')), 'MM/dd/yyyy hh:mm aa')}</span>
                                        </div> */}
                                        <div className={`${style.assess_content} pb-2`}>  
                                            <span className={style.assess_content_label}>Labels: </span> 
                                            <span className={style.assess_label}>{item?.injuryType !== "" && item?.injuryType !== null && item?.injuryType !== "null" && item?.injuryType}{item?.bodyType !== "" && item?.bodyType !== null && item?.bodyType !== "null" && ', '}{item?.bodyType !== "" && item?.bodyType !== null && item?.bodyType !== 'null' && item?.bodyType} {(item?.injuryType === "" || item?.injuryType === null || item?.injuryType === "null") && (item?.bodyType === "" || item?.bodyType === null || item?.bodyType === 'null') && 'None'} </span>
                                        </div>
                                        <div className={`${style.assess_content} pb-2`}>  
                                            <span className={style.assess_content_label}>Wound Acquired: </span> 
                                            <span className={style.assess_label}>{item?.woundAcquired} </span>
                                        </div>
                                        <div className={`${style.assess_content}`}>  
                                            <span className={style.assess_content_label}>Classification: </span> 
                                            <span className={style.assess_label}>
                                            {item?.classification !== "unknown" ? (allNullCheck(item?.classification) ?                                  
                                                <span className={`${style.classifi_content}`}>
                                                    {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                                                        if (data.key === item?.classification) {
                                                            return data.value
                                                        }
                                                    })
                                                    }
                                                </span> : <span className={`${style.classifi_content}`}>{`Unknown`}</span>) :
                                                <span className={`${style.classifi_content}`}>{`Unknown`}</span>}
                                            </span>
                                        </div>

                                    </div>  
                                </div>
                                
                                <div className={`col-12 ${style.lastContent} pl-3`}>
                                    <div className={`${style.assess_content} pb-1 pt-1`}>
                                    <span className={style.assess_content_label}>Photo taken by:</span> <span className={style.assess_label_subDetail_labelout}>{item?.photoTakenBy ? item?.photoTakenBy : 'NA'}</span>  
                                    {` | `}
                                    <span className={style.assess_content_label}>Photo taken on:</span> <span className={style.assess_label_subDetail_labelout}>{item?.identifiedDate ? fullMonthFormatDate(item?.identifiedDate) : 'NA'}</span>
                                    </div>

                                    <div className={`${style.assess_content} pb-1`}>
                                    <span className={style.assess_content_label}>Assessed by:</span> <span className={style.assess_label_subDetail_labelout}>{item?.assessedBy ? item?.assessedBy : 'NA'}</span>
                                    {` | `}
                                    <span className={style.assess_content_label}>Last assessed: </span>
                                    <span className={style.assess_label_subDetail_labelout}>
                                        {item?.lastAssessedDate ? fullMonthFormatDate(item?.lastAssessedDate) : 'NA'}
                                    </span>                                    
                                    </div>

                                    <div className={`${style.assess_content} pb-1`}>
                                    <span className={style.assess_content_label}>Signed:</span> <span className={style.assess_label_subDetail_labelout}>{item?.signedBy ? item?.signedBy : 'NA'}</span>
                                        {` | `}
                                    <span className={style.assess_content_label}>Reviewed & Locked:</span> <span className={style.assess_label_subDetail_labelout}>{item?.lockedBy ? item?.lockedBy : 'NA'}</span>
                                    </div>
                                </div>
                            </div>

                            {onHoverList===index && leaveHover && !movedSuccess && dropHereState ?                             
                                <div className={`${style.mergeListHover} mx-auto text-center`} onDrop={(e)=>drop(e, item?.id)} onDragOver={(e)=>allowDrop(e, item?.id)}>
                                    <div className={`${style.mergeListHoverPara}`}>
                                        <p>Drop here...</p>
                                    </div> 
                                    
                                </div>
                                : ""
                            }

                            {movedSuccess && onHoverList===index && !leaveHover && !giveConfirmation && <div className='d-flex justify-content-center pt-2' onDragLeave={(e)=>hoverLeaveRIghtList(index)}>
                                <div className="text-center py-3">                                                               
                                    {/* Cancel button  */}
                                    <Button
                                    classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel} ${style.btn_small_cancel_color} ${style.btn_small_cancel_bgcolor} mr-5`}}
                                    // id={CANCEL_ID}
                                    name="cancel"
                                    onClick={(e)=>{!allAssessmentState ? clickUndoChange(e) : clickUndoChangeAllAssessment(e)}}
                                    >
                                    {"Undo"}
                                    </Button>
                                    {/* Save Button */}
                                    <Button
                                    classes={{ className: `btn ${style.btn_custom} ${style.btn_small} ${style.btn_small_cancel_color}` }}
                                    type="submit"
                                    // id={SUBMIT_ID}
                                    onClick={(e)=>clickConfirmChange(e)}
                                    >
                                    {"Confirm"}
                                    </Button>
                                 </div>                         
                            </div>}

                            {giveConfirmation && !navigatePmtScreen && movedSuccess && onHoverList===index && !leaveHover &&
                                <div className={`${style.mergeWoundSuccess} text-center mr-3 ml-2 pt-3`}>                                    
                                    <p><span><i class="fa fa-check" aria-hidden="true" style={{color:"rgb(0, 173, 47)",paddingTop:"8px", paddingRight:'10px'}}></i></span> Wound merged successfully</p>                                
                                </div>
                            }
                        </div>
                    )
                }))
            }
            {woundAssessmentsAnatomyList?.assessments === undefined && <div className='text-center col-12 py-3'>
                <h6 className='text-center'>No records found</h6>
                </div>}
            </div>}
                    
            </div>
            </div>
        </div>
    )
}


MergeWoundAssessment.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
};