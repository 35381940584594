import React, { useEffect, useRef, useState } from 'react'
import { AccessErrorModal, Button, Form, MultiSelect, RadioButton, SelectBox } from '../../primary'
import { characteristicsImagesAsync, woundAssessmentUpdateAsync, patientAssessmentAsyncOverride,gettouchviewAsync,ne1updateassessmenttouchviewAsync, getwoundClosureOptions,setTouchViewDataByAssessID, getcauseAsync } from '../../../app/patientSlice'
import { toast } from 'react-toastify';
import editIcon from '../../../static/images/edit-icon.png'
import { useForm } from 'react-hook-form'
import { CharacteristicReferenceImages } from './characteristicReferenceImages'
import { CloseWarning } from '../../secondary'
import { useSelector } from 'react-redux'
import { allNullCheck, radioDefault } from '../../../utils/commonHelper'
import { signedLockedAccess } from '../../../utils'
import infoIcon from '../../../static/images/info.svg';

export function CharacteristicTouch({ index, dispatch, updateDone, selectedIndex, styles, patientId, assessmentId, assessmentDetails, getCharDetails, updateCharStatus, hideIt, setShowWound, overrideToggle,isCompleted, characteristicTabsData }) {
  const { register, errors, setError, clearErrors, handleSubmit,reset: reset} = useForm()
  const [assessmentDetailsNew, setAssessmentDetailsNew] = useState('');
    // Edit state
    const [editable, setEditable] = useState(false)
    //call the API for Update the data
    useEffect(()=>{
      dispatch(setTouchViewDataByAssessID({}));
      toast.dismiss('success1')
      setAssessmentDetailsNew('')
      reset({})
        let payload = { patientId, assessmentId, type: 'tissuetypes'} 
        dispatch(patientAssessmentAsyncOverride(payload))  
        dispatch(gettouchviewAsync(payload))  
        dispatch(getcauseAsync(payload)) 
        dispatch(getwoundClosureOptions())
        //gettissuetypeAsync 
    },[dispatch])
 
  const causeData = useSelector((state) => state.patient.causeDataByAssessID);

  const touchViewData = useSelector((state) => state.patient.touchViewDataByAssessID);
  const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)  
  const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
      /* get user role*/ 
    const getUserRole = useSelector(state => state.user);
  const [woundClosureErr, setwoundClosureErr] = useState(false);

  /*const typeOfwoundClosureOptions=[
    {
        "key": "sutures",
        "value": "Sutures"
    },
    {
        "key": "staples",
        "value": "Staples"
    },
    {
        "key": "adhesive",
        "value": "Adhesive"
    },
    {
        "key": "none",
        "value": "None"
    }
]*/

  const typeOfwoundClosureOptions = useSelector((state) => state.patient.woundClosureOptions);
  const [typeOfwoundClosureDefault, setTypeOfwoundClosureDefault] = useState([])
  useEffect(() => {
      if(touchViewData){
        setwoundClosureErr(false)
       //let typeOfwoundClosureUsed= [{isEnable:1,woundClosureType:'Sutures',quantity:3},{isEnable:0,woundClosureType:'Stapless',quantity:''},{isEnable:1,woundClosureType:'Adhesive',quantity:''}]
       let finalRec={...touchViewData?.touchView,typeOfwoundClosureUsed:touchViewData?.touchView?.woundClosure===null||touchViewData?.touchView?.woundClosure===undefined||touchViewData?.touchView===null?[]:touchViewData?.touchView?.woundClosure}
       setAssessmentDetailsNew({'touchView':finalRec})
       setTypeOfwoundClosureDefault(finalRec?.typeOfwoundClosureUsed) 
       if (isCompleted === 0) {
          setTypeOfwoundClosureDefault([])
          setAssessmentDetailsNew('')
          setEditable(true)
        }
      }else{
        setAssessmentDetailsNew('')
      }
  }, [touchViewData,isCompleted])

  const woundClosureDisplay= assessmentDetailsNew?.touchView?.woundClosure 
  //const woundClosureDisplay= [{"isEnable":"1","woundClosureType":"Sutures","quantity":"2"},{"isEnable":"1","woundClosureType":"Staples","quantity":"4"},{"isEnable":"1","woundClosureType":"Adhesive"}]

  const handlewoundClosure = (e, i, data) => { 


    
    if (e.target.checked&&document.getElementById('closure'+i)){
      document.getElementById('closure'+i).value=1
    }else if(document.getElementById('closure'+i)){
      document.getElementById('closure'+i).value=0
    }
   
    let dataCpy={...data}
    let DefaultCpy=[...typeOfwoundClosureDefault]
    if(typeOfwoundClosureDefault.length===0){
      if (e.target.checked) {
        dataCpy={...dataCpy,isEnable:1,quantity:'',woundClosureType:dataCpy?.value}
        setTypeOfwoundClosureDefault([dataCpy])
      }else{
        setTypeOfwoundClosureDefault([dataCpy={...dataCpy,isEnable:0,quantity:'',woundClosureType:dataCpy?.value}])
      }
    }else{
      if (e.target.checked) {
        const tempCp = [];
        dataCpy={...dataCpy,isEnable:1,quantity:'',woundClosureType:dataCpy?.value}
        //typeOfwoundClosureOptions
        let chkexit=0
        typeOfwoundClosureDefault.map((item,ind) => {
          let items={...item}
          if (item.woundClosureType === data.value) {
            chkexit=1
           
            tempCp.push(dataCpy)
          }else{
            if(dataCpy?.value==='None'&&item.woundClosureType!=='None'){
              items.isEnable=0
            }
            tempCp.push(items)
          }
        })
        if(chkexit===0){
         let finalCpy=[...DefaultCpy,{...dataCpy,isEnable:1,quantity:'',woundClosureType:dataCpy?.value}]
          setTypeOfwoundClosureDefault(finalCpy)
        }else{
          setTypeOfwoundClosureDefault(tempCp)
        }
       
      }else if (!e.target.checked){
        const tempCp = [];
        dataCpy={...dataCpy,isEnable:0,quantity:'',woundClosureType:dataCpy?.value}
        //typeOfwoundClosureOptions
        let chkexit=0
        typeOfwoundClosureDefault.map((item,ind) => {
          let items={...item}
          if (item.woundClosureType === data.value) {
            chkexit=1
            tempCp.push(dataCpy)
          }else{
            if(dataCpy?.value==='None'&&item.woundClosureType!=='None'){
              items.isEnable=0
            }
            tempCp.push(items)
          }
        })
        if(chkexit===0){
         let finalCpy=[...DefaultCpy,{...dataCpy,isEnable:0,quantity:'',woundClosureType:dataCpy?.value}]
          setTypeOfwoundClosureDefault(finalCpy)
        }else{
          setTypeOfwoundClosureDefault(tempCp)
        }
      }

    }
  
   
  }
  /* BWAT score */
  const [bwat, setBwat] = useState(null)


   // WoundImage Left fixing
  //  useEffect(()=>{   
  //   if(editable) {
  //     setShowWound(editable);
  //   }else{
  //     setShowWound(!editable);
  //   }  
          
  //   },[editable])


  // State for handling reference images 
  const [referenceImage, setReferenceImage] = useState(false)
  /* Warning state for closing modal */
  const [closeWarning, setCloseWarning] = useState(false)

  // States for shoing and hiding form fields 
  // Open area 
  const [openArea, setOpenArea] = useState(false)
  // Exudate type
  const [exudateType, setExudateType] = useState([])

  // Gettomg options list 
  const tissueTemperature = useSelector(state => state.patient.tissueTemperature);
  const tissueFirmness = useSelector(state => state.patient.tissueFirmness);
  const painLevel = useSelector(state => state.patient.painLevel);
  const blanchTest = useSelector(state => state.patient.blanchTest);
  const exudateTypeOption = useSelector(state => state.patient.exudateType);
  const exudateAmount = useSelector(state => state.patient.exudateAmount);
  const woundEdges = useSelector(state => state.patient.woundEdges);
  const skinColorAroundWound = useSelector(state => state.patient.skinColorAroundWound);
  const induration = useSelector(state => state.patient.induration);
  const edema = useSelector(state => state.patient.edema);
  const granulationTissue = useSelector(state => state.patient.granulationTissue);
  const epithelialization = useSelector(state => state.patient.epithelialization);
  const periwoundCondition = useSelector(state => state.patient.periwoundCondition);


  // Variable for displaying array with spaces (exudateType)
  const exudateTypeDisplay = []

  // const customerDetails = useSelector(state => state.customer.detailViewData)
  const userDetails = useSelector(state => state.user.userDetails)

  // We are conditional checking the 'tick' and 'need review' here one more time. 
  // Because when we make changes in tissue types, we need to reset classification.
  // Once we reset classification we need to display 'needs review' 

  // Set BWAT. If BWAT is YES, it will display some extra fields.
  useEffect(() => {
    if (userDetails) {
      setBwat(userDetails?.customerSettings?.woundAssessmentScore)
    }
  }, [userDetails])

  // If the API get call has touchview data, then show 'view' screen else show edit screen
  useEffect(() => {
    if (assessmentDetailsNew?.touchView?.openAreas && assessmentDetailsNew?.touchView?.openAreas === 1) {
      setOpenArea(true)
    }
    if (assessmentDetailsNew?.touchView?.exudateType && assessmentDetailsNew?.touchView?.exudateType.length > 0) {
      setExudateType(assessmentDetailsNew?.touchView?.exudateType)
    }
  
  }, [assessmentDetailsNew])

  /* Warning functions Yes and no */
  const handleYes = () => {
    if(exudateRef&&exudateRef.current){
     // exudateRef.current.resetSelectedValues([])
    }
    if (assessmentDetailsNew?.touchView?.exudateType && assessmentDetailsNew?.touchView?.exudateType.length > 0) {
      setExudateType(assessmentDetailsNew?.touchView?.exudateType)
    }else{
      setExudateType([])
    }
    setCloseWarning(false)
    setEditable(false)
  }
  /* No fn */
  const handleNo = () => {
    setCloseWarning(false)
  }

  //Handle open area field change 
  const handleOpenArea = (e) => {
    /* If yes, set open area state to true, else false */
    if (e.target.value === 'Yes') {
      setOpenArea(true)
    }
    else {
      setOpenArea(false)
    }
  }

  const [exudateNone, setExudateNone] = useState([])

  const exudateRef = useRef();

  //Handle exudate type
  const handleExudateType = (e) => {
    /* If yes, set open area state to true, else false */
    if (e && e?.target?.value) {
     /* let arr = [];
      e.map((data) => {
        if (data.key !== 'none') {
          if (arr.indexOf('none') === -1) {
            arr.push(data.key)
            setExudateNone([...arr])
          }
          else {
            arr.splice(arr.indexOf('none'), 1)
            arr.push(data.key)
            setExudateNone([...arr])
          }
        }
        if (data.key === 'none') {
          arr = [];
          arr.push(data.key)
          setExudateNone(['none'])
        }
      })*/
      setExudateType([e?.target?.value])
    }
    else {
      setExudateType([])
    }
  }

  // Getting details about wound assessment
  // Assign it to payload
  const payload = { type: 'touchviewpictures' }

  useEffect(() => {
    if (assessmentId) {
      // disptch to get touch and view photos
      dispatch(characteristicsImagesAsync(payload))
    }
  }, [assessmentId, dispatch])




  useEffect(() => {

    if (Object.keys(errors).length > 0) {
      setwoundClosureErr(false)
      if(causeData?.cause?.cause==='surgicalwound'){
    let chkEn=typeOfwoundClosureDefault.some(item => item.isEnable === 1||item.isEnable === '1')
          if(typeOfwoundClosureDefault.length===0||chkEn===false){
            setwoundClosureErr(true)
          }        
      }
      if (exudateType && exudateType?.length === 0) {
        setError('exudateType', {
          type: "manual",
          message: "Please select exudate type."
        })
      }
    }
  
  }, [errors])

  useEffect(() => {
    if (exudateType && exudateType?.length > 0) {
      clearErrors(['exudateType'])
    }
  }, [exudateType])
  const charStatus = useSelector(state => state.patient.charStatus)


  // Handle save 
  const handleSave = (data) => {
    setwoundClosureErr(false)
    let typeOfwoundClosureSel=[]
    if(causeData?.cause?.cause==='surgicalwound'&&data?.typeOfwoundClosureSel&&Array.isArray(data?.typeOfwoundClosureSel)){
     
      const numbers = data?.typeOfwoundClosureSel.map((item,index) => {
        if(item?.isEnable===1||item?.isEnable==='1')
        typeOfwoundClosureSel.push({isEnable: parseInt(item?.isEnable, 10),woundClosureType:item?.woundClosureType,quantity: item?.quantity?parseInt(item?.quantity, 10):0})
        //return parseInt(n, 10);
    });
    const chckCloseErr=data?.typeOfwoundClosureSel.some(item => item.isEnable === 1||item.isEnable === '1')
      if(chckCloseErr===false){
        setwoundClosureErr(true)
        return false
      }
    }
   // return false
    //setwoundClosureErr
    // if (exudateType && exudateType?.length === 0) {
    //   setError('exudateType', {
    //     type: "manual",
    //     message: "Please select atleast one Exudate Type."
    //   })
    //   return;
    // }
    // function to navigate to next screen     
    function navigate() {
      updateDone(index)
      if(Array.isArray(charStatus)&&charStatus[index]===1){
        setEditable(false)
    }else{
        selectedIndex(index + 1) /* user will get navigated to next tab */
    }
    }
    // Temp assessment with submitted data
    const tempAssessment = {
      touchView: {
        openAreas: data.openAreas ? data.openAreas === 'Yes' ? 1 : 0 : null,
        yellowFibrin: data.yellowFibrin ? data.yellowFibrin === 'Yes' ? 1 : 0 : null,
        bioFilm: data.bioFilm ? data.bioFilm === 'Yes' ? 1 : 0 : null,
        tissueTemperature: data.tissueTemperature,
        tissueFirmness: data.tissueFirmness,
        painLevel: data.painLevel,
        blanchTest: data.blanchTest,
        periwoundCondition:data.periwoundCondition,
        evidenceOfInfection: data.evidenceOfInfection ? data.evidenceOfInfection === 'Yes' ? 1 : 0 : null,
        exudateType: exudateType.length > 0 ? exudateType : [],
        exudateAmount: data.exudateAmount,
        woundOdor: data.woundOdor ? data.woundOdor === 'Yes' ? 1 : 0 : null,
        woundEdges: data.woundEdges ? data.woundEdges : null,
        skinColorAroundWound: data.skinColorAroundWound ? data.skinColorAroundWound : null,
        induration: data.induration ? data.induration : null,
        edema: data.edema ? data.edema : null,
        granulationTissue: data.granulationTissue ? data.granulationTissue : null,
        epithelialization: data.epithelialization ? data.epithelialization : null,
        woundClosure:data?.typeOfwoundClosureSel?typeOfwoundClosureSel:null
      },
    }
    // Merging our temp assessment with assessment details and dispatching
    const assessment = { ...assessmentDetails, ...tempAssessment }
    const payload = {
      patientId,
      assessmentId,
      assessment,
      navigate,
      getCharDetails,
      overrideisEnable : overrideToggle ? true : false
    }
    //dispatch(woundAssessmentUpdateAsync(payload))
    dispatch(ne1updateassessmenttouchviewAsync(payload))
  }


  {/* added for Web | NE1 | Touch & View Details */}
  const ne1getWounAssessmentDetailDataValue = ne1getWounAssessmentDetailData?.forms?.length + 23 + 'px'; 
  const referImage = {
    marginBottom: "0px",
    maxWidth: "275px",
    marginTop: ne1getWounAssessmentDetailData?.forms?.length > 0 ? ne1getWounAssessmentDetailDataValue.toString() : '20px',
    position: "absolute",
    left: "-146%",
    // top: "760%",
    transform: 'translateY(78%)',
  }

  const referImageClick = {
    marginBottom: "0px",
    maxWidth: "275px",
    marginTop: ne1getWounAssessmentDetailData?.forms?.length > 0 ? ne1getWounAssessmentDetailDataValue.toString() : '20px',
    position: "absolute",
    right: "1059px",
    // top: "361px",
    transform: 'translateY(78%)',
  }

  const referImageImg = {
    maxWidth: "280px"
  }
{/* --------------------------------------- */}

// <-----ZoomIn/Out OPtion----->
const [initalState, setInitalState] = useState(0);
const [zoomClick, setZoomClick] = useState(false);
    
    const zoomInAction = (direction) => {
        if(initalState <= 8){
            setZoomClick(true)
            setInitalState(initalState + 1) 
        }
        else{
            setZoomClick(true)
            setInitalState(9)
        }

        // const delta = 10 * direction;
        // const element = document.getElementById('patient_wound');
        // const positionInfo = element.getBoundingClientRect();
        // element.style.width = positionInfo.width+delta+'px';
        // element.style.height = positionInfo.height+(delta-1)+'px';
    }


    const zoomOutAction = (direction) => { 
        if(initalState >= 1 && initalState <= 9) {
            setZoomClick(true)
            setInitalState(initalState - 1)
        }
        
        if(initalState === 0){
            setZoomClick(true)
            setInitalState(0)
        } 
        if (initalState < 0){
            setZoomClick(true)
            setInitalState(0)
        }     
    }    

    const reSizeAction = () => {
        setZoomClick(true)
        setInitalState(0) 
    }

    useEffect(()=>{
        if(initalState === 0){
            setZoomClick(true)
            setInitalState(0)
        } 
    },[initalState])
// <-----ZoomIn/Out OPtion----->
//Wound Closer none option
let isNone= Array.isArray(typeOfwoundClosureDefault)&&typeOfwoundClosureDefault.find(item => {
  return item.value === "None"&&item.isEnable==1 || item.woundClosureType === "None"&&item.isEnable==1
})

let isEnableChkwoundClosureType= Array.isArray(woundClosureDisplay)&&woundClosureDisplay.filter(item => {
  return item.isEnable==1 
})
  return (
    <div className={`${styles.mesurement_blk} pt-1 pb-0`}>

      <AccessErrorModal />

      <div>
        {!editable && <>
          {/* Edit button  */}
          {!referenceImage && signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={
            () =>{setEditable(true);}             
            }>
            <img src={editIcon} alt="edit" />Edit
          </div>}

          {assessmentDetailsNew?.touchView === null ? 'Touch & View details not found. Click edit to start adding details.'

            :

            <div>

              {/* Reference image component */}
              {referenceImage && <CharacteristicReferenceImages setReferenceImage={setReferenceImage} styles={styles} />}

              {!referenceImage && <>

                {/* Reference images button */}
               
                <button
                  className={styles.reference_images}
                  onClick={() => setReferenceImage(true)}
                >
                  <span>
                    {/* <i class={`fa fa-circle-info`} style={{fontSize:"16px", paddingRight:"1px", color:"#0052cc"}} aria-hidden="true"></i> */}
                    <img src={infoIcon} alt="info" style={{fontSize:"16px", paddingRight:"3px", marginTop : "-3px", color:"#0052cc"}}/>
                  </span>
                  Reference Images
                </button>


                {/* Saved data */}
                <p><label>Are there any open areas?</label><span>{assessmentDetailsNew?.touchView?.openAreas === 1 ? 'Yes' : 'No'}</span></p>

                {assessmentDetailsNew?.touchView?.openAreas === 1 && <>
                  <p><label>Is yellow fibrin present in the wound?</label><span>{assessmentDetailsNew?.touchView?.yellowFibrin === 1 ? 'Yes' : 'No'}</span></p>
                  <p><label>Is biofilm present in the wound?</label><span>{assessmentDetailsNew?.touchView?.bioFilm === 1 ? 'Yes' : 'No'}</span></p>
                </>}

                {allNullCheck(assessmentDetailsNew?.touchView?.tissueTemperature) ?
                  <p><label>Tissue temperature:</label><span>
                    {tissueTemperature && tissueTemperature.length > 0 && tissueTemperature.map((data) => {
                      if (assessmentDetailsNew?.touchView?.tissueTemperature === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  : ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.tissueFirmness) ?
                  <p><label>Tissue firmness:</label><span>
                    {tissueFirmness && tissueFirmness.length > 0 && tissueFirmness.map((data) => {
                      if (assessmentDetailsNew?.touchView?.tissueFirmness === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  : ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.painLevel) ?
                  <p><label>Pain level:</label><span>
                    {painLevel && painLevel.length > 0 && painLevel.map((data) => {
                      if (assessmentDetailsNew?.touchView?.painLevel === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  : ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.blanchTest) ?
                  <p><label>Blanch test: capillary refill of intact skin:</label><span>
                    {blanchTest && blanchTest.length > 0 && blanchTest.map((data) => {
                      if (assessmentDetailsNew?.touchView?.blanchTest === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  : ''
                }

            {allNullCheck(assessmentDetailsNew?.touchView?.periwoundCondition) ?
                            <p><label>Periwound skin condition:</label><span>
                              {periwoundCondition && periwoundCondition.length > 0 && periwoundCondition.map((data) => {
                                if (assessmentDetailsNew?.touchView?.periwoundCondition === data.key) {
                                  return data.value
                                }
                              })}
                            </span></p>
                            : ''
                          }
                {allNullCheck(assessmentDetailsNew?.touchView?.evidenceOfInfection) ?
                  <p><label>Evidence of infection?</label><span>{assessmentDetailsNew?.touchView?.evidenceOfInfection === 1 ? 'Yes' : 'No'}</span></p>
                  :
                  ''}

                <p><label>Exudate type:</label><span>
                  {assessmentDetailsNew?.touchView?.exudateType && assessmentDetailsNew?.touchView?.exudateType.length > 0 && assessmentDetailsNew?.touchView?.exudateType.map((data) => {
                    exudateTypeOption && exudateTypeOption.length > 0 && exudateTypeOption.map((item) => {
                      if (item.key === data) {
                        exudateTypeDisplay.push(item.value)
                      }
                    })
                  })}
                  {exudateTypeDisplay && exudateTypeDisplay.length > 0 ? exudateTypeDisplay.join(', ') : '-'}
                </span></p>


                

                {assessmentDetailsNew?.touchView?.exudateType &&Array.isArray(exudateType)&& exudateType.indexOf('none') === -1&&assessmentDetailsNew?.touchView?.exudateAmount&& assessmentDetailsNew?.touchView?.exudateType.length > 0 &&
                  <p><label>Exudate amount: </label><span>
                    {exudateAmount && exudateAmount.length > 0 && exudateAmount.map((data) => {
                      if (assessmentDetailsNew?.touchView?.exudateAmount === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>}

                  {causeData?.cause?.cause==='surgicalwound'&&Array.isArray(isEnableChkwoundClosureType)&&<p><label>Type of wound closure used?&nbsp;</label><span>
                    
                  {isEnableChkwoundClosureType && isEnableChkwoundClosureType.length > 0 && isEnableChkwoundClosureType.map((data,i) => {
                    if(data?.isEnable===1){
                      return <>
                      {isEnableChkwoundClosureType.length!==1&&i!==0&&isEnableChkwoundClosureType.length!==i&&<>&nbsp;   |  &nbsp;</>}
                     <span>{data?.woundClosureType}</span>&nbsp; 
                     {(data?.woundClosureType!=='Adhesive'||data?.woundClosureType!=='None')&&data?.quantity!==0&&data?.quantity&&<span><b>Qty:</b><span style={{color: '#0052cc' }}> {data?.quantity}</span></span>}
                    
                     </>
                    }
                 
                  })}
                 
                </span></p>}

                {allNullCheck(assessmentDetailsNew?.touchView?.woundOdor) ?
                  <p><label>Wound odor:</label><span>{assessmentDetailsNew?.touchView?.woundOdor === 1 ? 'Yes' : 'No'}</span></p>
                  : ''
                }


                {allNullCheck(assessmentDetailsNew?.touchView?.woundEdges) ?
                  <p><label>Wound edges:</label><span>
                    {woundEdges && woundEdges.length > 0 && woundEdges.map((data) => {
                      if (assessmentDetailsNew?.touchView?.woundEdges === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  :
                  ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.skinColorAroundWound) ?
                  <p><label>Skin color surrounding wound:</label><span>
                    {skinColorAroundWound && skinColorAroundWound.length > 0 && skinColorAroundWound.map((data) => {
                      if (assessmentDetailsNew?.touchView?.skinColorAroundWound === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  :
                  ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.induration) ?
                  <p><label>Induration:</label><span>
                    {induration && induration.length > 0 && induration.map((data) => {
                      if (assessmentDetailsNew?.touchView?.induration === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  :
                  ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.edema) ?
                  <p><label>Edema:</label><span>
                    {edema && edema.length > 0 && edema.map((data) => {
                      if (assessmentDetailsNew?.touchView?.edema === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  :
                  ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.granulationTissue) ?
                  <p><label>Granulation tissue:</label><span>
                    {granulationTissue && granulationTissue.length > 0 && granulationTissue.map((data) => {
                      if (assessmentDetailsNew?.touchView?.granulationTissue === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  :
                  ''
                }

                {allNullCheck(assessmentDetailsNew?.touchView?.epithelialization) ?
                  <p><label>Epithelialization:</label><span>
                    {epithelialization && epithelialization.length > 0 && epithelialization.map((data) => {
                      if (assessmentDetailsNew?.touchView?.epithelialization === data.key) {
                        return data.value
                      }
                    })}
                  </span></p>
                  :
                  ''
                }

              </>}

            </div>
          }
        </>

          }
{editable&&
          <>
            {/* On close warning */}
            {closeWarning && <CloseWarning
              message="All progress in this section will be lost. Are you sure want to cancel?"
              yesFn={handleYes}
              noFn={handleNo}
            />
            }
{getUserRole?.role==='ExternalStandardUser'&&isCompleted===0?
 <><p>Touch view details is not selected for this wound</p></>
 
 :
 Array.isArray(characteristicTabsData)&&characteristicTabsData[0]['category']==="History/Cause"&&characteristicTabsData[0]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select History/Cause before selecting Touch & View details'}
                                </div>  
 
 </>
 :
 Array.isArray(characteristicTabsData)&&characteristicTabsData[1]['category']==="Tissue Type"&&characteristicTabsData[1]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select Tissue Type(s) before selecting Touch & View details'}
                                </div>  
 
 </>
 :
 <>

            {/* Form field  */}
            <div className="container-fluid pl-1 pr-1">
              <div className={`${styles.patient_content} pt-0`}>

                <Form formChanges={true} onSubmit={handleSubmit(handleSave)} className={'col-12 p-0'}>

                  {/* added for Web | NE1 | Touch & View Details */}

                  {/* Reference image component */} 
                  <div className={`col-12 ${styles.col_spec} mb-3`}>
                      
                      <div className={!referenceImage ? `row w-100`: `row justify-content-between w-100`}>
                        {referenceImage && <div className={`col-3`}>
                          <CharacteristicReferenceImages setReferenceImage={setReferenceImage} styles={styles} />
                        </div> 
                        }
                        <div className={`col-5 pt-1`}>
                          {!referenceImage && <>
                            {/* Reference images button */}
                            
                            <button
                              className={`${styles.reference_images} text-left`}
                              onClick={() => setReferenceImage(true)}
                            >
                              <span>
                                {/* <i class={`fa fa-info-circle`} style={{fontSize:"16px", paddingRight:"1px", color:"#0052cc", ba}} aria-hidden="true"></i> */}
                                <img src={infoIcon} alt="info" style={{fontSize:"16px", paddingRight:"3px", marginTop : "-3px", color:"#0052cc"}}/>
                              </span>
                              Reference Images
                            </button>
                            </>
                          }
                        </div>                        
                        
                        <div className={!referenceImage ? `col-6` : `col-4`}>
                          {assessmentDetails?.isWoundWithoutPhoto !== 1 && <>
                            <div className={!referenceImage ? `${referImage}` : `${referImageClick}`} style={!referenceImage ? referImage : referImageClick}>
                              <div className={`d-flex justify-content-between align-items-center pl-1 pb-2`} style={{width:'275px'}}>
                                  <h3 className='text-left pl-1' style={{fontSize : "16px", fontWeight : "bold"}}>Patient’s Wound</h3>
                                  <div className={`${styles.iconListEdit} d-flex align-items-center justify-content-between`}>
                                      <div className='mr-2'>
                                          <i class={`fa fa-search-plus ${styles.iconHover}`} style={{color : 'rgb(95 95 95)'}} onClick={zoomInAction} aria-hidden="true"></i>
                                      </div>
                                      <div className='mr-2'>
                                          <i class={`fa fa-search-minus ${styles.iconHover}`} style={{color : 'rgb(95 95 95)'}} onClick={zoomOutAction} aria-hidden="true"></i>
                                      </div>
                                      <div className=''>
                                          <i class={`fa fa-arrows-alt ${styles.iconHover}`} style={{transform :`rotate(45deg) scale(1.1)`,color : 'rgb(95 95 95)'}} onClick={reSizeAction} aria-hidden="true"></i>
                                      </div>
                                  </div>
                              </div>
                              <div  className={`${styles.patient_wound}`} style={{width:'275px', overflow:'hidden', cursor: 'grab'}}>
                                  <img 
                                      src={assessmentDetails?.picture} 
                                      alt="wound" 
                                      className={`${styles.patient_wound_img}`} 
                                      id="patient_wound" 
                                      style={Object.assign({},{width :'275px'},
                                          zoomClick === true && {transform : `scale(1.${initalState}, 1.${initalState})`})}
                                  />
                              </div>
                              {/* <img src={assessmentDetails?.picture} alt="wound" style={referImageImg}/> */}
                            </div>
                          </>
                          }
                        </div>

                        {/* save and cancle top side */} 
                        {/* {hideIt && <div className="col-12">
                          <div className='col-5'></div>
                          <div className={`col-7 offset-5 ${styles.top_save_cancel}`}>
                            <Button
                              classes={{ className: `btn btn-small ml-0 mr-4 ${styles.btn_small} ${styles.btn_custom}` }}
                              type="submit"
                              id="next"
                            >
                              Save
                            </Button>
                            <Button
                              type="button"
                              value="Cancel"
                              classes={{ className: `btn btn-small ml-2 ${styles.btn_small_cancel} ${styles.btn_custom}` }}
                              id="cancel"
                              onClick={() => {
                                window.scrollTo(0, 0)
                                setCloseWarning(true)
                              }}
                            />
                          </div>
                        </div>
                        } */}
                        {/* save and cancle top side */}

                      </div>  
                  </div>

                  {/* .......... */}



                 {/*<div className={assessmentDetails?.isWoundWithoutPhoto !== 1 ? `${styles.withPhotoSticky}` : `${styles.withoutPhotoSticky}`}>  save and cancle sticky */}
                
                <div className=''>
                  {/* Any open areas? */}
                  <div className={`col-12 ${styles.col_spec}`}>
                    <label className={`col-5 col-form-label ${styles.label_spec}`}>Are there any open areas? <span style={{ color: '#de350b' }}>*</span></label>
                    <RadioButton
                      id="openAreas"
                      name="openAreas"
                      dataTestid="openAreas"
                      classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                      }}
                      radioOption={['Yes', 'No']}
                      validator={register({ required: "Please select Yes or No" })}
                      errors={errors}
                      onChange={handleOpenArea}
                      defaultValue={radioDefault(assessmentDetailsNew?.touchView?.openAreas)}
                    />
                  </div>

                  {openArea && <>
                    {/* Yellow fibrin */}
                    <div className={`col-12 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>Is yellow fibrin present in the wound? <span style={{ color: '#de350b' }}>*</span></label>
                      <RadioButton
                        id="yellowFibrin"
                        name="yellowFibrin"
                        classes={{
                          labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                          inputClassName: `custom-control-input ${styles.custom_control_input}`,
                          inputColumn: "custom-control custom-radio custom-control-inline"
                        }}
                        radioOption={['Yes', 'No']}
                        validator={register({ required: "Please select Yes or No" })}
                        errors={errors}
                        defaultValue={radioDefault(assessmentDetailsNew?.touchView?.yellowFibrin)}
                      />
                    </div>

                    {/* Biofilm */}
                    <div className={`col-12 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>Is biofilm present in the wound? <span style={{ color: '#de350b' }}>*</span></label>
                      <RadioButton
                        id="bioFilm"
                        name="bioFilm"
                        classes={{
                          labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                          inputClassName: `custom-control-input ${styles.custom_control_input}`,
                          inputColumn: "custom-control custom-radio custom-control-inline"
                        }}
                        radioOption={['Yes', 'No']}
                        validator={register({ required: "Please select Yes or No" })}
                        errors={errors}
                        defaultValue={radioDefault(assessmentDetailsNew?.touchView?.bioFilm)}
                      />
                    </div>
                  </>}

                  {/* Evidence of infection */}
                  <div className={`col-12 ${styles.col_spec}`}>
                    <label className={`col-5 col-form-label ${styles.label_spec}`}>Evidence of infection? <span style={{ color: '#de350b' }}>*</span></label>
                    <RadioButton
                      id="evidenceOfInfection"
                      name="evidenceOfInfection"
                      classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                      }}
                      radioOption={['Yes', 'No']}
                      validator={register({ required: "Please select Yes or No" })}
                      errors={errors}
                      defaultValue={radioDefault(assessmentDetailsNew?.touchView?.evidenceOfInfection)}
                    />
                  </div>

                  {/* Exudate Type */}
                  {/* {exudateTypeOption && exudateTypeOption.length > 0 && <MultiSelect
                    id="exudateType"
                    name="exudateType"
                    label={`Exudate Type:`}
                    required={true}
                    classes={{
                      column: `col-12 ${styles.col_spec} ${styles.multi_select_fix}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec} ${styles.multi_select_label_fix}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.multi_select}`,
                    }}
                    optionValue={exudateTypeOption}
                    validator={(e) => {
                      register({ required: true })
                      exudateRef.current = e
                    }}
                    onSelect={handleExudateType}
                    onRemove={handleExudateType}
                    showCheckbox={true}
                    displayValue={"value"}
                    // /defaultValue=
                    defaultValue={Array.isArray(exudateType)&& exudateType.length > 0 ? exudateType :[]}
                   // defaultValue={exudateNone && exudateNone.length < 1 ? assessmentDetailsNew?.touchView?.exudateType : exudateNone}
                    errors={errors}
                    labelSubText={'Select all that apply'}
                  />} */}

                  {exudateTypeOption && exudateTypeOption.length > 0 &&  <>
                    {/* Exudate Amount */}
                    <SelectBox
                      id="exudateType"
                      name="exudateType"
                      label={`Exudate type:`}
                      required={true}
                      classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                      }}
                      optionValue={exudateTypeOption}
                      validator={register({ required: "Please select exudate type" })}
                    
                      errors={errors}
                      defaultValue={Array.isArray(exudateType)&& exudateType.length > 0 ? exudateType :[]}
                      placeholder='Select'
                      onChange={handleExudateType}
                    />
                  </>}

   
                  {exudateType &&Array.isArray(exudateType)&&exudateType.length > 0 && exudateType.indexOf('none') === -1 && <>
                    {/* Exudate Amount */}
                    <SelectBox
                      id="exudateAmount"
                      name="exudateAmount"
                      label={`Exudate amount:`}
                      required={true}
                      classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                      }}
                      optionValue={exudateAmount}
                      validator={register({ required: "Please select exudate amount" })}
                      errors={errors}
                      defaultValue={assessmentDetailsNew?.touchView?.exudateAmount}
                      placeholder='Select'
                    />
                  </>}

                                 {/*causeData?.cause - surgicalwound typeOfwoundClosureDefault*/}

         {


causeData?.cause?.cause==='surgicalwound'&&
<>

<div className={`col-12 ${styles.col_spec}`}>
          <label className={`col-5 col-form-label ${styles.label_spec}`}>Type of wound closure used? <span style={{ color: '#de350b' }}>*</span>
          <br/><small>(Select all that apply)</small>
          </label>
   
          <div className={`row  d-block`}>
                  {Array.isArray(typeOfwoundClosureOptions)&&typeOfwoundClosureOptions.map((data, i) => {
                  let checkDef=0
                  let quantity=''
                   Array.isArray(typeOfwoundClosureDefault) && typeOfwoundClosureDefault.length > 0 && typeOfwoundClosureDefault?.map((item) => {
                    if(item?.isEnable===1&&item?.woundClosureType===data?.value){
                      checkDef= 1
                      quantity=item?.quantity
                    }
                    })

                  return(

                    <div className={`col-12 d-flex`} style={{ height: '37px'}} key={i}>
          
                    {/* Checkbox */}
                    <div className={`custom-control-inline ${styles.custom_checkbox}`}>
                        <input
                            type="checkbox"
                            className={` ${styles.custom_control_input}`}
                           id={'closure'+i}
                           onClick={(e) => handlewoundClosure(e, i, data)} // Pass event and index 
                           
                           // onClick={(e) => handleSelect(e, i, data.id, false)} // Pass event and index 
                          //  defaultChecked={checkFlag[i] || selectedCheck(data.id)}
                           disabled={data?.value!=='None'&&isNone&&isNone.isEnable === 1 ? true : false}
                         checked= {data?.value!=='None'&&isNone&&isNone.isEnable === 1?false:checkDef===1?true:false}
                          defaultChecked= {checkDef===1?true:0}
                         // name={`typeOfwoundClosureSel[${i}][isEnable]`}
                        //  ref={register()}
                          value={checkDef===1?1:checkDef===0?0:0}
                           
                        />
                         <input type='hidden' name={`typeOfwoundClosureSel[${i}][isEnable]`} ref={register()} value={`${isNone&&data?.value!=='None'&&isNone?.isEnable === 1?0:Number(checkDef===1?1:checkDef===0?0:0)}`} />
                         <input type='hidden' name={`typeOfwoundClosureSel[${i}][woundClosureType]`} ref={register()} value={data?.value} />
                        <label
                            className={`custom-control-label ${styles.custom_control_label_check} ${data?.value!=='None'&&isNone&&isNone.isEnable === 1 ? styles.disable_gray : ''}`}
                            htmlFor={'closure'+i}></label>                                         
                    </div>
                    <div className={`col-2 ml-n3`}>{data?.value}</div>
                    {/* Heading */}
                    {/* <h4>{data.name} <span>({codeSpliter(data.code)})</span></h4> */}
                   
                    {/* <h4 className={data?.isEnable === 0 ? styles.disable_head : styles.undisable_head}>{data.woundClosureType}</h4> */}

{((checkDef===1&&data?.value!=='Adhesive'&&isNone?.isEnable !== 1)&&(checkDef===1&&data?.value!=='None'&&isNone?.isEnable !== 1))&&<>
                    &nbsp;
                    <div className={`col-1`}><b>
                      Qty:  &nbsp;
                    </b> 
                    </div>
                    <div className={`col-6 ml-2`}>
                   
                        {/* Quantity */}
                        {/* {  <span className={styles.display_percent}>
                               
                                {result && result.length > 0 && result?.map((item) => {
                                    if (item.woundClosureType === data.woundClosureType) {
                                        
                                        if (item.woundClosureType) {
                                            return item.woundClosureType 
                                        } else {
                                            return null
                                        }
                                    }
                                })}
                            </span>} */}
                    
                        {/* Input field */}
                        {data.quantity !== 'N/A'
                            && <input type="number" 
                            name={`typeOfwoundClosureSel[${i}][quantity]`} ref={register()} 
                            min={0}
                            step={1}
                            onKeyDown={(e) => {
                            if (e.code === 'Minus') {
                                e.preventDefault();
                                }
                              }}
                             defaultValue={quantity}
                            //onBlur={(e) => handleInput(e, i, data.id)} 
                            />}

                      
                    </div></>
                  }
                      </div>  
                
                  )

                  })
                  } 
                    {/* Error message */}
                    {woundClosureErr&&<><span className={'ml-3'} style={{ color: '#de350b'}}>Please select type of wound closure used</span></>}

                  </div>
                  
                 
          
          

        </div>

</>
}

                  {/* Wound edges */}
                  <SelectBox
                    id="woundEdges"
                    name="woundEdges"
                    label={`Wound edges:`}
                    required={bwat === 'bwat' ? true : false}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={woundEdges}
                    placeholder='Select'
                    validator={register({ required: bwat === 'bwat' ? "Please select wound edges" : false})}
                    errors={errors}
                    defaultValue={assessmentDetailsNew?.touchView?.woundEdges}
                  />

                  {/* Skin color surrounding wound  */}
                  <SelectBox
                    id="skinColorAroundWound"
                    name="skinColorAroundWound"
                    label={`Skin color surrounding wound:`}
                    required={bwat === 'bwat' ? true : false}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={skinColorAroundWound}
                    placeholder='Select'
                    validator={register({ required: bwat === 'bwat' ? "Please select skin color" : false })}
                    errors={errors}
                    defaultValue={assessmentDetailsNew?.touchView?.skinColorAroundWound}
                  />

                  {/* Induration */}
                  <SelectBox
                    id="induration"
                    name="induration"
                    label={`Induration:`}
                    required={bwat === 'bwat' ? true : false}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={induration}
                    placeholder='Select'
                    validator={register({ required: bwat === 'bwat' ? "Please select induration" : false })}
                    errors={errors}
                    defaultValue={assessmentDetailsNew?.touchView?.induration}
                  />

                  {/* Edema */}
                  <SelectBox
                    id="edema"
                    name="edema"
                    label={`Edema:`}
                    required={bwat === 'bwat' ? true : false}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={edema}
                    placeholder='Select'
                    validator={register({ required: bwat === 'bwat' ? "Please select edema" : false })}
                    errors={errors}
                    defaultValue={assessmentDetailsNew?.touchView?.edema}
                  />

                  {bwat === 'bwat' && <>

                      {/* Granulation tissue */}
                      <SelectBox
                        id="granulationTissue"
                        name="granulationTissue"
                        label={`Granulation tissue:`}
                        required={bwat === 'bwat' ? true : false}
                        classes={{
                          column: `col-12 ${styles.col_spec}`,
                          inputClassName: styles.basic_select,
                          labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                          inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                        }}
                        optionValue={granulationTissue}
                        placeholder='Select'
                        validator={register({ required: bwat === 'bwat' ? "Please select granulation tissue" : false })}
                        errors={errors}
                        defaultValue={assessmentDetailsNew?.touchView?.granulationTissue}
                      />

                      {/* Epithelialization */}
                      <SelectBox
                        id="epithelialization"
                        name="epithelialization"
                        label={`Epithelialization:`}
                        required={bwat === 'bwat' ? true : false}
                        classes={{
                          column: `col-12 ${styles.col_spec}`,
                          inputClassName: styles.basic_select,
                          labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                          inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                        }}
                        optionValue={epithelialization}
                        placeholder='Select'
                        validator={register({ required: bwat === 'bwat' ? "Please select epithelialization" : false})}
                        errors={errors}
                        defaultValue={assessmentDetailsNew?.touchView?.epithelialization}
                      />
                    </>
                  }
                  {/* Tissue temperature */}
                  <SelectBox
                    id="tissueTemperature"
                    name="tissueTemperature"
                    label={`Tissue temperature:`}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={tissueTemperature}
                    placeholder='Select'
                    validator={register}
                    defaultValue={assessmentDetailsNew?.touchView?.tissueTemperature}
                  />

                  {/* Tissue firmness */}
                  <SelectBox
                    id="tissueFirmness"
                    name="tissueFirmness"
                    label={`Tissue firmness:`}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={tissueFirmness}
                    placeholder='Select'
                    validator={register}
                    defaultValue={assessmentDetailsNew?.touchView?.tissueFirmness}
                  />

                  {/* Pain level */}
                  <SelectBox
                    id="painLevel"
                    name="painLevel"
                    label={`Pain level:`}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={painLevel}
                    placeholder='Select'
                    validator={register}
                    defaultValue={assessmentDetailsNew?.touchView?.painLevel}
                  />

                  {/* Blanch */}
                  <SelectBox
                    id="blanchTest"
                    name="blanchTest"
                    label={`Blanch test: capillary refill of intact skin:`}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={blanchTest}
                    placeholder='Select'
                    validator={register}
                    defaultValue={assessmentDetailsNew?.touchView?.blanchTest}
                  />
                    {/* Peri wound Condition */}
                  <SelectBox
                    id="periwoundCondition"
                    name="periwoundCondition"
                    label={`Periwound skin condition:`}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={periwoundCondition}
                    placeholder='Select'
                    validator={register}
                    defaultValue={assessmentDetailsNew?.touchView?.periwoundCondition}
                  />
                  
                  {/* Wound odor */}
                  <div className={`col-12 ${styles.col_spec}`}>
                    <label className={`col-5 col-form-label ${styles.label_spec}`}>Wound odor:</label>
                    <RadioButton
                      id="woundOdor"
                      name="woundOdor"
                      classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                      }}
                      radioOption={['Yes', 'No']}
                      validator={register}
                      defaultValue={radioDefault(assessmentDetailsNew?.touchView?.woundOdor)}
                    />
                  </div>
                  
                </div>


                  {/* Button section */}
                  {(signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt) && <div className="col-12 mt-5">
                  {/* {hideIt && <div className="col-12 mt-4 pt-4" style={{borderTop:'2px solid #d1d1d1'}}> save & cancel sticky*/}
                    <div className="offset-5">
                      <Button
                        classes={{ className: `btn btn-small ml-0 mr-4 ${styles.btn_small} ${styles.btn_custom}` }}
                        type="submit"
                        id="next"
                      >
                        Save
                      </Button>
                      <Button
                        type="button"
                        value="Cancel"
                        classes={{ className: `btn btn-small ml-2 ${styles.btn_small_cancel} ${styles.btn_custom}` }}
                        id="cancel"
                        onClick={() => {
                          window.scrollTo(0, 0)
                          setCloseWarning(true)
                        }}
                      />
                    </div>
                  </div>}


                </Form>

              </div>
            </div>
</>
        }
          </>
        }

      </div>

    </div>
  )
}
