import React, { useEffect, useState } from 'react'
import { CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants/index';
import { Form, Button, SelectBox, DropDownItems } from '../../primary'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { postRiskAssessmentsAsync, setRiskAssessmentResponseScore, getRiskAssessmentOptionsAsync } from '../../../app/patientSlice'
import { RiskAssessmentScale } from './riskAssessmentScale';
export function CreateRiskAssessmentFormView({ styles, dispatch, checkRiskAssessmentScale, handleCancel }) {
    const ERR_MSG="Please select the field";
    const [modalShowRiskScale, setModalShowRiskScale] = useState(false);
    /* Use hook form */
    const { reset, register, handleSubmit, control, errors } = useForm({
        mode: 'all'
    });
    const { patientId } = useParams();
    // Get API fetch loading
    const loading = useSelector(state => state.app.loading);
    const bradenSensoryPerception = useSelector(state => state.patient.bradenSensoryPerception);
    const bradenMoisture = useSelector(state => state.patient.bradenMoisture);
    const bradenActivity = useSelector(state => state.patient.bradenActivity);
    const bradenMobility = useSelector(state => state.patient.bradenMobility);
    const bradenNutrition = useSelector(state => state.patient.bradenNutrition);
    const bradenFrictionShear = useSelector(state => state.patient.bradenFrictionShear);
    const nortonPhysicalCondition = useSelector(state => state.patient.nortonPhysicalCondition);
    const nortonMentalCondition = useSelector(state => state.patient.nortonMentalCondition);
    const nortonActivity = useSelector(state => state.patient.nortonActivity);
    const nortonMobility = useSelector(state => state.patient.nortonMobility);
    const nortonIncontinence = useSelector(state => state.patient.nortonIncontinence);
    const riskAssessmentResponseScore = useSelector(state => state.patient.riskAssessmentResponseScore);
    useEffect(() => {
        dispatch(setRiskAssessmentResponseScore(''));
        if (checkRiskAssessmentScale && checkRiskAssessmentScale === 'norton') {
            dispatch(getRiskAssessmentOptionsAsync('nortonphysicalcondition'));
            dispatch(getRiskAssessmentOptionsAsync('nortonmentalcondition'));
            dispatch(getRiskAssessmentOptionsAsync('nortonactivity'));
            dispatch(getRiskAssessmentOptionsAsync('nortonmobility'));
            dispatch(getRiskAssessmentOptionsAsync('nortonincontinence'));
        }
        else {
            dispatch(getRiskAssessmentOptionsAsync('bradensensoryperception'));
            dispatch(getRiskAssessmentOptionsAsync('bradenmoisture'));
            dispatch(getRiskAssessmentOptionsAsync('bradenactivity'));
            dispatch(getRiskAssessmentOptionsAsync('bradenmobility'));
            dispatch(getRiskAssessmentOptionsAsync('bradennutrition'));
            dispatch(getRiskAssessmentOptionsAsync('bradenfrictionshear'));
        }
    }, [dispatch]);

    useEffect(() => {
        if (riskAssessmentResponseScore) {
            setModalShowRiskScale(true);
        } else {
            setModalShowRiskScale(false);
        }

    }, [riskAssessmentResponseScore]);
    /* Handle submit */
    const onSubmit = (data) => {
        let payload;
        if (checkRiskAssessmentScale === 'norton') {
            const norton_payload = {
                "physicalCondition": data.physicalCondition,
                "mentalCondtion": data.mentalCondition,
                "activity": data.activity,
                "mobility": data.mobility,
                "incontinence": data.incontinence,

            };
            payload = {
                "type": "norton",
                "norton": norton_payload,
                "braden": null
            }
        }
        else {
            const braden_payload = {
                "sensoryPerception": data.sensoryPerception,
                "moisture": data.moisture,
                "activity": data.activity,
                "mobility": data.mobility,
                "nutrition": data.nutrition,
                "frictionShear": data.frictionShear,

            };
            payload = {
                "type": "braden",
                "braden": braden_payload,
                "norton": null
            }
        }
        if (payload) {
            dispatch(postRiskAssessmentsAsync(patientId, payload));
        }
    }

    return (
        <>
            {!modalShowRiskScale && <div>
                {/* Form */}
                {!modalShowRiskScale && checkRiskAssessmentScale && checkRiskAssessmentScale === 'norton' && <Form onSubmit={handleSubmit(onSubmit)}>
                    <SelectBox
                        id={"physicalCondition"}
                        name={"physicalCondition"}
                        label={`Physical Condition:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`, inputClassName: styles.basic_select,
                            labelClassName: `col-5 col-form-label ${styles.label_spec}`, inputColumn: `col-5 p-0 mb-1 ${styles.select_wrapper}`
                        }}
                        optionValue={nortonPhysicalCondition ? nortonPhysicalCondition : []}
                        defaultValue={''}
                        validator={register({
                            required: ERR_MSG,
                        })}
                        errors={errors}
                        placeholder={"Select"}
                        required={true}
                    />
                    <SelectBox
                        id={"mentalCondition"}
                        name={"mentalCondition"}
                        label={`Mental Condition:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`, inputClassName: styles.basic_select,
                            labelClassName: `col-5 col-form-label ${styles.label_spec}`, inputColumn: `col-5 p-0 mb-1 ${styles.select_wrapper}`
                        }}
                        optionValue={nortonMentalCondition ? nortonMentalCondition : []}
                        defaultValue={''}
                        validator={register({
                            required: ERR_MSG,
                        })}
                        errors={errors}
                        placeholder={"Select"}
                        required={true}
                    />
                    <SelectBox
                        id={"activity"}
                        name={"activity"}
                        label={`Activity:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`, inputClassName: styles.basic_select,
                            labelClassName: `col-5 col-form-label ${styles.label_spec}`, inputColumn: `col-5 p-0 mb-1 ${styles.select_wrapper}`
                        }}
                        optionValue={nortonActivity ? nortonActivity : []}
                        defaultValue={''}
                        validator={register({
                            required: ERR_MSG,
                        })}
                        errors={errors}
                        placeholder={"Select"}
                        required={true}
                    />
                    <SelectBox
                        id={"mobility"}
                        name={"mobility"}
                        label={`Mobility:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`, inputClassName: styles.basic_select,
                            labelClassName: `col-5 col-form-label ${styles.label_spec}`, inputColumn: `col-5 p-0 mb-1 ${styles.select_wrapper}`
                        }}
                        optionValue={nortonMobility ? nortonMobility : []}
                        defaultValue={''}
                        validator={register({
                            required: ERR_MSG,
                        })}
                        errors={errors}
                        placeholder={"Select"}
                        required={true}
                    />
                    <SelectBox
                        id={"incontinence"}
                        name={"incontinence"}
                        label={`Incontinence:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`, inputClassName: styles.basic_select,
                            labelClassName: `col-5 col-form-label ${styles.label_spec}`, inputColumn: `col-5 p-0 mb-1 ${styles.select_wrapper}`
                        }}
                        optionValue={nortonIncontinence ? nortonIncontinence : []}
                        defaultValue={''}
                        validator={register({
                            required: ERR_MSG,
                        })}
                        errors={errors}
                        placeholder={"Select"}
                        required={true}
                    />
                    <div className="col-sm-12">
                        <div className="text-center pb-4">
                            <br />
                            {/* Save Button */}
                            <Button
                                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                                type="submit"
                                id={SUBMIT_ID}
                            >
                                {SAVE_BTN}
                            </Button>
                            {/* Cancel button  */}
                            <Button

                                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                                id={CANCEL_ID}
                                onClick={handleCancel}
                            >
                                {CANCEL_BTN}
                            </Button>
                        </div>
                    </div>
                </Form>} 
                {!modalShowRiskScale && checkRiskAssessmentScale && checkRiskAssessmentScale === 'braden' && <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex align-items-end">
                        <div className="col-5">
                            <label className="col-form-label"><span className={styles.label_bold}> Sensory Perception</span> - Ability to respond meaningfully to pressure related discomfort: <span className={styles.astrickStar}>&#42;</span></label>
                        </div>
                        <div className="col-6">
                            <DropDownItems
                                id={"sensoryPerception"}
                                name={"sensoryPerception"}
                                classes={{ column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select, inputColumn: `col-12 p-0 ${styles.select_wrapper}` }}
                                listOptions={bradenSensoryPerception ? bradenSensoryPerception : []}
                                defaultValue={''}
                                validator={register({
                                    required: ERR_MSG,
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-end  mt-2">
                        <div className="col-5">
                            <label className="col-form-label"><span className={styles.label_bold}> Moisture</span> - Degree to which skin is exposed to moisture: <span className={styles.astrickStar}>&#42;</span></label>
                        </div>
                        <div className="col-6 mb-2">
                            <DropDownItems
                                id={"moisture"}
                                name={"moisture"}
                                classes={{
                                    column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select,
                                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                                }}
                                listOptions={bradenMoisture ? bradenMoisture : []}
                                defaultValue={''}
                                validator={register({
                                    required: ERR_MSG,
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <div className="col-5 mb-2">
                            <label className="col-form-label"><span className={styles.label_bold}> Activity</span> - Degree of physical Activity: <span className={styles.astrickStar}>&#42;</span></label>
                        </div>
                        <div className="col-6">
                            <DropDownItems
                                id={"activity"}
                                name={"activity"}
                                classes={{
                                    column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select,
                                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                                }}
                                listOptions={bradenActivity ? bradenActivity : []}
                                defaultValue={''}
                                validator={register({
                                    required: ERR_MSG,
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-end mt-2">
                        <div className="col-5">
                            <label className="col-form-label"><span className={styles.label_bold}> Mobility</span> - Ability change and control body position: <span className={styles.astrickStar}>&#42;</span></label>
                        </div>
                        <div className="col-6 mb-2">
                            <DropDownItems
                                id={"mobility"}
                                name={"mobility"}
                                classes={{
                                    column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select,
                                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                                }}
                                listOptions={bradenMobility ? bradenMobility : []}
                                defaultValue={''}
                                validator={register({
                                    required: ERR_MSG,
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-end">
                        <div className="col-5">
                            <label className="col-form-label p-0"><span className={styles.label_bold}> Nutrition</span> - Usual food intake pattern
                                (NPO-Nothing by mouth, IV-Intrvenously, TPN-Total Parental Nutrition): <span className={styles.astrickStar}>&#42;</span></label>
                        </div>
                        <div className="col-6 mb-3">
                            <DropDownItems
                                id={"nutrition"}
                                name={"nutrition"}
                                classes={{
                                    column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select,
                                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                                }}
                                listOptions={bradenNutrition ? bradenNutrition : []}
                                defaultValue={''}
                                validator={register({
                                    required: ERR_MSG,
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="col-5">
                            <label className="col-form-label"><span className={styles.label_bold}> Friction & Shear:</span> <span className={styles.astrickStar}>&#42;</span></label>
                        </div>
                        <div className="col-6 mt-2">
                            <DropDownItems
                                id={"frictionShear"}
                                name={"frictionShear"}
                                classes={{
                                    column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select,
                                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                                }}
                                listOptions={bradenFrictionShear ? bradenFrictionShear : []}
                                defaultValue={''}
                                validator={register({
                                    required: ERR_MSG,
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="text-center pb-4">
                            <br />
                            {/* Save Button */}
                            <Button
                                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                                type="submit"
                                id={SUBMIT_ID}
                            >
                                {SAVE_BTN}
                            </Button>
                            {/* Cancel button  */}
                            <Button
                                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                                id={CANCEL_ID}
                                onClick={handleCancel}
                            >
                                {CANCEL_BTN}
                            </Button>
                        </div>
                    </div>
                </Form>
                }
            </div>
            }
            {/*Modal Scale*/}
            {modalShowRiskScale &&
                <RiskAssessmentScale modalOpen={true} scaleType={checkRiskAssessmentScale} score={riskAssessmentResponseScore} modalClose={handleCancel} />
            }
        </>
    )
}
