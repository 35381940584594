import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { SIGNIN_TOKEN, USER_REGISTRATION_PATHS } from './constants';
import {
  Login,
  Home,
  ForgotPassword,
  ResetPassword,
  Registration,
  Status,
  Products,
  UserActivation,
  Patients,
  Customers,
  Formulary,
  Users,
  Sso,
  FormBuilder,
  Assessment,
  WorkList,
  Announcement,
  Layouts,
  ContextualSupport,
  UserProfile,
  UpdatePassword,
  WoundReport,
  BIReports,
  TreatmentReport,
  Survey,
  QualityReport,
  UsageAnalytics,
  UnAuthorizedContent,
  LearnMore,
  ExternalPdfViewPage
} from './containers';
import { SimplePersistence } from './utils';
import {
  medline_block,
  left_block,
  right_block
} from './styles/common.module.scss';
import { ViewSingleFormulary } from './components/core/Formulary/viewSingleFormulary';
import { BiReportsList } from './components/core/BiReports/biReportsList';
import { PdfDownload, PlatformAnalytics, MergeWoundAssessment } from './components';
import { WoundReportPdf } from './components/core/WoundReport/woundReportPdf';
import { ActiveWoundsNotResolved } from './components/core/WoundReport/activeWoundsNotResolved';
import { PatientWithActiveWounds } from './components/core/WoundReport/patientWithActiveWounds';
import { PlatformAnalyticsPdf } from './components/core/PlatformAnalytics/platformAnalyticsPdf';
import { TreatmentReportPdf } from './components/core/TreatmentReport/treatmentReportPdf';
import { QualityReportPdf } from './components/core/QualityReport/qualityReportPdf';
import { PendingPhotos } from "./components/core/QualityReport/pendingPhotos";
import { PendingAssessments } from "./components/core/QualityReport/pendingAssessments";
import { PendingReviews } from "./components/core/QualityReport/pendingReviews";
import { CompletedAssessments } from "./components/core/QualityReport/completedAssessments";
import { RenderSession } from './renderSession';

const storage = new SimplePersistence();
const isSignedIn = (user) => user && user.isSignedIn && !!storage.getItem(SIGNIN_TOKEN);

const CustomRoute = (props) => {
  const { component: Component, path, roles, match, user, ...rest } = props//NOSONAR

  return (
    <Route
      {...rest}
      render={(prop) => {
        if (match) {
          prop.match = { ...prop.match, ...match };
        }
        if (!isSignedIn(user)) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: prop.location } }}
            />
          );
        }
        return <Component {...prop} />;        
      }}      
    />
  );
};

const RenderRoutes = (nav, user, dispatch) => {
  const [resetSession, setResetSession] = useState(false);
 
  const showNav = isSignedIn(user) && !USER_REGISTRATION_PATHS.includes(window.location.pathname);
  useEffect(() => {
    if(showNav&&user?.userDetails?.email){
      setResetSession(true)
    }
  }, [showNav,user,resetSession])
  return <Router>
    <div className={`container-fluid ${medline_block}`}>
      <div className="row p-0">
        {showNav && 
        <>
        {resetSession&&<RenderSession showNav={showNav} user={user} dispatch={dispatch} resetSession={resetSession} setResetSession={setResetSession} />}
       
        <div id={'check_left_block'} className={`${left_block}`} style={{transition: "all 0.1s ease-in-out"}}>
          {nav()}
        </div>
        </>
        }
        <div className={`${right_block}`} id={'check_right_block'}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route exact path="/createpassword" component={ResetPassword} />
            <Route exact path="/updatepassword" component={UpdatePassword} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/useractivation" component={UserActivation} />
            <Route exact path="/status/:type" component={Status} />
            <Route exact path="/extpdfviewer" component={ExternalPdfViewPage} />
            <CustomRoute exact path="/" component={Home} user={user} />
            <CustomRoute exact path="/admin/products" component={Products} user={user} />
            <CustomRoute exact path="/admin/products/:productURLType/:productId" component={Products} user={user} />
            {/* <CustomRoute exact path="/admin/products/:productType/:productId" component={Products} user={user} /> */}

            {/* Patient details testing */}
            <CustomRoute exact path="/patients" component={Patients} user={user} />
            <CustomRoute exact path="/patients/:patientId" component={Patients} user={user} />
            <CustomRoute exact path="/admin/patients" component={Patients} user={user} />
            <CustomRoute exact path="/admin/patients/:patientId" component={Patients} user={user} />
            <CustomRoute exact path="/patients/:patientId/assessment/:assessmentId" component={Assessment} user={user} />

            {/* PDF Download */}
            <CustomRoute exact path="/patients/:patientId/assessment/:assessmentId/pdfdownload" component={PdfDownload} user={user} />
            {/* Merge Wound Assessment */}
            <CustomRoute exact path="/patients/:patientId/assessment/:assessmentId/mergewoundassessment" component={MergeWoundAssessment} user={user} />

            {/*Admin customer*/}
            <CustomRoute exact path="/admin/customers" component={Customers} user={user} />
            <CustomRoute exact path="/admin/customers/:customerId" component={Customers} user={user} />
            {/*Admin Users*/}
            <CustomRoute exact path="/admin/users" component={Users} user={user} />
            <CustomRoute exact path="/admin/users/:userId" component={Users} user={user} />

            {/*Admin Sso*/}
            <CustomRoute exact path="/admin/sso" component={Sso} user={user} />
            <CustomRoute exact path="/admin/sso/:ssoId" component={Sso} user={user} />


            {/* Form builder */}
            <CustomRoute exact path="/admin/FormBuilder" component={FormBuilder} user={user} />
            <CustomRoute exact path="/admin/FormBuilder/:formId" component={FormBuilder} user={user} />

            {/* Work List */}
            <CustomRoute exact path="/worklist" component={WorkList} user={user} />
            {/*Announcement List*/}
            <CustomRoute exact path="/admin/announcement" component={Announcement} user={user} />
            <CustomRoute exact path="/admin/announcement/:id" component={Announcement} user={user} />

            {/* Layouts */}
            <CustomRoute exact path="/admin/layouts" component={Layouts} user={user} />
            <CustomRoute exact path="/admin/layouts/:layoutId" component={Layouts} user={user} />

            {/* Layouts */}
            <CustomRoute exact path="/admin/support" component={ContextualSupport} user={user} />
            <CustomRoute exact path="/admin/support/:supportDataId" component={ContextualSupport} user={user} />
            {/* User Profile */}
            <CustomRoute exact path="/userprofile" component={UserProfile} user={user} />

            {/* Formulary */}
            <CustomRoute exact path="/admin/formulary" component={Formulary} user={user} />
            <CustomRoute exact path="/admin/formulary/:formularyId" component={ViewSingleFormulary} user={user} />

            {/* Wound Report */}
            <CustomRoute exact path="/woundReport" component={WoundReport} user={user} />
            <CustomRoute exact path="/woundReport/pdfdownload" component={WoundReportPdf} user={user} />           
            {/* Wound Report/Overview */}
            <CustomRoute exact path="/woundReport/activewoundsnotresolved" component={ActiveWoundsNotResolved} user={user} />
            <CustomRoute exact path="/woundReport/patientwithactivewounds" component={PatientWithActiveWounds} user={user} />

            {/*BI reports */}
            <CustomRoute exact path="/biReports" component={BIReports} user={user} />
            <CustomRoute exact path="/biReports/biReportsList" component={BiReportsList} user={user} />

            {/* Treatment Report */}
            <CustomRoute exact path="/treatmentReport" component={TreatmentReport} user={user} />
            <CustomRoute exact path="/treatmentReport/pdfdownload" component={TreatmentReportPdf} user={user} /> 
            
            {/* Quality Report */}
            <CustomRoute exact path="/qualityReport" component={QualityReport} user={user} />
            <CustomRoute exact path="/qualityReport/pdfdownload" component={QualityReportPdf} user={user} />
            {/* Quality Report/Overview */}
            <CustomRoute exact path="/qualityReport/pendingphotos" component={PendingPhotos} user={user} />
            <CustomRoute exact path="/qualityReport/pendingassessments" component={PendingAssessments} user={user} />
            <CustomRoute exact path="/qualityReport/pendingreviews" component={PendingReviews} user={user} />
            <CustomRoute exact path="/qualityReport/completedassessments" component={CompletedAssessments} user={user} />

            {/* Platform Analytics */}
            <CustomRoute exact path="/platformanalytics" component={PlatformAnalytics} user={user} />
            <CustomRoute exact path="/platformanalytics/pdfdownload" component={PlatformAnalyticsPdf} user={user} />

            {/* surveyResult */}
            <CustomRoute exact path="/admin/surveyResult" component={Survey} user={user} />

            {/* UsageAnalytics */}
            <CustomRoute exact path="/admin/usageAnalytics" component={UsageAnalytics} user={user} />
            <CustomRoute exact path="/unAuthorized" component={UnAuthorizedContent} user={user} />            
            <CustomRoute exact path="/learnMore" component={LearnMore} user={user} />    
            <Route component={UnAuthorizedContent}/>        
          </Switch>
        </div>
      </div>
    </div>
  </Router>
}

export default RenderRoutes;
