export const List = (url, method, limit, offset, dispatch, dispatchMethod,type,customSearch) => {
  let listPayLoad = {};
  listPayLoad['url'] = url
  listPayLoad['method'] = method
  listPayLoad['queryParam'] = { limit: limit, offSet: isNaN(offset)===true?0:offset }
  if(type){
    listPayLoad['queryParam']={...listPayLoad['queryParam'],...{type:type}} ;
  }
  if(customSearch !=""){
    listPayLoad['queryParam']={...listPayLoad['queryParam'],...{search:customSearch}} ;
  }
  dispatch(dispatchMethod(listPayLoad))
}

/*Pending & Locked Button */
export const ListPendLock = (url, method, limit, offset, dispatch, dispatchMethod, type, BtnType) => {
  if(type === 'Pending'){
    let listPayLoad = {};
    listPayLoad['url'] = url
    listPayLoad['method'] = method
    listPayLoad['queryParam'] = { limit: limit, offSet: isNaN(offset)===true?0:offset }
    if(BtnType === true){
      listPayLoad['queryParam']={...listPayLoad['queryParam'],...{Pending:BtnType}} ;
    }
    dispatch(dispatchMethod(listPayLoad))
  }
  if(type === 'Locked'){
    let listPayLoad = {};
    listPayLoad['url'] = url
    listPayLoad['method'] = method
    listPayLoad['queryParam'] = { limit: limit, offSet: isNaN(offset)===true?0:offset }
    if(BtnType === true){
      listPayLoad['queryParam']={...listPayLoad['queryParam'],...{Locked:BtnType}} ;
    }
    dispatch(dispatchMethod(listPayLoad))
  }
  if(type === "Both"){
    let listPayLoad = {};
    listPayLoad['url'] = url
    listPayLoad['method'] = method
    listPayLoad['queryParam'] = { limit: limit, offSet: isNaN(offset)===true?0:offset }
    if(BtnType === true){
      listPayLoad['queryParam']={...listPayLoad['queryParam'],...{Locked:BtnType},...{Pending:BtnType}};
    }
    dispatch(dispatchMethod(listPayLoad))
  }
}/*Pending & Locked Button */

export const Search = (event, url, method, limit, offset, dispatch, dispatchMethod,sortType, BtnType, BtnValue,searchColumns) => {
  if (event.keyCode === 13) {
    let searchPayLoad = {};
    searchPayLoad['url'] = url
    searchPayLoad['method'] = method
    searchPayLoad['queryParam'] = { limit: limit, offSet: isNaN(offset)===true?0:offset, search:(searchColumns&&event&&event?.target?.value)?searchColumns+':'+event.target.value.trim(): (event&&event.target&&event.target.value)?event.target.value.trim():''}
    if(searchColumns&&!event?.target?.value){
      searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{search:searchColumns+':'}} ;
    }
    if(sortType){
      searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{sort:sortType}} ;
    }
    if(BtnType==='Pending'){/*Pending & Locked Button */
      searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{Pending:BtnValue}} ;
    }
    if(BtnType==='Locked'){
      searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{Locked:BtnValue}} ;
    }
    if(BtnType==='Both'){
      searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{Locked:BtnValue},...{Pending:BtnValue}} ;
    }/*Pending & Locked Button */
    dispatch(dispatchMethod(searchPayLoad));
  }
}

export const Sorting = (url, method, limit, offset, dispatchMethod,search,sortType,setSortType, BtnType, BtnValue) => {
  let sortingPayLoad = {};
  sortingPayLoad['url'] = url
  sortingPayLoad['method'] = method
  sortingPayLoad['queryParam'] = { limit: limit, offSet: isNaN(offset)===true?0:offset,sort: (sortType)?sortType:'' }
  sortingPayLoad['asyncMethod'] = dispatchMethod
  sortingPayLoad['setSortType'] = setSortType
  if(search){
    sortingPayLoad['queryParam']={...sortingPayLoad['queryParam'],...{search:search}} ;
  }
  if(BtnType==='Pending'){/*Pending & Locked Button */
    sortingPayLoad['queryParam']={...sortingPayLoad['queryParam'],...{Pending:BtnValue}} ;
  }
  if(BtnType==='Locked'){
    sortingPayLoad['queryParam']={...sortingPayLoad['queryParam'],...{Locked:BtnValue}} ;
  }
  if(BtnType==='Both'){
    sortingPayLoad['queryParam']={...sortingPayLoad['queryParam'],...{Locked:BtnValue},...{Pending:BtnValue}} ;
  }/*Pending & Locked Button */
  return sortingPayLoad
}

export const Pagination = (data, setActivePage, url, method, limit, dispatch, dispatchMethod,search, biReports,sortType, BtnType, BtnValue) => {
  setActivePage(data);
  let paginationPayLoad = {};
  paginationPayLoad['url'] = url
  paginationPayLoad['method'] = method
  paginationPayLoad['queryParam'] = { limit: limit, offSet: (((data * limit) - limit) < limit) ? 0 : (data * limit) - limit }
  if(search !=""){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{search:search}} ;
  }
  if(biReports) {
    paginationPayLoad['reportData'] = biReports
  }
  if(sortType !=""){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{sort:sortType?sortType:''}} ;
  }
  if(BtnType === "Pending"){/*Pending & Locked Button */
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{Pending:BtnValue}} ;
  }
  if(BtnType === "Locked"){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{Locked:BtnValue}} ;
  }
  if(BtnType === "Both"){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{Locked:BtnValue},...{Pending:BtnValue}} ;
  }/*Pending & Locked Button */
  dispatch(dispatchMethod(paginationPayLoad));
}

export const ReportPagination = (data, setActivePage, url, method, payload, limit, dispatch, dispatchMethod,search) => {
  setActivePage(data);
  let paginationPayLoad = {};
  paginationPayLoad['url'] = url
  paginationPayLoad['method'] = method
  paginationPayLoad['payload']=payload
  paginationPayLoad['queryParam'] = { limit: limit, offSet: (((data * limit) - limit) < limit) ? 0 : (data * limit) - limit }
  if(search){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{search:search}} ;
  }
  dispatch(dispatchMethod(paginationPayLoad));
}

export const Delete = (data, dispatch, dispatchMethod, getFormList) => {
  dispatch(dispatchMethod(data))
}


export const SelectedRow = (data, setDetailsRowData, tabUrl, history, isAdminView, userRole) => {
  if (data) {
    isAdminView ? history.push(`/admin/${tabUrl}/${data.id}`) : history.push(`/${tabUrl}/${data.id}`);    
  }
  setDetailsRowData(data)
}


export const SelectedProductRow = (data, setDetailsRowData, tabUrl, productType, history, isAdminView) => {
  if (data) {
      isAdminView ? history.push(`/admin/${tabUrl}/${productType}/${data.id}`) : history.push(`/${tabUrl}/${productType}/${data.id}`);
    
  }
  setDetailsRowData(data)
}

export const FacilityData=(value,url,method,limit,dispatch,resetDispatchMethod,dispatchMethod )=>{
  if (value !== '') {
    let facilityPayLoad = {};
    facilityPayLoad['url'] = url
    facilityPayLoad['method'] = method
    facilityPayLoad['queryParam'] = { facilityNumber: value, offSet: 0, limit: limit }
    dispatch(resetDispatchMethod())
    dispatch(dispatchMethod(facilityPayLoad))
  }
}
