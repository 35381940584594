import React, { useEffect, useState } from 'react'
import { format } from "date-fns";
import { AccessErrorModal, Button } from '../../primary'
import { Modal, ActionModal } from '../../secondary'
import { linkAssessmentUpdateAsync, setHistoryCauseFormData, woundAssessmentUpdateAsync, patientAssessmentAsyncOverride, characteristicsImagesAsync,woundAssessmentReEvalAsync,woundClassificationAsync,createAssessMentWithoutPhoto,setCreateAssessmentData,createAssessmentAsync,ne1updateassessmentHistoryCauseAsync,updatereevalAsync} from '../../../app/patientSlice'
import editIcon from '../../../static/images/edit-icon.png'
import { HistoricalView } from './historicalView'
import { CharacteristicHistoryEdit } from './characteristicHistoryEdit'
import { CloseWarning } from '../../secondary'
import { useForm } from 'react-hook-form'
import { useSelector, shallowEqual } from 'react-redux'
import { allNullCheck, signedLockedAccess ,daysAgo } from '../../../utils'
import Loader from '../../../static/images/spinner.svg';
import styles from "./characteristic.module.scss";
export function AnatomyListPopUp({ index, location, patientId, updateDone, assessmentId=null, dispatch, selectedIndex, assessmentDetails,
    getCharDetails, hideIt, overrideToggle,modalClose,history,setChckDocNewExtAssess }) {
    const charStatus = useSelector(state => state.patient.charStatus)
    const createAssessmentData = useSelector(state => state.patient.createAssessmentData)
    /* State for start button */
    const [fresh, setFresh] = useState(true)
    // Edit state
    const [editable, setEditable] = useState(false)
    // Handle Save (state for moving from historical view to history cause page)
    const [save, setSave] = useState(false)
    /* State for catgory */
    const [category, setCategory] = useState()
    /* Selected cause */
    const [selectedCause, setSelectedCause] = useState()
    /* Warning state for closing modal */
    const [closeWarning, setCloseWarning] = useState(false)
    /* State to display hide history view */
    const [historyState, setHistoryState] = useState(false)
  
    /* State to handle current or new wound info from history view */
    const [newWound, setNewWound] = useState(false)
    const [currentWound, setCurrentWound] = useState(false)
    /* Current wound id (we get this id when user clicks 'same as current wound') */
    const [currentId, setCurrentId] = useState(null)
    /*Error check HistCause*/
    const [errChkHistCause, setErrChkHistCause] = useState(false)
    // Getting data 
    const historyCauseFormData = useSelector(state => state.patient.historyCauseFormData, shallowEqual);
    const historyCause = useSelector(state => state.patient.historyCause);
    const piHistory = useSelector(state => state.patient.piHistory);
    const woundAge = useSelector(state => state.patient.woundAge);
    const photoType = useSelector(state => state.patient.photoType);
    const documentProvidedBy = useSelector(state => state.patient.documentProvidedBy);
    /* get user role*/
    const getUserRole = useSelector(state => state.user);

    const { register, errors, handleSubmit, watch } = useForm({
        defaultValues: historyCauseFormData
    })

    // useEffect(() => {
    //     dispatch(setHistoryCauseFormData({ cause: '' }))
    // }, [location])

    /* Warning functions Yes and no */
    const handleYes = () => {
        setHistoryState(false)
        setEditable(false)
        setCloseWarning(false)
        //setEditable(false)
        setSave(false)
        dispatch(setHistoryCauseFormData({ cause: '' }))
    }
    /* No fn */
    const handleNo = () => {
        setCloseWarning(false)
    }
//handleStartNew

    // On click start 
    const handleStart = () => {
         setHistoryState(true)
        setEditable(false)
        //setEditable(true)
    }

    // Next screen historical view 
    const handleNext = () => {
        setHistoryState(true)
        setEditable(false)
    }

    // We should not show 'historical view' when user revisits the history/cause.
    // So I am checking wheather newWound has value or not. If there is value, then I am assuming 
    // user is editing 2nd time. If newWound don't have value, it is first time.

 


    //  call the API for Update the data
    // useEffect(()=>{
    //     let payload = { patientId, assessmentId, type: 'tissuetypes'} 
    //     dispatch(patientAssessmentAsyncOverride(payload))  
    //     const classificationPayload = {
    //         patientId,
    //         assessmentId,
    //         data: {
    //             woundBedIdentifiable: null,
    //             configurationRequest: 1
    //         }
    //     }
    //     dispatch(woundClassificationAsync(classificationPayload))
    // },[dispatch])

    const handleSave = (data, reset) => {
       
        if((category)&&(selectedCause)){
            setErrChkHistCause(false)
        let documentProvidedByPost= data?.documentProvidedBy ? data?.documentProvidedBy : assessmentDetails?.historyCause?.documentProvidedBy?assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase():assessmentDetails?.historyCause?.documentProvidedBy;
        if(data?.documentProvidedBy){
            if(data?.documentProvidedBy&&data?.documentProvidedBy.replace(/\s/g, '').toLowerCase()==='other'&&data?.documentProvidedByOther){
                documentProvidedByPost=data?.documentProvidedByOther
            }
        }
        if(data?.documentProvidedBy===''){
            documentProvidedByPost=''
        }        
        function navigate() {
           
            setSave(false)
            updateDone(index)
            if(Array.isArray(charStatus)&&charStatus[index]===1){
                setEditable(false)
            }else{
                setSelectedCause('')
                setCategory('')
                if(getUserRole?.role!=='ExternalStandardUser'){
                    selectedIndex(index + 2)
                }else{
                    selectedIndex(index + 1)
                }
               
            }
        }
        //update history cause options details updateHistoryCauseDetailsOption
        if(data?.otherNamecause){
            let chckExitOtheropt=false
            historyCause && historyCause.length > 0 && historyCause.map((histDataOpt) => {
              if(histDataOpt.key==='other'){
                     return chckExitOtheropt =Array.isArray(histDataOpt.options)&&histDataOpt.options.some((val) => val.key.toLowerCase() === data?.otherNamecause.toLowerCase())
              }
            })
            if(!chckExitOtheropt){
                const payloadOptUpdate = {
                    ne1ID:patientId,
                    assessmentId:assessmentId,
                    historyCauseType: "other",
                    historyCauseTypeValue: data?.otherNamecause,
                    KeyUpdated: null 
                    }
            }
        }
     
     //str.replace(/\s/g, '').toLowerCase()
     
     
        // Temp assessment with submitted data
        const tempAssessment = {
            historyCause: {
                cause: selectedCause ? selectedCause : assessmentDetails?.historyCause?.cause,
                causeCategory: category ? category : assessmentDetails?.historyCause?.causeCategory,
                woundAge: data?.woundAge ? data?.woundAge.replace(/\s/g, '').toLowerCase() : null,
                conditionPOA: data?.conditionPOA ? data?.conditionPOA === 'Yes' ? 1 : 0 : assessmentDetails?.historyCause?.conditionPOA,
                photoType: data?.photoType ? data?.photoType.replace(/\s/g, '').toLowerCase() : assessmentDetails?.historyCause?.photoType?assessmentDetails?.historyCause?.photoType.replace(/\s/g, '').toLowerCase():assessmentDetails?.historyCause?.photoType,
                documentProvidedBy: documentProvidedByPost?documentProvidedByPost.replace(/\s/g, '').toLowerCase() : null,
                piHistory: pi ? data?.piHistory ? data?.piHistory.replace(/\s/g, '').toLowerCase() : null : null,
                newWound: save ? 1 : assessmentDetails?.historyCause?.newWound
            }
        }
        // Merging our temp assessment with assessment details and dispatching
        const assessment = { ...assessmentDetails, ...tempAssessment }
        const payload = {
            patientId,
            assessmentId,
            assessment,
            navigate,
            getCharDetails,
            reset,
            overrideenable : overrideToggle ? true : false
        }
        // if users clicks 'same as current wound' the below linking API call will get triggerd 
        if (currentWound) {
            const linkPayload = {
                patientId,
                assessmentId,
                currentId
            }
            /* Linking assessment when user clicks 'same as current wound' */
            dispatch(linkAssessmentUpdateAsync(linkPayload))
        }
        // Saving history cause data 
      //  dispatch(woundAssessmentUpdateAsync(payload))
        }else{
            setErrChkHistCause(true)
        }

    }

    /* Pressure Injury PI */
    const [pi, setPi] = useState()
    /* POA or Non POA */
    const [poa, setPoa] = useState(false)

    /* POA for form handling */
    const [formPoa, setFormPoa] = useState(false)

    /* PDocumentation provided by: other */
    const [otherDocType, setOtherDocType] = useState(false)
    const [otherDocTypeVal, setOtherDocTypeVal] = useState('')
    const [viewDocType, setViewDocType] = useState('')
    const [viewDocTypeSelect, setViewDocTypeSelect] = useState('')

    useEffect(() => {
        let docView=documentProvidedBy && documentProvidedBy.length > 0 && documentProvidedBy.map((data) => {
            if (assessmentDetails?.historyCause?.documentProvidedBy&&assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase() === data.key) {
                return data.value
            }
        })
        docView = docView && Object.values(docView).filter(function (el) {
            return el != null;
          });
        if(docView){
            let docViews=docView;
            setViewDocType(docViews)
            setViewDocTypeSelect(Array.isArray(docViews)&&docViews.length>0?docViews[0].replace(/\s/g, '').toLowerCase():'')
        }
        if(docView.length===0){
            setViewDocType(`Other - ${assessmentDetails?.historyCause?.documentProvidedBy}`)
            if(assessmentDetails?.historyCause?.documentProvidedBy)
            setViewDocTypeSelect('other')
        }
        const chkDfalDoc=Array.isArray(documentProvidedBy)&&documentProvidedBy.some((val) => ((assessmentDetails?.historyCause?.documentProvidedBy)&&(val.key === assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase())))
        if (assessmentDetails?.historyCause?.documentProvidedBy&&assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase() === 'other' || !chkDfalDoc) {
            if(assessmentDetails?.historyCause&&assessmentDetails?.historyCause?.documentProvidedBy){
                setOtherDocType(true)
                setOtherDocTypeVal(assessmentDetails?.historyCause?.documentProvidedBy)
            }
        } 
        // Set fresh form 
        if (assessmentDetails?.historyCause?.newWound) {
            setFresh(false)
        } else {
            setFresh(true)
        }
        // Setting PI
        if (assessmentDetails?.historyCause?.causeCategory === 'pi') {
            setPi(true)
        } else {
            setPi(false)
        }
        // Setting POA
        if (assessmentDetails?.woundAcquired === 'POA') {
            setPoa(true)
            setFormPoa(false)
        } else {
            setPoa(false)
            setFormPoa(true)
        }
        if (assessmentDetails?.historyCause?.conditionPOA === 1 && assessmentDetails?.woundAcquired === 'POA') {
            setPoa(false)
        }
    }, [assessmentDetails])
/*new flow*/
const [historyStateNew, setHistoryStateNew] = useState(true)

const patientData = useSelector(state => state.patient.detailViewData)
const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions) // Get wound classification select box options

 //   const historyCause = useSelector(state => state.patient.historyCause);
    // single assessment details.
    const detailAssessment = useSelector(state => state.patient.detailAssessment);
    // multiple assessment details. 
    const woundAssessments = useSelector(state => state.patient.woundAssessmentsAnatomyList);


    let sameAnotomy = [];
    // compare between two data
    woundAssessments && woundAssessments?.assessments?.length > 0 &&Array.isArray(woundAssessments.assessments)&& woundAssessments?.assessments?.map((obj) => {//NOSONAR
        obj?.assessments?.filter((assessment) => {//NOSONAR
                if (assessment?.anatomy === createAssessmentData?.anatomy) {
                    sameAnotomy.push(assessment)
                } 
        })//NOSONAR
    })//NOSONAR

    const handleStartNew = () => {
        if( sameAnotomy && sameAnotomy.length > 0){
          setHistoryStateNew(true)
          
        }else{
            setHistoryState(false)
            setEditable(true)
            setNewWound(true)
            setSave(true)
            setHistoryStateNew(false)
        }
        
    }
    const handleReEval = (e,id) => {
      let assessmentId=createAssessmentData?.assessmentId;
      let parentAssessmentid=id
      //createAssessmentData?.assessmentId
        e.preventDefault();
        const payload = {
            patientId,
            assessmentId,
            parentAssessmentid
        }
        //woundAssessmentReEvalAsync
        dispatch(updatereevalAsync(payload))
       // dispatch(woundAssessmentReEvalAsync(payload))
        setHistoryStateNew(false)
        if(modalClose){
            modalClose()
        }
    }
    const handleNew = () => {
      const payload = {
        newWound: 1
    }
    /* Dispatch it */
    if(setChckDocNewExtAssess){
        setChckDocNewExtAssess(true)
    }else{
        dispatch(setCreateAssessmentData(payload))
        setHistoryStateNew(false)
        if (createAssessmentData?.isWoundWithoutPhoto) {
            let redirect=1
            if(createAssessmentData?.assessmentId){
              dispatch(ne1updateassessmentHistoryCauseAsync(createAssessmentData,redirect));
            }else{
                dispatch(createAssessMentWithoutPhoto(patientId, history));
            }
         
           //createAssessMentWithoutPhotoAnatomyCheck
         } else {
          // dispatch(createAssessmentAsync(patientId, history, currentWoundPhoto));
           
         }
    }
    if(modalClose){
        modalClose()
    }
    }

    return (
        <div className={`${styles.anatomyPop} pt-1 pb-0`}>


{historyStateNew&& <Modal
  modalOpen={historyStateNew}
  modalClose={()=>{modalClose();setHistoryStateNew(false)}}
  heading={ <p className={`${styles.newflowhead}`}>Patient has an existing <strong>{historyCause && historyCause.length > 0 && historyCause.map((item) => {
    let value;
    if (item?.options?.length > 0&&createAssessmentData?.cause) {
        item.options.map((sub) => {
            if (sub.key ===createAssessmentData?.cause.toLowerCase()) {
                value = sub.value
            }
        })
    }
    else {
        if (createAssessmentData?.cause&&item.key === createAssessmentData?.cause.toLowerCase()) {
            value = item.value
        }
    }
    return value;
})}</strong> wound on  <strong>{  humanAnatomy &&
    humanAnatomy.length > 0 &&
    humanAnatomy.map((data) => {//NOSONAR
      if (data.key === createAssessmentData?.anatomy) {
        return(data.value);
      }
    })//NOSONAR
}</strong></p>}
 
  className={"modal_box"}
  modalSize={styles.custom_modal_size}
  modalScroll={false}
  custom_content={styles.custom_new_content}
  subHeading={<p className={`${styles.newflowheadsub}`}>Consider <span><b>Re-Eval</b></span> of an existing wound or do you wish to document this as a <span><b>New Wound?</b></span></p>}
  close={styles.modal_close}
  custom_body={styles.custom_new_body}
  footerContent={<div className={` text-center' ${styles.doc_as_new_pop}`}>
                                        
  <Button
type={"submit"}
value="Document as New Wound"
id={"submit"}
classes={{ className: `btn pr-3 pl-3 ${styles.btn_custom} ${styles.btn_small} w-auto` }}
onClick={handleNew}
/>
  </div>}
>
{
                sameAnotomy && sameAnotomy.length > 0 ? sameAnotomy.map((data, index) => {
                     let chkclassification=''
                    return <div key={index} className={`${styles.wound_left_block}  ${sameAnotomy.length===index+1?'':styles.wound_left_block_hr_line}`}>
                        <figure className="figure align-top">
                            <img className={`figure-img img-fluid ${styles.image_size}`} src={data.picture} />
                        </figure>
                        <div className={`${styles.patient_details}`}>
                        <p><h4>{data?.title}</h4> {data?.isLocked===1&&<Button
                                type={"submit"}
                                value="Signed & locked"
                                classes={{ className: `btn ${styles.sign_lock} ml-3 mt-n0` }}
                            />}</p>
                            <div className={`${styles.new_flow_prop_by}`}><p><label>History/Cause: </label>   
                            
                            
                            <span>
                            {data?.causeCategory!=='other'&&historyCause && historyCause.length > 0 && historyCause.map((dataHistOpt) => {
                                let value;
                                if (dataHistOpt?.options?.length > 0) {
                                    dataHistOpt.options.map((sub) => {
                                        if (sub.key=== data?.cause?.toLowerCase()) {
                                            value = `${dataHistOpt.value}-${sub.value}`
                                            value=`${data?.causeCategory==='unabletodetermine'?dataHistOpt.value:`${dataHistOpt.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (dataHistOpt.key === data?.cause?.toLowerCase()) {
                                        value = dataHistOpt.value
                                    }
                                }
                                return value;
                            })}
                            
                            {data?.causeCategory==='other'&&historyCause && historyCause.length > 0 && historyCause.map((dataHistOpt) => {
                                let value;
                                if (dataHistOpt?.options?.length > 0 ) {
                                    dataHistOpt?.options?.map((sub) => {
                                        if (sub.key === data?.cause) {
                                            value=`${data.causeCategory==='unabletodetermine'?dataHistOpt.value:`${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (dataHistOpt.key === data?.cause) {
                                        value = dataHistOpt.value
                                    }
                                }
                                return value;
                            })}
                            
                           {data?.causeCategory==='others'&&`${data?.cause}`}
                        </span>
                            
                            
                            
                            
                            </p>
                              <p><label>Wound Acquired: </label>   <span className={`${data?.woundAcquired==="POA"?styles.newflow_wound_ac_green:styles.newflow_wound_ac}`}>{data?.woundAcquired}</span></p>
                              <p><label>Classification: </label>   
                              
                              {data?.classification!== "unknown" ? (allNullCheck(data?.classification) ?                                  
<span className={`${styles.classifi_content}`}>
    
    {
   
    woundClassificationOptions && woundClassificationOptions.length > 0 && woundClassificationOptions?.map((dataClassification) => {
       
        if (dataClassification.key === data?.classification) {
            chkclassification=data?.classification
            return dataClassification.value
        }
    })
    }
    {chkclassification===''?data?.classification:''}
</span> : <span >{`Unknown`}</span>):
<span >{`Unknown`}</span>
} 
                              
                              </p>
                              </div>
                            <p className={`${styles.new_flow_photo_by}`}><label>Photo By: </label>   <span>{data?.identifiedBy}</span></p>
                            <div className={'row'}>
                            <div className={`col-md-8 ${styles.new_flow_photo_by}`}>
                            <p><label>Photo On: </label>   <span>{data?.identifiedDate?format(new Date(data?.identifiedDate), "MMMM dd, yyyy"):''}</span></p>
                            <p><label>Last Assessed: </label>   <span>{data?.lastAssessedDate ? daysAgo(data.lastAssessedDate) : 'NA'}</span></p>
                            </div>
                            <div className={'col-md-3'}>
 <Button
                                type={"submit"}
                                value="Re-Eval"
                                classes={{ className: `btn ${styles.re_eval}` }}
                                onClick={(e) => handleReEval(e,data?.id)}
                            />
                            </div>
                            </div>

                        </div>
                                    
                                    
                    </div>
                })

                    :

                    <p className={`${styles.heading_wound}`}>No records available.</p>

            }
            

</Modal>
}




        </div>
    )
}
