import React, { useState, useEffect } from 'react'
import downArrow from '../../../static/images/downarrow.svg'
import { toggleInfo, handleSelectHelper, handleSelectHelperOverride } from '../../../utils'
// import { allNullCheck, handleInputData, handleInputDataOverride, handleSelectHelper, handleSelectHelperOverride, selectedCheckHelper, selectedCheckHelperOverride, signedLockedAccess } from '../../../utils'
import { CharAiTissueLists } from './charAiTissueLists'

export function CharTissueList({
    styles,
    tissueTypesData,
    result,
    inputFlag,
    checkFlag,
    assessmentDetails,
    selectedCheck,
    handleInput,
    handleSelect, 
    validPercent,
    aiAvailable,
    checkDefault,   
    toggleChange,
    toggleOn,
    inputFlagOverride,
    checkFlagOverride,
    selectedCheckOverride,
    handleInputOverride,
    handleSelectOverride,
    setInputFlagOverride,
    setCheckFlagOverride,
    setResult,
    editable
}) {

     // Save and Cancel Sticky
    //  useEffect(()=>{
    //     if(checkFlag?.map(d=>d)?.find(f=>f===true)){
    //         setClick(true)
    //     }
    //     else{
    //         setClick(false)
    //     }
    // },[checkFlag])
    // Save and Cancel Sticky
    
    useEffect(() => {
         //if Toggle 'OFF' then all checked items to uncheck
        if(toggleOn===false){
            let tempArrayOverride = new Array(tissueTypesData?.overrideTissueType?.length).fill(false); 
            setCheckFlagOverride(tempArrayOverride)
            let toggleOn= false
            handleSelectOverride (toggleOn, true, null, null, false, tempArrayOverride, tempArrayOverride)
        }
    }, [toggleOn])
    useEffect(() => {
        //if Toggle 'OFF' then all checked items to uncheck
        if(toggleOn===false){
            let tempArrayOverride = new Array(tissueTypesData?.overrideTissueType?.length).fill(false); 
            setCheckFlagOverride(tempArrayOverride)
            let toggleOn= false
            handleSelectOverride (toggleOn, true, null, null, false, tempArrayOverride, tempArrayOverride)
        }
    }, [toggleOn])
         useEffect(() => {
            let startProductBarPos=-1;
            if(document.getElementById('checkAdd')){
                window.onscroll=function(){
                    if(document.getElementById('checkAdd')){
                        let bar = document.getElementById('checkAdd');
                        // document.querySelector('#checkAdd').style.setProperty('padding', -1);
                         if(startProductBarPos<0)
                            startProductBarPos=findPosY(bar);
                       
                         if(window.pageYOffset>startProductBarPos){
                           bar.style.position='fixed';
                           bar.style.top=0;
                         }else{
                             if(bar?.style?.position){
                                 bar.style.position='relative';
                             }
                         }
                    }
               
                  
                  };
                  
                  function findPosY(obj) {
                    var curtop = 0;
                    if (typeof (obj?.offsetParent) != 'undefined' && obj?.offsetParent) {
                      while (obj?.offsetParent) {
                        curtop += obj.offsetTop;
                        obj = obj.offsetParent;
                      }
                      curtop += obj.offsetTop;
                    }
                    else if (obj?.y)
                      curtop += obj.y;
                    return curtop;
                  }
            }
          
       }, [tissueTypesData])
   
    
    return (
        // List
        <>
            <div className={`col-12 text-left px-0 d-flex align-items-center justify-content-center`}>
                <div className={` col-12 ml-0 pl-2 ${styles.wound_block_text}`}>
                    <b>Select all tissue types that apply</b>
                </div>
                {/* <div className="text-right custom-control custom-switch col-5 ml-4 pt-0">
                    <div className={`${styles.toggle_shells} d-flex justify-content-around`}>
                        <div className={`${styles.toggle_button_status}`}>Override: </div> 
                        <input 
                            type="checkbox"
                            name="toggle" 
                            className="custom-control-input"
                            id={"toggleSwitch"}
                            data-testid={"toggleSwitch"}                                                    
                            onChange={toggleChange}
                            defaultChecked={toggleOn}
                        />
                        <label className={`custom-control-label ${styles.toggle_switch}`} for="toggleSwitch"></label> 
                    </div>
                </div> */}
            </div>
        <div>
         
            {/* <hr className={styles.tab_border_fix}/> */}

            {tissueTypesData?.isRecommended  === 1 &&

                <div id="ai" className={`${styles.tissue_ai} ${styles.nothing}`}>

                    {/* AI tissue types */}
                    <div id="aiBlock">
                        {/* Heading part */}
                        <div className={`${styles.tissue_head}  ${styles.nothing}`}>

                            <h4>Suggested Tissue Types <span onClick={() => toggleInfo('tissueInfoAi')}>i</span></h4>
                            <p id="tissueInfoAi" style={{ display: 'none' }}>These tissue types are suggested by the AI</p>
                            <div className={styles.tissue_arrow}><img src={downArrow} alt="down arrow" onClick={() => toggleInfo('tissueBodyAi')} /></div>

                        </div>

                        <div className={styles.tissue_body} id="tissueBodyAi" >
                            <CharAiTissueLists
                                styles={styles}
                                tissueTypesData={tissueTypesData?.tissueTypes}
                                handleSelect={handleSelect}
                                checkFlag={checkFlag}
                                selectedCheck={selectedCheck}
                                inputFlag={inputFlag}
                                result={result}
                                handleInput={handleInput}
                                validPercent={validPercent}
                                assessmentDetails={assessmentDetails}
                                aiAvailable={aiAvailable}
                                checkDefault={checkDefault}
                                ai={1}
                            />
                        </div>
                    </div>


                    {/* Additional tissue types */}
                    <div id="additionalBlock"> 
                        {/* Heading part */}
                        <div className={`${styles.tissue_head}  ${styles.nothing}`}>

                            <h4>Additional Tissue Types <span onClick={() => toggleInfo('tissueInfoAdd')}>i</span></h4>
                            <p id="tissueInfoAdd" style={{ display: 'none' }}>Select additional tissue types which are applicable</p>
                            <div className={styles.tissue_arrow}><img src={downArrow} alt="down arrow" onClick={() => toggleInfo('tissueBody')} /></div>

                        </div>

                        <div className={styles.tissue_body} id="tissueBody" style={{ display: 'none' }}>
                            {/* <h6>Select all tissue types that apply</h6> */}
                            <CharAiTissueLists
                                styles={styles}
                                tissueTypesData={tissueTypesData?.tissueTypes}
                                handleSelect={handleSelect}
                                checkFlag={checkFlag}
                                selectedCheck={selectedCheck}
                                inputFlag={inputFlag}
                                result={result}
                                handleInput={handleInput}
                                validPercent={validPercent}
                                assessmentDetails={assessmentDetails}
                                aiAvailable={aiAvailable}
                                ai={0}
                            />
                        </div>
                    </div>

                </div>

            }

            {tissueTypesData?.isRecommended  === 0 &&

                // Added for Save Cancel Sticky 
                // <div id="default" className={`${styles.scroll_hide}`} style={{height: '405px',overflowY: 'scroll', overflowX: 'hidden',marginTop: '10px'}}>
                // <div id="default" className={`${styles.scroll_hide}`} style={{height: click && '405px',overflowY: click && 'scroll', overflowX: click && 'hidden',marginTop: click && '10px'}}>
                
                <div id="default">
                    {/* tissue types */}
                    <div className={styles.tissue_list}>

                        {tissueTypesData?.tissueTypes?.length === 0 && <div className={`pl-2 pt-3 pb-2`}>Sorry. No tissue types available for this wound.</div>}
                        
                        {tissueTypesData?.tissueTypes && tissueTypesData?.tissueTypes?.length > 0 && tissueTypesData?.tissueTypes?.map((data, i) => {
                            return <>
                                {/* Individual item */}
                                <div className={styles.tissue_list_item} key={i}>
                                    {/* Checkbox */}
                                    <div className={`custom-control-inline ${styles.custom_checkbox}`}>
                                        <input
                                            type="checkbox"
                                            className={` ${styles.custom_control_input}`}
                                            id={data.code + data.id}
                                            onClick={(e) => handleSelect(e, i, data.id, false)} // Pass event and index
                                            defaultChecked={checkFlag[i] || selectedCheck(data.id)}
                                            // disabled={data.enable === 1 ? false : true}
                                            disabled={(data.enable === 1) || (data.enable === 0 && toggleOn === true) ? false : true}
                                            data-testid="tissueCheckBox"
                                        />
                                        <label
                                            className={`custom-control-label ${styles.custom_control_label_check} ${data.enable === 0 && toggleOn === false && styles.disable_check}`}
                                            htmlFor={data.code + data.id}>&nbsp;</label>
                                    </div>
                                    {/* <h4 className={data.enable === 0 && styles.disable_head}>{data.name}</h4> */}
                                    <h4 className={data.enable === 0 && toggleOn === false ? styles.disable_head : styles.undisable_head}>{data.name}</h4>
                                    {/* Percent, textbox and checkbox */}
                                    <div className={styles.list_fields}>
                                        {/* Percentage */}
                                        {!inputFlag[i] // Find the specifix index is true or false (for displaying input box)
                                            && <span className={styles.display_percent}>
                                                {/* Get percentage from accessment details by passing the code  */}
                                                {result && result.length > 0 && result.map((item) => {
                                                    if (item.id === data.id) {
                                                        // If there is wound percentage return it, else return null
                                                        if (item.woundFillingPercentage) {
                                                            return item.woundFillingPercentage + '%'
                                                        } else {
                                                            return null
                                                        }
                                                    }
                                                })}
                                            </span>}

                                        {/* Input field */}

                                        {inputFlag[i] && data.id <= 5
                                            && <input type="text" name={data.code} placeholder="% Covered" onBlur={(e) => handleInput(e, i, data.id)} />}

                                        {/* Error message */}
                                        {inputFlag[i] && data.id <= 5 && data.code !== 'N/A' && validPercent === data.id && <em>Please enter valid percentage!</em>}

                                    </div>
                                </div>
                            </>
                        })}
                    </div>

                    {((tissueTypesData?.overrideTissueType && tissueTypesData?.overrideTissueType?.length > 0) || (tissueTypesData?.overrideTissueType !== null)) &&   
                        <div  className={tissueTypesData?.overrideTissueType?.length > 0 ? "justify-content-between custom-control custom-switch col-12 pt-0 pl-0 pr-0 mb-4" : "d-none"}>                        
                            <div id={'checkAdd'}  style={{'padding':-1}} className={`${styles.toggle_shells} ${styles.wound_block_text_override} d-flex justify-content-between px-0 pr-0`}>
                                <div className={`${styles.toggle_button_status} pl-2`}><b>Consider additional tissue types (uncommon) </b></div> 
                                <input 
                                    type="checkbox"
                                    name="toggle" 
                                    className="custom-control-input"
                                    id={"toggleSwitch"}
                                    data-testid={"toggleSwitch"}                                                   
                                    onChange={toggleChange}
                                    // defaultChecked={toggleOn}
                                    checked={toggleOn}
                                />
                                <label className={`custom-control-label ${styles.toggle_switch} px-0`} for="toggleSwitch"></label> 
                            </div>
                        </div>
                    }
                    
                    {/* override tissue types */}
                    <div className={styles.tissue_list}>

                        {/* {tissueTypesData?.tissueTypes?.length > 0 && tissueTypesData?.overrideTissueType?.length === 0 && <div className={`pl-2`}>Sorry. No tissue types available for this wound.</div>} */}

                        {tissueTypesData?.overrideTissueType && tissueTypesData?.overrideTissueType?.length > 0 && tissueTypesData?.overrideTissueType?.map((data, i) => {
                            return <>
                                {/* Individual item */}
                                <div className={styles.tissue_list_item} key={i}>
                                    {/* Checkbox */}
                                    <div className={`custom-control-inline ${styles.custom_checkbox}`}>
                                        <input
                                            type="checkbox"
                                            className={` ${styles.custom_control_input}`}
                                            id={data.code + data.id}
                                            onClick={(e) => handleSelectOverride(toggleOn, e.target.checked, i, data.id, false)} // Pass event and index
                                            defaultChecked={checkFlagOverride[i] || selectedCheckOverride(data.id)}
                                            disabled={(data.enable === 1 && toggleOn === false) ? true : false}
                                            data-testid="tissueCheckBox"
                                            checked={checkFlagOverride[i]}
                                        />
                                        <label
                                            className={`custom-control-label ${styles.custom_control_label_check} ${data.enable === 1 && toggleOn === false && styles.disable_check}`}
                                            htmlFor={data.code + data.id}>&nbsp;</label>
                                    </div>
                                    {/* <h4 className={data.enable === 0 && styles.disable_head}>{data.name}</h4> */}
                                    <h4 className={data.enable === 1 && toggleOn === false ? styles.disable_head : styles.undisable_head}>{data.name}</h4>
                                    {/* Percent, textbox and checkbox */}
                                    <div className={styles.list_fields}>
                                        {/* Percentage */}
                                        {!inputFlagOverride[i] // Find the specifix index is true or false (for displaying input box)
                                            && <span className={styles.display_percent}>
                                                {/* Get percentage from accessment details by passing the code  */}
                                                {result && result.length > 0 && result.map((item) => {
                                                    if (item.id === data.id) {
                                                        // If there is wound percentage return it, else return null
                                                        if (item.woundFillingPercentage) {
                                                            return item.woundFillingPercentage + '%'
                                                        } else {
                                                            return null
                                                        }
                                                    }
                                                })}
                                            </span>}

                                        {/* Input field */}

                                        {inputFlagOverride[i] && data.id <= 5
                                            && <input type="text" name={data.code} placeholder="% Covered" onBlur={(e) => handleInputOverride(e, i, data.id)} />}

                                        {/* Error message */}
                                        {inputFlagOverride[i] && data.id <= 5 && data.code !== 'N/A' && validPercent === data.id && <em>Please enter valid percentage!</em>}

                                    </div>
                                </div>
                            </>
                        })}
                    </div>


                </div>
            }
        </div>
        </>
    )
}
