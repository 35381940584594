import { createSlice } from '@reduxjs/toolkit';
import { CHECK_CUSTOMER_NUMBER, CUSTOM_FORM_LIST, CUSTOM_FORM_LIST_VIEW, CUSTOM_FORM_VIEW, DELETE_FORM, LIST_VIEW_LIMIT, SUCCESS_STATUS } from '../constants';
import { Service } from '../service';
import { constructEndpoint } from '../utils/commonHelper';
import { deleteAsync, setError, setLoading } from './appSlice';
import { format } from "date-fns";
import { ne1getwoundassessmentcharacteristicsAsync } from './patientSlice';
const { request } = Service;

export const customFormSlice = createSlice({
    name: 'forms',
    initialState: {
      formList:{},
      formDetailsData:{},
      customForm:{},
      orgData:{},
      upDateStatus:{},
      createFormStatus:{},
      setForm:{},
      viewForms:{},
      upDateSaveForm:{},
      productCategoryListOptions:{},
      productAttributes:{},
      productCategoryDesc:[],
      productFamilyCode:[],
      materiallist:{},
      assignEmail:{}
    },
    reducers: {
      setFormList:(state, action)=>{
        state.formList = action.payload 
      },
      setFormDetailsData: (state, action) => {
        state.formDetailsData = action.payload
      },
      resetFormDetailsData: (state, action) => {
        state.formDetailsData = {}
      },
      setCustomForm:(state, action) => {
        state.customForm = action.payload
      },
      setFacilitiesData:(state, action) => {
        state.orgData = action.payload
      },
      reSetFacilitiesData:(state, action) => {
        state.orgData = {}
      },
      setUpdateStatus:(state, action) => {
        state.upDateStatus = action.payload
      },
      setCreateFormStatus:(state, action) => {
        state.createFormStatus = action.payload
      },
      setForm: (state,action) => {
        state.setForm = action.payload;
      },
      setviewForms:(state,action) => {
        state.viewForms = action.payload;
      },
      setStatusUpdateForm:(state,action) => {
        state.upDateSaveForm = action.payload;
      },
      setProductCategoryListOptions:(state, action) => {
        state.productCategoryListOptions = action.payload;
      },
      setProductAttributes:(state, action) => {
        state.productAttributes = action.payload;
      },
      setMateriallist:(state, action) => {
        state.materiallist = action.payload;
      },
      setAssignEmail:(state, action) => {
        state.assignEmail = action.payload;
      },
    }
})
export const {
  setFormList,
  setFormDetailsData,
  resetFormDetailsData,
  setCustomForm,
  setFacilitiesData,
  reSetFacilitiesData,
  setUpdateStatus,
  setCreateFormStatus,
  setForm,
  setviewForms,
  setStatusUpdateForm,
  setProductCategoryListOptions,
  setProductAttributes,
  setProductCategoryDesc,
  setProductFamilyCode,
  setMateriallist,
  setAssignEmail
} = customFormSlice.actions;

// callbackfunction
export const getCallBackListAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      if (data && data.status === SUCCESS_STATUS) {

        data.data.forms.map((value)=>{ // added for datecreate & update
          value['dateCreated'] = value.dateCreated
            ? format(new Date(value.dateCreated), "MMMM dd, yyyy")
            : "";
          value['dateUpdated'] = value.dateUpdated
            ? format(new Date(value.dateUpdated), "MMMM dd, yyyy")
            : "";
        })// added for datecreate & update

        dispatch(setFormList(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
        window.location='/unAuthorized';
      }
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

  // delete patient list
export const deleteFormListAsync = (payload) => async dispatch => {
    try {
      dispatch(setLoading(true));
      payload.map((value)=>{
        dispatch(deleteAsync(DELETE_FORM, value.id))
      })
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }
  

    // create form
export const createFormAsync = (payload, history) => async dispatch => {
    try {
      dispatch(setLoading(true));
      const data = await request(CUSTOM_FORM_LIST, {
        method: 'POST',
        body: JSON.stringify(payload.data)
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setCreateFormStatus(data))
        let listPayLoad = {};
            listPayLoad['url']= CUSTOM_FORM_LIST
            listPayLoad['method']= 'Get'
            listPayLoad['queryParam'] = {limit:LIST_VIEW_LIMIT, offSet:0}
            dispatch(getCallBackListAsync(listPayLoad))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      dispatch(setCreateFormStatus(obj))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

    // detailview
  export const formDetailsAsync = (payload, history) => async dispatch => {
    try {
      //dispatch(setFormDetailsData({}))
      dispatch(setLoading(true));
      const data = await request(`${CUSTOM_FORM_LIST}/${payload}`, {
        method: 'GET',
      });
      if (data && data.status === SUCCESS_STATUS) {
       
       
        dispatch(setFormDetailsData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
      if(obj?.response?.status === 405 || obj?.response?.status === 404){
        window.location = "/unAuthorized";
      }
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

    // update
  export const upDateformDetailsAsync = (payload, history) => async dispatch => {
    try {
      dispatch(setLoading(true));
      const data = await request(`${CUSTOM_FORM_LIST}/${payload.id}`, {
        method: 'PUT',
        body: JSON.stringify(payload)
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setUpdateStatus(data))
        dispatch(formDetailsAsync(payload.id))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

  export const getFacilitiesAsync = (payload, history) => async dispatch => {
    try {
      dispatch(setLoading(true));
      const data = await request(`${CHECK_CUSTOMER_NUMBER}?customerNumber=${payload}`, {
        method: 'GET',
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setFacilitiesData(data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      dispatch(setFacilitiesData(obj))
    }
    finally {
      dispatch(setLoading(false));
    }
  }

  // custom form tab list
  export const getCustomFormAsync = (payload) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      //dispatch(setForm({}))
      const data = await request(constructEndpoint(CUSTOM_FORM_LIST_VIEW,payload), {
        method: 'GET'
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setForm(data))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }


  // custom form
  export const getFormViewAsyn = (payload) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setviewForms({}))
      const data = await request(constructEndpoint(CUSTOM_FORM_VIEW,payload.id, payload.queryParam), {
        method: 'GET'
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setviewForms(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }
  // custom form
  export const updateViewFormAsyn = (payload, getQueryParam) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const obj  = {
        ne1Id: payload.queryParam.ne1Id,
        assessmentId: payload.queryParam.assessmentId,
        formData: payload.data.formdata
      }
      const data = await request(constructEndpoint(CUSTOM_FORM_VIEW,payload.id), {
        method: payload.method,
        body:JSON.stringify(obj)
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setStatusUpdateForm(data))
        dispatch(getCustomFormAsync(getQueryParam))
       let patientId=getQueryParam?.ne1Id
       let assessmentId=getQueryParam?.assessmentId
        dispatch(ne1getwoundassessmentcharacteristicsAsync( { patientId,assessmentId }))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }

  export const productCategoryListOptions = () => async (dispatch) => {
    try {
      //dispatch(setProductCategoryListOptions({}))
      dispatch(setLoading(true));
      const data = await request(constructEndpoint('/productselector/productcategorylistoptions'), {
        method: 'GET'
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setProductCategoryListOptions(data))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }

  export const productAttribute = (payload) => async (dispatch) => {
    try {
if(payload&&payload.division){
      dispatch(setLoading(true));
      const data = await request(constructEndpoint('/productselector/attribute'), {
        method: 'POST',
        body:JSON.stringify(payload)
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setProductAttributes(data))
        return data;
      }
    }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }
  //listoptions/materiallist
  export const getMateriallist = () => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      //const data ={"status":"success","data":{"options":[{"division":"71","productCategoryDesc":"Multi-Layer Compression Systems","materialNumber":"6075","exactMaterialNumber":"AND7800","materialName":"AND7800","title":"AND7800 - DRESSING,COMPRESSION SYS,COFLEX,2 LAYER","productFamilyCode":"PF11482","productFamilyDesc":"CoFlex TLC Two-Layer Compression System"},{"division":"71","productCategoryDesc":"Multi-Layer Compression Systems","materialNumber":"6162","exactMaterialNumber":"AND7800H","materialName":"AND7800H","title":"AND7800H - DRESSING,COMPRESSION SYS,COFLEX,2 LAYER","productFamilyCode":"PF11482","productFamilyDesc":"CoFlex TLC Two-Layer Compression System"}]},"message":null}
      const data = await request(constructEndpoint('/listoptions/materiallist'), {
        method: 'GET'
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setMateriallist(data))
      }
    }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }
  // Update FormBuilder media
export const updateFormBuilderMediaList = ({formId,questionId,list, setUploadSuccess, setServerError,questionIndex,customFormBuilder,setcustomFormBuilder}) => async dispatch => {
  try {
    //console.log('formId',formId)
    //console.log('listlistlist',list)
    dispatch(setLoading(true));
    const data = await request(`${CUSTOM_FORM_LIST}/${formId}/questions/${questionId}/media`, {
      method: 'POST',
      body: JSON.stringify(list)
    });
    if (data && data.status === SUCCESS_STATUS) {
     
      if(setUploadSuccess){
        setUploadSuccess(true)
        setTimeout(() => {
          setUploadSuccess(false)
        }, 5000)
      }
      let resQuestionId=data?.data.questionId?data?.data.questionId:questionId
      dispatch(getFormbuilderImages({formId,resQuestionId,list, setUploadSuccess, setServerError,questionIndex,customFormBuilder,setcustomFormBuilder}));
    }
  
  }
  catch (error) {  
    const obj = { ...error }
    if(setServerError){
      setServerError(obj.errorMessage)
      setTimeout(() => {
        setServerError(null)
      }, 5000)
    }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
//Get Formbuilder images
export const getFormbuilderImages = ({formId,resQuestionId,list, setUploadSuccess, setServerError,questionIndex,customFormBuilder,setcustomFormBuilder}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    //const data ={"status":"success","data":{"options":[{"division":"71","productCategoryDesc":"Multi-Layer Compression Systems","materialNumber":"6075","exactMaterialNumber":"AND7800","materialName":"AND7800","title":"AND7800 - DRESSING,COMPRESSION SYS,COFLEX,2 LAYER","productFamilyCode":"PF11482","productFamilyDesc":"CoFlex TLC Two-Layer Compression System"},{"division":"71","productCategoryDesc":"Multi-Layer Compression Systems","materialNumber":"6162","exactMaterialNumber":"AND7800H","materialName":"AND7800H","title":"AND7800H - DRESSING,COMPRESSION SYS,COFLEX,2 LAYER","productFamilyCode":"PF11482","productFamilyDesc":"CoFlex TLC Two-Layer Compression System"}]},"message":null}
    const data = await request(constructEndpoint(`${CUSTOM_FORM_LIST}/${formId}/questions/${resQuestionId}/media`), {
      method: 'GET'
    });
    if (data && data.status === SUCCESS_STATUS) {
      let media=data.data;
      let customFormObject = [...customFormBuilder]
      let selectTypeDat = { ...customFormObject[questionIndex] }
      if(selectTypeDat?.id===0||selectTypeDat?.id==="0"){
        selectTypeDat.id=resQuestionId
      }
      selectTypeDat.media = media;
      let customFormObj = [...customFormObject]
      customFormObj.splice(questionIndex, 1, selectTypeDat)
      setcustomFormBuilder(customFormObj)
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

 //listoptions/useremail
 export const getAssignEmailAsyn = ({query,setEmailTagsSuggestions,setIsBusy}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(`/listoptions/useremail?search=${query}`), {
      method: 'GET'
    });
  /*  data={
      "status": "success",
      "data": {
          "userEmail": [
              {
                  "id": 160,
                  "email": "sahilkhajuria@photon.com"
              },
              {
                  "id": 49,
                  "email": "sahilkhajuria@photon.in"
              }
          ]
      },
      "message": "users email fetch successfully"
  }*/
    if (data && data.status === SUCCESS_STATUS) {
      //dispatch(setAssignEmail(data.data))
      let finalEmailSugestion = [];
      Array.isArray(data?.data?.userEmail)&&data?.data?.userEmail?.map((item) => {
        finalEmailSugestion.push({ id: item.id, name: item.email });
      });
      setEmailTagsSuggestions(finalEmailSugestion)
      setIsBusy(false)
    }
  }
  catch (error) {
 
    setEmailTagsSuggestions([])
    setIsBusy(false)
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//importproducttoformulary
export const importProductToFormulary = (payload,setImportedMaterials) => async (dispatch) => {
  try {

    dispatch(setLoading(true));
    const  data = await request(constructEndpoint('/importproducttoformulary'), {
      method: 'POST',
      body:JSON.stringify(payload)
    });
 
    if (data && data.status === SUCCESS_STATUS) {
      setImportedMaterials(data)
    }else if(data && data.status === 'error'){
      setImportedMaterials(data)
    }

  }
  catch (error) {
    let obj = { ...error }
    /* If there are error, dispatch setError action */
   // let errCk= obj?.finalErrorRes?JSON.parse(obj?.finalErrorRes):''
    setImportedMaterials({status:'error',data:null,message:obj?.errorMessage})
  }
  finally {
    dispatch(setLoading(false));
  }
}
  export default customFormSlice.reducer;
  