import React, { useEffect} from 'react';
import {
  open,
  hide,
  modal_header,
  modal_title,
  serviceErrrAccess,
  access_error_size,
  overlay_check
} from './modal.module.scss';
import PropTypes from 'prop-types';

export let Modal = ({ modalOpen, modalClose, heading, children, className, modalSize, reset,
  modalScroll = true, overlay, overlay_class, custom_heading, subHeading, subNumber, noScroll = false, accessError, accessErrorClass, pdfModal,custom_body,custom_content,custom_fade, modal_title, close,footerContent }) => {

    return (
    <><div id={'modal_id'} className={`modal ${(modalOpen) ? open : hide} ${(modalOpen) ? 'opencheck' : 'closecheck'} ${className}`} aria-hidden="true">
      <div className={`modal-dialog ${noScroll ? '' : modalScroll && 'modal-dialog-scrollable'} modal-dialog-centered ${modalSize} ${accessErrorClass && access_error_size}`}>
        <div className={`${custom_content?custom_content:''} ${pdfModal?'d-none':'modal-content rounded-0'}`}>
          {accessError && <div className={`col-12 ${serviceErrrAccess}`}>{accessError}</div>}
          {accessError && <div className={`p-4`}></div>}
          {heading && <div className={`modal-header pl-4 pr-4 border-0 ${custom_heading} ${modal_header}`}>
            <div className={overlay_class}>{overlay}</div>
            <h5 className={`modal-title ${modal_title}`}>{heading} {subHeading && <br />} <span> {subHeading ? subHeading : ''}</span> <strong>{subNumber ? subNumber : ''}</strong></h5>
            <button type="button" className={`close modalRefresh ${close}`} data-dismiss="modal" aria-label="Close" onClick={() => modalClose(
              (reset) ? reset() : ''
              
              )
              
              }>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>}
          <div className={`modal-body p-0 ${overlay_class?overlay_check:''} ${custom_body?custom_body:''}`}>
            {children}
          </div>
          <div className="modal-footer p-0 border-0">&nbsp;{footerContent}</div>
        </div>
      </div>
    </div>
      {(modalOpen) && <div className={`modal-backdrop fade show ${custom_fade?custom_fade:''}`}></div>}</>
  );
};
Modal.propTypes = {
  modalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
  heading: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
  modalSize: PropTypes.any,
  overlay: PropTypes.any,
  overlay_class: PropTypes.any,
  custom_heading: PropTypes.any
};
