import React, { useEffect, useState, useRef } from 'react';
import style from './woundAssessment.module.scss';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector, useDispatch } from 'react-redux';
import LogoImage from '../../../static/images/measurmenttest.jpg';
import { Modal } from '../../secondary/Modal/modal';
import {  patientAssessmentHistoryAsync, patientAssessmentAsync, ne1updateassessmentsignlockAsync, setAssessmentHistory, createNewParentFromChild, setChildAsItsOwnParent, setDetailAssessment, setWoundAssessment } from '../../../app/patientSlice';
import { format } from 'date-fns'
import { setLoading } from '../../../app/appSlice';
import Loader from '../../../static/images/spinner.svg';
import { assessmentStageGetterMove, fullMonthFormatDate, pdfPageMove, allNullCheck} from '../../../utils';
import { Button } from '../../primary/Button/button';
import PDFIcon from '../../../static/images/pdfIcon.svg';
import { handleDeleteData, openDeleteConfirmationData, modalOpenData } from '../../../utils/patientsHelper';
import { Confirmation } from '../../secondary/Confirmation/confirmation';
import warning from '../../../static/images/icon-check-danger.png';
import { setDeleteAdminChildStatus } from '../../../app/appSlice'; 

export let AssessmentHistory = ({ modalOpen, modalClose, assessmentTitle, modalTitlteId, history, setmodalShow, deleteAssessment, deleteHistoryAssessment, childLevelDelete, setChildLevelDelete, setNewWoundOptionChildLevel }) => {

  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const { patientId } = useParams();
  const assessmentId = modalTitlteId; 
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(patientAssessmentHistoryAsync(patientId, assessmentId));
  }, [patientId, assessmentId])
  
  const assessmnetHistoryData = useSelector(state => state.patient.assessmentHistory);
  const childAsItsOwnParentAssessment = useSelector(state => state.patient.childAsItsOwnParent);

  useEffect(() => {
    setHoverUnlockIcon(false)
    setShowDeleteConfirmation(false)
    setTinyDelay(false)
    setClickThreeDots('')
    setOpenTooltip(false)
    setHoverUnlockIcon(false)
    setHoverMergeIcon(false)
    setHoverDeleteIcon(false)
    setLockUnlockToggle(false)
    setLockUnlockToggleLockedOne(false)
    setLockUnlockToggleLockedZero(false)
    setLockUnlockDone(false)
    setLockUnlockToggleLockedZeroPopup(false)
    setLockUnlockToggleLockedOnePopup(false)
    if(isAdminView){
      setNewWoundOptionChildLevel(false)
    }
  }, [])

  const [tinyDelay, setTinyDelay] = useState(false)

useEffect(()=>{
  let getloadValue = assessmnetHistoryData?.followuphistory?.map(data=>data?.assessments?.map(item=>item)) !== undefined ? true : false
  if(getloadValue){
    setTimeout(()=>{
      setTinyDelay(true)
    }, 1000)    
  }    
 })

  // Delete assessment id after confirmation message purpose
  const deleteAdminChildStatus = useSelector((state) => state.app.deleteAdminChildStatus); 
  useEffect(()=>{
    if(deleteAdminChildStatus && childLevelDelete){   
      setTinyDelay(false)  
      loadOffAssessmentDelete()     
    }
  },[deleteAdminChildStatus, childLevelDelete])

  const loadOffAssessmentDelete = () => {
    setTimeout(()=>{
      setTinyDelay(true)
      setOpenTooltip(false)
      setClickThreeDots('')
      setHoverUnlockIcon(false)
      setHoverMergeIcon(false)
      setHoverDeleteIcon(false)
      dispatch(setDeleteAdminChildStatus(false))
      if(isAdminView){
        setChildLevelDelete(false)
      }                
    },5000) 
  }

  //getting History/cause value
  const historyCause = useSelector(state => state.patient.historyCause);
  // Get wound progress
  const woundProgress = useSelector(state => state.patient.woundProgress);  
  // Role
  const userRole = useSelector(state => state.user);

  const modalRefresh = () => {
    setmodalShow(false);
  }

  // redirect to assessment details screen fn
  const detaileView = (data) => {
    if (userRole.role !== 'ExternalStandardUser') {
      history.push('/patients/' + patientId + '/assessment/' + data);
      modalRefresh();
    }
  }

  const [pdfModal, setPdfModal] = useState(false)
  const [showImage, setShowImage] = useState(false)

  const pdfmodalopen = () => { //added for Pdf with/without Image
    setPdfModal(true);
  }
  const pdfmodalclose = () => {
    setPdfModal(false)
  }

  //added for Pdf with/without Image
  const pdfPageTrue = (data) => {  
    let showImage = true
    pdfPageMove(data, dispatch, setLoading, history, patientId, modalRefresh, showImage)
  }
  const pdfPageFalse = (data) => { 
    let showImage = false 
    pdfPageMove(data, dispatch, setLoading, history, patientId, modalRefresh, showImage)
  }
  //added for Pdf with/without Image

  // redirect to assessment details screen fn
  const pdfPage = (data) => {
    pdfPageMove(data, dispatch, setLoading, history, patientId, modalRefresh, showImage)
  }

  const assessmentStageGetter = (data) => {
    return assessmentStageGetterMove(data)
  }

  // click on three dots action 
  const [historyId, setHistoryId] = useState(); //PDf Popup Getting ID from assessment history list
  const [clickThreeDots, setClickThreeDots] = useState("")
  const [openTooltip, setOpenTooltip] = useState(false)
  const threeDotPanelClick = (assessmentID) => {
    dispatch(setDetailAssessment({}));
    if(assessmnetHistoryData?.followuphistory?.map(data=>data?.assessments?.map(item=>item.id===assessmentID))){
      setClickThreeDots(assessmentID)
      setOpenTooltip(true)
      setLockUnlockToggle(false)
      setLockUnlockToastMsg(false)
    } 
    assessmnetHistoryData?.followuphistory?.map(data=>{
      data?.assessments?.map(item=>{
        if(item?.isLocked === 1){
          setLockUnlockToggleLockedOne(true)           
        }
        else{
          setLockUnlockToggleLockedZero(false)
        }
      })
    }) 
  }

  // outside click on three dots action
  const wrapperRef = useRef(null)
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (!wrapperRef?.current?.contains(event.target)) {
      setOpenTooltip(false)
      setClickThreeDots("")
      setLockUnlockToggle(false)       
      assessmnetHistoryData?.followuphistory?.map(data=>{
        data?.assessments?.map(item=>{
          if(item?.isLocked === 1){
            setLockUnlockToggleLockedOne(true)           
          }
          else{
            setLockUnlockToggleLockedZero(false)
          }
        })
      })
    }
    else{
      setOpenTooltip(true)  
      setLockUnlockToggle(false)    
    }
  };

  // Delete Functionlaity for each assessment
  const [assessmentIdValue, setAssessmentId] = useState();
  const [historyAssessmentId, setHistoryAssessmentId] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [assessTitleContent, setAssessTitleContent] = useState('')
  const openDeleteConfirmation = (_assessmentId, _historyAssessmentId, woundTitle) => {
    setAssessTitleContent(woundTitle)
    openDeleteConfirmationData(_assessmentId, _historyAssessmentId, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation)
  }

  const handleDelete = () => {
    handleDeleteData(historyAssessmentId, deleteHistoryAssessment, assessmentIdValue, deleteAssessment, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation)
  }

  const [hoverUnlockIcon, setHoverUnlockIcon] = useState(false)
  const [hoverMergeIcon, setHoverMergeIcon] = useState(false)
  const [hoverDeleteIcon, setHoverDeleteIcon] = useState(false)
  const [hoverNewWound, setHoverNewWound] = useState(false)
  const [lockUnlockToggle, setLockUnlockToggle] = useState(false)
  const [lockUnlockToggleLockedOne, setLockUnlockToggleLockedOne] = useState(false)
  const [lockUnlockToggleLockedZero, setLockUnlockToggleLockedZero] = useState(false)
  const [lockUnlockToggleLockedOnePopup, setLockUnlockToggleLockedOnePopup] = useState(false)
  const [lockUnlockToggleLockedZeroPopup, setLockUnlockToggleLockedZeroPopup] = useState(false)
  const [lockUnlockDone, setLockUnlockDone] = useState(false)

  // Default set to lock/unlock status
  useEffect(()=>{
  let getlockassessment = [];   
  assessmnetHistoryData?.followuphistory?.map(data=>{
    data?.assessments?.map(item=>{
      if(item?.isLocked === 1){
        setLockUnlockToggleLockedOne(true)
        return(
          getlockassessment.push(data?.id)          
        )
      }
      else{
        setLockUnlockToggleLockedZero(false)
        setLockUnlockToggleLockedOne(false)
      }
    })
  })
  },[assessmnetHistoryData])  

  // Lock/unlock functionality
  const [getObject, setGetObject] = useState({})
  const changeLockUnlockLockedOnePopup = (assessmentid, lockValue, signValue) => {
    const payloadObj = {
      'assessmentid' : assessmentid,
      'lockValue': lockValue,
      'signValue': signValue,
      'LUONE': true
    }
    setGetObject(payloadObj)
    setLockUnlockToggleLockedOnePopup(true)
    setLockUnlockToggleLockedOne(prev => !prev)
    setLockUnlockToggleLockedZeroPopup(false)
  }
  const changeLockUnlockLockedZeroPopup = (assessmentid, lockValue, signValue) => {
    const payloadObj = {
      'assessmentid' : assessmentid,
      'lockValue': lockValue,
      'signValue': signValue,
      'LUONE': false
    }
    setGetObject(payloadObj)
    setLockUnlockToggleLockedZeroPopup(true)
    setLockUnlockToggleLockedZero(prev => !prev)
    setLockUnlockToggleLockedOnePopup(false)
  }

  const [toggleClicked, setToggleClicked] = useState(false)
  const onClickToggleLockUnlock = () => {
    setToggleClicked(true)
  }  

  const [latestReEval, setLatestReEval] = useState(false)
  const lockUnlockConfirmation = () => {
    let assessmentid = getObject.assessmentid
    let lockValue = getObject.lockValue
    let signValue = getObject.signValue
    let latestReEvalId = parseInt(assessmnetHistoryData?.followuphistory?.map(data=>data?.assessments?.map(item=>item))?.map(ele=>ele[0]?.id));
    if(getObject.LUONE === true){
      changeLockUnlockLockedOne(assessmentid, lockValue, signValue)
      setToggleClicked(false)
      if(latestReEvalId===assessmentid){
        setLatestReEval(true)
      } 
      if(latestReEvalId!==assessmentid){
        setLatestReEval(false)
      }
    }
    if(getObject.LUONE === false){
      changeLockUnlockLockedZero(assessmentid, lockValue, signValue)
      setToggleClicked(false)
      if(latestReEvalId===assessmentid){
        setLatestReEval(true)
      } 
      if(latestReEvalId!==assessmentid){
        setLatestReEval(false)
      }
    }
  }

  const changeLockUnlockLockedOne = (assessmentid, lockValue, signValue) => {
    let getLockValue = lockValue === 1 ? 0 : 1; 
    dispatch(setAssessmentHistory({}));
    const assessment = {'signed':signValue, 'locked': getLockValue}
    const assessmentId = assessmentid
    let payload = {
      patientId,
      assessmentId,
      assessment
    }
    dispatch(ne1updateassessmentsignlockAsync(payload))
    setLockUnlockDone(true)        
    // isLockedOneAssessment(assessmentid, getLockValue, signValue)
  }

  const changeLockUnlockLockedZero = (assessmentid, lockValue, signValue) => {
    let getLockValue = lockValue === 1 ? 0 : 1;
    dispatch(setAssessmentHistory({}));
    const assessment = {'signed':signValue, 'locked': getLockValue}
    const assessmentId = assessmentid
    let payload = {
      patientId,
      assessmentId,
      assessment
    }
    dispatch(ne1updateassessmentsignlockAsync(payload))
    setLockUnlockDone(true) 
    // isLockedZeroAssessment(assessmentid, getLockValue, signValue)
  } 
  
  // const isLockedOneAssessment = (assessmentid, lockValue, signValue) => {
  //   const assessment = {'signed':signValue, 'locked': lockValue}
  //   const assessmentId = assessmentid
  //   let payload = {
  //     patientId,
  //     assessmentId,
  //     assessment
  //   }
  //   dispatch(ne1updateassessmentsignlockAsync(payload))
  //   setLockUnlockDone(true)
  // }
  // const isLockedZeroAssessment = (assessmentid, lockValue, signValue) => {   
  //   const assessment = {'signed':signValue, 'locked': lockValue}
  //   const assessmentId = assessmentid
  //   let payload = {
  //     patientId,
  //     assessmentId,
  //     assessment
  //   }
  //   dispatch(ne1updateassessmentsignlockAsync(payload))
  //   setLockUnlockDone(true)    
  // }

  const [lockUnlockToastMsg, setLockUnlockToastMsg] = useState(false)
  const detailAssessment = useSelector(state=>state.patient.detailAssessment)
  useEffect(()=>{
    if(lockUnlockDone){   
      setLockUnlockToastMsg(false)   
      setTimeout(()=>{
        setTinyDelay(false)
        dispatch(patientAssessmentHistoryAsync(patientId, assessmentId));
        loadOffAssessment()
        setLockUnlockToastMsg(true)
      },500)      
    }
  },[lockUnlockDone])
  
  const loadOffAssessment = () => {
    if(latestReEval){
      dispatch(patientAssessmentAsync(patientId))
    }
    setTimeout(()=>{
      setTinyDelay(true)
      setLockUnlockToastMsg(false)
      setOpenTooltip(false)
      setClickThreeDots('')
      setHoverUnlockIcon(false)
      setHoverMergeIcon(false)
      setHoverDeleteIcon(false)
      setLockUnlockToggle(false)
      setLockUnlockDone(false) 
      setLockUnlockToggleLockedZero(false)
      setLockUnlockToggleLockedOne(false)
      setLockUnlockToggleLockedZeroPopup(false)
      setLockUnlockToggleLockedOnePopup(false)     
    },3000) 
  }

  // Merge functionality
  const onMergeChange = (valueId) => {
    let adminPage = false;  
    history.push({
      pathname: '/patients' + `/${patientId}` + '/assessment/' + valueId + '/' + 'mergewoundassessment',
      state : adminPage
    });
    dispatch(setDetailAssessment({}));
    dispatch(setAssessmentHistory({}));
    dispatch(setWoundAssessment({}));
  }

  // New create functionality
  const [onNewWoundCreateConfirm, setOnNewWoundCreateConfirm] = useState(false)
  const [onNewWoundCreateId, setOnNewWoundCreateId] = useState()
  const onNewWoundCreatePopup = (assessmentId) => {
    setOnNewWoundCreateConfirm(true)
    setOnNewWoundCreateId(assessmentId)
  }

  const onNewWoundCreate = () => {  
    let payload = {
        "patientId": patientId,
        "assessmentId": `${onNewWoundCreateId}`,
    }
    setOnNewWoundCreateConfirm(false)
    dispatch(createNewParentFromChild(payload))
  }

  useEffect(()=>{
    if(childAsItsOwnParentAssessment){
      const finalArrValue = parseInt(assessmnetHistoryData?.followuphistory?.map(data=>data?.assessments?.filter(item=>item.id!==assessmentId))?.map(item=>item[0]?.id))
      setTimeout(()=>{
        setTinyDelay(false)
        dispatch(patientAssessmentHistoryAsync(patientId, finalArrValue));
        loadOffAssessmentNewWound()
      },300) 
    }
  },[childAsItsOwnParentAssessment])

  const loadOffAssessmentNewWound = () => {
    setTimeout(()=>{
      setTinyDelay(true)
      if(assessmnetHistoryData?.followuphistory.length > 0){
        setmodalShow(false)
        setNewWoundOptionChildLevel(true)
      }
    },1000) 
  }

  useEffect(()=>{
    if(Array.isArray(assessmnetHistoryData?.followuphistory) && assessmnetHistoryData?.followuphistory.length === 0) {
      dispatch(patientAssessmentAsync(patientId))
      // modalRefresh()
    }
  },[assessmnetHistoryData])

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
        modalSize={style.custom_modal_size}
        pdfModal={pdfModal}
      >
        {
          <div className={style.parent}>
            <div>
              <div className={`${style.modal_heading} pl-4 pt-3 mt-3`}>Assessment History</div>
              {/* <div className={`pl-4 ${style.sub_heading}`}>

                {
                  `${assessmentTitle}`
                }
              </div> */}
              {deleteAdminChildStatus && <div className={`alert alert-success ${style.form_alert} ${style.deleteToastMsg}`}>{'Wound deleted successfully'}</div>}
              {lockUnlockToastMsg && detailAssessment?.locked===1  && <div className={lockUnlockToastMsg && detailAssessment?.locked===1 ? `alert alert-success ${style.form_alert} ${style.deleteToastMsg}`: 'd-none'}>{'Wound has been locked'}</div>}
              {lockUnlockToastMsg && detailAssessment?.locked===0  && <div className={lockUnlockToastMsg && detailAssessment?.locked===0 ? `alert alert-success ${style.form_alert} ${style.deleteToastMsg}`: 'd-none'}>{'Wound has been unlocked'}</div>}
            </div>
            <button type="button" className={!pdfModal && `close ${style.close_icon}`} onClick={() => modalRefresh()}>&times;</button>
            {/* <div className={`pl-4 pr-3 text-right`}>
              <Button
                value={"Compare"}
                id={'Compare'}
                classes={{ className: `btn pb-2 pt-2 mb-4 mr-2 ${style.no_btn}` }}
              />
            </div> */}
            <br />
            {!tinyDelay ? <div className={style.loader_fix}><img src={Loader} alt="loading" /></div>

              :
              <>
              {lockUnlockToggleLockedOnePopup && toggleClicked && 
                <Confirmation  onClose={() => setLockUnlockToggleLockedOnePopup(false)} title={" "}>
                  <div className="text-center pr-5 pl-5">
                    <p>
                      <img src={warning} alt="warnning-icon" />
                    </p>
                    <p className="pb-3">
                    {!lockUnlockToggleLockedOne ? 'Do you want to unlock the assessment for this wound?' : 'Do you want to lock the assessment for this wound?'}
                    </p>
                    <div className="mb-2">                      
                      <Button
                        id="closeId"
                        value="No"
                        classes={{ className: `btn mr-4 pb-2 pt-2 ${style.no_btn}` }}
                        onClick={() => setLockUnlockToggleLockedOnePopup(false)}
                      />
                      <Button
                        value="Yes"
                        classes={{
                          className: `btn mr-2 pb-2 pt-2 ml-2 ${style.yes_btn}`,
                        }}
                        onClick={() => lockUnlockConfirmation()}
                      />
                    </div>
                  </div>
              </Confirmation>     
              } 

              {lockUnlockToggleLockedZeroPopup && toggleClicked && 
                <Confirmation  onClose={() => setLockUnlockToggleLockedZeroPopup(false)} title={" "}>
                  <div className="text-center pr-5 pl-5">
                    <p>
                      <img src={warning} alt="warnning-icon" />
                    </p>
                    <p className="pb-3">
                    {!lockUnlockToggleLockedZero ? 'Do you want to unlock the assessment for this wound?' : 'Do you want to lock the assessment for this wound?'}
                    </p>
                    <div className="mb-2">                      
                      <Button
                        id="closeId"
                        value="No"
                        classes={{ className: `btn mr-4 pb-2 pt-2 ${style.no_btn}` }}
                        onClick={() => setLockUnlockToggleLockedZeroPopup(false)}
                      />
                      <Button
                        value="Yes"
                        classes={{
                          className: `btn mr-2 pb-2 pt-2 ml-2 ${style.yes_btn}`,
                        }}
                        onClick={() => lockUnlockConfirmation()}
                      />
                    </div>
                  </div>
              </Confirmation>     
              } 

              {onNewWoundCreateConfirm && 
                <Confirmation  onClose={() => setOnNewWoundCreateConfirm(false)} title={" "}>
                  <div className="text-center pr-5 pl-5">
                    <p>
                      <img src={warning} alt="warnning-icon" />
                    </p>
                    <p className="pb-3">
                    {'Do you want to change this assessment to a new wound?'}
                    </p>
                    <div className="mb-2">                      
                      <Button
                        id="closeId"
                        value="No"
                        classes={{ className: `btn mr-4 pb-2 pt-2 ${style.no_btn}` }}
                        onClick={() => setOnNewWoundCreateConfirm(false)}
                      />
                      <Button
                        value="Yes"
                        classes={{
                          className: `btn mr-2 pb-2 pt-2 ml-2 ${style.yes_btn}`,
                        }}
                        onClick={onNewWoundCreate}
                      />
                    </div>
                  </div>
                </Confirmation>
              }
                
                {assessmnetHistoryData?.followuphistory?.length !== 0 ? <>                  
                  {
                    assessmnetHistoryData && assessmnetHistoryData?.followuphistory?.map((item, index) => { //NOSONAR
                      return <div className={style.admission_status_space}>

                        <div className={`pl-4 ${style.sub_heading}`}>

                          {
                            `${item.woundTitle}`
                          }
                        </div>
                        <div className={`pl-4 pr-4 mb-2 mt-1 pt-1 ${style.admission_status}`}>History/Cause: {' '} 
                          {item.causeCategory !== "unknown" ? ((item.causeCategory && item.cause) ?
                            <span className=''>                                  
                              {historyCause && historyCause?.length > 0 && historyCause?.map((data)=>{
                                if(data.key===item.causeCategory){
                                  return data?.options?.map((subdata)=>{
                                    if(subdata.key===item.cause || subdata.value===item.cause){
                                      return data.value + '-' + subdata.value
                                    }
                                  })
                                }
                              })}
                            </span>
                            : <span className=''>{`-`}</span>)
                            : <span className=''>{`Unknown`}</span>
                          }
                        </div>
                        <div className={`pl-4 pr-4 mb-2 pb-1 mt-1 ${style.admission_status}`}>Admission Date: {fullMonthFormatDate(item.admissionDate)}</div>
                        <table className="w-100" cellPadding='0' cellSpacing='0'>
                          <thead>
                            <tr className={style.complete_th}>
                              <th>Wound</th>
                              <th>Record Type</th>
                              <th>Photo Taken On</th>
                              <th>Wound Progress</th>
                              <th>% Change</th>
                              <th>Workflow Status</th>
                              <th>View/Download PDF</th>
                              {isAdminView && <th></th>}
                              
                            </tr>
                          </thead>
                          {
                            item && item?.assessments?.map((history, index) => { //NOSONAR
                              return <tbody>
                                <tr className={`${style.complete_td} mb-2`}>
                                  <td onClick={() => detaileView(history.id)}>
                                    {
                                      history.picture ? <img
                                        src={history.picture}
                                        alt="wound"
                                        className={style.modal_image}
                                      /> :
                                        <img src={LogoImage} alt="wound image" className={style.modal_image} />
                                    }
                                  </td>
                                  <td onClick={() => detaileView(history.id)}>
                                    {
                                      history.recordType ? <span className={`${style.recordTypeBatch}`}>{history.recordType}</span>
                                      :
                                      '-'
                                    }
                                  </td>
                                  <td onClick={() => detaileView(history.id)}>{format(new Date(history.identifiedDate), 'LLLL d, yyyy hh:mmaa')}</td>
                                  <td onClick={() => detaileView(history.id)}>
                                    {
                                      history?.woundStage ?
                                        woundProgress && woundProgress.length > 0 && woundProgress?.map((data) => {
                                          if (data.key === history?.woundStage?.toLowerCase()) {
                                            return data.value
                                          }
                                        })
                                        :
                                        ''
                                    }
                                  </td>

                                  {/* <td onClick={() => detaileView(history.id)}>{`${history.closurePercentage}${history.closurePercentage !== 'Unknown' ? history.closurePercentage && `${history.closurePercentage !== '' ? `%` : ''}` : ''}`}</td> */}
                                  <td onClick={() => detaileView(history.id)}>                                    
                                      {history.closurePercentage ? 
                                        (history.closurePercentage !== "Unknown" ?  <span className={history.closurePercentage !== '0' ? (history.closurePercentage < '0' ? `${style.closureColor_green}` : `${style.closureColor_red}`) : ('')}>
                                          <span style={{paddingRight: "4px"}}>                                             
                                              <i className={history.closurePercentage !== "0" && (history.closurePercentage < "0" ? `fa-solid fa-down-long ${style.closureColor_green}` : `fa-solid fa-up-long ${style.closureColor_red}`)}></i>
                                          </span>
                                          {Number(history.closurePercentage?.replace( /^\D+/g, ''))} {`%`}
                                        </span> 
                                        :<span className={`pl-2`}>{"-"}</span>) 
                                      : <span className={`pl-2`}>{"-"}</span>}
                                  
                                    {/* {`${history.closurePercentage}${history.closurePercentage !== 'Unknown' ? (history.closurePercentage !== '0' ?  (history.closurePercentage < '0' ? <span style={{color:"#15b471"}}>{history.closurePercentage}</span> :  <span className={`${style.closureColor_green}`}>{history.closurePercentage}</span>) : <span>{history.closurePercentage}</span>): ''}`} */}
                                      
                                  </td>

                                  <td onClick={() => detaileView(history.id)}>
                                    {assessmentStageGetter(history.assessmentStage)}
                                  </td>
                                  <td key={index}>
                                    {(history.assessmentStage === 'pendingreview' || history.assessmentStage === 'signedlocked') ?
                                      <span className={`${style.pdf_link} pl-5`} 
                                          // onClick={() => pdfPage(history.id)}
                                          onClick={() => {pdfmodalopen();setHistoryId(history.id)}}
                                      >
                                          {/* View/Download */}
                                          <img src={PDFIcon} onClick={() => {pdfmodalopen();setHistoryId(history.id)}} />
                                        </span>
                                      :
                                      '-'
                                    }

                                    {/* <CheckBox
                                    classes={{
                                      column: "col form-check p-0",
                                      inputClassName: `${style.custom_control_input}`
                                    }}
                                    type={"checkbox"}
                                    id={`check_${index}`}
                                    name={`check_${index}`}
                                    onClick={e => e.stopPropagation()}
                                  /> */}
                                  </td>
                                  {isAdminView && <td>                                    
                                    <div className={`${style.Three_Dots_Panel_History}`} onClick={()=>threeDotPanelClick(history.id)}>
                                      <i className="fa fa-ellipsis-v" style={history.id !== clickThreeDots ? {color : "#464646"} : {color: '#919191'}} aria-hidden="true"></i>
                                      
                                      {openTooltip && history.id === clickThreeDots && 
                                      <div className={`${style.threeDotsTooltipHistory}`} ref={wrapperRef}>
                                        <div className={`${style.lockSection} ${history?.isSigned===0 && style.toggle_shells_disabled}`} onMouseOver={()=>{setHoverUnlockIcon(true)}} onMouseLeave={()=>{setHoverUnlockIcon(false)}}>
                                        {history?.isLocked === 1 ? 
                                        <>
                                          <i className={lockUnlockToggleLockedOne ? "fa fa-lock" : "fa fa-unlock-alt"} aria-hidden="true" style={{fontSize:'19px', color: !hoverUnlockIcon ? "#505f79" : "#ffffff", padding: '0 6px'}}></i>
                                          <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'5px'}}>{lockUnlockToggleLockedOne ? 'Locked' : 'Unlocked'} </p>
                                        </>
                                        :
                                        <>
                                          <i className={lockUnlockToggleLockedZero ? "fa fa-lock" : "fa fa-unlock-alt"} aria-hidden="true" style={{fontSize:'19px', color: !hoverUnlockIcon ? "#505f79" : "#ffffff", padding: '0 6px'}}></i>
                                          <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'5px'}}>{lockUnlockToggleLockedZero ? 'Locked' : 'Unlocked'} </p>
                                        </>
                                        }
                                        {history?.isLocked === 1 ? 
                                        <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}>    
                                          <input 
                                              type="checkbox"
                                              name={history?.id} 
                                              className="custom-control-input"
                                              id={history?.id}
                                              data-testid={"toggleSwitch"}
                                              // defaultValue = {}
                                              // checked = {traveNurse === 1}                                                     
                                              // onChange={checkHandleClick}
                                              defaultChecked = {(!lockUnlockToggle&& history?.isLocked === 1)}                                                     
                                              onChange={()=>changeLockUnlockLockedOnePopup(history?.id, history?.isLocked, history?.isSigned)}                                                                                               
                                              onClick={()=>onClickToggleLockUnlock()}                                            
                                          />
                                          <label className={`custom-control-label ${style.toggle_switch}`} for={history?.id}></label> 
                                        </div>
                                        :
                                        <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}>    
                                          <input 
                                              type="checkbox"
                                              name={history?.id}
                                              className="custom-control-input"
                                              id={history?.id}
                                              data-testid={"toggleSwitch"}
                                              // defaultValue = {}
                                              // checked = {traveNurse === 1}                                                     
                                              // onChange={checkHandleClick}
                                              disabled={history?.isSigned===0}                                                  
                                              onChange={()=>changeLockUnlockLockedZeroPopup(history?.id, history?.isLocked, history?.isSigned)}                                                                                               
                                              onClick={()=>onClickToggleLockUnlock()}
                                          />
                                          <label className={`custom-control-label ${style.toggle_switch}`} for={history?.id}></label> 
                                        </div>}

                                        </div>
                                        <div 
                                          className={`${style.mergeSection} ${history.recordType === 'Initial Assessment' && style.toggle_shells_disabled}`} 
                                          onMouseOver={()=>{setHoverMergeIcon(true)}} 
                                          onMouseLeave={()=>{setHoverMergeIcon(false)}}
                                          onClick={()=>onMergeChange(history.id)}
                                        >
                                          <svg data-name="Component 124 – 3" xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 32 32" style={{paddingLeft:'3px'}}>
                                              <path data-name="Rectangle 194" style={{fill:'none'}} d="M0 0h32v32H0z"/>
                                              <path d="M22.221 3a1.131 1.131 0 0 1 1.131 1.131v18.09a1.131 1.131 0 0 1-1.131 1.131H4.131A1.131 1.131 0 0 1 3 22.221V4.131A1.131 1.131 0 0 1 4.131 3zM12.045 5.261H5.261v6.783h2.262v-2.26l3.392 3.392-3.392 3.392v-2.261H5.261v6.783h6.784v-2.261h2.261v2.261h6.784v-6.783h-2.261v2.261l-3.392-3.392 3.392-3.392v2.26h2.261V5.261h-6.783v2.262h-2.262zm2.261 9.045v2.261h-2.261v-2.26zm0-4.523v2.261h-2.261v-2.26z" transform="translate(2 3)" style={{fill: !hoverMergeIcon ? '#505f79' : '#ffffff'}}/>
                                          </svg>
                                          <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'5px'}}>Merge </p>  
                                          <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}> 
                                          </div>                                    
                                        </div>
                                        <div 
                                          className={`${style.newWoundSection} ${history.recordType === 'Initial Assessment' && style.toggle_shells_disabled}`} 
                                          onMouseOver={()=>{setHoverNewWound(true)}} 
                                          onMouseLeave={()=>{setHoverNewWound(false)}}
                                          onClick={()=>onNewWoundCreatePopup(history.id)}
                                        >
                                          <svg data-name="Component 124 – 3" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32 32">
                                              <path data-name="Rectangle 194" style={{fill:'none'}} d="M0 0h32v32H0z"/>
                                              <path data-name="New Wound Dark" d="M6 19.771A6 6 0 0 1 1.755 9.532l7.778-7.778a6 6 0 1 1 8.485 8.485l-7.779 7.778a5.984 5.984 0 0 1-4.232 1.754zm2.89-6.885a.995.995 0 1 0 .291-.708 1 1 0 0 0-.291.708zm3-3a.993.993 0 1 0 .29-.709 1 1 0 0 0-.29.709zm-6 0a.995.995 0 1 0 .291-.708 1 1 0 0 0-.291.708zm3-3a.995.995 0 1 0 .291-.708 1 1 0 0 0-.291.708zm8.3 12.157v-1.455h-1.463a.728.728 0 0 1 0-1.455h1.455v-1.456a.728.728 0 1 1 1.455 0v1.455h1.455a.728.728 0 1 1 0 1.455h-1.454v1.455a.728.728 0 0 1-1.455 0z" transform="translate(4.999 6.004)" style={{fill: !hoverNewWound ? '#505f79' : '#ffffff'}}/>
                                          </svg>
                                          <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'4px'}}>New wound </p>  
                                          <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}> 
                                          </div>                                    
                                        </div>
                                        <div 
                                          className={`${style.deleteSection} ${history.recordType === 'Initial Assessment' && style.toggle_shells_disabled}`} 
                                          onClick={() => openDeleteConfirmation(history.id, "", item?.woundTitle)} 
                                          onMouseOver={()=>{setHoverDeleteIcon(true)}} 
                                          onMouseLeave={()=>{setHoverDeleteIcon(false)}}
                                        >
                                        <svg data-name="Component 124 – 3" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" style={{paddingLeft:'3px'}}>
                                          <path data-name="Rectangle 194" style={{fill:'none'}} d="M0 0h32v32H0z"/>
                                          <path data-name="Delete Dark" d="M71.714 67.366v-2.573a.857.857 0 0 1 .857-.857h6.857a.857.857 0 0 1 .857.857v2.573h6.857a.857.857 0 1 1 0 1.714H64.857a.857.857 0 0 1 0-1.714zm1.714 0h5.143v-1.714h-5.142zm-6 20.571a.857.857 0 0 1-.857-.857v-18h18.858v18a.857.857 0 0 1-.857.857zm6-5.143a.857.857 0 0 0 .857-.857v-8.571a.857.857 0 1 0-1.714 0v8.571a.857.857 0 0 0 .858.858zm5.143 0a.857.857 0 0 0 .857-.857v-8.571a.857.857 0 0 0-1.714 0v8.571a.857.857 0 0 0 .857.858z" transform="translate(-60 -59.936)" style={{fill: !hoverDeleteIcon ? '#505f79' : '#ffffff'}}/>
                                        </svg>
                                          <p style={{alignItems:'baseline', paddingTop:'2px', paddingLeft:'5px'}} onClick={() => openDeleteConfirmation(history.id)}>Delete </p>  
                                          <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`} onClick={() => openDeleteConfirmation(history.id)}> 
                                          </div>                                    
                                        </div>
                                      </div>}

                                    </div> 

                                    

                                  </td>}
                                </tr>
                                <tr><td className={style.tr_space}></td></tr>
                              </tbody>
                            })
                          }
                        </table>
                      </div>
                    })
                  }
                </>
                  :

                  <h4 className="text-center p-4">No data available.</h4>

                }
              </>
            }

          </div>
        }
      </Modal>

      {/* confirmation */}
      {
        showDeleteConfirmation && <Confirmation
          onClose={() => setShowDeleteConfirmation(false)}
          title={" "}
        >
          <div className="text-center pr-5 pl-5">
            <p>
              <img src={warning} alt="warnning-icon" />
            </p>
            <p className="pb-3">Do you wish to delete<br/><b>{assessTitleContent}?</b></p>
            <div className="mb-2">
              <Button
                value="No"
                classes={{ className: `btn mr-3 ml-2 pb-2 pt-2 ${style.no_btn}` }}
                onClick={() => setShowDeleteConfirmation(false)}
              />
              <Button
                value="Yes"
                classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn}` }}
                onClick={handleDelete}
              />
            </div>
          </div>
        </Confirmation>
      }

      {/* added for Pdf with/without Image */}
        {pdfModal && <Modal
          modalOpen={pdfmodalopen}
          modalClose={pdfmodalclose}
          modalSize={style.custom_modal_size_pdf}
        >
          {
            <div className={style.modalparent}>
              <div className={`${style.modal_heading_pdf} pb-2`}>
                <div className={`${style.modal_header_pdf} pl-3 pt-3`}>
                  <h3>PDF Download</h3>
                </div>
                <button type="button" className={`close ${style.close_icon_pdf} pr-3`} onClick={() => pdfmodalclose()}>&times;</button>
              </div>
            
              <div className={`${style.modal_footer}`}>
                <div className={'px-4 mt-5'}>
                  <h2> Do you want to include <br /> wound images? </h2>
                </div>
                <div className={'d-flex justify-content-center px-3 pt-5 pb-2'}>
                <Button
                      type="submit"
                      value="Yes"
                      classes={{ className: `btn btn-small ml-0 mr-5 ${style.btn_small} ${style.btn_custom}` }}
                      id="save"
                      onClick={()=>pdfPageTrue(historyId)}
                  />
                  
                  <Button
                      type="button"
                      value="No"
                      classes={{ className: `btn btn-small ${style.btn_small_cancel} ${style.btn_custom} ml-4` }}
                      id="cancel"
                      onClick={()=>pdfPageFalse(historyId)}                                                                          
                  />
                </div>
              </div>
            
            <br />
            

          </div>
          }        
        </Modal>
      }
      {/* added for Pdf with/without Image */}
      
    </>
  )
}

AssessmentHistory = connect()(withRouter(AssessmentHistory));
