import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ActionModal, CloseWarning } from '../../secondary'
import styles from './createCustomer.module.scss'
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CreateEditCustomer } from './createEditCustomer'
import { createCustomerAsyn, setResMsgState } from '../../../app/customerSlice';
import { SUCCESS_STATUS, ERROR_STATUS, CUSTOMER_CREATED_MSG } from '../../../constants';
import iconSuccess from '../../../static/images/icons-check-success.png'
import iconDanger from '../../../static/images/icon-check-danger.png'
import { setAccessError } from '../../../app/appSlice';

export let CreateCustomer = ({ dispatch, history, modalOpen, modalClose , setSearchColumns, setSearchKey , setActivePage }) => {

  const { reset, register, handleSubmit, control, errors } = useForm({ mode: 'all' });
  const [msgModal, setMsgModal] = useState(false);
  const resMsgState = useSelector(state => state.customer.resMsgState);
  const accessError = useSelector(state => state.app.accessError)
  let userDetails = useSelector(state => state.user);
  const onSubmitCustomer = (data) => {
    let postData = {
      "accountGroup": data.accountGroup,
      "customerName": data.customerName,
      "phone": data.phone,
      "address": {
        "addressLine1": data.streetAddress,
        "region": data.Region,
        "city": data.city,
        "district": data.districtName,
        "state": data.state,
        "country": data.countryCode,
        "zipCode": data.postalCode
      }
    }
    if(data.salesOfficeDescription)
    {
      postData={...postData, "salesOfficeDescription": data.salesOfficeDescription}
    }
    dispatch(createCustomerAsyn(postData, history))
  }

  const resetForm = (e, resets) => {
    reset();
    e.preventDefault();
    if (resets) {
      resets();
    }
    setMsgModal(false)
    modalClose();
  }
  const modalCheckClose = () => {
    reset();
    setMsgModal(false)
    modalClose();
  }

  const handleNo = () => {
    dispatch(setAccessError(null))
  }
  useEffect(() => {
    dispatch(setResMsgState({}));
  }, [dispatch])
  
  useEffect(() => {
    if (resMsgState === "success") {
      setMsgModal(true)
      if(document.getElementById('search')){
        document.getElementById('search').value=''
        //document.getElementById('search').value=userDetails.customerNumber
        if(setSearchKey)
          setSearchKey('')
        if(setActivePage)
          setActivePage(1)
    }
    if(document.getElementById('searchSelectbox')){
        document.getElementById('searchSelectbox').value="customerNumber"
        if(setSearchColumns)
        setSearchColumns('customerNumber')
    }
      setTimeout(() => {
        setMsgModal(false)
        reset();
        modalCheckClose()
        dispatch(setResMsgState({}))
        dispatch(setAccessError(null))
      }, 5000)
    }
  }, [resMsgState]);


  return (
    <>
      {!msgModal &&
        <Modal
          modalOpen={modalOpen}
          modalClose={modalClose}
          heading={"Create Customer"}
          className={"modal_box"}
          modalSize={styles.custom_modal_size}
          reset={reset}
          accessError={accessError && <CloseWarning
            message={accessError ? accessError : 'Access Denied'}
            noFn={handleNo}
            accessError={'accessError'}
          />
          }
        >
          <CreateEditCustomer
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmitCustomer}
            control={control}
            errors={errors}
            handleCancel={resetForm}
            isModal="true"
          />
        </Modal>
      }
      {msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalClose}
      >
        <div className="text-center p-4">
          {resMsgState === SUCCESS_STATUS && <ActionModal
            iconImg={iconSuccess}
            heading={CUSTOMER_CREATED_MSG}
            buttonText="Close"
            buttonAction={() => modalCheckClose()}
          />
          }
          {resMsgState === ERROR_STATUS && <ActionModal
            iconImg={iconDanger}
            heading="Customer not yet created!"
            buttonText="Close"
            buttonAction={() => modalCheckClose()}
          />
          }
        </div>
      </Modal>
      }
    </>
  )
}
CreateCustomer.propTypes = {
  dispatch: PropTypes.func
};
CreateCustomer = connect()(withRouter(CreateCustomer));
