import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoginForm } from '../components';
import PropTypes from 'prop-types';
import {
  loginAsync,
  loginEmailAsync,  
} from '../app/userSlice'; 
import { clearError } from "../app/appSlice";

export let Login = ({ dispatch, history , checkIp=true }) => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [localIP, setLocalIP] = useState('');

  useEffect(() => {
    const getLocalIP = () => {
     // const pc = new RTCPeerConnection({ iceServers: [] });
     const pc = new RTCPeerConnection({
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
      ],
    });
      pc.createDataChannel('');
      pc.createOffer()
        .then(offer => pc.setLocalDescription(offer))
        .catch(err => console.error('Error creating offer:', err));

      pc.onicecandidate = (ice) => {
        if (ice && ice.candidate && ice.candidate.candidate) {
          const ipMatch = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(ice.candidate.candidate);
          if (ipMatch) {
            setLocalIP(ipMatch[1]);
            pc.onicecandidate = null; // Stop listening after getting the IP
          }
        }
      };
    };
if(checkIp){
  getLocalIP();
}
   
  }, []);

  const handleLogin = data => {
    dispatch(loginAsync(data, history,localIP));
    
  } 

  const handleLoginEmail =  data => { 
      dispatch(clearError())
      if(setIsEmailValid){
      // setIsEmailValid(true)
      }
      dispatch(loginEmailAsync(data, history,setIsEmailValid));    
  } 
  return <LoginForm doLogin={handleLogin} doLoginEmail={handleLoginEmail} isEmailValid={isEmailValid} setIsEmailValid={setIsEmailValid}  history={history} localIP={localIP} />;
};

Login.propTypes = {
  dispatch: PropTypes.func,
};
 
Login = connect()(withRouter(Login));
