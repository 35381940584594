import React, { useEffect, useState, useRef } from 'react';
import { Accordion } from '../../secondary/Accordion/accordion';
import LogoImage from '../../../static/images/measurmenttest.jpg';
import deleteIcon from '../../../static/images/delete-icon.png';
import unLockIcon from '../../../static/images/UnlockIcon.png';
import mergeIcon from '../../../static/images/MergeIcon.png';
import deleteIconWhite from '../../../static/images/deleteIconDropdown.png';
import warning from '../../../static/images/icon-check-danger.png';
import { useSelector } from 'react-redux';
import style from './woundAssessment.module.scss';
import { Button, AccessErrorModal, TextField } from '../../primary';
import { Modal, ActionModal } from '../../secondary';
import { Confirmation } from '../../secondary/Confirmation/confirmation';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns'
import { daysAgo, fullMonthFormatDate, getLabel, getSubLabel, allNullCheck } from '../../../utils';
import { CreateAssessment } from './createAssessment';
import { AssessmentHistory } from '../../core/Patients/assessmentHistory';
import { WoundPhotoAssessment } from '../../core/Patients/woundPhotoAssessment';
import { optionTypeAsync, setWoundAssessment, updatepatientassessmentcustomlabels, detailAssessmentAsync, ne1updateassessmentsignlockAsync, setMergeWoundSuccess, setMergeNavigateSuccess, patientAssessmentAsync, setChildAsItsOwnParent, setDetailAssessment, setAssessmentHistory } from '../../../app/patientSlice'; //Chnaged for Label
import { handleDeleteData, openDeleteConfirmationData, modalOpenData } from '../../../utils/patientsHelper';
import Loader from '../../../static/images/spinner.svg';
import RightArrow from "../../../static/images/right_arrow.svg";
import { scryRenderedDOMComponentsWithTag } from 'react-dom/test-utils';
import { setDeleteAdminStatus, setDeleteAdminChildStatus } from '../../../app/appSlice';

export const WoundAssessment = ({ patientData, dispatch, getAssessments, getAssessmentHistory, deleteAssessment, deleteHistoryAssessment, deleteAssessmentChildLevel, history, childLevelDelete, setChildLevelDelete }) => {
  useEffect(() => {
    dispatch(setWoundAssessment('reset'))
  }, [])

  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const { patientId } = useParams();

  // Role
  const userRole = useSelector(state => state.user);
  const woundAssessments = useSelector(state => state.patient.woundAssessments);

  useEffect(() => {      
    getAssessments(patientId);
    setHoverUnlockIcon(false)
    setShowDeleteConfirmation(false)
    setAssessTitleContent('')
    setAssessReEvalCount(false)
    setAssessmentModal(false)
    setmodalShow(false)
    setAssessmentTitle('')
    setModalTitlteId('')
    setTinyDelay(false)
    setClickThreeDots('')
    setOpenTooltip(false)
    setHoverUnlockIcon(false)
    setHoverMergeIcon(false)
    setHoverDeleteIcon(false)
    setLockUnlockToggle(false)
    setLockUnlockToggleLockedOne(false)
    setLockUnlockToggleLockedZero(false)
    setLockUnlockDone(false)
    setReEvalMergeConfirm(false)
    setNavigateMergWoundYes(null)
    dispatch(setDeleteAdminStatus(false))
    dispatch(setDeleteAdminChildStatus(false))
    setLockUnlockToggleLockedZeroPopup(false)
    setLockUnlockToggleLockedOnePopup(false)
    if(isAdminView){
      setChildLevelDelete(false)
      setNewWoundOptionChildLevel(false)
    }
  }, [])  

  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(()=>{
    let getloadValue = woundAssessments?.assessments !== undefined ? true : false;
    if(getloadValue){
      setTimeout(()=>{
        setTinyDelay(true)
      }, 1000)    
    }    
   })

  let currentTitle = '';
  const [assessmentId, setAssessmentId] = useState();
  const [historyAssessmentId, setHistoryAssessmentId] = useState();
  // options API
  const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
  const historyCause = useSelector(state => state.patient.historyCause);
  // const customLabels = useSelector(state.patient.detailAssessment)
  const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions) // Get wound classification select box options
  
  // *******************
  // confirmation modal
  // *******************
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [assessTitleContent, setAssessTitleContent] = useState('')
  const [assessReEvalCount, setAssessReEvalCount] = useState(false)

  const openDeleteConfirmation = (_assessmentId, reEvalValue, assessTitle, _historyAssessmentId) => {
    openDeleteConfirmationData(_assessmentId, _historyAssessmentId, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation)
    if(reEvalValue>0){
      setAssessReEvalCount(true)
      setAssessTitleContent(assessTitle)
    }
    else{
      setAssessReEvalCount(false)
      setAssessTitleContent(assessTitle)
    }
  }
const deleteAdminStatus = useSelector(state => state.app.deleteAdminStatus)
  useEffect(()=>{
    if(deleteAdminStatus){
      setTinyDelay(false)
      setTimeout(() => {
        setTinyDelay(true)
        dispatch(setDeleteAdminStatus(false))
      }, 3000)
    }
  },[deleteAdminStatus])

  const statusBadg = {
    'pendingassessment': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_assess}`}>Pending Assessment</span>,
    'reviewed': <span className={`pt-2 pb-2  ${style.assess_status} ${style.reviewed}`}>Reviewed</span>,
    'pendingreview': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_review}`}>Pending Review</span>,
    'signedlocked': <span className={`pt-2 pb-2 ${style.assess_status} ${style.signed_locked}`}>Signed &#38; Locked</span>
  }

  // Merge wound confirmation message
  const mergeWoundSuccess = useSelector(state => state.patient.mergeWoundSuccess)
  const mergeNavigateSuccess = useSelector(state => state.patient.mergeNavigateSuccess)

  useEffect(() => {
    setTimeout(() => {
        dispatch(setMergeWoundSuccess(null)); 
    }, 5000)
  }, [mergeNavigateSuccess, dispatch])

  useEffect(()=>{
    let getloadValue = woundAssessments?.assessments !== undefined ? true : false
    if(getloadValue){
      setTimeout(()=>{
        dispatch(setMergeNavigateSuccess(false));
      }, 1000)    
    }    
   })

  /************* */
  /* DO NOT DELETE */
  /*************** */
  /// IMPORTANT -- below we only need to do condition check for 'pending review' and 'signed and locked'.
  /// if we are checking 'pending assessment', it is temp only
  // Checking wheather the assessment stage is 'pending review' or 'signed or locke'. Only then display 'Re-Eval' button.
  // Else don't display the 're-eval' button. 
  // const checkAssessmentState = (data) => {
  //   if (data?.toLowerCase().split(' ').join('') === PENDING_REVIEW || data?.toLowerCase().split(' ').join('') === SIGNED_LOCKED) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }


  // State for modal open / close
  const [assessmentModal, setAssessmentModal] = useState(false)
  const [assessId, setAssessId] = useState()

  {/****************************************** */
    /*  DO NOT REMOVE THE BELOW COMMENTED CODE  */
    /******************************************* */
  }

  /* re-eval assessment modal */
  /* Trigger opening the modal */
  // const reEvalOpen = (id) => {
  //   setAssessmentModal(true);
  //   setAssessId(id)
  // }
  // /* For closing the modal */
  // const reEvalClose = () => {
  //   setAssessmentModal(false);
  // }

  {/****************************************** */
    /*  DO NOT REMOVE THE ABOVE COMMENTED CODE  */
    /******************************************* */
  }

  // Delete Confirmation
  const handleDelete = () => {
    handleDeleteData(historyAssessmentId, deleteHistoryAssessment, assessmentId, deleteAssessment, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation)
  }

  const [modalShow, setmodalShow] = useState(false);
  const [assessmentTitle, setAssessmentTitle] = useState('');
  const [modalTitlteId, setModalTitlteId] = useState('');
  const [statecheck, setStateCheck] = useState("Passing")

  const modalOpen = (id, title) => {
    dispatch(setAssessmentHistory({}))
    modalOpenData(id, title, setAssessmentTitle, setModalTitlteId, setmodalShow)    
  } 

  // Three dots click functionality
  const [clickThreeDots, setClickThreeDots] = useState("")
  const [openTooltip, setOpenTooltip] = useState(false)
  const threeDotPanelClick = (assessmentID) => {  
    dispatch(setDetailAssessment({})); 
    if(woundAssessments?.assessments?.map(data=>data?.assessments?.map(item=>item.id===assessmentID))){
      setClickThreeDots(assessmentID)
      setOpenTooltip(true)
      setLockUnlockToggle(false) 
    } 
    woundAssessments?.assessments?.map(data=>data?.assessments?.map(item=>{
      if(item.isLocked===1){
        setLockUnlockToggleLockedOne(true)
      }
      else{
        setLockUnlockToggleLockedZero(false)
      }
    }))   
  }

  // Outside click three dots functionality
  const wrapperRef = useRef(null)
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (!wrapperRef?.current?.contains(event.target)) {
      setOpenTooltip(false)
      setClickThreeDots("")
      setLockUnlockToggle(false) 
      woundAssessments?.assessments?.map(data=>data?.assessments?.map(item=>{
        if(item.isLocked===1){
          // setLockUnlockToggleLockedOne(true)
        }
        else{
          // setLockUnlockToggleLockedZero(false)
        }
      }))
    }
    else{
      setOpenTooltip(true)   
      setLockUnlockToggle(false)    
    }
  };

  const [hoverUnlockIcon, setHoverUnlockIcon] = useState(false)
  const [hoverMergeIcon, setHoverMergeIcon] = useState(false)
  const [hoverDeleteIcon, setHoverDeleteIcon] = useState(false)
  const [lockUnlockToggle, setLockUnlockToggle] = useState(false)
  const [lockUnlockToggleLockedOne, setLockUnlockToggleLockedOne] = useState(false)
  const [lockUnlockToggleLockedZero, setLockUnlockToggleLockedZero] = useState(false)
  const [lockUnlockToggleLockedOnePopup, setLockUnlockToggleLockedOnePopup] = useState(false)
  const [lockUnlockToggleLockedZeroPopup, setLockUnlockToggleLockedZeroPopup] = useState(false)
  const [assessmentlistCount, setAssessmentlistCount] = useState(false)
  const [lockUnlockDone, setLockUnlockDone] = useState(false)
  
  // Set Lock/unlock status
  useEffect(()=>{
    setReEvalMergeConfirm(false)
    // dispatch(setDeleteAdminStatus(false))
    let getlockassessment = [];
    woundAssessments?.assessments?.map(data=>data?.assessments?.map(item=>{
      if(item.isLocked===1){
        setLockUnlockToggleLockedOne(true)
        return(
          getlockassessment.push(item?.id)          
        )
      }
      else{
        setLockUnlockToggleLockedZero(false)
        setLockUnlockToggleLockedOne(false)
      }
    }))   
    woundAssessments?.assessments?.map(data=>{
      if(data.assessments.length > 1){
        setAssessmentlistCount(true)
      }
      else{
        setAssessmentlistCount(false)
      }
    })
  },[woundAssessments])
  
  // Lock/Unlock functionality
  const [getObject, setGetObject] = useState({})
  const changeLockUnlockLockedOnePopup = (assessmentid, lockValue, signValue) => {
    const payloadObj = {
      'assessmentid' : assessmentid,
      'lockValue': lockValue,
      'signValue': signValue,
      'LUONE': true
    }
    setGetObject(payloadObj)
    setLockUnlockToggleLockedOnePopup(true)
    setLockUnlockToggleLockedOne(prev => !prev)
    setLockUnlockToggleLockedZeroPopup(false)
  }
  const changeLockUnlockLockedZeroPopup = (assessmentid, lockValue, signValue) => {
    const payloadObj = {
      'assessmentid' : assessmentid,
      'lockValue': lockValue,
      'signValue': signValue,
      'LUONE': false
    }
    setGetObject(payloadObj)
    setLockUnlockToggleLockedZeroPopup(true)
    setLockUnlockToggleLockedZero(prev => !prev)
    setLockUnlockToggleLockedOnePopup(false)
  }

  const [toggleClicked, setToggleClicked] = useState(false)
  const onClickToggleLockUnlock = () => {
    setToggleClicked(true)
  }
    
  const lockUnlockConfirmation = () => {
    let assessmentid = getObject.assessmentid
    let lockValue = getObject.lockValue
    let signValue = getObject.signValue
    if(getObject.LUONE === true){
      changeLockUnlockLockedOne(assessmentid, lockValue, signValue)
      setToggleClicked(false)
    }
    if(getObject.LUONE === false){
      changeLockUnlockLockedZero(assessmentid, lockValue, signValue)
      setToggleClicked(false)
    }
  }

  const changeLockUnlockLockedOne = (assessmentid, lockValue, signValue) => {
    let getLockValue = lockValue === 1 ? 0 : 1;   
    dispatch(setWoundAssessment({}));   
    const assessment = {'signed':signValue, 'locked': getLockValue}
    const assessmentId = assessmentid
    let payload = {
      patientId,
      assessmentId,
      assessment
    }
    let parentLockUnlock = true; 
    dispatch(ne1updateassessmentsignlockAsync(payload, parentLockUnlock))
    setLockUnlockDone(true)  
  }

  const changeLockUnlockLockedZero = (assessmentid, lockValue, signValue) => {
    let getLockValue = lockValue === 1 ? 0 : 1;
    dispatch(setWoundAssessment({}));
    const assessment = {'signed':signValue, 'locked': getLockValue}
    const assessmentId = assessmentid
    let payload = {
      patientId,
      assessmentId,
      assessment
    }
    let parentLockUnlock = true;
    dispatch(ne1updateassessmentsignlockAsync(payload, parentLockUnlock))
    setLockUnlockDone(true) 
  } 
  
  // const isLockedOneAssessment = (assessmentid, lockValue, signValue) => {
  //   const assessment = {'signed':signValue, 'locked': lockValue}
  //   const assessmentId = assessmentid
  //   let payload = {
  //     patientId,
  //     assessmentId,
  //     assessment
  //   }
  //   let parentLockUnlock = true;
  //   dispatch(ne1updateassessmentsignlockAsync(payload, parentLockUnlock))
  //   setLockUnlockDone(true)
  // }
  // const isLockedZeroAssessment = (assessmentid, lockValue, signValue) => {   
  //   const assessment = {'signed':signValue, 'locked': lockValue}
  //   const assessmentId = assessmentid
  //   let payload = {
  //     patientId,
  //     assessmentId,
  //     assessment
  //   }
  //   let parentLockUnlock = true;
  //   dispatch(ne1updateassessmentsignlockAsync(payload, parentLockUnlock))
  //   setLockUnlockDone(true)    
  // }

const [lockUnlockToastMsg, setLockUnlockToastMsg] = useState(false)
const detailAssessment = useSelector(state=>state.patient.detailAssessment)
  useEffect(()=>{
    if(lockUnlockDone){ 
      setLockUnlockToastMsg(false)           
      setTimeout(()=>{
        setTinyDelay(false)
        getAssessments(patientId)
        loadOffAssessment()
        setLockUnlockToastMsg(true)
      },500)      
    }
  },[lockUnlockDone])

  const loadOffAssessment = () => {
    setTimeout(()=>{
      setTinyDelay(true)
      setLockUnlockToastMsg(false) 
      setOpenTooltip(false)
      setClickThreeDots('')
      setHoverUnlockIcon(false)
      setHoverMergeIcon(false)
      setHoverDeleteIcon(false)
      setLockUnlockDone(false) 
      setLockUnlockToggleLockedZero(false)
      setLockUnlockToggleLockedOne(false)
      setLockUnlockToggleLockedZeroPopup(false)
      setLockUnlockToggleLockedOnePopup(false)       
    },3000) 
  }
  
  // Merge functionality
  const [reEvalMergeConfirm, setReEvalMergeConfirm] = useState(false)
  const [navigateMergWoundYes, setNavigateMergWoundYes] = useState(null)
  const onMergeChange = (valueId, reEvalCount) => {    
    if(reEvalCount > 0) {
      setReEvalMergeConfirm(true)
      setNavigateMergWoundYes(valueId)
    } 
    else{
      let adminPage = true; 
      history.push({
        pathname: '/patients' + `/${patientId}` + '/assessment/' + valueId + '/' + 'mergewoundassessment',
        state : adminPage
      }); 
      dispatch(setDetailAssessment({}));
      dispatch(setAssessmentHistory({}));
      dispatch(setWoundAssessment({})); 
    }      
  }

  // Merge Confirmation Popup functionality
  const onClickYesConfirmNavigateMergeWound = () => {
    let adminPage = true;
    let valueId = navigateMergWoundYes;  
    history.push({
      pathname: '/patients' + `/${patientId}` + '/assessment/' + valueId + '/' + 'mergewoundassessment',
      state : adminPage
    }); 
    dispatch(setDetailAssessment({}));
    dispatch(setAssessmentHistory({}));
    dispatch(setWoundAssessment({})); 
  }

// Getting Response After using'New wound' option created as a new wound
const childAsItsOwnParentAssessment = useSelector(state => state.patient.childAsItsOwnParent);
const [newWoundOptionChildLevel, setNewWoundOptionChildLevel] = useState(false)

useEffect(()=>{
  if(newWoundOptionChildLevel){
    setTinyDelay(false)
    setTimeout(()=>{
      setNewWoundOptionChildLevel(false)
    },4500)
  }
},[newWoundOptionChildLevel])

useEffect(()=>{  
  let getloadValue = woundAssessments?.assessments !== undefined ? true : false;
    if(getloadValue && childAsItsOwnParentAssessment){
      setTimeout(()=>{
        setTinyDelay(true)
        dispatch(setChildAsItsOwnParent(false))
      }, 2000)    
    }
},[childAsItsOwnParentAssessment])

  return (
  <>
  {!tinyDelay ? <div  className={`${'loader'} col-12`}><img src={Loader} alt="loading" /></div>:
  <div>

    {patientData?.moreInfo?.ne1WMSStatus === 1 && 
      <WoundPhotoAssessment
        patientData={patientData}
        dispatch={dispatch}
        getAssessments={getAssessments}
      />}

      {reEvalMergeConfirm && 
      <Modal
          // modalOpen={modalOpen}
          // modalClose={()=>{setmodalShow(false)}}
          // // heading={"Create Item"}
          // className={"modal_box"}
          // modalSize={style.custom_modal_size}
          // reset={()=>{setmodalShow(false)}}
          modalOpen={modalOpen}
          modalClose={() => setReEvalMergeConfirm(false)}
          heading={""}
          className={`${style.modal_box_Merge_Confirmation}`}
          modalSize={`${style.custom_modal_size_Merge_Confirmation}`}
          reset={() => setReEvalMergeConfirm(false)}
      >
        <div className={`${style.createManualPopup}`}>
            {/* <h1>Enter the account number</h1> */}
            <h1>This wound has multiple assessments, Do you wish to merge all assessments for this wound?</h1>
            
              <div className={`d-flex justify-content-center align-items-center text-center pt-3`}>                  
                <Button
                  classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel} mr-4`}}
                  type="button"
                  onClick={() => setReEvalMergeConfirm(false)}
                >
                  {'No'}
                </Button>
                <Button
                  classes={{ className: `btn ${style.btn_custom} ${style.btn_small}` }}
                  type="Save"
                  onClick={() => onClickYesConfirmNavigateMergeWound()}
                >
                  {"Yes"}
                </Button>
              </div>
        </div>
        
      </Modal>
    }

    {lockUnlockToggleLockedOnePopup && toggleClicked && 
      <Confirmation  onClose={() => setLockUnlockToggleLockedOnePopup(false)} title={" "}>
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">
           {!lockUnlockToggleLockedOne ? 'Do you want to unlock the assessments for this wound?' : 'Do you want to lock the assessments for this wound?'}
          </p>
          <div className="mb-2">            
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn mr-4 pb-2 pt-2 ${style.no_btn}` }}
              onClick={() => setLockUnlockToggleLockedOnePopup(false)}
            />
            <Button
              value="Yes"
              classes={{
                className: `btn mr-2 pb-2 pt-2 ml-2 ${style.yes_btn}`,
              }}
              onClick={() => lockUnlockConfirmation()}
            />
          </div>
        </div>
    </Confirmation>     
    } 

    {lockUnlockToggleLockedZeroPopup && toggleClicked && 
      <Confirmation  onClose={() => setLockUnlockToggleLockedZeroPopup(false)} title={" "}>
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">
           {!lockUnlockToggleLockedZero ? 'Do you want to unlock the assessments for this wound?' : 'Do you want to lock the assessments for this wound?'}
          </p>
          <div className="mb-2">            
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn mr-4 pb-2 pt-2 ${style.no_btn}` }}
              onClick={() => setLockUnlockToggleLockedZeroPopup(false)}
            />
            <Button
              value="Yes"
              classes={{
                className: `btn mr-2 pb-2 pt-2 ml-2 ${style.yes_btn}`,
              }}
              onClick={() => lockUnlockConfirmation()}
            />
          </div>
        </div>
    </Confirmation>     
    } 

    {/* <AccessErrorModal /> */}
    
    

    {patientData.ne1Id === patientId &&  <>

      {patientData?.moreInfo?.ne1WMSStatus === 1 ? <>

        {/* Re-eval modal  */}
        {assessmentModal && <CreateAssessment modalClose={setAssessmentModal} reEval={true} assessmentId={assessId} />}
        {/* Merging Success Message  */}
        {mergeWoundSuccess !== null &&  <div className={mergeWoundSuccess !== null ? `alert alert-success ${style.form_alert}` : 'd-none'}>{mergeWoundSuccess !== null && mergeWoundSuccess}</div>}
         {/* Lock/Unlock Message  */}
         {lockUnlockToastMsg === true && detailAssessment?.locked === 1 && <div className={lockUnlockToastMsg && detailAssessment?.locked === 1 ? `alert alert-success ${style.form_alert}` : 'd-none'}>{'Wound has been locked'}</div>}
         {lockUnlockToastMsg === true &&  detailAssessment?.locked === 0 && <div className={lockUnlockToastMsg && detailAssessment?.locked === 0 ? `alert alert-success ${style.form_alert}` : 'd-none'}>{'Wound has been unlocked'}</div>}
        {/* New Wound Created through the New Wound Option Message  */}
        {newWoundOptionChildLevel &&  <div className={`alert alert-success ${style.form_alert}`}>{'Successfully saved as a new wound'}</div>}
        {/* heading && <PatientHeading heading={heading} /> */}
        {deleteAdminStatus && <div className={`alert alert-success ${style.form_alert}`}>{'Wound deleted successfully'}</div>}
        {
          woundAssessments && woundAssessments.assessments && Array.isArray(woundAssessments.assessments) && woundAssessments.assessments.map((data, index) => {
            return (<div key={index}>
              <Accordion
                idControl={index}
                heading={
                  <div>Admission Date: {format(new Date(data.admissionDate.replace('Z', '')), 'MM/dd/yyyy hh:mm aa')}
                    {
                      data.dischargeDate ? <span> - Discharge Date: {format(new Date(data.dischargeDate), 'MM/dd/yyyy hh:mm aa')} </span> : null
                    }
                  </div>
                }
                content={<div>
                  {
                    data.assessments && data.assessments.length > 0 ? data.assessments.map((assessment, index) => {
                      currentTitle = assessment.title;
                      return <div key={index} className={`row m-0 ${style.accordion_panel}`} id={'woundAssessmentListTable'}>
                        <div className="col-lg-2 col-md-3 col-sm-3 pl-0">
                          <div className={style.img_parent}>
                            <img src={assessment.picture || LogoImage} alt="wound" className={style.wound_img} />
                            {
                              !isAdminView && assessment?.woundStage && <span className={`${style.img_stage} pt-1 pb-1`}>
                                {assessment && assessment.woundStage}
                              </span>
                            }
                          </div>
                        </div>

                        <div className={`col-10`}>
                          <div className={`row align-items-start`}>

                            <div className="col-lg-9 col-md-7 col-sm-6 pl-2">                          
                            <div className={`${style.panel_heading} ${style.body_heading} mb-4`}>
                              {userRole.role !== '' ? <Link params={{ testvalue: "hello" }} to={
                                {
                                  pathname: ('/patients/' + patientId + '/assessment/' + assessment.id),
                                  state: { 'assessment': assessment, 'humanAnatomy': humanAnatomy, 'customlabels': statecheck },
                                  assessmentsIndex: index
                                }

                              }
                              >
                                <div className="mr-3">
                                  {
                                    assessment && assessment?.title
                                  }
                                </div>
                              </Link> 
                              :
                              <div className="mr-3">
                                  {
                                    assessment && assessment?.title
                                  }
                                </div>
                              }
                              {/* <div className={style.assess_stage}>
                                {
                                  !isAdminView && statusBadg[assessment.assessmentStage]
                                }
                              </div> */}
                              </div>
                              <div className={`${style.detaile}`}>

                              <div className={`${style.assess_content_label} ${style.assess_content} mb-4`}>  
                                <span className={style.assess_label}>History/Cause: </span>                                

                                {assessment?.causeCategory !== "unknown" ? (allNullCheck(assessment?.causeCategory) && allNullCheck(assessment?.cause) ?
                                  (assessment?.causeCategory !== "others"||assessment?.causeCategory.toLowerCase() !== "others") ?
                                  <span>                                  
                                    {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                      if(data.key===assessment?.causeCategory.toLowerCase()){
                                        return data?.options?.map((item)=>{
                                          if(item.key!=='unabletodetermine'){
                                            if(item.key===assessment?.cause || item.value===assessment?.cause){
                                              if(assessment?.causeCategory === "other"){
                                                return  item.value
                                              }else{
                                                return data.value + '-' + item.value
                                              }
                                            }
                                          }
                                          else{
                                            return data.value
                                          }
                                        })
                                      }
                                    })}
                                  </span> : <span>{ assessment?.cause}</span>
                                  : <span>{`-`}</span>)
                                  : <span>{`Unknown`}</span>
                                }
                                  
                              </div>

                              {/* added for Labels */} 
                              <div className={`${style.assess_content_label} ${style.assess_content} mb-4`}>  
                                <span className={style.assess_label}>Labels: </span>
                                
                                {(assessment?.bodyType === "null" && assessment.injuryType === 'None') &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {(assessment?.injuryType === "null" && assessment?.bodyType === "null") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>} 
                                {(assessment?.injuryType === null && assessment?.bodyType === null) &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {(assessment?.injuryType === "None" && assessment?.bodyType === "None")  &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {(assessment?.bodyType === "" && assessment?.injuryType === "") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {((assessment?.bodyType === "null"||assessment?.bodyType === null) && assessment?.injuryType === "") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {((assessment?.injuryType === "null"||assessment?.injuryType === null) && assessment?.bodyType === "") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}                                                                
                                
                                
                               {(assessment?.injuryType !== "null" && assessment?.bodyType !== "null") || (assessment?.injuryType !== "None") ? <>
                               <span className={assessment?.injuryType !== "null" && assessment?.injuryType !== null && assessment?.injuryType !== "None" && assessment?.injuryType !== "" && `${style.sub_label_content} mr-3`}>
                                  {assessment?.injuryType !== "null" && assessment?.injuryType !== null && assessment?.injuryType !== "None" ? <span>{assessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>}
                                  {/* {assessment?.injuryType !== ""  ? (assessment?.injuryType !== "null" && assessment?.injuryType !== null && assessment?.injuryType !== "None" ? <span>{assessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>) : <span className={assessment?.injuryType === "None" ? 'd-none': 'd-block'}> None </span>} */}
                                  {/* {assessment?.injuryType !== "" ? (assessment?.injuryType !== "null" && assessment?.injuryType !== null  ? <span>{assessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>) : <span> None </span>} */}
                                </span>
                               
                                <span className={assessment?.bodyType !== "null" && assessment?.bodyType !== null && assessment?.bodyType !== "None" && assessment?.bodyType !== "" && `${style.sub_label_content} mr-3`}>
                                  {assessment?.bodyType !== "null" && assessment?.bodyType !== null && assessment?.bodyType !== "None" ? <span>{assessment?.bodyType}</span> : <span className='d-none'>{"None"}</span>}
                                  {/* {assessment?.bodyType !== ""  ? (assessment?.bodyType !== "null" && assessment?.bodyType !== null && assessment?.bodyType !== "None" ? <span>{assessment?.bodyType}</span> : <span className='d-none'>{"None"}</span>) : <span className={assessment?.bodyType === "None" ? 'd-none': 'd-block'}> None </span>} */}
                                </span>
                               </> : <span className={(assessment?.bodyType === "null" && assessment.injuryType === 'None') ? 'd-none' : `${style.sub_label_content} mr-3`} 
                                      // style={(assessment?.injuryType === "None" && assessment?.bodyType === "null") && {display:'none'}}
                                    >
                                  <span className={(assessment?.bodyType === "None" || assessment?.injuryType === "None") ? 'd-none': (assessment?.injuryType === "None" && assessment?.bodyType === "null") && 'd-block'}> None </span>
                                </span>}
                                
                                
                              </div>


                         {/* <Form className={`${style.LabelMain}`} onBlur={handleSubmit(doSubmit)} ref={formRef}>  */}                

                              <div className={`${style.assess_content_woundAcq} ${style.assess_content} mb-4`}>  
                                <span className={style.assess_label}>Wound Acquired: </span> {assessment.woundAcquired ? ((assessment.woundAcquired==="POA") ? <span className={`${style.POAColor}`}>{assessment.woundAcquired}</span>: <span className={`${style.NONPOAColor}`}>{assessment.woundAcquired}</span>) : 'NA'}
                              </div>

                              {/* <div className={`${style.assess_content} mb-4`}>
                                <span className={style.assess_label}>Classification: </span>
                                {
                                  getSubLabel(assessment?.cause, historyCause)
                                }
                              </div> */}

                              <div className={`${style.assess_content} mb-4`}>
                                <span className={`${style.assess_label}`}>Classification: </span>
                                {assessment?.classification !== "unknown" ? (allNullCheck(assessment?.classification) ?                                  
                                <span className={`${style.classifi_content}`}>
                                    {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                                        if (data.key === assessment?.classification) {
                                            return data.value
                                        }
                                    })
                                    }
                                </span> : <span className={`${style.classifi_content}`}>{`Unknown`}</span>):
                                <span className={`${style.classifi_content}`}>{`Unknown`}</span>}
                              </div>                              

                            </div>                           

                          </div>
                          

                          <div className="col-lg-3 col-md-2">
                            <div className={`row align-items-center ${style.full_height}`}>
                              <div className={`col-lg-10 col-md-12 ${style.closure_ctr}`}>
                                <div className={style.assess_stage}>
                                  {/* {
                                    !isAdminView && statusBadg[assessment.assessmentStage]
                                  } */}
                                  {statusBadg[assessment?.assessmentStage]}
                                </div>
                                {
                                  !isAdminView && 
                                    <div className={style.closure_container}>
                                      <div className={`${style.clousre_text} text-center`}>% Change <br/>                                        
                                        {assessment?.changeInCm ? 
                                          (assessment?.changeInCm !== "Unknown" ?
                                              <span className={assessment?.changeInCm !== "0" ? (assessment?.changeInCm < "0" ? `${style.closureColor} ${style.closureColor_green} mt-2` : `${style.closureColor} ${style.closureColor_red} mt-2`) : `${style.closureColor} ${style.closureColor_grey} mt-2`}>
                                                <span style={{paddingRight: "4px"}}>                                             
                                                    <i className={assessment?.changeInCm !== "0" && (assessment?.changeInCm < "0" ? `fa-solid fa-down-long ${style.closureColor_green}` : `fa-solid fa-up-long ${style.closureColor_red}`)}></i>
                                                </span>
                                                {Number(assessment?.changeInCm?.replace( /^\D+/g, ''))} cm<sup>2</sup>
                                                {/* {assessment?.changeInCm} cm<sup>2</sup> */}
                                              </span> : <span className={`${style.closureColor} ${style.closureColor_grey} mt-2`}>
                                                0 cm<sup>2</sup>
                                            </span>
                                          ) : ("-")
                                        } 

                                        {assessment?.closurePercentage ? 
                                          (assessment?.closurePercentage !== "Unknown" ? 
                                            <span className={assessment?.changeInCm !== "0" ? (assessment?.changeInCm < "0" ? `${style.closureColor} ${style.closureColor_green} ${assessment?.changeInCm !== '0' && `pl-2`}`: `${style.closureColor} ${style.closureColor_red} ${assessment?.changeInCm !== '0' && `pl-2`}`) : `${style.closureColor} ${style.closureColor_grey} ${assessment?.changeInCm !== '0' && `pl-2`}`}>
                                              {`(`}{Number(assessment.closurePercentage?.replace( /^\D+/g, ''))} %{`)`}
                                            </span> : <span className={`${style.closureColor} ${style.closureColor_grey}`}>
                                              {`(Unknown)`}
                                            </span>
                                          ) : ("-") 
                                        } 

                                      </div>                                      
                                     
                                    </div>                                  
                                }
                              </div>
                              <div className={`col-lg-2 col-md-12 pr-0 ${isAdminView && style.full_height}`}>
                                {
                                 // !isAdminView ?

                                    /****************************************** */
                                    /*  DO NOT REMOVE THE BELOW COMMENTED CODE  */
                                    /******************************************* */

                                    // checkAssessmentState(assessment?.assessmentStage) && patientData.statusInfo.status !== DISCHARGED &&

                                    // <div className={style.full_height}>
                                    //   <div className={`${style.closure_container}`}>
                                    //     <Button
                                    //       type="button"
                                    //       name={"Btn"}
                                    //       value="Re-Eval"
                                    //       classes={{ className: `btn  ${style.btn_small_cancel} ${style.btn_custom}` }}
                                    //       onClick={() => reEvalOpen(assessment?.id)}
                                    //     />
                                    //   </div>
                                    // </div>
                                    /****************************************** */
                                    /*  DO NOT REMOVE THE ABOVE COMMENTED CODE  */
                                    /******************************************* */

                                    //<>
                                   // </>
                                    //:
                                    // <div>
                                    //   <div className={`text-right`}>
                                    //     <img src={deleteIcon} alt="delete icon" id="asdf" className={style.del_icon} onClick={() => openDeleteConfirmation(assessment.id)} />
                                    //   </div>
                                    // </div>
                                }
                                {/* {((isAdminView && userRole.role === 'MedlineAdministrator') || (userRole.role === 'ExternalAdministrator')) && */}
                               
                               
                               
                                {/* {isAdminView &&
                                 <div>
                                 <div className={`text-right`}>
                                   <img src={deleteIcon} alt="delete icon" id="asdf" className={style.del_icon} onClick={() => openDeleteConfirmation(assessment.id)} />
                                 </div>
                               </div>
                                } */}

                                {isAdminView && 
                                  <div className={`${style.Three_Dots_Panel}`} onClick={()=>threeDotPanelClick(assessment.id)}>
                                    <i className="fa fa-ellipsis-v" style={assessment.id !== clickThreeDots ? {color : "#464646"} : {color: '#919191'}} aria-hidden="true"></i>
                                  </div>
                                }
                                {openTooltip && assessment.id === clickThreeDots && 
                                  <div className={`${style.threeDotsTooltip} ${assessment.reEvalSequence < 1 && style.threeDotsTooltipNotReEvelSeq}`} ref={wrapperRef}>
                                    {assessment.reEvalSequence < 1 && <div className={`${style.lockSection} ${assessment?.isSigned===0 && style.toggle_shells_disabled}`} onMouseOver={()=>{setHoverUnlockIcon(true)}} onMouseLeave={()=>{setHoverUnlockIcon(false)}}>                                                                  
                                      {assessment?.isLocked === 1 ?
                                        <>
                                          <i className={lockUnlockToggleLockedOne ? "fa fa-lock" : "fa fa-unlock-alt"} aria-hidden="true" style={{fontSize:'19px', color: !hoverUnlockIcon ? "#505f79" : "#ffffff", padding: '0 6px'}}></i>
                                          <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'5px'}}>{lockUnlockToggleLockedOne ? 'Locked' : 'Unlocked'} </p>
                                        </> 
                                        :
                                        <>
                                          <i className={lockUnlockToggleLockedZero ? "fa fa-lock" : "fa fa-unlock-alt"} aria-hidden="true" style={{fontSize:'19px', color: !hoverUnlockIcon ? "#505f79" : "#ffffff", padding: '0 6px'}}></i>
                                          <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'5px'}}>{lockUnlockToggleLockedZero ? 'Locked' : 'Unlocked'} </p>
                                        </>
                                      }                                      
                                      {assessment?.isLocked === 1 ? 
                                      <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}>    
                                        <input 
                                            type="checkbox"
                                            name={assessment?.id} 
                                            className="custom-control-input"
                                            id={assessment?.id}
                                            data-testid={"toggleSwitch"}
                                            defaultChecked = {(!lockUnlockToggle&& assessment?.isLocked === 1)}  
                                            onChange={()=>changeLockUnlockLockedOnePopup(assessment?.id, assessment?.isLocked, assessment?.isSigned)}                                                                                               
                                            onClick={()=>onClickToggleLockUnlock()}
                                        />
                                        <label className={`custom-control-label ${style.toggle_switch}`} for={assessment?.id}></label> 
                                      </div> : 
                                      <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}>    
                                        <input 
                                            type="checkbox"
                                            name={assessment?.id} 
                                            className="custom-control-input"
                                            id={assessment?.id}
                                            data-testid={"toggleSwitch"}
                                            disabled={assessment?.isSigned===0}    
                                            onChange={()=>changeLockUnlockLockedZeroPopup(assessment?.id, assessment?.isLocked, assessment?.isSigned)}                                                                                               
                                            onClick={()=>onClickToggleLockUnlock()}   
                                        />
                                        <label className={`custom-control-label ${style.toggle_switch}`} for={assessment?.id}></label> 
                                      </div>
                                      }
                                    </div>}
                                    <div 
                                      className={assessmentlistCount ? `${style.mergeSection}` : `${style.mergeSectionDisabled}`} 
                                      onMouseOver={()=>{setHoverMergeIcon(true)}} 
                                      onMouseLeave={()=>{setHoverMergeIcon(false)}}
                                      onClick={()=>onMergeChange(assessment.id, assessment.reEvalSequence)}
                                      disabled={assessmentlistCount===false ? true : false}
                                      contentEditable={assessmentlistCount===false ? false : true}
                                    >
                                      <svg data-name="Component 124 – 3" xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 32 32" style={{paddingLeft:'3px'}}>
                                          <path data-name="Rectangle 194" style={{fill:'none'}} d="M0 0h32v32H0z"/>
                                          <path d="M22.221 3a1.131 1.131 0 0 1 1.131 1.131v18.09a1.131 1.131 0 0 1-1.131 1.131H4.131A1.131 1.131 0 0 1 3 22.221V4.131A1.131 1.131 0 0 1 4.131 3zM12.045 5.261H5.261v6.783h2.262v-2.26l3.392 3.392-3.392 3.392v-2.261H5.261v6.783h6.784v-2.261h2.261v2.261h6.784v-6.783h-2.261v2.261l-3.392-3.392 3.392-3.392v2.26h2.261V5.261h-6.783v2.262h-2.262zm2.261 9.045v2.261h-2.261v-2.26zm0-4.523v2.261h-2.261v-2.26z" transform="translate(2 3)" style={{fill: !hoverMergeIcon ? '#505f79' : '#ffffff'}}/>
                                      </svg>
                                      <p style={{alignItems:'baseline', paddingTop:'3px', paddingLeft:'5px'}}>Merge </p>  
                                      <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`}> 
                                      </div>                                    
                                    </div>
                                    <div className={`${style.deleteSection}`} onClick={() => openDeleteConfirmation(assessment.id, assessment.reEvalSequence, assessment.title)} onMouseOver={()=>{setHoverDeleteIcon(true)}} onMouseLeave={()=>{setHoverDeleteIcon(false)}}>
                                    <svg data-name="Component 124 – 3" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" style={{paddingLeft:'3px'}}>
                                      <path data-name="Rectangle 194" style={{fill:'none'}} d="M0 0h32v32H0z"/>
                                      <path data-name="Delete Dark" d="M71.714 67.366v-2.573a.857.857 0 0 1 .857-.857h6.857a.857.857 0 0 1 .857.857v2.573h6.857a.857.857 0 1 1 0 1.714H64.857a.857.857 0 0 1 0-1.714zm1.714 0h5.143v-1.714h-5.142zm-6 20.571a.857.857 0 0 1-.857-.857v-18h18.858v18a.857.857 0 0 1-.857.857zm6-5.143a.857.857 0 0 0 .857-.857v-8.571a.857.857 0 1 0-1.714 0v8.571a.857.857 0 0 0 .858.858zm5.143 0a.857.857 0 0 0 .857-.857v-8.571a.857.857 0 0 0-1.714 0v8.571a.857.857 0 0 0 .857.858z" transform="translate(-60 -59.936)" style={{fill: !hoverDeleteIcon ? '#505f79' : '#ffffff'}}/>
                                    </svg>
                                      <p style={{alignItems:'baseline', paddingTop:'2px', paddingLeft:'5px'}} onClick={() => openDeleteConfirmation(assessment.id, assessment.reEvalSequence, assessment.title)}>Delete </p>  
                                      <div className={`${style.toggle_shells} custom-control custom-switch ml-auto`} onClick={() => openDeleteConfirmation(assessment.id, assessment.reEvalSequence, assessment.title)}> 
                                      </div>                                    
                                    </div>
                                  </div>}  

                              </div>
                            </div>
                            </div>
                          </div>



                          <div className={`row mt-3`}>
                          <div className={`col-12`}>
                              <div className={`row align-items-center ${style.assess_content_subDetails}`}>

                                <div className={`col-9 pr-0`}>
                                  <div className={`${style.assess_content} mb-2 pt-1`}>
                                    <span className={style.assess_label_subDetail_label}>Photo taken by:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.photoTakenBy ? assessment?.photoTakenBy : 'NA'}</span>  
                                    {` | `}
                                    <span className={style.assess_label_subDetail_label}>Photo taken on:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.identifiedDate ? fullMonthFormatDate(assessment?.identifiedDate) : 'NA'}</span>
                                  </div>

                                  <div className={`${style.assess_content} mb-2`}>
                                    <span className={style.assess_label_subDetail_label}>Assessed by:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.assessedBy ? assessment.assessedBy : 'NA'}</span>
                                    {` | `}
                                    <span className={style.assess_label_subDetail_label}>Last assessed: </span>
                                    <span className={style.assess_label_subDetail_labelout}>
                                      {assessment.lastAssessedDate ? daysAgo(assessment?.lastAssessedDate) : 'NA'}
                                      {/* {index !== 0 ?
                                          assessment.lastAssessedDate ? fullMonthFormatDate(assessment?.lastAssessedDate) : 'NA'
                                          :
                                          assessment.lastAssessedDate ? daysAgo(assessment?.lastAssessedDate) : 'NA'
                                      } */}
                                    </span>                                    
                                  </div>

                                  <div className={`${style.assess_content} mb-1`}>
                                    <span className={style.assess_label_subDetail_label}>Signed:</span> <span className={style.assess_label_subDetail_labelout}>{assessment.signedBy ? assessment.signedBy : 'NA'}</span>
                                      {` | `}
                                    <span className={style.assess_label_subDetail_label}>Reviewed & Locked:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.lockedBy ? assessment?.lockedBy : 'NA'}</span>
                                  </div>

                                </div>

                                <div className={`col-3`}>                                
                                    {
                                      assessment && assessment?.reEvalSequence > 0 && userRole?.role !== 'ExternalStandardUser' ? 
                                        <div className={`row align-items-center`}>
                                          <div className={`${style.assement_all_link}`}>
                                            {
                                              
                                              <a
                                                className={style.assess_link}
                                                onClick={() => modalOpen(assessment.id, assessment.title)}
                                              >
                                                All assessments                                              
                                                <span 
                                                    className={`${style.right_Arrow}`}
                                                    onClick={() => modalOpen(assessment.id, assessment.title)}                         
                                                    >                                                    
                                                    <i class="fa-solid fa-circle-right" aria-hidden="true"></i>
                                                </span> 
                                              </a>
                                            }
                                          </div>                                          
                                      </div>

                                      : ''
                                    }
                                  
                                </div>  

                              </div>
                          </div>
                          </div>
                        </div>
                        

                        

                      </div>

                    }) : <div className={`pt-4 pb-4 text-center ${style.no_data_content}`}>There are no assessments</div>
                  }
                </div>
                }
              />

              {/* confirmation */}
              {
                showDeleteConfirmation && <Confirmation
                  onClose={() => setShowDeleteConfirmation(false)}
                  title={" "}
                >
                  <div className="text-center pr-5 pl-5">                    
                    {assessReEvalCount ? <p className="pb-3">Do you wish to delete <b>{assessTitleContent}</b> including all the associated assessments?</p>
                    : 
                    <p className="pb-3">Do you wish to delete<br/><b>{assessTitleContent}?</b></p>
                    }
                    <div className="mb-2">
                      <Button
                        value="Cancel"
                        classes={{ className: `btn mr-3 ml-2 pb-2 pt-2 ${style.no_btn}` }}
                        onClick={() => setShowDeleteConfirmation(false)}
                      />
                      <Button
                        value="Delete"
                        classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn}` }}
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                </Confirmation>
              }
            </div>
            )
          })
        }

        {
          modalShow && <AssessmentHistory
            modalOpen={modalShow}
            modalClose={'modalClose'}
            setmodalShow={setmodalShow}
            assessmentTitle={assessmentTitle}
            modalTitlteId={modalTitlteId}
            getAssessmentHistory={getAssessmentHistory}
            deleteAssessment={deleteAssessmentChildLevel}
            deleteHistoryAssessment={deleteHistoryAssessment}
            setChildLevelDelete={setChildLevelDelete}
            childLevelDelete={childLevelDelete}
            setNewWoundOptionChildLevel={setNewWoundOptionChildLevel}
          />
        }
      </>
      :
      <div className="m-3" style={{background: "#fff", boxShadow: '0px 10px 20px #e4e4e4', padding: '1rem', paddingLeft: '32px', transform:'translateX(-15px'}}><h3>Patient is Inactive</h3></div>
      }
    </>}
  </div>
  }
  </>
  )
}
// WoundAssessment.propTypes = {
//   detailAssessment: PropTypes.object  
// }

// WoundAssessment = connect()(withRouter(WoundAssessment));
