import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getDownloadWoundReportCSVDetails, getWoundDashBoardAssessment, setWoundReportCsv } from '../../../app/woundReportSlice';
import { DASHBOARD_WOUNDASSESSMENT, LIST_VIEW_LIMIT } from '../../../constants';
import Spreadsheet from '../../../static/images/ExportSpredsheet.svg';
import { firstLetterCaps } from '../../../utils';
import { DataTable, Pagination } from '../../secondary';
import style from './report.module.scss';
// import ReactExport from 'react-export-excel'
// const ExcelFile=ReactExport.ExcelFile;
// const ExcelSheet=ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn=ReactExport.ExcelFile.ExcelColumn;

export let Report = ({ dispatch, history, woundProgressValue, tempPayload, selectedDivOption }) => {

    const userDetails = useSelector(state => state.user);
    const customerName = useSelector(state => state.user.customerName);

    const [adminAccess, setAdminAccess] = useState(false);
    useEffect(() => {
        if (userDetails && userDetails.role == "ExternalStandardUser") {
            setAdminAccess(true);
        }
    }, [userDetails])

    useEffect(() => {
        setSortType('')
        setOffSet(0)
        setActivePage(1)
        const woundAssessmentPayload = { ...tempPayload, optionSelected: selectedDivOption, woundProgress: woundProgressValue }
        const listPayLoad = {};
        listPayLoad['url'] = DASHBOARD_WOUNDASSESSMENT
        listPayLoad['method'] = 'Post'
        listPayLoad['payload'] = woundAssessmentPayload
        listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
        if(woundAssessmentPayload&&woundAssessmentPayload.facility&&woundAssessmentPayload.facility.length>0){
            dispatch(getWoundDashBoardAssessment(listPayLoad))
        }
       
    }, [tempPayload, selectedDivOption, woundProgressValue])
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const woundAssessmentData = useSelector(state => state.woundreport.woundAssessmentData);
    useEffect(() => {
        dispatch(setWoundReportCsv({}));
        setTimeout(() => {
            const total = (woundAssessmentData.woundAssessment && woundAssessmentData.metadata) ? woundAssessmentData.metadata.total : 50
            const queryParam = `limit=` + total + `&offSet=0`;
            const userDetailPayload = { ...tempPayload, optionSelected: selectedDivOption, woundProgress: woundProgressValue }
            if(userDetailPayload&&userDetailPayload.facility&&userDetailPayload.facility.length>0&&total!==0){
                dispatch(getDownloadWoundReportCSVDetails(userDetailPayload, queryParam))
            }
            
        }, 100)
    }, [woundAssessmentData,tempPayload, selectedDivOption, woundProgressValue])

    const woundReportCsv = useSelector(state => state.woundreport.woundReportCsv);
    const csvLink = useRef();

    const getFileName = () => {
        // const csvDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
        // return `${customerName}.${firstLetterCaps(woundProgressValue)}.Wound_Dashboard.${csvDownloadDate}.csv`;
        const csvDownloadDate = format(new Date(), "MM-dd-yyyy")
        return `NE1-Wounds-Report-${csvDownloadDate}.csv`;
    }

    const columns = [

        // {
        //     Header: ' Wound photo',
        //     accessor: 'woundImage',
        //     imageData: 'picture',
        //     restWidth: '120px',
        //     disableSortBy: true,

        // },
        {
            Header: 'MRN #',
            accessor: 'mrn',
            restWidth: '100px',
        },
        {
            Header: 'Patient\'s name',
            accessor: 'patientName',
            restWidth: '150px',
        },
        {
            Header: 'Wound name',
            accessor: 'woundName',
            restWidth: '220px',
        },
        {
            Header: 'History/Cause',
            accessor: 'historyCause',
            restWidth: '280px'

        },
        {
            Header: 'Classification',
            accessor: 'classification',
            restWidth: '200px'

        },
        {
            Header: 'Last assessed',
            accessor: 'lastAccessed',
            restWidth: '150px'

        },
        {
            Header: 'Admission date',
            accessor: 'admissionDate',
            restWidth: '250px'
        },
        {
            Header: 'Discharge date',
            accessor: 'dischargeDate',
            restWidth: '250px'
        },
        {
            Header: 'HPR Variable 1 - Worst Tissue Type',
            accessor: 'worstTissueType',
            restWidth: '265px'
        },
        {
            Header: 'HPR Variable 2 - Surface Area Value',
            accessor: 'surfaceAreaValue',
            restWidth: '265px'
        },
        {
            Header: 'HPR Variable 3 - Intact Value',
            accessor: 'intactValue',
            restWidth: '265px'
        },


    ];

    //redirect
    const getSelectedRowData = (data) => {
        if (data && !adminAccess) {
            history.push(`/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`);
        }
    }

    const deleteDataList = () => {
    }

    const selectRowCount = () => {
    }

    const handleSort = useCallback(data => {
        if (data && data.sortBy[0] && data.sortBy[0].id) {
            const woundAssessmentPayload = { ...tempPayload, optionSelected: selectedDivOption, woundProgress: woundProgressValue }
            const sortingColumn = data.sortBy[0]
            const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
            let sortParam = sortingColumn.id + sortingType
            const listPayLoad = {};
            listPayLoad['url'] = DASHBOARD_WOUNDASSESSMENT
            listPayLoad['method'] = 'Post'
            listPayLoad['payload'] = woundAssessmentPayload
            listPayLoad['queryParam'] = { offSet: isNaN(offSet)===true?0:offSet, limit: LIST_VIEW_LIMIT, sort: sortParam }
            setSortType(sortParam)
            if(woundAssessmentPayload&&woundAssessmentPayload.facility&&woundAssessmentPayload.facility.length>0){
                dispatch(getWoundDashBoardAssessment(listPayLoad))
            }
           
        }

    }, [dispatch, tempPayload, selectedDivOption, woundProgressValue,offSet])

    const getActivePage = (data) => {
        setActivePage(data);
        const woundAssessmentPayload = { ...tempPayload, optionSelected: selectedDivOption, woundProgress: woundProgressValue }
        const listPayLoad = {};
        listPayLoad['url'] = DASHBOARD_WOUNDASSESSMENT
        listPayLoad['method'] = 'Post'
        listPayLoad['payload'] = woundAssessmentPayload
        listPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT,sort: (sortType)?sortType:'' }
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        if(woundAssessmentPayload&&woundAssessmentPayload.facility&&woundAssessmentPayload.facility.length>0){
            dispatch(getWoundDashBoardAssessment(listPayLoad))
        }
    }

    const fileHeaders = [
        { label: 'MRN #', key: 'mrn' },
        { label: 'Patient name', key: 'patientName' },
        { label: 'DOB', key: 'dob' },
        { label: 'Wound name', key: 'woundName' },
        { label: 'History/Cause', key: 'historyCause' },
        { label: 'Classification', key: 'classification' },
        { label: 'Wound acquired', key: 'woundAcquired' },
        { label: 'Photo taken by', key: 'photoTakenBy' },
        { label: 'Photo taken on', key: 'photoTakenOn' },
        { label: 'Assessed by', key: 'lastAssessedby' },
        { label: 'Last assessed', key: 'lastAccessed'},
        { label: 'Signed', key: 'signed'},
        { label: 'Reviewed & locked', key: 'reviewedLocked'},
        { label: "HPR Variable 1 - Worst Tissue Type", key: "worstTissueType" },    
        { label: "HPR Variable 2 - Surface Area Value", key: "surfaceAreaValue" },
        { label: "HPR Variable 3 - Intact Value", key: "intactValue" } 

    ];

    return (
        <>
            {woundReportCsv?.woundAssessment && woundReportCsv?.woundAssessment.length > 0 &&
                <>
                    {/* <div><ExcelDownload data={woundReportCsv?.woundAssessment}/></div> */}
                    <div className="col-sm-12 mb-2 justify-content-end d-flex">
                        <span className={style.down_spread}><img src={Spreadsheet} alt="" />

                            {/* <ExcelFile filename={getFileName()} element={<label className={style.excel}> Download as CSV</label>} >
                    <ExcelSheet data={woundReportCsv?.woundAssessment} name={getFileName()}>
                        <ExcelColumn label="Wound Id" value="woundId" />
                        <ExcelColumn label="Patient Name" value="patientName" />
                        <ExcelColumn label="History/Cause" value="historyCause" />
                        <ExcelColumn label="Classification" value="classification" />

                        <ExcelColumn label="Anatomy" value="anatomy" />
                        <ExcelColumn label="Last Accessed" value="lastAccessed" />
                        <ExcelColumn label="Admission Date & Time" value="admissionDate" />
                        <ExcelColumn label="Discharge Date & Time" value="dischargeDate" />
                        
                    </ExcelSheet>
                </ExcelFile> */}

                            <label>
                                <CSVLink
                                    onClick={() => csvLink.current.link.download = getFileName()}
                                    headers={fileHeaders}
                                    data={woundReportCsv?.woundAssessment}
                                    fileName={getFileName}
                                    target="_blank"
                                    ref={csvLink}
                                >
                                    Download as CSV
                                </CSVLink>
                            </label>

                        </span>
                    </div>
                </>
            }
            {woundAssessmentData && woundAssessmentData?.woundAssessment &&
                <div className="col-12" id={"overviewLoadingTable"}>
                    <DataTable
                        columns={columns}
                        data={woundAssessmentData && woundAssessmentData?.woundAssessment}
                        sorting={true}
                        getSelectedRowData={getSelectedRowData}
                        setSelectedRows={selectRowCount}
                        onSort={handleSort}
                        detailView={true}
                        deleteDataList={deleteDataList}
                        showFixedColumn={false}
                        workListView={true}
                        reportView={true}
                        removeCursor={userDetails.role === "ExternalStandardUser" ? true : false}
                        sortType={(sortType)?sortType:''}
                    />
                </div>
            }
            {(Array.isArray(woundAssessmentData.woundAssessment)) && (woundAssessmentData.woundAssessment.length > 1) && <Pagination
                activePage={activePage}
                itemsCountPerPage={woundAssessmentData?.metadata ? woundAssessmentData?.metadata?.limit : 50}
                totalItemsCount={woundAssessmentData?.metadata ? woundAssessmentData?.metadata?.total : 0}
                getActivePage={getActivePage}
                reportView={true}
            />
            }

        </>
    )

}
Report.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}
Report = connect()(withRouter(Report));
