import React, { useEffect, useState, useRef} from 'react'
import { toast } from 'react-toastify';
import { AccessErrorModal, Button, Form } from '../../primary'
import editIcon from '../../../static/images/edit-icon.png'
import {  TextField } from "../../primary";
import { CloseWarning, Tab as TabComponent } from '../../secondary';
import { characteristicsImagesAsync, setCharStatus, woundAssessmentUpdateAsync, patientAssessmentAsyncOverride, gettissuetypeAsync,ne1updateassessmenttissuetypeAsync,ne1getWounAssessmentDetailAsync } from '../../../app/patientSlice'
import { CharTissueImage } from './charTissueImage';
import { CharTissueList } from './charTissueList';
import { useSelector } from 'react-redux';
import { allNullCheck, handleInputData, handleInputDataOverride, handleSelectHelper, handleSelectHelperOverride, selectedCheckHelper, selectedCheckHelperOverride, signedLockedAccess } from '../../../utils'
import Slider from "react-slick";
import "./react-slick.min.css";
import "./react-slick-theme.css";

export function CharacteristicTissue({ index, dispatch, updateDone, patientId, assessmentId, selectedIndex, styles, assessmentDetails,
    getCharDetails, hideIt, setShowWound, overrideToggle,isCompleted, characteristicTabsData }) {
        const getUserRole = useSelector(state => state.user);
        const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)
        const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
        const tissueTypeData = useSelector((state) => state.patient.tissueTypeDataByAssessID);
        const [assessmentDetailsNew, setAssessmentDetailsNew] = useState('');
    // Settings for react slick
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
    };    

    // Assign it to payload
    // const payload = { patientId, assessmentId, type: 'tissuetypes'}

    // useEffect(() => {
    //     // disptch to get touch and view photos
    //     dispatch(characteristicsImagesAsync(payload))
    // }, [dispatch])

    const [result, setResult] = useState([]); // Array of object with selected id and percentage
    const [resultOne, setResultOne] = useState([]) // tissuetype array
    const [resultTwo, setResultTwo] = useState([]) // override tissue type array
    
    
    const [inputFlag, setInputFlag] = useState([]) // For temp array for showing input field
    const [checkFlag, setCheckFlag] = useState([]) // For temp array for checkbox flag

    const [inputFlagOverride, setInputFlagOverride] = useState([]) // override tissue type array
    const [checkFlagOverride, setCheckFlagOverride] = useState([]) // override tissue type array

    // Select the AI selected tissue types by default if tissue types are not saved (meaning it is null)    
    const [checkDefault, setCheckDefault] = useState(null)

    // To see wheater we started selecting tissue types
    const [startedEditing, setStartedEditing] = useState(false)

    // Edit functionality
    const [editable, setEditable] = useState(false)
    /* Warning state for closing modal */
    const [closeWarning, setCloseWarning] = useState(false)

    // WoundImage Left fixing
    // useEffect(()=>{
    //     setShowWound(editable)
    // },[editable])
    useEffect(() => {
        if(tissueTypeData){
            if(isCompleted === 0){
                setEditable(true)
            }
            setAssessmentDetailsNew({'tissueTypes':tissueTypeData?.tissuesTypes,other:tissueTypeData?.other})
            if(Array.isArray(tissueTypeData?.tissuesTypes)){
               // setResult([])
                setResult(tissueTypeData?.tissuesTypes)
            }
           
        }
 
    }, [tissueTypeData,isCompleted])
    useEffect(() => {
        if(Array.isArray(tissueTypeData?.tissuesTypes)){
           setResult(tissueTypeData?.tissuesTypes)
        }
        
  }, [editable]);
    useEffect(() => {
               // setResult([])
                setCheckFlag([])
                setInputFlag([])
                setCheckFlagOverride([])
                setInputFlagOverride([])
     
    }, [])
    // If no data is saved, we are showing edit mode directly
    useEffect(() => {
        if(assessmentDetailsNew?.tissueTypes === null){
            setEditable(true)
        }
    }, [assessmentDetails])
    

    // Update results getting from result 1 & 2
    useEffect(()=>{
       //  if(Array.isArray(resultOne).length>0||Array.isArray(resultTwo).length>0)
            setResult([...resultOne].concat([...resultTwo]))
    },[resultOne, resultTwo])


    // Get data of tissue types [id, code, name, images and AI selected]
    const tissueTypesData = useSelector(state => state.patient.tissueTypesData)

    const tissueOverrideStatus = useSelector(state => state.patient.tissueOverrideStatus) // get Toggle Default status from API


    useEffect(() => { 
       if(assessmentDetailsNew?.tissueTypes !== null){
            // Result value assign to tissuetype(resultOne) array
            let valueOne= [];
            tissueTypesData?.tissueTypes?.map((value1)=>{//NOSONAR
                if(assessmentDetailsNew?.tissueTypes?.map(value2=>{//NOSONAR
                    if(value2.id===value1.id){
                        return(
                            valueOne.push(value2)
                        )
                    }
                }))//NOSONAR
                setResultOne(valueOne)
            }) //NOSONAR 
             // Result value assign to Over ride tissuetype(resultTwo) array
            let valueTwo= [];
            tissueTypesData?.overrideTissueType?.map((value1)=>{//NOSONAR
                if(assessmentDetailsNew?.tissueTypes?.map(value2=>{//NOSONAR
                    if(value2.id===value1.id){
                        return(
                            valueTwo.push(value2)
                        )
                    }
                }))//NOSONAR
                setResultTwo(valueTwo)
            })//NOSONAR
        }

        else{
            // Result value assign to tissuetype(resultOne) array
            let arrayValTissuetype = []       
            result?.map((item1)=>{//NOSONAR
                if(tissueTypesData?.tissueTypes?.map(item2=>{//NOSONAR
                    if(item2.id===item1.id){
                    return(
                        arrayValTissuetype.push(item2)
                    )                        
                    }
                }))//NOSONAR
                setResultOne(arrayValTissuetype)
            })//NOSONAR


            // Result value assign to Over ride tissuetype(resultTwo) array
            let arrayValOverrideTissue = []
            result?.map((item1)=>{//NOSONAR
                if(tissueTypesData?.overrideTissueType?.map(item2=>{//NOSONAR
                    if(item2.id===item1.id){
                    return(
                        arrayValOverrideTissue.push(item2)
                    )                        
                    }
                }))//NOSONAR
                setResultTwo(arrayValOverrideTissue)
            })//NOSONAR
        }
    },[tissueTypesData, assessmentDetails,editable]);


//  Copy tissue types from API to result
    // useEffect(() => {
    //     if (assessmentDetailsNew?.tissueTypes) {
    //         setResult([...assessmentDetailsNew?.tissueTypes])
    //     }
    // }, [assessmentDetails, editable])
   

    //Assign to API that Toggle Default status
    useEffect(()=>{
        toast.dismiss('success1')
        let payload = { patientId, assessmentId, type: 'tissuetypes'} 
        dispatch(ne1getWounAssessmentDetailAsync(payload));
        dispatch(patientAssessmentAsyncOverride(payload))
        dispatch(gettissuetypeAsync(payload))
        
        
    },[dispatch])


    // Create temporary tissue type array for 'input'
    useEffect(() => {
        // Create temp array filled with 'false' of the lengh of API tissue type data.
        // This is for 'checkbox' and showing/hiding 'input field'
       // setResult([])
        setCheckFlag([])
        setInputFlag([])
        setCheckFlagOverride([])
        setInputFlagOverride([])
        const tempArray = new Array(tissueTypesData?.tissueTypes?.length).fill(false);
        const tempArrayOverride = new Array(tissueTypesData?.overrideTissueType?.length).fill(false);        
        setInputFlag(tempArray)
        setInputFlagOverride(tempArrayOverride)
        setCheckFlag(tempArray)
        setCheckFlagOverride(tempArrayOverride)
    }, [tissueTypesData,assessmentDetails,assessmentDetailsNew])

   

    // Code spliter for spliting name and code
    // function codeSpliter(code){
    //     if(code !== 'N/A'){ // If code is not equal to N/A
    //         const arr = code?.split('.'); // Split the code by '.'
    //         return arr[0]; // return first element (which is '7')
    //     }else{
    //         return ''
    //     }
    // }
    

    // Handle select field 
    const handleSelect = (e, i, id, ai) => {   //tissue types
        setNoPercent(false)

        handleSelectHelper(e, i, id, ai, inputFlag, checkFlag, setInputFlag, setCheckFlag, resultOne, setResultOne, setCheckDefault, setStartedEditing, setNoPercentError)
    }

    const handleSelectOverride = (toggleOn, e, i, id, ai) => {  //Override tissue types
        setNoPercent(false)
        handleSelectHelperOverride(toggleOn,e, i, id, ai, inputFlagOverride, checkFlagOverride, setInputFlagOverride, setCheckFlagOverride, resultTwo, setResultTwo, setCheckDefault, setStartedEditing, setNoPercentError)
    }
    

    // Error to handle invalid percentage
    const [validPercent, setValidPercent] = useState(false)

    useEffect(() => {
        if (validPercent) {
            setTimeout(() => {
                setValidPercent(false)
            }, 1700)
        }
    }, [validPercent])

    // Handle input field
    const handleInput = (e, i, id) => {
        handleInputData(e, i, id, inputFlag, setInputFlag, resultOne, setResultOne, setValidPercent, setStartedEditing)
    }
    const handleInputOverride = (e, i, id) => {
        handleInputDataOverride(e, i, id, inputFlagOverride, setInputFlagOverride, resultTwo, setResultTwo, setValidPercent, setStartedEditing)
    }

    ////////////////////////// Necrotic tissue /////////////////////////////////
    // // Selected tissue type 
    // const selected = {
    //     tissues: [
    //         {
    //             name: 'Black/Tab Eschar (7)',
    //             percent: '50%'
    //         },
    //         {
    //             name: 'Yellow - Slough (6)',
    //             percent: '87%'
    //         }
    //     ]
    // }

    // const necroticBuilder = (selected) => {
    //     let isNecrotic; // Is necrotic ?
    //     const necroticType = []; // Necrotic type 
    //     // Map through the selected items and push the item name and percentage to above array if it is 
    //     // 'Black/Tab Eschar' or 'Yellow - Slough'
    //     selected?.tissues && selected?.tissues?.length > 0 && selected?.tissues.forEach((item) => { //NOSONAR
    //         if (item.name === 'Black/Tab Eschar (7)' || item.name === 'Yellow - Slough (6)') {
    //             isNecrotic = true; // Set is necrotic to true 
    //             necroticType.push(item) // Push to an array 
    //         }
    //     })//NOSONAR
    //     // Build an object and return it 
    //     const necrotic = {
    //         isNecrotic,
    //         necroticType
    //     }
    // }

    // // Calling above function 
    // necroticBuilder(selected)
    ////////////////////////// Necrotic tissue /////////////////////////////////


    // Creating an emptry array 'tissueIds' and copying the 'selected ids' from API
    // Calling the below 'selectedCheck' function with 'id' from below mapping.
    // This selectedCheck will see weather the id is present in our tissueIds array or not. 
    // If it is there, it will return true else return false.




    // For zooming
    /* State for body part */
  const [bodyPart, setBodyPart] = useState("");
  /* Selected ID */
  const [id, setId] = useState("");
  /* View (either front or back view) */
  const [view, setView] = useState(null);


  const Viewer = useRef(null);
  
  /* Fit it inside viewer */
//   useEffect(() => {
//     if (!editable) {
//       Viewer.current.fitToViewer();
//     }
//   }, []);

//   useEffect(() => {
//     if (!editable) {
//     //   if (assessmentDetails?.isFrontView === 1) {
//     //     document.getElementById("back")?.classList?.add("d-none"); //NOSONAR
//     //     document.getElementById("front")?.classList?.remove("d-none"); //NOSONAR
//     //   }
//     //   if (assessmentDetails?.isFrontView === 0) {
//     //     document.getElementById("front")?.classList?.add("d-none"); //NOSONAR
//     //     document.getElementById("back")?.classList?.remove("d-none"); //NOSONAR
//     //   }

//     //   humanAnatomy &&
//     //     humanAnatomy.length > 0 &&
//     //     humanAnatomy.map((data) => {//NOSONAR
//     //       if (data.key === assessmentDetails?.anatomy) {
//     //         setPartApi(data.value);
//     //       }
//     //     });
//         //NOSONAR
//       setTimeout(() => {
//         const part = document.querySelectorAll("img"); // Getting all paths
//         const partArr = Array.from(part);
//         partArr &&
//           partArr.length > 0 &&
//           partArr.map((data, i) => {//NOSONAR
            
//             data.classList.remove("active");
//           }); //NOSONAR
//         partArr &&
//           partArr.length > 0 &&
//           partArr.map((data, i) => {//NOSONAR
//             // Mapping through paths array
//             // If 'anatomy' from api maches id, add class 'active' to specific id
//             if (
//               data.id.toLowerCase().replace(/_/g, "") ===
//               assessmentDetails?.anatomy
//             ) {
//               let ele;
//               if (
//                 assessmentDetails?.isFrontView === 1 &&
//                 assessmentDetails?.anatomy !== ""
//               ) {
//                 ele = document
//                   .getElementById("front")
//                   ?.querySelector("#" + data.id);
//               } else if (
//                 assessmentDetails?.isFrontView === 0 &&
//                 assessmentDetails?.anatomy !== ""
//               ) {
//                 ele = document
//                   .getElementById("back")
//                   ?.querySelector("#" + data.id);
//               }
//               ele?.classList?.add("active"); //NOSONAR
//               return;
//             }
//           }); //NOSONAR
//       }, 100);
//     }
//   }, [assessmentDetails]);

//   // Highlight body part based on API
//   useEffect(() => {
//     if (!editable) {
//     //   if (assessmentDetails?.isFrontView === 1) {
//     //     document.getElementById("back")?.classList?.add("d-none"); //NOSONAR
//     //     document.getElementById("front")?.classList?.remove("d-none"); //NOSONAR
//     //   }
//     //   if (assessmentDetails?.isFrontView === 0) {
//     //     document.getElementById("front")?.classList?.add("d-none"); //NOSONAR
//     //     document.getElementById("back")?.classList?.remove("d-none"); //NOSONAR
//     //   }
//       // Get the full anatomy name
//     //   humanAnatomy &&
//     //     humanAnatomy.length > 0 &&
//     //     humanAnatomy.map((data) => {//NOSONAR
//     //       if (data.key === assessmentDetails?.anatomy) {
//     //         setPartApi(data.value);
//     //       }
//     //     });
//         //NOSONAR
//       const part = document.querySelectorAll("img"); // Getting all paths
//       const partArr = Array.from(part);

//       partArr &&
//         partArr.length > 0 &&
//         partArr.map((data, i) => {//NOSONAR
//           // Mapping through paths array
//           // If 'anatomy' from api maches id, add class 'active' to specific id
//           if (
//             data.id.toLowerCase().replace(/_/g, "") ===
//             assessmentDetails?.anatomy
//           ) {
//             let ele;
//             if (
//               assessmentDetails?.isFrontView === 1 &&
//               assessmentDetails?.anatomy !== ""
//             ) {
//               ele = document
//                 .getElementById("front")
//                 ?.querySelector("#" + data.id);
//             } else if (
//               assessmentDetails?.isFrontView === 0 &&
//               assessmentDetails?.anatomy !== ""
//             ) {
//               ele = document
//                 .getElementById("back")
//                 ?.querySelector("#" + data.id);
//             }
//             ele?.classList?.add("active"); //NOSONAR
//             return;
//           }
//         });//NOSONAR
//     }
//   }, [editable]);

//   // if (partApi && !editable) {
//   //     // Below code is to hide 'front' or 'back' view if body part of the specifc view is not highlighted
//   //     // We are basicially finding element by parent and searching for the class 'active', if it is there
//   //     // is no 'active' class we are hiding that element.
//   //     const front = document.getElementById('front') && Array.from(document.getElementById('front')?.getElementsByClassName('active')).length
//   //     const back = document.getElementById('back') && Array.from(document.getElementById('back')?.getElementsByClassName('active')).length
//   //     if (front === 0) {
//   //         document.getElementById('front')?.classList?.add('d-none')//NOSONAR
//   //     }
//   //     if (back === 0) {
//   //         document.getElementById('back')?.classList?.add('d-none')//NOSONAR
//   //     }
//   // }

//   // Hightlight body part in edit mode
//   useEffect(() => {
//     if (editable) {
//       const editPart = document.querySelectorAll("img"); // Getting all paths
//       const editPartArr = Array.from(editPart);
//       editPartArr &&
//         editPartArr.length > 0 &&
//         editPartArr.map((data, i) => {//NOSONAR
//           // Mapping through paths array
//           // If 'anatomy' from api maches id, add class 'active' to specific id
//           if (
//             data.id.toLowerCase().replace(/_/g, "") ===
//             assessmentDetails?.anatomy
//           ) {
//             let ele;
//             if (
//               assessmentDetails?.isFrontView === 1 &&
//               assessmentDetails?.anatomy !== ""
//             ) {
//               ele = document
//                 .getElementById("front")
//                 ?.querySelector("#" + data.id);
//             } else if (
//               assessmentDetails?.isFrontView === 0 &&
//               assessmentDetails?.anatomy !== ""
//             ) {
//               ele = document
//                 .getElementById("back")
//                 ?.querySelector("#" + data.id);
//             }
//             ele?.classList?.add("active"); //NOSONAR
//             setId(data.id);
//             return;
//           }
//         }); //NOSONAR
//     }
//   }, [editable]);

//   // Handle body part select
//   const handleSelectZoom = (e) => {
//     /* If ID is already there, clear it first */
//     if (id) {
//       handleClear(id);
//     }

//     /* Set it */
//     setId(e.target.id);
//     /* Set body part using textContent */
//     setBodyPart(e.target.textContent);
//     /* Add active class */
//     e?.target?.classList?.add("active"); //NOSONAR

//     const front =
//       document.getElementById("front") &&
//       Array.from(
//         document.getElementById("front")?.getElementsByClassName("active")
//       ).length;
//     const back =
//       document.getElementById("back") &&
//       Array.from(
//         document.getElementById("back")?.getElementsByClassName("active")
//       ).length;

//     if (front === 0) {
//       setView(0);
//     }
//     if (back === 0) {
//       setView(1);
//     }
//   };

//   /* Handling clear */
//   const handleClear = (id) => {
//     /* If ID exists clear it */
//     if (id) {
//       const part = document.getElementById(id);
//       /* remove active class */
//       part?.classList?.remove("active"); //NOSONAR
//       const forceRemove = Array.from(document.getElementsByClassName("active")); //NOSONAR
//       forceRemove &&
//         forceRemove.map((data, i) => {//NOSONAR
//           data?.classList?.remove("active"); //NOSONAR
//         }); //NOSONAR
//     }
//   };


    let tissueIds = [];

    useEffect(() => {
        // First IF is for edit mode
        if (assessmentDetailsNew?.tissueTypes && assessmentDetailsNew?.tissueTypes?.length > 0) {
            if (!startedEditing) {
                const temp = [];
                assessmentDetailsNew?.tissueTypes.map((item, i) => { //NOSONAR
                    temp.push(item.id)
                })//NOSONAR
                tissueIds = [...temp]

                let checkFlagTemp = [...checkFlag]
                tissueTypesData?.tissueTypes?.length > 0 && tissueTypesData?.tissueTypes.map((data, i) => { //NOSONAR
                    if (tissueIds.indexOf(data.id) !== -1) {
                        checkFlagTemp[i] = true;
                    } else {
                        checkFlagTemp[i] = false
                    }
                }) //NOSONAR
                setCheckFlag(checkFlagTemp)

                let checkFlagTempOverride = [...checkFlagOverride]
                tissueTypesData?.overrideTissueType?.length > 0 && tissueTypesData?.overrideTissueType.map((data, i) => { //NOSONAR
                    if (tissueIds.indexOf(data.id) !== -1) {
                        checkFlagTempOverride[i] = true;
                    } else {
                        checkFlagTempOverride[i] = false
                    }
                })//NOSONAR
                setCheckFlagOverride(checkFlagTempOverride)
            }
        } 
        // else {
        //     // If not in edit mode, if it is new, then using AI we are selecting checkbox and showing input box
        //     if (!startedEditing) {
        //         let checkFlagTemp = [...checkFlag]
        //         let inputFlagTemp = [...inputFlag]
        //         tissueTypesData?.tissueTypes?.length > 0 && tissueTypesData?.tissueTypes.map((data, i) => { //NOSONAR
        //             if (data.aiSelected === 1) {
        //                 checkFlagTemp[i] = true;
        //             } else {
        //                 checkFlagTemp[i] = false
        //             }
        //             if (data.aiSelected === 1 && data.id <= 5 && data.code !== 'N/A') {
        //                 inputFlagTemp[i] = true;
        //             }
        //             else {
        //                 inputFlagTemp[i] = false;
        //             }
        //         }) //NOSONAR
        //         setCheckFlag(checkFlagTemp)
        //         setInputFlag(inputFlagTemp)
        //     }
        // }
    }, [tissueTypesData, result])


    // function to check weather the ID exits in API or not
    const selectedCheck = (tid) => {
        const ret = selectedCheckHelper(tid, tissueIds)
        return ret;
    }

    const selectedCheckOverride = (tid) => {
        const ret = selectedCheckHelperOverride(tid, tissueIds)
        return ret;
    }


    const [aiAvailable, setAiAvailable] = useState(false)

    useEffect(() => {
        if (tissueTypesData?.tissueTypes && tissueTypesData?.tissueTypes?.length > 0) { //NOSONAR
            tissueTypesData?.tissueTypes?.forEach((data, i) => { //NOSONAR
                if (data.aiSelected === 1) {
                    setAiAvailable(true)
                    return;
                }
            }) //NOSONAR
        } //NOSONAR
        if (tissueTypesData?.overrideTissueType && tissueTypesData?.overrideTissueType?.length > 0) { //NOSONAR
            tissueTypesData?.overrideTissueType?.forEach((data, i) => { //NOSONAR
                if (data.aiSelected === 1) {
                    setAiAvailable(true)
                    return;
                }
            }) //NOSONAR
        } //NOSONAR
    }, [tissueTypesData])

    useEffect(() => {
        if (assessmentDetailsNew?.tissueTypes === null) {
            setCheckDefault(true)
        }
    }, [assessmentDetailsNew])

    // For AI selected, we are adding the AI selected items to the result by default. 
    // useEffect(() => {
    //     if (checkDefault) {
    //         const temp = []
    //         tissueTypesData?.tissueTypes && tissueTypesData?.tissueTypes?.length > 0 && tissueTypesData?.tissueTypes?.forEach((data, i) => { //NOSONAR
    //             if (data.aiSelected === 1) {
    //                 temp.push({ id: data.id })
    //             }
    //         }) //NOSONAR
    //         setResult([...temp])
    //     }
    // }, [checkDefault, tissueTypesData])


    

    // const [click, setClick]= useState(false)  // Save and Cancel Sticky 


    // Override Toggle Feature
    const [toggleOn, setToggleOn] = useState(false)
    
    const toggleChange = () => {
        if(toggleOn === false){
            setToggleOn(true)
        }else{
            setToggleOn(false);
        }         
    }

    useEffect(()=>{  
        // If anyone selected from Override Tissue type then toggle being ON conditon
        checkFlagOverride.map(check=>{//NOSONAR
            if(check === true) {
                return(
                    setToggleOn(true)
                )                
            }                      
        })//NOSONAR
    },[checkFlagOverride])

    // If nothing is selected from Override Tissue type then toggle being OFF conditon
    const [noSave, setNoSave] = useState(false)
    useEffect(()=>{
        checkFlagOverride.map((check)=>{//NOSONAR            
            if(check===false){
                setNoSave(false)
            }            
        })//NOSONAR
        checkFlagOverride.map((check)=>{//NOSONAR
            if(check===true){
                setNoSave(true)
            }            
        })//NOSONAR
    },[handleInputOverride])

    useEffect(()=>{
        if(noSave === true){
            setToggleOn(true)
        }
        // if(noSave === false){
        //     setToggleOn(false)
        // }
    },[noSave, editable])

    const methodsave = () => {
        if(noSave===false && toggleOn===true){
            setToggleOn(false)
        }
    }

    useEffect(()=>{
        if(tissueOverrideStatus===1 || overrideToggle){
            setToggleOn(true)
            setNoSave(true)
        }
        if(tissueOverrideStatus===0 || overrideToggle){
            setToggleOn(false)
            setNoSave(false)
        }
    },[tissueOverrideStatus])    

    // useEffect(()=>{
    //     if(!toggleOn){
    //         setToggleOn(false)
    //         setNoSave(false)
    //     }
        
    // },[toggleChange])


    useEffect(() => {
        // disptch to get touch and view photos       
        let payload = { patientId, assessmentId, type: 'tissuetypes', overrideenable : tissueOverrideStatus ===1 ? true : false} // Assign it to payload
        dispatch(characteristicsImagesAsync(payload))       
    }, [overrideToggle])
    // Override Toggle Feature


    /* Tab list */
    const tabList = [
        {
            name: 'Image View',
            content: <CharTissueImage
                styles={styles}
                tissueTypesData={tissueTypesData}
                assessmentDetails={assessmentDetailsNew}
                inputFlag={inputFlag}                                
                checkFlag={checkFlag}
                result={result}
                selectedCheck={selectedCheck}                
                handleInput={handleInput}                
                handleSelect={handleSelect}                
                inputFlagOverride={inputFlagOverride}
                checkFlagOverride={checkFlagOverride}
                selectedCheckOverride={selectedCheckOverride}
                handleInputOverride={handleInputOverride}
                handleSelectOverride={handleSelectOverride}
                validPercent={validPercent}
                aiAvailable={aiAvailable}
                checkDefault={checkDefault}
                toggleChange={toggleChange}
                toggleOn={toggleOn}
                setCheckFlagOverride={setCheckFlagOverride}
                setResult={setResult}
                //    codeSpliter={codeSpliter}
            />,
            className: styles.tab_fix // To remove box shadow from the tab
        },
        {
            name: 'List View',
            content: <CharTissueList
                styles={styles}
                tissueTypesData={tissueTypesData}
                assessmentDetails={assessmentDetailsNew}
                inputFlag={inputFlag}
                checkFlag={checkFlag}                
                result={result}
                selectedCheck={selectedCheck}
                handleInput={handleInput}
                handleSelect={handleSelect}
                inputFlagOverride={inputFlagOverride}
                checkFlagOverride={checkFlagOverride}
                selectedCheckOverride={selectedCheckOverride}
                handleInputOverride={handleInputOverride}
                handleSelectOverride={handleSelectOverride}
                validPercent={validPercent}
                aiAvailable={aiAvailable}
                checkDefault={checkDefault}
                toggleChange={toggleChange}
                toggleOn={toggleOn}
                setCheckFlagOverride={setCheckFlagOverride}
                setResult={setResult}
                editable={editable}
            />,
            className: styles.tab_fix
        },
    ];

    /* Warning functions Yes and no */
    const handleYes = () => {
        setResult([])
        setCheckFlag([])
        setInputFlag([])
        setCheckFlagOverride([])
        setInputFlagOverride([])
        setResult(tissueTypeData?.tissuesTypes)
        // setAssessmentDetailsNew('')
        // let payload = { patientId, assessmentId, type: 'tissuetypes'} 
        // // dispatch(ne1getWounAssessmentDetailAsync(payload));
        //  dispatch(patientAssessmentAsyncOverride(payload))
        //  dispatch(gettissuetypeAsync(payload))
        const tempArray = new Array(tissueTypesData?.tissueTypes?.length).fill(false);
        setInputFlag(tempArray)
        setCheckFlag(tempArray)
        setCheckDefault(true)
        setCloseWarning(false)
        setStartedEditing(false)
        const tempArrayOverride = new Array(tissueTypesData?.overrideTissueType?.length).fill(false);
        setInputFlagOverride(tempArrayOverride)
        setCheckFlagOverride(tempArrayOverride)
        setCheckDefault(true)
        setCloseWarning(false)
        setStartedEditing(false)
        setEditable(false)
    }
    /* No fn */
    const handleNo = () => {
        setCloseWarning(false)
    }

    const [noSelection, setNoSelection] = useState(false)
    const [noPercent, setNoPercent] = useState(false)
    const [noPercentError, setNoPercentError] = useState(false)

    const charStatus = useSelector(state => state.patient.charStatus)

    useEffect(() => {    
        setNoPercent(false)
        setNoPercentError(false)  
        let getValue = []
        result && result.length > 0 && result.forEach((data) => {//NOSONAR
            if(data?.id <= 5){
                getValue.push(data.woundFillingPercentage)
            }
        })//NOSONAR
        let finalValue = getValue.includes(undefined || '')
        if(finalValue){
            setNoPercent(true)
        }
       /* else if(!finalValue){
            setNoPercent(false)
            setNoPercentError(false)
        } */
            result && result.length > 0 && result.forEach((data) => {
            if (data?.id <= 5 && !allNullCheck(data.woundFillingPercentage)) {
                setNoPercent(true)
            }
            /*if (data?.id <= 5 && allNullCheck(data.woundFillingPercentage)) {
                setNoPercent(false)
                setNoPercentError(false)
            }*/
        })
    }, [result])
   
    //call the API for Update the data        
    const [refTissOverInfo, setRefTissOverInfo] = useState(false)
    useEffect(()=>{
        if(refTissOverInfo && !editable){
            let payloadTissue = { patientId, assessmentId, type: 'tissuetypes'} 
            dispatch(patientAssessmentAsyncOverride(payloadTissue))
        }        
    },[refTissOverInfo, !editable])//call the API for Update the data
    // Handle save 
    const handleSave = () => {

        let getValue = []
        result && result.length > 0 && result.forEach((data) => {//NOSONAR
            if(data?.id <= 5){
                getValue.push(data.woundFillingPercentage)
            }
        })//NOSONAR

        let finalValue = getValue.includes(undefined ||'')
        if (result.length === 0&&!document.getElementById('otherNamtIt')?.value) {
            setNoSelection(true)
            setTimeout(() => {
                setNoSelection(false)
            }, 5000)
            return;
        } else {
            setNoSelection(false)
        }
        if (noPercent || finalValue) {
            setNoPercentError(true)
            return;
        } else {
            setNoPercentError(false)
        }
        methodsave();
        setRefTissOverInfo(true)
        let classify = true
        if (JSON.stringify(assessmentDetailsNew?.tissueTypes) === JSON.stringify(result)) {
            classify = assessmentDetails.classification
        } else {
            classify = true
        }
        function navigate() {
            updateDone(index)
            if(Array.isArray(charStatus)&&charStatus[index]===1){
                setEditable(false)
            }else{
                selectedIndex(index + 1) /* user will get navigated to next tab */
            }
            if (classify === true) {
                const temp = [...charStatus]
                temp[2] = 1
                temp[temp.length - 2] = 0
                dispatch(setCharStatus([...temp]))
            }
        }

        /* result duplicate ID removed */
        const uniqueIds = new Set(result.map((item) => item.id))//NOSONAR
        const itemsWithUniqueIds = [...uniqueIds].map(id => result.find(item => item.id === id)).filter(Boolean)//NOSONAR
        /* result duplicate ID removed */
        const tempAssessment = {
            tissueTypes: [...itemsWithUniqueIds],
            other: document.getElementById('otherNamtIt')?.value||null,
            classification: classify === true ? null : classify
        }
        const assessment = { ...assessmentDetails, ...tempAssessment }
        const payload = {
            patientId,
            assessmentId,
            assessment,
            navigate,
            getCharDetails,
            overrideenable : toggleOn === true ? noSave === true ? true : false : false,
        }
        //dispatch(woundAssessmentUpdateAsync(payload))  
        setResultOne([])
        setResultTwo([])
        setResult([]) 
        dispatch(ne1updateassessmenttissuetypeAsync(payload))         
    }
    const woundPhotoSticky = {
        marginBottom: "0px",
        maxWidth: "500px",
        transform: 'translateY(45px)',
        position : "sticky",
        top: "0",
        bottom: "0",
        paddingLeft: "32px",
    }

    
    const [initalState, setInitalState] = useState(0);
    const [zoomClick, setZoomClick] = useState(false);
        
        const zoomInAction = (direction) => {
            if(initalState <= 8){
                setZoomClick(true)
                setInitalState(initalState + 1) 
            }
            else{
                setZoomClick(true)
                setInitalState(9)
            }    
           
        }
    
    
        const zoomOutAction = (direction) => {
            if(initalState >= 1 && initalState <= 9) {
                setZoomClick(true)
                setInitalState(initalState - 1)
            }
            
            if(initalState === 0){
                setZoomClick(true)
                setInitalState(0)
            } 
            if (initalState < 0){
                setZoomClick(true)
                setInitalState(0)
            }     
        }    
    
        const reSizeAction = () => {
            setZoomClick(true)
            setInitalState(0) 
        }
    
        useEffect(()=>{
            if(initalState === 0){
                setZoomClick(true)
                setInitalState(0)
            } 
        },[initalState])
    // <-----ZoomIn/Out OPtion----->
    function findPosYz(obj) {
        var curtop = 0;
        if (typeof (obj?.offsetParent) != 'undefined' && obj?.offsetParent) {
          while (obj?.offsetParent) {
            curtop += obj.offsetTop;
            obj = obj.offsetParent;
          }
          curtop += obj.offsetTop;
        }
        else if (obj?.y)
          curtop += obj.y;
        return curtop;
      }
    useEffect(() => {
       function chckTxtbox(){
        let startProductBarPos=-1;
        if(document.getElementById('otherNamtItpar')&&document.getElementById('otherNamtIt')?.value){
           
                let otherNamtItpar = document.getElementById('otherNamtItpar');
                let otherNamtItchild = document.getElementById('otherNamtItchild');
                otherNamtItpar.style.setProperty('padding', '-1');
                otherNamtItchild.style.setProperty('padding', '-1');
                if(startProductBarPos<0)startProductBarPos=findPosYz(otherNamtItpar);
                if(window.pageYOffset>=900&&window.pageYOffset<=8600){
                    if(otherNamtItpar?.style){
                        otherNamtItpar.removeAttribute('style')
                        otherNamtItpar.style.display='ruby-text';
                        otherNamtItpar.style. marginBottom='30px';

                        otherNamtItchild.style.top='82%';
                        otherNamtItchild.style.height='44px';
                        otherNamtItchild.style.width='100%';
                        otherNamtItchild.style.maxWidth='661px';
                        otherNamtItchild.style.position='fixed';
                    }
                }else{
                    if(otherNamtItpar?.style){
                        otherNamtItpar.removeAttribute('style')
                        otherNamtItchild.removeAttribute('style')
                    }
                  
                }
              
        }else if(document.getElementById('otherNamtItpar')){
            let otherNamtItpar = document.getElementById('otherNamtItpar');
            let otherNamtItchild = document.getElementById('otherNamtItchild');
                otherNamtItpar.removeAttribute('style')
                otherNamtItchild.removeAttribute('style')
        }
       }
            window.addEventListener('scroll', chckTxtbox);
   }, [tissueTypesData,editable,assessmentDetailsNew,document.getElementById('otherNamtIt')])
    return (
        <div className={`${styles.mesurement_blk} mt-n5`}>
            
            <AccessErrorModal />

            {!editable ?

                <div>
                    {/* Edit button  */}
                    {signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={() => setEditable(true)}>
                        <img src={editIcon} alt="edit" />Edit
                    </div>}

                    <div>
                        {/* Current wound */}
                        {assessmentDetails?.isWoundWithoutPhoto!==1&&<>
                            <div className="d-flex">
                                <div className={styles.wound_block}>
                                    {/* Heading */}
                                    <h3 className='text-center'>Patient’s Wound</h3>
                                    {/* Image */}
                                    <div className={`${styles.zoomIn_Out}`}> {/* Zoom In/ Out Options */}
                                        <div  className={`${styles.patient_wound}`}>
                                            <img 
                                                className={`${styles.patient_wound_img}`} 
                                                id="patient_wound" 
                                                src={assessmentDetails?.picture} 
                                                alt="wound" 
                                                style={Object.assign({},
                                                    zoomClick === true && {transform : `scale(1.${initalState}, 1.${initalState})`})}
                                                // style={Object.assign({},
                                                //     zoomClick === true && (initalState >= 0 ? {transform : `scale(1.${initalState}, 1.${initalState})`} : {transform : `scale(-1.${Math.abs(initalState)}, -1.${Math.abs(initalState)})`}))}
                                            />
                                        </div>
                                        
                                        <div className={`${styles.iconListView} d-block align-items-center justify-content-between`}>
                                            <div className='mb-2'>
                                                <i class={`fa fa-search-plus ${styles.iconHover}`} id="zoomInAction" style={{color : 'rgb(95 95 95)'}} onClick={zoomInAction} aria-hidden="true"></i>
                                            </div>
                                            <div className='mb-2'>
                                                <i class={`fa fa-search-minus ${styles.iconHover}`} id="zoomOutAction" style={{color : 'rgb(95 95 95)'}} onClick={zoomOutAction} aria-hidden="true"></i>
                                            </div>
                                            <div className=''>
                                                <i class={`fa fa-arrows-alt ${styles.iconHover}`} id="reSizeAction" style={{transform :`rotate(45deg) scale(1.1)`,color : 'rgb(95 95 95)'}} onClick={reSizeAction} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={`${styles.heading_toggle_view} align-self-center`}>
                                    <p className=''><span className={`${styles.toggle_label}`}>Override: </span>{overrideToggle || tissueOverrideStatus===1 ? 'Yes' : 'No'}</p>
                                </div>
                            </div>
                        </>}
                        
                        <div className="">
                            {assessmentDetails?.isWoundWithoutPhoto!==1 ? <>
                                {assessmentDetailsNew?.tissueTypes && assessmentDetailsNew?.tissueTypes?.length > 0 && <div className={`mt-5`}>
                                    <h5 style={{margin : "0px"}}>Selected Tissue Types</h5>
                                    <hr style={{borderTop: "2px solid #a4a4a4", marginTop : "4px"}}/>
                                </div>
                                }                                
                            </>:<>
                            {assessmentDetailsNew?.tissueTypes && assessmentDetailsNew?.tissueTypes?.length > 0 && <div className={`mt-5`}>
                                <div className="d-flex align-items-end">
                                    <div className="heading_tissue">
                                        <h5 style={{margin : "0px"}}>Selected Tissue Types</h5>
                                    </div>                                    
                                    <div className={`${styles.heading_toggle}`}>
                                        <p className=''><span className={`${styles.toggle_label}`}>Override: </span>{overrideToggle || tissueOverrideStatus===1 ? 'Yes' : 'No'}</p>
                                    </div>
                                </div>                                
                                <hr style={{borderTop: "2px solid #a4a4a4", marginTop : "4px"}}/>
                            </div>}
                            </>}
                           
                        </div>

                        {/* Type wound */}
                        <div className={styles.wound_block}>
                            {assessmentDetailsNew?.tissueTypes && assessmentDetailsNew?.tissueTypes?.length > 0 && assessmentDetailsNew?.tissueTypes?.map((data) => {
                                return tissueTypesData?.tissueTypes && tissueTypesData?.tissueTypes?.length > 0 && tissueTypesData?.tissueTypes?.map((item, iKey) => {
                                    if (data.id === item.id) {
                                        return <div key={iKey}>                                          
                                        
                                            <h3>{item.name} &nbsp; {data.woundFillingPercentage && <span>{data.woundFillingPercentage}%</span>}</h3>
                                            {/* Slider */}
                                            <Slider {...settings}>
                                                {/* Map through images */}
                                                {item?.referencePictures && item?.referencePictures?.length > 0 && item?.referencePictures.map((image, iImage) => {
                                                    return <div key={iImage}>
                                                        {/* Image */}
                                                        <img src={image} alt="wound" />
                                                    </div>
                                                })
                                                }
                                            </Slider>

                                            <br />
                                        </div>
                                    }
                                })
                            })}
                            {assessmentDetailsNew?.tissueTypes && assessmentDetailsNew?.tissueTypes?.length > 0 && assessmentDetailsNew?.tissueTypes?.map((data) => {
                                return tissueTypesData?.overrideTissueType && tissueTypesData?.overrideTissueType?.length > 0 && tissueTypesData?.overrideTissueType?.map((item, iKey) => {
                                    if (data.id === item.id) {
                                        return <div key={iKey}>                                          
                                        
                                            <h3>{item.name} &nbsp; {data.woundFillingPercentage && <span>{data.woundFillingPercentage}%</span>}</h3>

                                            {/* Slider */}
                                            <Slider {...settings}>
                                                {/* Map through images */}
                                                {item?.referencePictures && item?.referencePictures?.length > 0 && item?.referencePictures.map((image, iImage) => {
                                                    return <div key={iImage}>
                                                        {/* Image */}
                                                        <img src={image} alt="wound" />
                                                    </div>
                                                })
                                                }
                                            </Slider>

                                            <br />
                                        </div>
                                    }
                                })
                            })}




                        </div>

                    </div>
                    <h5> {assessmentDetailsNew?.other &&<>{'Other: '} &nbsp;<span>{assessmentDetailsNew?.other}</span></> }</h5>
                                          
                </div>

                :

                <>
                    {/* On close warning */}
                    {closeWarning && <CloseWarning
                        message="All progress in this section will be lost. Are you sure want to cancel?"
                        yesFn={handleYes}
                        noFn={handleNo}
                    />
                    }

{getUserRole?.role==='ExternalStandardUser'&&ne1getWounAssessmentDetailData?.isTissueTypeDone===0?
 <><p className={'mt-5'}>Tissue type is not selected for this wound</p></>
 
 :
 Array.isArray(characteristicTabsData)&&characteristicTabsData[0]['category']==="History/Cause"&&characteristicTabsData[0]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select History/Cause before selecting Tissue Types'}
                                </div>  
 
 </>
 
 :
  <Form formChanges={true} onSubmit={(event) => event.preventDefault()}>
                    <div className={'col-12'}>
                        <div className={`row`}>
                      
                            <div className={`col-8`}>
                                {/* Tabs */}

                                <TabComponent tabList={tabList} tissueView={true}/>
                                {noSelection && <div className="text-danger text-center mt-n4 ml-0 position-absolute">Please select one or more tissue types.</div>}
                                {noPercentError && <div className="text-danger text-center  mt-n4 ml-0 position-absolute">Please enter % covered if you have selected Eschar and/or Slough as tissue types.</div>}
                             <div >
                        <div  className={ "justify-content-between custom-control custom-switch col-12 pt-0 pl-0 pr-0 mb-4"}>                        
                            <div  className={`${styles.toggle_shells} ${styles.wound_block_text_override} d-flex justify-content-between px-0 pr-0`}>
                                <div className={`${styles.toggle_button_status} pl-2`}><b>Other</b></div> 
                 
                            </div>
                        </div>
                       
                    <div  id={'otherNamtItpar'} >
                    <div id={'otherNamtItchild'}>
                        <TextField
                      id={"otherNamtIt"}
                      name={"otherNamtIt"}
                      label={""}
                      classes={{
                        column: ` ${styles.col_spec} mt-n2 pb-4`,
                        labelClass: ``,
                        inputColumn: 'col-12 p-0',
                        inputClassName: `${styles.basic_input} w-100 p-2 mb-2`
                      }}
                       type="search"
                      // validator={register(checkName)}
                      //validator={register}
                      defaultValue={isCompleted===1?assessmentDetailsNew?.other:''}
                      placeholder={'Name it something else'}

                    />
                     </div>
                      </div>
                    </div>
                            </div>
                            <div className={`col-4`} style={{position:"relative"}}>
                                    {/* Current wound */}
                                    {assessmentDetails?.isWoundWithoutPhoto !==1 &&<>
                                        
                                        <div style={woundPhotoSticky}> 
                                            <div className={`${styles.zoomIn_Out}`} >
                                                <div className={`d-flex justify-content-between align-items-center pb-2`}>
                                                    <h3 className='text-left' style={{fontSize : "16px", fontWeight : "bold"}}>Patient’s Wound</h3>                       
                                                    <div className={`${styles.iconListEdit} d-flex align-items-center justify-content-between`}>
                                                        <div className='mr-2'>
                                                            <i class={`fa fa-search-plus ${styles.iconHover}`} style={{color : 'rgb(95 95 95)'}} onClick={zoomInAction} aria-hidden="true"></i>
                                                        </div>
                                                        <div className='mr-2'>
                                                            <i class={`fa fa-search-minus ${styles.iconHover}`} style={{color : 'rgb(95 95 95)'}} onClick={zoomOutAction} aria-hidden="true"></i>
                                                        </div>
                                                        <div className=''>
                                                            <i class={`fa fa-arrows-alt ${styles.iconHover}`} style={{transform :`rotate(45deg) scale(1.1)`,color : 'rgb(95 95 95)'}} onClick={reSizeAction} aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div  className={`${styles.patient_wound}`} style={{width:'250px'}}>
                                                    <img 
                                                        src={assessmentDetails?.picture} 
                                                        // style={{width: "250px"}} 
                                                        alt="wound" 
                                                        className={`${styles.patient_wound_img}`} 
                                                        id="patient_wound" 
                                                        style={Object.assign({},{width :'250px'},
                                                            zoomClick === true && {transform : `scale(1.${initalState}, 1.${initalState})`})}
                                                    />
                                                </div>
                                            </div>                                                               
                                        </div>
                                        </>
                                    }                                     
                            </div>


                            {/* Buttons */}
                                {/* Button section */}
                                {/* {(signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && click===true) && <div className="col-12 mt-4 pt-4 px-0" style={{borderTop:'2px solid #d1d1d1'}}> */} {/* Save & Cancel Sticky */}
                                
                                {(signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt) && <div className="col-12 px-0" >    
                                    <div className='' style={{paddingLeft:'135px'}}>
                                    <Button
                                        classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom} mr-4` }}
                                        type="submit"
                                        id="next"
                                        onClick={() => handleSave()}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        type="button"
                                        value="Cancel"
                                        classes={{ className: `btn btn-small ${styles.btn_small_cancel} ${styles.btn_custom} ml-2` }}
                                        id="cancel"
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            setCloseWarning(true)
                                        }}
                                    />
                                    </div>
                                </div>
                                }
                           
                        </div>
                    </div>
                    </Form>}
                </>
            }

        </div>
    )
}
