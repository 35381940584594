import React, { useRef, useEffect } from 'react'
import {
    FIRST_NAME_ID,
    GENDER_ID, LAST_NAME_ID, US_STATES,
    DATE_OF_BIRTH_ID, PHONE_TYPE_ID, ADDRESS_ID, APT_SUITE_ID,
    PHONE_ID, CITY_ID, SAVE_BTN, SUBMIT_ID, CANCEL_BTN, CANCEL_ID, REASON_FOR_VISIT_ID,
    ROOM_ID, FLOOR_ID, UNIT_ID, WRISTBAND_ID, ADMISSION_DATE_ID, ZIP_CODE_ID, STATE_ID,
    PHONE_LBL, DATE_OF_BIRTH_LBL, GENDER_LBL, LAST_NAME_LBL, FIRST_NAME_LBL, REASON_FOR_VISIT_LBL,
    ROOM_LBL, FLOOR_LBL, UNIT_LBL, WRISTBAND_LBL, ADMISSION_TIME_ID, ADMISSTION_DATE_LBL,
    ZIP_CODE_LBL, STATE_LBL, CITY_LBL, APT_SUITE_OTHER_LBL, ADDRESS_LBL, PHONE_TYPE_LBL, CONTACT_INFORMATION_HEAD, CARE_RECORD_HEAD, MRN_Number
} from '../../../constants'
import { Form, TextField, Button, SelectBox, DateTimePicker } from '../../primary'
import {
    checkNoFuturedate,
    checkFirstName,
    checkLastName,
    checkGender,
    selectBoxConverter,
    phoneMasker,
    checkAplhaNumeric,
    checkAdmissionDate,
    checkPhoneNumberWithoutValidation,
    numericValueOnly,
    checkMRNNumber
} from '../../../utils'
import styles from './createEditPatient.module.scss'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {optionTypeAsync } from '../../../app/patientSlice'
export function CreateEditPatient({ register, handleSubmit, control, handleCancel, onSubmit, errors, patientData }) {

    /* Date picker for DOB */
    /* Check for default value */
    const dob = patientData ? new Date(patientData.dob) : null

    /* Date picker for Admission date */
    /* Check for default value */
    /* If no API date it will take today's date and time */
    const admissionDate = patientData?.careInfo?.admissionDate !== undefined ? new Date(patientData?.careInfo?.admissionDate) : new Date()
    /* Time state */



    /* Use reference for calendar button on click popup functionality */
    const dobRef = useRef()
    const admissionRef = useRef();

    /*************************
     * Phone number field
     *************************/
    /* Get value from phone field using useREf */
    const phoneField = useRef();

    /* If phone number is typed call the above phoneHandle function */
    if (phoneField.current) {
        if (phoneField.current.value.length > 9) {
            phoneField.current.value = phoneMasker(phoneField.current.value)
        }
    }

    /* Fetching reaosn for visit data  */

    const reasonForVisitData = useSelector(state => state.patient.reasonForVisit)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(optionTypeAsync('reasonforvisit'));
    }, [dispatch])
    return (
        <>
            <Form formChanges={true} className={` ${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)} >

                <div className={`col-12 ${styles.patient_content_mrn_field} ml-3`}>
                    <TextField
                        id={'mrn'}
                        name={'mrn'}
                        label={`Enter ${MRN_Number}:`}
                        classes={{
                            column: `col-6 ${styles.col_spec} ${styles.col_spec_mrn}`,
                            labelClass: `col-5 col-form-label ${styles.label_spec} pl-4`,
                            inputColumn: 'col-6 p-0',
                            inputClassName: styles.basic_input
                        }}
                        type="text"
                        // validator={register(numericValueOnly)}
                        validator={register(checkMRNNumber)}
                        defaultValue={patientData && patientData?.mrn}
                        errors={errors}
                        required={true}
                        charLimit={20}
                        labelMrn={"mrn"}
                        disabled={patientData && patientData?.mrn?true:false}
                    />                   
                    
                </div>

                {/* Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                    <p className={`col-12 ${styles.subheading_2}`}>{CONTACT_INFORMATION_HEAD}</p>
                </div>

                {/* First Name */}

                <TextField
                    id={FIRST_NAME_ID}
                    name={FIRST_NAME_ID}
                    label={`${FIRST_NAME_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkFirstName)}
                    defaultValue={patientData && patientData?.firstName}
                    errors={errors}
                    required={true}
                />

                <TextField
                    id={LAST_NAME_ID}
                    name={LAST_NAME_ID}
                    label={`${LAST_NAME_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkLastName)}
                    defaultValue={patientData && patientData?.lastName}
                    errors={errors}
                    required={true}
                />

                <SelectBox
                    id={GENDER_ID}
                    name={GENDER_ID}
                    label={`${GENDER_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={[{ value: 'Male', key: 'male' }, { value: 'Female', key: 'female' }, { value: 'Other', key: 'other' }, { value: 'Unknown', key: 'unknown' }]}
                    validator={register(checkGender)}
                    defaultValue={patientData && patientData?.gender?.toLowerCase()}
                    required={true}
                />

                {/* DOB */}
                <DateTimePicker
                    id={DATE_OF_BIRTH_ID}
                    name={DATE_OF_BIRTH_ID}
                    label={`${DATE_OF_BIRTH_LBL}:`}
                    control={control}
                    classes={{
                        date_picker: styles.basic_input,
                        dateColumn: "col-6 p-0",
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`
                    }}
                    defaultValue={dob}
                    displayIcon={true}
                    validator={checkNoFuturedate}
                    showMonthDropdown={true}
                    showYearDropdown={true}
                    dateRef={dobRef}
                    errors={errors}
                    required={true}
                />

                {/* Phone */}
                <TextField
                    id={PHONE_ID}
                    name={PHONE_ID}
                    label={`${PHONE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={(e) => {
                        register(e, checkPhoneNumberWithoutValidation)
                        phoneField.current = e
                    }}

                    defaultValue={patientData && patientData?.phone}
                    errors={errors}
                //required={true}
                />

                {/* Phone type */}
                <SelectBox
                    id={PHONE_TYPE_ID}
                    name={PHONE_TYPE_ID}
                    label={`${PHONE_TYPE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={[{ value: 'Cell', key: 'cell' }, { value: 'Home', key: 'home' }, { value: 'Work ', key: 'work' }]}
                    validator={register}
                    defaultValue={patientData && patientData?.phoneType}
                />

                {/* Address */}
                <TextField
                    id={ADDRESS_ID}
                    name={ADDRESS_ID}
                    label={`${ADDRESS_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register}
                    defaultValue={patientData && patientData?.address?.addressLine1}
                />

                {/* Apt Suite Other */}
                <TextField
                    id={APT_SUITE_ID}
                    name={APT_SUITE_ID}
                    label={`${APT_SUITE_OTHER_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkAplhaNumeric)}
                    errors={errors}
                    defaultValue={patientData && patientData?.address?.addressLine2}
                />

                {/* City */}
                <TextField
                    id={CITY_ID}
                    name={CITY_ID}
                    label={`${CITY_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register}
                    defaultValue={patientData && patientData?.address?.city}
                />


                {/* State */}
                <SelectBox
                    id={STATE_ID}
                    name={STATE_ID}
                    label={`${STATE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    // optionValue={selectBoxConverter(US_STATES)}
                    optionValue={US_STATES}
                    validator={register}
                    defaultValue={patientData && patientData?.address?.state}
                />

                {/* Zip code */}
                <TextField
                    id={ZIP_CODE_ID}
                    name={ZIP_CODE_ID}
                    label={`${ZIP_CODE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="number"
                    validator={register}
                    defaultValue={patientData && patientData?.address?.zipCode}
                />

                {/* MRN  No */}
                {/* <TextField
                    id={'mrn'}
                    name={'mrn'}
                    label={`MRN #:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(numericValueOnly)}
                    defaultValue={patientData && patientData?.mrn}
                    errors={errors}
                /> */}

                {/* *************************** */}
                {/* Care record section */}
                {/* *************************** */}

                {/* Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                    <p className={`col-12 ${styles.subheading_2}`}>{CARE_RECORD_HEAD}</p>
                </div>

                {/* Admission date */}

                <DateTimePicker
                    id={ADMISSION_DATE_ID}
                    name={ADMISSION_DATE_ID}
                    label={`${ADMISSTION_DATE_LBL}:`}
                    timeName={ADMISSION_TIME_ID}
                    control={control}
                    classes={{
                        date_picker: styles.basic_input,
                        time_picker: `${styles.admission_time_fix} ${styles.basic_input}`,
                        dateColumn: `col-4 p-0 ${styles.admission_left}`,
                        timeColumn: `col-2 p-0`,
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`
                    }}
                    defaultValue={admissionDate}
                    defaultTime={admissionDate}
                    displayIcon={true}
                    timeRequired={true}
                    showTimeInput={true}
                    dateRef={admissionRef}
                    timeFormat="h:mm aa"
                    validator={checkAdmissionDate}
                    errors={errors}
                    required={true}
                    timeID={ADMISSION_TIME_ID}
                />


                {/* Wristband */}
                <TextField
                    id={WRISTBAND_ID}
                    name={WRISTBAND_ID}
                    label={`${WRISTBAND_LBL} #:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkAplhaNumeric)}
                    errors={errors}
                    defaultValue={patientData && patientData?.careInfo?.wristBandId}
                />
                {/* Unit */}
                <TextField
                    id={UNIT_ID}
                    name={UNIT_ID}
                    label={`${UNIT_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    // validator={register(checkAplhaNumeric)}
                    validator={register}
                    errors={errors}
                    defaultValue={patientData && patientData?.careInfo?.unit}
                />

                {/* Floor */}
                <TextField
                    id={FLOOR_ID}
                    name={FLOOR_ID}
                    label={`${FLOOR_LBL} #:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="number"
                    validator={register}
                    defaultValue={patientData && patientData?.careInfo?.floorNumber}
                />

                {/* Room */}
                <TextField
                    id={ROOM_ID}
                    name={ROOM_ID}
                    label={`${ROOM_LBL} #:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="number"
                    validator={register}
                    defaultValue={patientData && patientData?.careInfo?.roomNumber}
                />

                <SelectBox
                    id={REASON_FOR_VISIT_ID}
                    name={REASON_FOR_VISIT_ID}
                    label={`${REASON_FOR_VISIT_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={reasonForVisitData}
                    placeholder={"None"}
                    validator={register}
                    defaultValue={patientData && patientData?.careInfo?.reasonForVisit}
                />

                <div className="col-sm-12">
                    <div className="text-center pb-4">
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small}` }}
                            type="submit"
                            id={SUBMIT_ID}
                        >
                            {SAVE_BTN}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleCancel}
                        >
                            {CANCEL_BTN}
                        </Button>


                    </div>
                </div>

            </Form>
        </>
    )
}

CreateEditPatient.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    control: PropTypes.object,
    handleCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    patientData: PropTypes.object
}
