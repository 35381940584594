import { createSlice } from '@reduxjs/toolkit';
import {format} from 'date-fns';
import { CHECK_CUSTOMER_NUMBER, DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT, DASHBOARD, DASHBOARD_TREAMENTOUTCOME_ASSESSMENT, DASHBOARD_WOUNDASSESSMENT, ERROR_STATUS, SUCCESS_STATUS } from '../constants';
import { Service } from '../service';
import { constructEndpoint } from '../utils/commonHelper';
import { setError, setLoading } from './appSlice';
const { request } = Service;

export const woundReportSlice = createSlice({
    name: 'woundreport',
    initialState: {
      overViewData:{},
      woundCountData:{},
      woundAssessmentData:{},
      treatmentOverViewData:{},
      treatmentWorklistData:{},
      surveyListLoader: false,
      surveryListOptions: {}, // Survey list options
      surveyResults: {}, // Survey result
      surveyResultsCsv: {}, // For CSV download
      surveyResultsLoader: false,
      customerAvailable: null, // Check wheater customer exists or not
      qualityAssuranceOverViewData:{},
      qualityAssuranceCountData:{},
      qualityAssuranceAssessmentData:{},
      platformOverview: {}, // State for stroing platform overview
      platformResults: {}, // Platform results
      platformResultsCsv: {}, // For CSV downlaod
      woundReportCsv:{},
      treatmentReportCsv:{},
      qualityReportCsv:{},
      woundReportPayload:{},
      treatmentReportPayload:{},
      qualityReportPayload:{},
      isSurveyDownloadReady:'',
      overviewDownloadCsvfile: {},
      qaOverViewPagesData:{},
      woundReportOverviewDownloadCsvfile: {},
    },
    reducers: {
      setOverViewData:(state,action)=>{
        state.overViewData=action.payload
      },
      setWoundCountData:(state,action)=>{
        state.woundCountData=action.payload
      },
      setWoundAssessmentData:(state,action)=>{
        state.woundAssessmentData=action.payload
      },
      setTreatmentOverViewData:(state,action)=>{
        state.treatmentOverViewData=action.payload
      },
      setTreatmentWorklistData:(state,action)=>{
        state.treatmentWorklistData=action.payload
      },
      setSurveyListLoader:(state, action) => { // Survey list options
        state.surveyListLoader = action.payload
      }, 
      setSurveryListOptions:(state, action) => { // Survey list options
        state.surveryListOptions = action.payload
      }, 
      setSurveyResults:(state, action) => { // Survey result
        state.surveyResults = action.payload
      },
      setSurveyResultsLoader:(state, action) => {
        state.surveyResultsLoader = action.payload
      },
      setSurveyResultsCsv:(state, action) => { // Survey result
        state.surveyResultsCsv = action.payload
      },
      setCustomerAvailable:(state, action) => { // Check wheater customer exists or not
        state.customerAvailable = action.payload
      },
      setQualityAssuranceOverViewData:(state,action)=>{
        state.qualityAssuranceOverViewData=action.payload
      },
      setQualityAssuranceCountData:(state,action)=>{
        state.qualityAssuranceCountData=action.payload
      },
      setQualityAssuranceAssessmentData:(state,action)=>{
        state.qualityAssuranceAssessmentData=action.payload
      },
      setPlatformOverview: (state, action) => { // Saving platform overview data
        state.platformOverview = action.payload
      },
      setPlatformResults: (state, action) => { // Saving platform results grid
        state.platformResults = action.payload
      },
      setPlatformResultsCsv: (state, action) => { // Saving platform results CSV data
        state.platformResultsCsv = action.payload
      },
      setWoundReportCsv:(state,action)=>{
        state.woundReportCsv=action.payload
      },
      setTreatmentReportCsv:(state,action)=>{
        state.treatmentReportCsv=action.payload
      },
      setQualityReportCsv:(state,action)=>{
        state.qualityReportCsv=action.payload
      },
      setWoundReportPayload:(state,action)=>{
        state.woundReportPayload=action.payload
      },
      setTreatmentReportPayload:(state,action)=>{
        state.treatmentReportPayload=action.payload
      },
      setQualityReportPayload:(state,action)=>{
        state.qualityReportPayload=action.payload
      },
      setIsSuveyDownloadReady: (state, action) => {
        state.isSurveyDownloadReady = action.payload
      },     
      setOverviewDownloadCsvfile: (state, action) => {
        state.overviewDownloadCsvfile = action.payload
      },
      setqaOverViewPagesData: (state, action) => {
        state.qaOverViewPagesData = action.payload
      },
      setWoundReportOverviewDownloadCsvfile: (state, action) => {
        state.woundReportOverviewDownloadCsvfile = action.payload
      }
    }
})

export const {
    setOverViewData,
    setWoundCountData,
    setWoundAssessmentData,
    setTreatmentOverViewData,
    setTreatmentWorklistData,
    setSurveyListLoader,
    setSurveryListOptions,
    setSurveyResults,
    setSurveyResultsLoader,
    setCustomerAvailable,
    setQualityAssuranceOverViewData,
    setQualityAssuranceCountData,
    setQualityAssuranceAssessmentData,
    setSurveyResultsCsv,
    setPlatformOverview,
    setPlatformResults,
    setPlatformResultsCsv,
    setWoundReportCsv,
    setTreatmentReportCsv,
    setQualityReportCsv,
    setWoundReportPayload,
    setTreatmentReportPayload,
    setQualityReportPayload,
    setIsSuveyDownloadReady,
    setOverviewDownloadCsvfile,
    setqaOverViewPagesData,
    setWoundReportOverviewDownloadCsvfile
} = woundReportSlice.actions;
 
export const getWoundDashBoardOverview = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD}/overview`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setOverViewData(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const getWoundDashBoardWoundcount = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD}/woundcount`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setWoundCountData(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}  

// Wound Assessment
export const getWoundDashBoardAssessment = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  const payloadData= payload.payload
  dispatch(setWoundAssessmentData({}))
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payloadData)
    });
    
    if (data && data.status === SUCCESS_STATUS) {
      data.data.woundAssessment.map((value)=>{
        value['lastAccessed'] = (value.lastAccessed) ? value.lastAccessed : '';
        value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy') : '';
        value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy') : '';
        value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
      })
      dispatch(setWoundAssessmentData(data.data))
      if(payload.url==="/dashboard/woundassessment/overview/activewoundsnotresolved"){     
        dispatch(postWoundReportOverviewDownloadCsvReports(url, payloadData, history))
      }
      if(payload.url==="/dashboard/activepatientlist"){     
        dispatch(postWoundReportOverviewDownloadCsvReports(url, payloadData, history))
      }
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  } 
  finally {
    dispatch(setLoading(false));
  }
}

export const postWoundReportOverviewDownloadCsvReports = (url, payloadData, history) => async (
  dispatch
) => {    
  let urlPath;
  if(url==="/dashboard/woundassessment/overview/activewoundsnotresolved"){
    urlPath = "/dashboard/woundassessment/csv/overview/activewoundsnotresolved"
  }
  if(url==="/dashboard/activepatientlist"){
    urlPath = "/dashboard/csv/activepatientlist"
  }
  dispatch(setWoundReportOverviewDownloadCsvfile({}))
  try {
    dispatch(setLoading(true));  
    const resp = await request(urlPath, {
      method: "POST",
      body: JSON.stringify(payloadData),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      const finalDat = resp.data;
      // finalDat.Postdata = payloadData;       
    if (finalDat && Array.isArray(finalDat?.reports)) {
      finalDat?.reports.map((value) => {      
        value['lastAccessed'] = (value.lastAccessed) ? value.lastAccessed : '';
        value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate), "MM/dd/yyyy") : '';
        value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), "MM/dd/yyyy") : '';
        value['photoTakenon'] = (value.photoTakenon) ? format(new Date(value.photoTakenon), "MM/dd/yyyy") : '';
        value['dob'] = (value.dob) ? format(new Date(value.dob), "MM/dd/yyyy") : '';
      });
    }
      dispatch(setWoundReportOverviewDownloadCsvfile(finalDat));

    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};

//Treatment Outcome overview
export const getTreatmentOutcomeOverViewData = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`/reports/treatmentoverview`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setTreatmentOverViewData(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}


// Treatment Worklist 
export const getTreatmentWorklist  = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  const payloadData= payload.payload
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payloadData)
    });
    
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setTreatmentWorklistData(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  } 
  finally {
    dispatch(setLoading(false));
  }
}

// Get survey list

export const getSurveyListOptions = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    dispatch(setSurveyListLoader(true));
    const data = await request(`/reports/surveylistoptions`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setSurveryListOptions(data.data));
    }
  }
  catch (error) {    
    dispatch(setSurveryListOptions({}));
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
    dispatch(setSurveyListLoader(false));
  }
}

// Getting survey results

export const getSurveyResults = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    dispatch(setSurveyResultsLoader(true))
    const data = await request(`/reports/survey?limit=50`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.survey.map((value)=>{
        value['submittedOn'] = (value.submittedOn) ? format(new Date(value.submittedOn), 'MMM dd, yyyy hh:mm aa') : '';
        value['surveypublishedDate'] = (value.surveypublishedDate) ? format(new Date(value.surveypublishedDate), 'MMM dd, yyyy hh:mm aa') : '';
      })
      dispatch(setSurveyResults(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
    dispatch(setSurveyResultsLoader(false))
  }
}

// Same as above API to get all survey but automation for pagination and sorting

export const getSurveyResultsAlt = (payload, history) => async (dispatch, getState) => {
  const url = payload.url;
  let queryParam = payload.queryParam && payload?.queryParam
  if (payload.sortType) {
    const temp = { ...queryParam }
    temp['sort'] = payload.sortType
    queryParam = { ...temp }
  } 
  const state = getState()
  // If 'all', add survey IDs to the payload
  if(payload?.toBeSent?.surveyId[0] === 'all'){    
    let temp = []
    if(state.woundreport.surveryListOptions?.survey && state.woundreport.surveryListOptions.survey?.length > 0){
      state.woundreport.surveryListOptions.survey.map((data) => {
        temp.push(data.id)
      })
    }
    payload.toBeSent.surveyId = [...temp]
  }
  if(payload?.toBeSent?.surveyId?.length === 0){
    return;
  }
  // if (!queryParam['search'] && payload.search !== ''){
  //   const temp = { ...queryParam }
  //   temp['search'] = payload.search
  //   queryParam = { ...temp }
  // }
  const method = payload.method
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payload.toBeSent)
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.survey.map((value)=>{
        value['submittedOn'] = (value.submittedOn) ? format(new Date(value.submittedOn), 'MMM dd, yyyy hh:mm aa') : '';
        value['surveypublishedDate'] = (value.surveypublishedDate) ? format(new Date(value.surveypublishedDate), 'MMM dd, yyyy hh:mm aa') : '';
      })
      dispatch(payload.setMethod(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

/* Exclusively for CSV download */

export const getSurveyCsv = (payload, sortType,limit) => async (dispatch, getState) => {
  let endPoint = null;
  if(sortType){
    endPoint = `/reports/survey?limit=${limit}&sort=${sortType}`
  }else{
    endPoint = `/reports/survey?limit=${limit}`
  }
  try {
    dispatch(setLoading(true));
    const data = await request(endPoint, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.survey.map((value)=>{
        value['submittedOn'] = (value.submittedOn) ? format(new Date(value.submittedOn), 'MMM dd, yyyy hh:mm aa') : '';
        value['surveypublishedDate'] = (value.surveypublishedDate) ? format(new Date(value.surveypublishedDate), 'MMM dd, yyyy hh:mm aa') : '';
      })
      dispatch(setSurveyResultsCsv(data.data));
      dispatch(setIsSuveyDownloadReady('downloadAllSurvey'));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    dispatch(setSurveyResultsCsv({}));
    dispatch(setIsSuveyDownloadReady(''));
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Check wheater the customer exists or not
export const checkCustomer = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(CHECK_CUSTOMER_NUMBER + '?customerNumber=' + payload, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCustomerAvailable(true))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setCustomerAvailable(false))
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//Quality Assurance overview
export const getQualityAssuranceOverViewData = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`/reports/qualityassurance/overview`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setQualityAssuranceOverViewData(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const getQualityAssuranceCountData = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`/reports/qualityassurance/woundassessment/subsectioncount`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setQualityAssuranceCountData(data.data));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}  


// Quality Assurance WoundAssessment Worklist 
export const getQualityAssuranceWoundAssessmentWorklist  = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  const payloadData= payload.payload
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payloadData)
    });
    
    if (data && data.status === SUCCESS_STATUS) {
      data.data.woundAssessment.map((value)=>{
        value['lastAccessed'] = (value.lastAccessed) ? value.lastAccessed : '';
        value['photoTakenOn'] = (value.photoTakenOn) ? format(new Date(value.photoTakenOn), 'MMMM dd, yyyy') : '';       
        value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy') : '';
        value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy') : '';
        // value['lastAccessed'] = (value.lastAccessed) ? format(new Date(value.lastAccessed), 'MMMM dd, yyyy') : '';
      })
      dispatch(setQualityAssuranceAssessmentData(data.data))      
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  } 
  finally {
    dispatch(setLoading(false));
  }
}

// OverView Pages reports
export const getQualityAssuranceOverViewPages  = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  const payloadData= payload.payload
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payloadData)
    });
    
    if (data && data.status === SUCCESS_STATUS) {
      const finalDat = data.data;
      // finalDat.woundAssessment = payload.payload;
    if (finalDat && Array.isArray(finalDat.woundAssessment)) {
      finalDat.woundAssessment.map((value)=>{
        value['lastAccessed'] = (value.lastAccessed) ? value.lastAccessed : '';
        value['photoTakenOn'] = value.photoTakenOn ? format(new Date(value.photoTakenOn), 'MMMM dd, yyyy') : '';     
        value['admissionDate'] = value.admissionDate ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy') : '';
        value['dischargeDate'] = value.dischargeDate ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy') : '';
        // value['lastAccessed'] = value.lastAccessed ? format(new Date(value.lastAccessed), 'MMMM dd, yyyy') : '';
      })
      dispatch(setqaOverViewPagesData(finalDat))
      if(payload.url==="/reports/qualityassurance/woundassessment/overview/pendingphotos" || payload.url==="/reports/qualityassurance/woundassessment/overview/pendingassessments" || payload.url==="/reports/qualityassurance/woundassessment/overview/pendingreviews" || payload.url==="/reports/qualityassurance/woundassessment/overview/completedassessments"){
        dispatch(postOverviewDownloadCsvReports(url, payloadData, history))
      }
    }
    }else {
      if (data.message) {
        dispatch(setError(data.message));
      }
    }

  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  } 
  finally {
    dispatch(setLoading(false));
  }
}

// OverView Pages reports in Csv for api call

export const postOverviewDownloadCsvReports = (url, payloadData, history) => async (
  dispatch
) => {  
  let urlPath;
  if(url==="/reports/qualityassurance/woundassessment/overview/pendingphotos"){
    urlPath = "/reports/qualityassurance/woundassessment/csv/overview/pendingphotos"
  }
  if(url==="/reports/qualityassurance/woundassessment/overview/pendingassessments"){
    urlPath = "/reports/qualityassurance/woundassessment/csv/overview/pendingassessments"
  }
  if(url==="/reports/qualityassurance/woundassessment/overview/pendingreviews"){
    urlPath = "/reports/qualityassurance/woundassessment/csv/overview/pendingreviews"
  }
  if(url==="/reports/qualityassurance/woundassessment/overview/completedassessments"){
    urlPath = "/reports/qualityassurance/woundassessment/csv/overview/completedassessments"
  }
  try {
    dispatch(setLoading(true));  
    const resp = await request(urlPath, {
      method: "POST",
      body: JSON.stringify(payloadData),
    });

    if (resp && resp.status === SUCCESS_STATUS) {
      const finalDat = resp.data;
      // finalDat.Postdata = payloadData;    
    if (finalDat && Array.isArray(finalDat.reports)) {
      finalDat.reports.map((value) => {
        value['lastAccessed'] = (value.lastAccessed) ? value.lastAccessed : '';
        value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate), "MM/dd/yyyy") : '';
        value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), "MM/dd/yyyy") : '';
        value['photoTakenOn'] = (value.photoTakenOn) ? format(new Date(value.photoTakenOn), "MM/dd/yyyy") : '';
        // value['lastAccessed'] = (value.lastAccessed) ? format(new Date(value.lastAccessed), "MM/dd/yyyy") : '';
      });
    }
      dispatch(setOverviewDownloadCsvfile(finalDat));

    } else {
      if (resp.message) {
        dispatch(setError(resp.message));
      }
    }
  } catch (error) {
    const obj = { ...error };
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage));
  } finally {
    dispatch(setLoading(false));
  }
};


// Platform analytics
export const getPlatformOverview = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`/reports/platformanalytics/overview`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setPlatformOverview(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Platform results
export const getPlatformResults = (payload, history) => async dispatch =>{
  try {
    dispatch(setLoading(true));
    const data = await request(`/reports/platformanalytics/customerdata?limit=50`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setPlatformResults(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Same as above API to get all survey but automation for pagination and sorting

export const getPlatformResultsAlt = (payload, history) => async (dispatch) => {
  const url = payload.url;
  let queryParam = payload.queryParam && payload?.queryParam
  if (payload.sortType) {
    const temp = { ...queryParam }
    temp['sort'] = payload.sortType
    queryParam = { ...temp }
  } 
  // if (!queryParam['search'] && payload.search !== ''){
  //   const temp = { ...queryParam }
  //   temp['search'] = payload.search
  //   queryParam = { ...temp }
  // }
  const method = payload.method
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payload.toBeSent)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(payload.setMethod(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

/* Platform results csv */

export const getPlatformResultsCsv = (payload, sortType) => async (dispatch, getState) => {
  let endPoint = null;
  if(sortType){
    endPoint = `/reports/platformanalytics/customerdata?limit=1000&sort=${sortType}`
  }else{
    endPoint = `/reports/platformanalytics/customerdata?limit=1000`
  }
  try {
    dispatch(setLoading(true));
    const data = await request(endPoint, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setPlatformResultsCsv(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const getDownloadWoundReportCSVDetails = (payload, queryParam) => async dispatch => {
  let queryStr = ''
  if (queryParam) {
    queryStr = '?' + queryParam
  }
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD_WOUNDASSESSMENT}`+queryStr, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.woundAssessment.map((value)=>{
        value['lastAccessed'] = (value.lastAccessed) ? value?.lastAccessed: '';
        value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy') : '';
        value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy') : '';
      })
      dispatch(setWoundReportCsv(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
export const getDownloadTreatmentReportCSVDetails = (payload, queryParam) => async dispatch => {
  let queryStr = ''
  if (queryParam) {
    queryStr = '?' + queryParam
  }
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD_TREAMENTOUTCOME_ASSESSMENT}`+queryStr, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
        dispatch(setTreatmentReportCsv(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
export const getDownloadQualityReportCSVDetails = (payload, queryParam) => async dispatch => {
  let queryStr = ''
  if (queryParam) {
    queryStr = '?' + queryParam
  }
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT}`+queryStr, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.woundAssessment.map((value)=>{
        value['lastAccessed'] = (value.lastAccessed) ? value?.lastAccessed: '';
        value['photoTakenOn'] = (value.photoTakenOn) ? format(new Date(value.photoTakenOn), 'MM/dd/yyyy') : '';
        value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MM/dd/yyyy') : '';
        value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MM/dd/yyyy') : '';        
        // value['lastAccessed'] = (value.lastAccessed) ? format(new Date(value.lastAccessed), 'MMMM dd, yyyy h:mm aa') : '';
      })
      dispatch(setQualityReportCsv(data.data));
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export default woundReportSlice.reducer;
