import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Tab as ReactTabs, Tabs, TabList, TabPanel } from 'react-tabs';
import { PropTypes } from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import styles from './verticalTab.module.scss';
import tick from '../../../static/images/check-mark.png'
import { setCharStatus } from '../../../app/patientSlice';

export const VerticalTab = ({ tabList, defaultIndex, dispatch, InterventionTab = false,interventionId, showwound, setShowWound }) => {   
   
    /* Get 'charStatus' from redux */
    const charStatus = useSelector(state => state.patient.charStatus)
    // const assessmentDetails = useSelector(state => state.patient.detailAssessment)

    /* State for selected index */
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex?defaultIndex:0);

    /* on Select updated selected index */
    const onSelect = (i) => setSelectedIndex(i)

    // Function to update tab which is done
    function updateDone(index, flag) {
        const temp = [...charStatus]  /* Copy array to temp variable */
        if (!flag) {
            temp[index] = 1; /* Update specific index to 1 */
        } else {
            temp[index] = 0; /* Set it as 0 if it is first time */
        }
        dispatch(setCharStatus(temp)) /* Dispatch new array */
    }

    useEffect(() => {
            if(interventionId===0){
                setSelectedIndex(0)
            }        
      }, [interventionId])    

      
    return (
        <div className={styles.vertical_tab}>
            <Tabs onSelect={onSelect} defaultIndex={defaultIndex} selectedIndex={selectedIndex}>
                <TabList className={InterventionTab ? styles.react_tabs_Intervention : styles.react_tabs}>
                    {/* Map through tabs  */}
                    {tabList && tabList.length > 0 && Array.isArray(tabList) && tabList.map((tab, i) => (
                        
                        <ReactTabs className={styles.react_tabs_list} selectedClassName={styles.selected} key={tab.name}>
                            {
                                /* Tick image, if status of specific array is '1'  */
                                (tab.completed === undefined) && charStatus[i] === 1 && <img src={tick} alt="tick"/>
                            }
                            {
                                (tab.completed === 1) && <img src={tick} alt="tick" className={styles.selectedTick} />
                            }
                            {
                                /* Needs review text, if status of specific array is '0' */
                                (tab.completed === undefined) && charStatus[i] === 0 && <span>Needs Review</span>
                            }
                            {
                                (tab.completed === 0) && <span>Needs Review</span>
                            }
                            {
                                (tab.completed === 'noTick') && <span> </span>
                            }
                            {tab.name}                            
                        </ReactTabs>                       
                        
                    ))} 
                        {/* Current wound Tissu and Touch&View*/}
                        {/* {showwound === true && assessmentDetails?.isWoundWithoutPhoto !==1 && <> 
                                <div className={`${styles.wound_block} text-center py-4 pr-3`}>
                                    <h3 class="mb-2">Patient’s Wound</h3>
                                    <img src={assessmentDetails?.picture} alt="wound" />            
                                </div>      
                            </>
                        }                 */}
                </TabList>            

                            

                {/* Map throgh tab panel */}
                {tabList && tabList.length > 0 &&Array.isArray(tabList) && tabList.map((tabC, index) => (
                    <TabPanel className={styles.react_tab_pane} selectedClassName={styles.selected} key={index}>
                        {/* Close our component and pass index as props */}
                        {React.cloneElement(
                            tabC.content,
                            {
                                index: index, // Passing index
                                charStatus: charStatus, // Pass the state
                                dispatch: dispatch,
                                updateDone: updateDone,
                                selectedIndex: setSelectedIndex, // Navigation to next
                            }
                        )}
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};

VerticalTab.propTypes = {
    tabList: PropTypes.array,
    onSelect: PropTypes.func,
    selectedIndex: PropTypes.number,
    InterventionTab: PropTypes.bool
};
