import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../primary/Button/button';
import styles from './signAndLock.module.scss'
import signImg from '../../../static/images/sign.svg'
import lockImg from '../../../static/images/lock.svg'
import signassignmentImg from '../../../static/images/signassignment.png'
import { ActionModal, Modal } from '../../secondary'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { detailAssessmentAsync, woundAssessmentUpdateAsync, signLockAsync, woundAssessmentReEvalAsync,validatecharacteristicsAsync, ne1updateassessmentsignlockAsync} from '../../../app/patientSlice'
import { lockSuccessAsyncData } from '../../../utils/patientsHelper'

export let SignAndLock = ({ location, assessmentDetail, signLockActions, hideIt, userDetails, patientData ,ne1getWounAssessmentDetailData}) => {

    //const detailAssessment = useSelector(state => state.patient.detailAssessment);
    // Get signWithOutMinimumDataset values 
    //const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)
    const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
    //const minDataSet=assessmentDetail?.signWithOutMinimumDataset
    const minDataSet=ne1configsignwithoutmindatasetData?.isSignWithOutMinimumDataset
    const [isSignMinPopShow, setIsSignMinPopShow] = useState(0)
    /* Dispatch */
    const dispatch = useDispatch()
    // Getting details about wound assessment
    // Get values from url
    const { patientId, assessmentId } = useParams();
    // States for sign and lock button 
    const [sign, setSign] = useState(false)
    const [lock, setLock] = useState(false)

    const [admitStatus, setAdmitStatus] = useState(false)
    useEffect(()=>{
        if(patientData?.statusInfo?.status!=="discharged" && patientData?.statusInfo?.status!=="readmitted"){
            setAdmitStatus(true)
        }
        else{
            setAdmitStatus(false)
        }
    },[patientData])

    // States for signed and locked view
    const [signed, setSigned] = useState(false)
    const [locked, setLocked] = useState(false)

    const getCharDetails = () => {
        // Assign it to payload
        const payload = { patientId, assessmentId }
        // Dispatching get assessment details call
        dispatch(detailAssessmentAsync(payload))
    }

    useEffect(() => {
        const payload = { patientId, assessmentId, type: 'signlockactions' }
        // To get details about what butotn to display
        dispatch(signLockAsync(payload))
        getCharDetails();
    }, [dispatch])

    // On change of location get assessment details
    useEffect(() => {
        getCharDetails();
    }, [location])

    // Setting sign and lock button based on the API data
    useEffect(() => {
        if(ne1configsignwithoutmindatasetData?.isAssessmentWithMinimumDatasetDone===1&&ne1getWounAssessmentDetailData?.isSignedDetailDone===0){
            setSign(true)
            setLock(false)
           }
           if(ne1getWounAssessmentDetailData?.isSignedDetailDone===1&&ne1getWounAssessmentDetailData?.isSignedLockedDetailDone===0){
            setSign(false)
            setLock(true)
           }
         // if (assessmentDetail?.signed === 1 && ((assessmentDetail?.classification?.woundBedIdentifiable === null && assessmentDetail?.classification?.woundClassification !=="Unknown") || ((assessmentDetail?.classification?.woundBedIdentifiable===0 || assessmentDetail?.classification?.woundBedIdentifiable === 1) && assessmentDetail?.classification?.woundClassification !=="Unknown"))) {
            if (ne1getWounAssessmentDetailData?.isSignedDetailDone === 1 ) {
                setSigned(true)
                 } else if(ne1getWounAssessmentDetailData?.isSignedDetailDone === 0){
                     setSigned(false)
                 }
                 //if (assessmentDetail?.locked === 1 && ((assessmentDetail?.classification?.woundBedIdentifiable === null && assessmentDetail?.classification?.woundClassification !=="Unknown") || ((assessmentDetail?.classification?.woundBedIdentifiable===0 || assessmentDetail?.classification?.woundBedIdentifiable === 1) && assessmentDetail?.classification?.woundClassification !=="Unknown"))) {
                 
                 if (ne1getWounAssessmentDetailData?.isSignedLockedDetailDone === 1 ) {
                     setLocked(true)
                 } else if(ne1getWounAssessmentDetailData?.isSignedLockedDetailDone === 0){
                     setLocked(false)
                 }


        //ne1configsignwithoutmindatasetAsync
       /* signLockActions && signLockActions.length > 0 && signLockActions.map((data) => {
            if (data.key === 'sign') {
                if (data.enable === 1) {
                    setSign(true)
                    setLock(false)
                }
                if (data.enable === 0) {
                    setSign(false)
                    setLock(false)
                }
            }
            if (data.key === 'signlock') {
                if (data.enable === 1 || data.enable === 0) {
                    setLock(true)
                }
            }
            if (data.enable === 1 )
             setIsSignMinPopShow(data?.enable )
        })*/
      
    })

    // Functions and state for modal open and close
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const modalOpen = (e, type) => {
        e.preventDefault();
        setModalType(type)
        setShowModal(true);
    }
    const modalClose = () => {
        setShowModal(false);
    }

    /* State to check drop down assw */
    const [viewDrop, setViewDrop] = useState(false)

    // Function to handle just clicking on 'sign
    const handleSign = (e) => {
        e.preventDefault();
        setViewDrop(false)
        // Updating to 'Signed' and changing 'assessmentStage' to 'pendingreview'
        const assessment = { ...assessmentDetail } // Copying assessment details
        assessment['assessmentStage'] = 'pendingreview';
        assessment['signed'] = 1;
        const payload = {
            patientId,
            assessmentId,
            assessment,
            setSigned,
            setLock,
            getCharDetails,
        }
       // dispatch(woundAssessmentUpdateAsync(payload))
       dispatch(ne1updateassessmentsignlockAsync(payload))
        modalClose()
    }
    const handleCheckSign = (e) => {
        const payload = { patientId, assessmentId }
        // Dispatching get assessment details call
        dispatch(detailAssessmentAsync(payload))
        e.preventDefault();
        if(minDataSet===1){
            const payload = {
                patientId,
                assessmentId,
            }
            dispatch(validatecharacteristicsAsync(payload,e,modalOpen,handleSign))
        }else{
            handleSign(e)
        }
    }

    // Check lock response
    const checkLock = useSelector(state => state.patient.checkLock)

    // When checklock API returns 'success' then update wound assessment with 
    // signed = 1 and locked = 1. This function will be called from patientSlice.
    const lockSuccessAsync = () => {
        lockSuccessAsyncData(setSigned, setLocked, assessmentDetail, patientId, assessmentId, getCharDetails, dispatch, woundAssessmentUpdateAsync,ne1updateassessmentsignlockAsync)
    }

    // If checklock API returns 'error' then don't update, just display error message.
    // This function will be called from patientSlice.
    const lockFailAsync = () => {
        setModalType('error')
        setShowModal(true);
    }

    // Call checkLock API
    const checkLockAsync = () => {
        const payload = { patientId, assessmentId, type: 'checklock', lockSuccessAsync, lockFailAsync }
        // To get details about what butotn to display
        dispatch(signLockAsync(payload))
    }

    // Main function to handle continue after clicking 'sign & lock'
    const handleContinue = (e) => {
        modalClose();
        // Calling checkLock API
        checkLockAsync();
    }

    /* Function to toggle drop assessment*/
    const handleDrop = (e) => {
        e.preventDefault();
        setViewDrop(!viewDrop)
    }

    /* Trigger Drop Down Items close */
    const dropRef = useRef();

    // Click event listener and calling handleClickOutside function if we click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setViewDrop(false);
        }
    };

    //handle ReEval click
const handleReEval = (e) => {
    e.preventDefault();
    const payload = {
        patientId,
        assessmentId,
    }
    //woundAssessmentReEvalAsync
    dispatch(woundAssessmentReEvalAsync(payload))
}


        //handle ReEval Msg Pop click

const handleReEvalMSg = (e) => {
    e.preventDefault();
    modalOpen(e, 'reEvalPop')
}

 // Main function to handle continue after clicking 'sign & lock'
 const handleContinueReEval= (e) => {
    modalClose();
    // Calling checkLock API
    handleReEval(e);
}

    return (
        <>
            <Modal
                modalOpen={showModal}
                modalClose={modalClose}
            >{modalType === 'minDataSignComplete' ?
            <div className={styles.modalparent}>
              <div className={`${styles.modal_heading} pb-2`}>
                <div className={`${styles.modal_header} pl-3 pt-3`}>
                  <h3>Sign Assessment</h3>
                </div>
                <button type="button" className={`close ${styles.close_icon} pr-3`} onClick={() => modalClose()}>&times;</button>
              </div>
              <div className={'d-flex justify-content-center  pt-0 pb-0'}>
                <img
                      src={signassignmentImg}
                  />
                                  </div>
              <div className={`${styles.modal_footer}`}>
                <div className={'px-4 mt-5'}>
                  <h3> The assessment documentation lacks essential information, including characteristics and a treatment plan. Would you like to proceed with signing the assessment?
   </h3>
                </div>
                <div className={'d-flex justify-content-center px-3 pt-3 pb-0'}>
                <Button
                      type="submit"
                      value="Sign"
                      classes={{ className: `btn btn-small  ${styles.btn_small} ${styles.btn_custom}` }}
                      id="save"
                      onClick={(e)=>handleSign(e)}
                  />                 
                </div>
              </div>
          </div>

          :modalType === 'reEvalPop'?<>
            <div className={`m-4 ${styles.modal_outer}`}>
                    {/* Close btn */}
                    <span className={styles.close} onClick={modalClose}>X</span>
                    <ActionModal
                        heading={"The current wound assessment is not completed or signed. Do you wish to start a new assessment?"}
                        buttonText={"Continue"}
                        buttonAction={handleContinueReEval}
                        buttonTextAlt={"Cancel"}
                        buttonActionAlt={modalClose}
                    />
                    {modalType === 'error' && <h4 className={styles.lock_error}>{checkLock?.errorMessage}</h4>}
                </div>
          
          </>:
                <div className={`m-4 ${styles.modal_outer}`}>
                    {/* Close btn */}
                    <span className={styles.close} onClick={modalClose}>X</span>
                    {modalType === 'action' && <ActionModal
                        heading={"Are you sure you want to lock this assessment? Once locked, no changes can be made to this assessment."}
                        buttonText={"Continue"}
                        buttonAction={handleContinue}
                        buttonTextAlt={"Cancel"}
                        buttonActionAlt={modalClose}
                    />}
                    {modalType === 'error' && <h4 className={styles.lock_error}>{checkLock?.errorMessage}</h4>}
                </div>}
            </Modal>

            {/* Displaying signed or locked */}
            {signed && <span className={styles.signed}><span className={styles.signned_sign}></span>Signed {locked && '& Locked'}</span>}

            {/* Sign and Sign/Lock button */}
            {/* {['NE1.Review', 'NE1.Wound Expert'].includes(role) && <> */}
            {(userDetails?.role === 'ExternalNE1WoundExpert' || userDetails?.role === 'ExternalNE1Review' || userDetails?.role === 'ExternalAdministrator') && hideIt && 
                !signed && !locked &&  (sign||minDataSet===1)  && (lock || !lock) && <span
                    className={`${styles.lock} dropdown`}>
                    <a href="#" onClick={handleDrop} ref={dropRef}><img src={signImg} alt="sign" />Sign</a>
                    {viewDrop && <span className="dropdown-menu dropdown-menu-right show" ref={dropRef}>
                        <a data-testid="sign" className="dropdown-item " href="#" onClick={(e) => handleCheckSign(e)}>Sign</a>
                        <a className={`dropdown-item ${styles.sign_border_top}`} href="#" onClick={(e) => modalOpen(e, 'action')}>
                            Sign &amp; Lock
                        </a>
                    </span>}
                </span>
            }          

            {/* Sign button */}
            {/* {role === 'NE1.Assesment' && <> */}
            {(userDetails?.role !== 'ExternalNE1WoundExpert' && userDetails?.role !== 'ExternalNE1Review' && userDetails?.role !== 'ExternalAdministrator' && userDetails?.role !== "ExternalStandardUser") && hideIt && 
            // {userDetails?.role !== "ExternalStandardUser" && hideIt &&
                !signed && !locked && (lock || !lock) && (sign||minDataSet===1) && <span className={styles.lock} onClick={(e) => handleCheckSign(e)}>
                    <a data-testid="sign" href="#" onClick={(e) => handleCheckSign(e)}><img src={signImg} alt="sign" />Sign</a>
                </span>
            }   

            {/* Lock button */}
            {/* {['NE1.Review', 'NE1.Wound Expert'].includes(role) && <> */}
            {(userDetails?.role !== 'ExternalNE1Assesment' && userDetails?.role !== 'ExternalStandardUser'&& userDetails?.role !== 'MedlineStandardUser') &&
                hideIt && signed && !locked && !sign && lock && <span className={styles.lock} onClick={(e) => modalOpen(e, 'action')}>
                    <a data-testid="lock" href="#" onClick={(e) => modalOpen(e, 'action')}><img src={lockImg} alt="lock" />Lock</a>
                </span>
            }
           
           {/* Re-Eval button */}
            {(admitStatus && (( signed) ||  
                (assessmentDetail?.isWoundWithoutPhoto===1 && signed && assessmentDetail?.isChildSigned === 1 && assessmentDetail?.parentAssessmentId === null) || 
                (assessmentDetail?.isWoundWithoutPhoto===1 && signed && assessmentDetail?.isChildSigned === 0 && assessmentDetail?.parentAssessmentId !== null)))&& 
                <span className={styles.re_eval}><a href="#" onClick={(e) => handleReEval(e)}>Re-Eval</a></span>}
            {/* </>} */}

              {/* Re-Eval button */}
              {(admitStatus && ((!signed)) && userDetails?.role !== 'ExternalStandardUser')&& 
                <span className={styles.re_eval}><a href="#" onClick={(e) => handleReEvalMSg(e)}>Re-Eval</a></span>}
            {/* </>} */}

        </>
    )
}

function mapStateToProps(state) {
    return {
        assessmentDetail: state.patient.detailAssessment,
        signLockActions: state.patient.signLockActions,
        ne1getWounAssessmentDetailData:state.patient.ne1getWounAssessmentDetail
    }
}

SignAndLock = connect(mapStateToProps)(withRouter(SignAndLock));
