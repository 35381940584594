import React, { useEffect } from 'react'
import Slider from "react-slick";

export function CharAiTissueImages({
    styles,
    aiAvailable,
    tissueTypesData,
    handleSelect,
    checkFlag,
    selectedCheck,
    inputFlag,
    result,
    handleInput,
    ai,
    validPercent,
    settings,
    checkDefault }) { 

    //     useEffect(() => {
    //         let startProductBarPos=-1;
    //         if(document.getElementById('checkAdd')){
    //             window.onscroll=function(){
    //                 let bar = document.getElementById('checkAdd');
    //                 document.getElementById('checkAdd').style.setProperty('padding', '-1');
    //                 if(startProductBarPos<0)startProductBarPos=findPosY(bar);
                  
    //                 if(window.pageYOffset>startProductBarPos){
    //                   bar.style.position='fixed';
    //                   bar.style.top=0;
    //                 }else{
    //                     if(bar?.style?.position){
    //                         bar.style.position='relative';
    //                     }
    //                 }
                  
    //               };
                  
    //               function findPosY(obj) {
    //                 var curtop = 0;
    //                 if (typeof (obj?.offsetParent) != 'undefined' && obj?.offsetParent) {
    //                   while (obj?.offsetParent) {
    //                     curtop += obj.offsetTop;
    //                     obj = obj.offsetParent;
    //                   }
    //                   curtop += obj.offsetTop;
    //                 }
    //                 else if (obj?.y)
    //                   curtop += obj.y;
    //                 return curtop;
    //               }
    //         }
          
    //    }, [tissueTypesData])
       
    return (
        <div className={styles.carousel_block}>

            {/* Displaying message if there is no AI suggessted tissue types available */}
            {ai === 1 ? aiAvailable === false ? <div>Sorry. No AI Suggested tissue types available for this wound.</div> : null : null}

            {tissueTypesData && tissueTypesData.length > 0 && tissueTypesData.map((data, i) => {
                if (data.aiSelected === ai) {
                    return <>
                        <div className={styles.carousel_head} key={i}>
                            {/* Checkbox */}
                            <div className={`custom-control-inline ${styles.custom_checkbox}`}>
                                <input
                                    type="checkbox"
                                    className={` ${styles.custom_control_input}`}
                                    id={data.id + data.id}
                                    onClick={(e) => handleSelect(e, i, data.id, ai)} // Pass event and index
                                    defaultChecked={checkFlag[i] || selectedCheck(data.id)}
                                    disabled={data.enable === 1 ? false : true}
                                    data-testid="tissueCheckBox"
                                />
                                <label
                                    className={`custom-control-label ${styles.custom_control_label_check} ${data.enable === 0 && styles.disable_check} ${styles.nothing}`}
                                    htmlFor={data.id + data.id}>&nbsp;</label>
                            </div>
                            {/* Heading */}
                            {/* <h4>{data.name} <span>({codeSpliter(data.code)})</span></h4> */}
                            <h4 className={data.enable === 0 && styles.disable_head}>{data.name}</h4>
                            {/* Percent, textbox and checkbox */}
                            <div className={styles.list_fields}>

                                {/* Percentage */}
                                {!inputFlag[i] // Find the specifix index is true or false (for displaying input box)
                                    && <span className={`${styles.display_percent} ${styles.nothing}`}>
                                        {/* Here we Get percentage from accessment details by passing the code  */}
                                        {result && result.length > 0 && result?.map((item) => {
                                            if (item.id === data.id) {
                                                // Here If there is wound percentage return it, else return null 
                                                if (item.woundFillingPercentage) {
                                                    return item.woundFillingPercentage + '%'
                                                } else {
                                                    return null
                                                }
                                            }
                                        })}
                                    </span>}

                                {/* Input field */}
                                {inputFlag[i] && data.id <= 5 && data.code !== 'N/A'
                                    && <input type="text" name={data.code} placeholder="% Covered" onBlur={(e) => handleInput(e, i, data.id)} data-testid="aitissueimagesinput"/>}

                                {/* Error message */}
                                {inputFlag[i] && data.id <= 5 && data.code !== 'N/A' && validPercent === data.id && <em data-testid="aitissueimageserror">Please enter valid percentage!</em>}

                            </div>
                        </div>
                        <div className={styles.carousel_body} data-testid="aitissueimagesbody">
                            {/* Slider */}
                            <Slider {...settings}>
                                {/* Map through images */}
                                {data.referencePictures && data.referencePictures.length > 0 && data.referencePictures.map((image, iImage) => {
                                    return <div key={iImage}>
                                        {/* Image */}
                                        <img src={image} alt="wound" className={data.enable === 0 && styles.disable_image} data-testid="aitissueimagesimage"/>
                                    </div>
                                })
                                }
                            </Slider>
                            <br />
                        </div>
                    </>
                }
            })}
        </div>
    )
}
