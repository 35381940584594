import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    FIRST_NAME_LBL,
    LAST_NAME_LBL,
    DATE_OF_BIRTH_LBL,
    PHONE_TYPE_LBL,
    ADDRESS_LBL,
    APT_SUITE_OTHER_LBL,
    CITY_LBL, GENDER_LBL,
    PHONE_LBL, STATE_LBL,
    ZIP_CODE_LBL,
    ADMISSTION_DATE_LBL,
    WRISTBAND_LBL,
    UNIT_LBL,
    REASON_FOR_VISIT_LBL,
    FLOOR_LBL, ROOM_LBL,
    CONTACT_INFORMATION_HEAD,
    CARE_RECORD_HEAD,
    EDIT,
    PATIENT_GENDER,
    PATIENT_PHONE_TYPE,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    SUCCESS_STATUS,
    ERROR_STATUS
} from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png'
import "react-datepicker/dist/react-datepicker.css";
import styles from './basicInfo.module.scss'
import { PatientHeading } from './patientHeading'
import { CreateEditPatient } from './createEditPatient'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'
import { setEditState, setWristBandError, optionTypeAsync } from '../../../app/patientSlice';
import { allNullCheck, dateBasic, dateOfBirthDate, ServiceError } from '../../../utils';
import { AccessErrorModal  } from '../../primary';
import { clearError } from "../../../app/appSlice";
import Loader from '../../../static/images/spinner.svg';
export function BasicInfo({ patientData, updatePatientData }) {

    /* State to check editable forms */
    const [editable, setEditable] = useState(false)
    /* Function to toggle edit */
    const handleEdit = () => {
        setEditable(!editable)
    }

    /* Use hook form */
    const { register, handleSubmit, control, errors, setError } = useForm({
        mode: 'onBlur'
    });

    const dateOfBirth = patientData && patientData.dob ? format(new Date(patientData.dob), "MM/dd/yyy") : '';

    /* On Submit */
    const onSubmit = (data) => {
        const admissionDateTime = format(new Date(data.admissionDate), "MM/dd/yyy") + ' ' + format(new Date(data.admissionTime), "h:mm aa");
        // handleEdit();
        const tempData = {
            mrn: data?.mrn?data?.mrn: patientData?.mrn,
            firstName: data.firstName,
            lastName: data.lastName,
            gender: data.gender,
            dob: format(new Date(data.dateOfBirth), 'MM/dd/yyyy'),
            phone: data.phone,
            phoneType: data.phoneType,
            address: {
                addressLine1: data.address,
                addressLine2: data.aptSuiteOther,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode
            },
            careInfo: {
                id: patientData?.careInfo?.id,
                admissionDate: new Date(admissionDateTime).toISOString(),
                wristBandId: data.wristband,
                unit: data.unit,
                floorNumber: data.floor,
                roomNumber: data.room,
                reasonForVisit: data.reasonForVisit
            }
        }
        /* Merging */
        const result = { ...patientData, ...tempData }
        /* Dispatching */
        updatePatientData(result)
    }

    /* Reason for visit option list */
    const reasonForVisitData = useSelector(state => state.patient.reasonForVisit)

    /* Edit success */
    const editState = useSelector(state => state.patient.editState)
    const wristBandError = useSelector(state => state.patient.wristBandError)
    const dispatch = useDispatch()
    useEffect(() => {
        setTimeout(() => {
            dispatch(setEditState(false))
        }, 5000)
    }, [editState, dispatch])

    const ErrorMessage = ServiceError();      
    useEffect(() => {
        if (editState === "success"){
            handleEdit();
        }
        else if (editState === "error"){
            if (ErrorMessage==="MRN already registered with another patient") {
                setError("mrn", {
                type: "manual",
                message: ErrorMessage
                });
                document.getElementById("mrn").focus();
                dispatch(clearError());
            }
        }
            
    }, [ErrorMessage,dispatch,editState])

    useEffect(() => {
        setTimeout(() => {
            dispatch(setWristBandError(null))
        }, 5000)
    }, [wristBandError, dispatch])    

    const [editDelay, setEditDelay] = useState(false)

    useEffect(() => {
        dispatch(optionTypeAsync('reasonforvisit'));
        setTimeout(() => {
            setEditDelay(true)
        }, 3000)
    }, [])


    return (
        <>
            {/* Edit button */}
            {editDelay&&!editable && allNullCheck(patientData?.firstName) && <div className={`float-sm-right ${styles.tab_edit}`} onClick={handleEdit}><img src={editIcon} alt="edit" />{EDIT}</div>}

            {/* To display access error */}
            <AccessErrorModal />
            {!editDelay?<div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>
              :
              <>
                {/* Generic heading  */}
            {/* <PatientHeading heading={patientData} /> */}
            {/* Create edit form */}

                           
            <div className={`col-sm-12 ${styles.single_product_block}`}>
                <div className="row">
                    <div className={`p-0 ${styles.patient_content}`}>
                        {!editable ?

                            <>
                                {/* Edit success */}
                                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}                                

                                {/* Edit failure */}
                                {editState === ERROR_STATUS && wristBandError === null && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}

                                {editState === ERROR_STATUS && wristBandError !== null && <div className={`alert alert-danger ${styles.form_alert}`}>{wristBandError}</div>}

                                {/* *************************** */}
                                {/* Contact information section */}
                                {/* *************************** */}

                                {/* Heading */}
                                <div className={`col-sm-12 ${styles.col_spec} pt-4`}>
                                    <p className={`col-12 ${styles.subheading_2}`}>{CONTACT_INFORMATION_HEAD}</p>
                                </div>

                                {/* First Name */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{FIRST_NAME_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.firstName}</span></div>
                                </div>

                                {/* Last Name */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{LAST_NAME_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.lastName}</span></div>
                                </div>

                                {/* Gender */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{GENDER_LBL}:</label>
                                    <div className="col-6 p-0">
                                        <span className={`col-form-label ${styles.content_spec}`}>
                                            {PATIENT_GENDER[patientData?.gender?.toLowerCase()]}
                                        </span>
                                    </div>
                                </div>

                                {/* Date of birth */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{DATE_OF_BIRTH_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{dateOfBirthDate(dateOfBirth)}</span></div>
                                </div>

                                {/* Phone */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{PHONE_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.phone}</span></div>
                                </div>

                                {/* Phone type */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{PHONE_TYPE_LBL}:</label>
                                    <div className="col-6 p-0">
                                        <span className={`col-form-label ${styles.content_spec}`}>
                                            {PATIENT_PHONE_TYPE[patientData?.phoneType?.toLowerCase()]}
                                        </span>
                                    </div>
                                </div>

                                {/* Address */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{ADDRESS_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.address?.addressLine1}</span></div>
                                </div>

                                {/* Apt suite */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{APT_SUITE_OTHER_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.address?.addressLine2}</span></div>
                                </div>

                                {/* City */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{CITY_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.address?.city}</span></div>
                                </div>

                                {/* State */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{STATE_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.address?.state}</span></div>
                                </div>

                                {/* Zip code */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{ZIP_CODE_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.address?.zipCode}</span></div>
                                </div>

                                {/* MRN No */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{'MRN #'}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.mrn}</span></div>
                                </div>

                                {/****************
                                * Care record section
                                **************** */}

                                {/* Heading */}
                                <div className={`col-sm-12 ${styles.col_spec}`}>
                                    <p className={`col-12 ${styles.subheading_2}`}>{CARE_RECORD_HEAD}</p>
                                </div>

                                {/* Admission date */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{ADMISSTION_DATE_LBL}:</label>
                                    <div className="col-6 p-0">
                                        <span className={`col-form-label ${styles.content_spec}`}>
                                            {dateBasic(patientData?.careInfo?.admissionDate)}
                                        </span>
                                    </div>
                                </div>

                                {/* Wrist band */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{WRISTBAND_LBL} #:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.careInfo?.wristBandId}</span></div>
                                </div>

                                {/* Unit */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{UNIT_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.careInfo?.unit}</span></div>
                                </div>

                                {/* Floor */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{FLOOR_LBL} #:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.careInfo?.floorNumber}</span></div>
                                </div>

                                {/* Room */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{ROOM_LBL} #:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{patientData?.careInfo?.roomNumber}</span></div>
                                </div>

                                {/* Reason for visit */}
                                <div className={`col-6 ${styles.col_spec}`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{REASON_FOR_VISIT_LBL}:</label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>
                                        {/* Map through our array and display the corresponding value     */}
                                        {reasonForVisitData.map((item) => {
                                            let value;
                                            if (item.key === patientData?.careInfo?.reasonForVisit) {
                                                value = item.value;
                                            }
                                            return value;
                                        })}
                                    </span></div>
                                </div>

                            </>

                            :

                            <>

                            <CreateEditPatient
                                register={register}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                control={control}
                                errors={errors}
                                patientData={patientData}
                                handleCancel={handleEdit}
                            />
                            </>
                        }

                    </div>
                </div>
            </div></>}
        </>
    )
}

BasicInfo.propTypes = {
    heading: PropTypes.object,
    patientData: PropTypes.object
}
