import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {getWoundDashBoardAssessment, setQualityReportPayload, setWoundAssessmentData} from "../../../app/woundReportSlice";
import { DataTable, Pagination } from "../../secondary";
import { Header } from "../../secondary/Header";
import styles from "./overviewRowData.module.scss";
import { DASHBOARD_WOUNDASSESSMENT_OVERVIEW, LIST_VIEW_LIMIT } from '../../../constants';

import Spreadsheet from "../../../static/images/ExportSpredsheet.svg";
import { CSVLink } from "react-csv";
import Loader from '../../../static/images/spinner.svg';	
import { format } from "date-fns";

export const PatientWithActiveWounds = ({ history }) => {
  
    const dispatch = useDispatch();

    const userDetails = useSelector(state => state.user);
    const woundReportPayload = useSelector(state => state.woundreport.woundReportPayload);
    const woundReportPayloadFinal = {
      "fromDate": woundReportPayload?.fromDate,
      "toDate": woundReportPayload?.toDate,
      "facility": woundReportPayload?.facility,
      "payor": woundReportPayload?.payor,
      "woundType": woundReportPayload?.woundType,
      "isDischargedPatient": woundReportPayload?.isDischargedPatient
    }
  
  //when onload payload passing to API
  useEffect(() => {
    const listPayLoad = {};
    listPayLoad["url"] =  `/dashboard/activepatientlist`;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundReportPayloadFinal;
    listPayLoad["queryParam"] = { offSet: 0, limit: LIST_VIEW_LIMIT };
    if (woundReportPayload && woundReportPayload.facility && woundReportPayload.facility.length !== 0) {
      dispatch(getWoundDashBoardAssessment(listPayLoad));
    }
  }, []);
    
    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])   
  
  const [activePage, setActivePage] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [sortType, setSortType] = useState('')   

//Getting data from Clicked rows in Overview table through the API
const woundAssessmentData = useSelector((state) => state.woundreport.woundAssessmentData);

const [tinyDelay, setTinyDelay] = useState(false)
 useEffect(()=>{
  let getloadValue = woundAssessmentData?.woundAssessment !== undefined ? true : false
  if(getloadValue){
    setTimeout(()=>{
      setTinyDelay(true)
    }, 1000)    
  }    
 })

const columns = [
    {
      Header: 'MRN #',
      accessor: 'mrn',
      restWidth: '150px',
    },
    {
      Header: 'NE1 Id',
      accessor: 'nE1Id',
      restWidth: '200px',
    },
    {
        Header: 'First name',
        accessor: 'firstName',
        restWidth: '180px',
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
      restWidth: '180px',
    },
    {
        Header: 'Gender',
        accessor: 'gender',
        restWidth: '130px'

    },
    {
        Header: 'DOB',
        accessor: 'dob',
        restWidth: '250px'

    },
    {
        Header: 'Admission date',
        accessor: 'admissionDate',
        restWidth: '250px'
    },    
];

const [adminAccess, setAdminAccess] = useState(false);
    useEffect(() => {
        if (userDetails && userDetails.role == "ExternalStandardUser") {
            setAdminAccess(true);
        }
    }, [userDetails])    

  //redirect to patient-> assessments page 
  const getSelectedRowData = (data) => {   
    if (data && !adminAccess) {
        history.push(`/${"patients"}/${data.nE1Id}`);
    }
}
//   const getSelectedRowData = (data) => {
//     if (data) {
//       history.push(
//         `/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`
//       );
//     }
//   };

  const deleteDataList = () => {};

  const selectRowCount = () => {};  

  //sorting API call
  const handleSort = useCallback(
    (data) => {
      if (data && data.sortBy[0] && data.sortBy[0].id) {        
        const woundAssessmentPayload = { ...woundReportPayload, optionSelected: woundReportPayload?.optionSelected, woundProgress: woundReportPayload?.woundProgress }
        const sortingColumn = data.sortBy[0];
        const sortingType = sortingColumn
          ? sortingColumn.desc
            ? ":DESC"
            : ":ASC"
          : "";
        let sortParam = sortingColumn.id + sortingType;
        const listPayLoad = {};
        listPayLoad["url"] =   `/dashboard/activepatientlist`;
        listPayLoad["method"] = "Post";
        listPayLoad["payload"] = woundReportPayloadFinal;
        listPayLoad["queryParam"] = {
          offSet: isNaN(offSet)===true?0:offSet,
          limit: LIST_VIEW_LIMIT,
          sort: sortParam,
        };
        setSortType(sortParam)
        if (
          woundAssessmentPayload &&
          woundAssessmentPayload.facility &&
          woundAssessmentPayload.facility.length > 0
        ) {
          dispatch(getWoundDashBoardAssessment(listPayLoad));
        }
      }
    },
    [dispatch, woundReportPayload]
  );

  //active Page API Call
  const getActivePage = (data) => {
    setActivePage(data);
    const woundAssessmentPayload = { ...woundReportPayload, optionSelected: woundReportPayload?.optionSelected, woundProgress: woundReportPayload?.woundProgress }
    const listPayLoad = {};
    listPayLoad["url"] =   `/dashboard/activepatientlist`;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundReportPayloadFinal;
    listPayLoad["queryParam"] = {
      limit: LIST_VIEW_LIMIT,
      offSet:
        data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT < LIST_VIEW_LIMIT
          ? 0
          : data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT,
          sort: (sortType)?sortType:''
    };
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    if (
      woundAssessmentPayload &&
      woundAssessmentPayload.facility &&
      woundAssessmentPayload.facility.length > 0
    ) {
      dispatch(getWoundDashBoardAssessment(listPayLoad));
    }
  };

  const downloadDate = format(new Date(), "MM-dd-yyyy");
    
    const [csvHeader, setCsvHeader] = useState([
      { label: 'MRN #', key: 'mrn' },
      { label: 'NE1 Id', key: 'nE1Id' },     
      { label: 'First name', key: 'firstName' },
      { label: 'Last name', key: 'lastName' },
      { label: 'Gender', key: 'gender' },
      { label: 'DOB', key: 'dob' },      
      { label: 'Admission date', key: 'admissionDate' }    
    ]);
  
    const woundReportOverviewDownloadCsvfile = useSelector((state) => state.woundreport.woundReportOverviewDownloadCsvfile);  

  return (
    <>
        <div className={`${styles.back_btn}`}>
            <Header 
                // heading="Admin Portal"
                // subHeading="Pending Assessments"  
                reportHeading={"Patients with active wounds"} 
                backLink={'/woundReport'}  
                handleBack={() => history.goBack()} 
            />
        </div>
        {!tinyDelay ?    
          <div className={`${styles.loader} col-12`}>
            <img src={Loader} alt="loading" />
          </div>:
          <div className="col-12">
        {woundAssessmentData && Array.isArray(woundAssessmentData?.woundAssessment) && (
            <>
              <div className="col-sm-12 mb-2 justify-content-end d-flex">
                <span className={styles.down_spread}>
                  <img src={Spreadsheet} alt="" />
                  <label>
                    <CSVLink                      
                      headers={csvHeader}
                      data={
                        woundReportOverviewDownloadCsvfile && Array.isArray(woundReportOverviewDownloadCsvfile?.reports)
                          ? woundReportOverviewDownloadCsvfile?.reports
                          : []
                      }
                      filename={`NE1-Patient-Report-Active-Wounds-${downloadDate}.csv`}
                      target="_blank"
                    >
                      Download
                    </CSVLink>
                  </label>
                </span>
              </div>
            <div className="col-12 px-0">
              <DataTable
                columns={columns}
                data={woundAssessmentData && woundAssessmentData?.woundAssessment}
                sorting={true}
                getSelectedRowData={getSelectedRowData}
                setSelectedRows={selectRowCount}
                onSort={handleSort}
                detailView={true}
                deleteDataList={deleteDataList}
                showFixedColumn={false}
                workListView={true}
                reportView={true}
                sortType={(sortType)?sortType:''}
              />
            </div>
          </>
        )}
        {Array.isArray(woundAssessmentData.woundAssessment) &&
          woundAssessmentData.woundAssessment.length > 1 && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={
                woundAssessmentData?.metadata
                  ? woundAssessmentData?.metadata?.limit
                  : 50
              }
              totalItemsCount={
                woundAssessmentData?.metadata
                  ? woundAssessmentData?.metadata?.total
                  : 0
              }
              getActivePage={getActivePage}
              reportView={true}
            />
        )}
      </div>}
    </>
  );
}//NOSONAR


PatientWithActiveWounds.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
};