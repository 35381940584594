import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  EDIT, PATIENT_PICTURE_LBL,
  EMAIL_ID, EMAIL_LBL, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN, PATIENT_PICTURE_ID,
  BODY_MASS_INDEX_ID, DIABETIC_ID, COMORBID_CONDITIONS_ID, SMOKING_STATUS_ID, INSURANCE_PAYER_ID,
  NE1_WMS_STATUS_ID, NE1_WMS_STATUS_LBL, INSURANCE_PAYER_LBL, SMOKING_STATUS_LBL,
  COMORBID_CONDITIONS_LBL, DIABETIC_LBL, BODY_MASS_INDEX_LBL, PATIENT_DIABETIC, PATIENT_SMOKING_STATUS,
  PATIENT_NE1_WMS_STATUS,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from '../../../constants'
import { Form, Button, TextField, RadioButton, SelectBox, MultiSelect } from '../../primary'
import styles from './moreInfo.module.scss'
import { PatientHeading } from './patientHeading'
import { allNullCheck, checkEmailAlt, getKeyByValue } from '../../../utils'
import editIcon from '../../../static/images/edit-icon-blue.png'
import { PictureIcon } from '../../secondary'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import DefaultImg from '../../../static/images/picture-icon-default.png'
import { setEditState, optionTypeAsync } from '../../../app/patientSlice'
import { AccessErrorModal  } from '../../primary';
import Loader from '../../../static/images/spinner.svg';

export function MoreInfo({ patientData, updatePatientData }) {

  
  /* State to check editable forms */
  const [editable, setEditable] = useState(false)
  /* Function to toggle edit */
  const handleEdit = () => {
    setEditable(!editable)
  }

  /* Handle image preview */
  /* Preview image state */
  const [previewImg, setPreviewImg] = useState(null)
  /* Disable button state */
  const [buttonState, setButtonState] = useState(true)

  /* Set default image to a state if there is any */
  useEffect(() => {
    if (patientData?.moreInfo?.profilePicture) {
      setButtonState(false)
      setPreviewImg(patientData?.moreInfo?.profilePicture)
    }
  }, [patientData])

  /* Handle multiselect dropdown */
  const comorbidRef = useRef();

  /* State for storing comorbid conditions value */
  const [comorbidData, setComorbidData] = useState([]);

  /* State for displaying comorbid items */
  const comorbidItems = [];

  /* Handle comorbid select */
  const handleComorbidSelect = () => {
    /* Get all values - It will be array of objects */
    const selectedItems = comorbidRef.current.getSelectedItems()
    /* Map through the above selected items and filter out key */
    const tempData = selectedItems.map((data) => {
      return data.key
    });
    /* Set it in a state */
    setComorbidData(tempData)
  }

  /* Use hook form */
  const { register, handleSubmit, errors } = useForm();

  /* On Submit */
  const onSubmit = (data) => {

    handleEdit();


    let finalPic;
    if (previewImg) {
      finalPic = previewImg.replace('data:image/png;base64,', '')
      finalPic = finalPic.replace('data:image/jpeg;base64,', '')
      finalPic = finalPic.replace('data:image/jpg;base64,', '')
      finalPic = finalPic.replace('data:image/svg+xml;base64,', '')
    }


    const tempData = {
      moreInfo: {
        // profilePicture : previewImg ? previewImg : patientData.profilePicture,
        profilePicture: finalPic ? finalPic : patientData.profilePicture,
        email: data.email,
        bmi: data.bodyMassIndex,
        comorbidConditions: comorbidData,
        insurancePayer: data.insurancePayer,
        diabetic: getKeyByValue(PATIENT_DIABETIC, data.diabetic), // This function is for taking values from radio btn
        smokingStatus: getKeyByValue(PATIENT_SMOKING_STATUS, data.smokingStatus),
        ne1WMSStatus: getKeyByValue(PATIENT_NE1_WMS_STATUS, data.ne1WmsStatus)
      }
    }
    /* Merging default object with new data */
    const result = { ...patientData, ...tempData }
    /* Dispatching */
    updatePatientData(result)
  }

  /* Fetch dropdown datas */
  const comorbidConditionsData = useSelector(state => state.patient.comorbidConditions)
  const insurancePayerData = useSelector(state => state.patient.insurancePayer)

  /* Edit success */
  const editState = useSelector(state => state.patient.editState)

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(setEditState(false))
    }, 5005)
  }, [editState, dispatch])

  const [editDelay, setEditDelay] = useState(false)

  useEffect(() => {
    dispatch(optionTypeAsync('insurancepayer'));
    dispatch(optionTypeAsync('comorbidcondition'));
      setTimeout(() => {
          setEditDelay(true)
      }, 3000)
  }, [])
  return (
    <>
      {/* Edit button */}
      {!editable && editDelay && allNullCheck(patientData?.firstName) && <div className={`float-sm-right ${styles.tab_edit} ${styles.nothing}`} onClick={handleEdit}><img src={editIcon} alt="edit" />{EDIT}</div>}
      {/* Generic heading  */}
      <AccessErrorModal />
      {!editDelay? <div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
        <>
         {/* <PatientHeading heading={patientData} /> */}
      <div className={`col-sm-12 ${styles.single_product_block} ${styles.nothing}`}>
        <div className="row">
          <div className={`  ${styles.patient_content} ${styles.nothing}`}>

            {!editable ?
              <>

                {/* Edit success */}
                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}

                {/* Edit failure */}
                {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}

                <div className="col-12 pt-3"></div>

                {/* Patient picture */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 ${styles.label_spec} ${styles.profile_label_fix}`}>{PATIENT_PICTURE_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {/* Image */}
                      {/* Below here, i added alternative image if the image from API got failed  */}
                      {
                        !patientData?.moreInfo?.profilePicture ? <img
                          src={DefaultImg}
                          alt="No image"
                          className={styles.patient_img} /> :
                          <img src={patientData?.moreInfo?.profilePicture} alt="Patient" className={styles.patient_img} />
                      }
                    </span>
                  </div>
                </div>

                {/* Email */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{EMAIL_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {patientData?.moreInfo?.email}
                    </span>
                  </div>
                </div>

                {/* Body mass index */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{BODY_MASS_INDEX_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {patientData?.moreInfo?.bmi}
                    </span>
                  </div>
                </div>

                {/* Diabetic */}
                <div className={`col-6 ${styles.col_spec} ${styles.neg_margin}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{DIABETIC_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {PATIENT_DIABETIC[patientData?.moreInfo?.diabetic]}
                    </span>
                  </div>
                </div>

                {/* comorbid conditions */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{COMORBID_CONDITIONS_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {/* Map through our array and display the corresponding value     */}
                      {patientData?.moreInfo?.comorbidConditions?.forEach((data) => { //NOSONAR
                        /* Map through our local array to get value */
                        comorbidConditionsData?.forEach((item) => { //NOSONAR
                          if (item.key === data) {
                            comorbidItems.push(item.value)
                          }
                        })//NOSONAR
                      })}
                      {/* Display comorbid items array */}
                      {comorbidItems.join(', ')}
                    </span>
                  </div>
                </div>

                {/* Smoking status */}
                <div className={`col-6 ${styles.col_spec} ${styles.neg_margin}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{SMOKING_STATUS_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {PATIENT_SMOKING_STATUS[patientData?.moreInfo?.smokingStatus]}
                    </span>
                  </div>
                </div>

                {/* Insurance payer */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{INSURANCE_PAYER_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {/* Map through our array and display the corresponding value     */}
                      {insurancePayerData?.map((item) => {
                        let value;
                        if (item.key === patientData?.moreInfo?.insurancePayer) {
                          value = item.value;
                        }
                        return value;
                      })}
                    </span>
                  </div>
                </div>

                {/* NE1 Status */}
                <div className={`col-6 ${styles.col_spec} ${styles.neg_margin}`}>
                  <label className={`col-6 ${styles.label_spec}`}>{NE1_WMS_STATUS_LBL}:</label>
                  <div className="col-6 p-0">
                    <span className={styles.content_spec}>
                      {PATIENT_NE1_WMS_STATUS[patientData?.moreInfo?.ne1WMSStatus]}
                    </span>
                  </div>
                </div>

              </>
              :

              <div>
                <br />
                <Form formChanges={true} className={`p-0 ${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)} >

                  {/* Patient picture */}
                  <div className={`col-6 ${styles.col_spec}`}>
                    {/* Label */}
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{PATIENT_PICTURE_LBL}:</label>
                    {/* Picture icon component  */}
                    <PictureIcon
                      previewImg={previewImg} // Default preview image if there is any
                      setPreviewImg={setPreviewImg} // Function to set above state 
                      buttonState={buttonState} // State for hinding and showing "browse" button
                      setButtonState={setButtonState} // Function to set above state 
                      validator={register}
                      id={PATIENT_PICTURE_ID} // ID and name 
                      name={PATIENT_PICTURE_ID}
                    />
                  </div>

                  {/* Email */}
                  <TextField
                    id={EMAIL_ID}
                    name={EMAIL_ID}
                    label={`${EMAIL_LBL}:`}
                    classes={{
                      column: `col-6 ${styles.col_spec}`,
                      labelClass: `col-6 col-form-label ${styles.label_spec}`,
                      inputColumn: 'col-6 p-0',
                      inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkEmailAlt)}
                    errors={errors}
                    defaultValue={patientData && patientData.moreInfo.email}
                  />

                  <TextField
                    id={BODY_MASS_INDEX_ID}
                    name={BODY_MASS_INDEX_ID}
                    label={`${BODY_MASS_INDEX_LBL}:`}
                    classes={{
                      column: `col-6 ${styles.col_spec}`,
                      labelClass: `col-6 col-form-label ${styles.label_spec}`,
                      inputColumn: 'col-6 p-0',
                      inputClassName: styles.small_input
                    }}
                    type="number"
                    validator={register}
                    defaultValue={patientData && patientData.moreInfo.bmi}
                  />

                  <div className={`col-6 ${styles.col_spec}`}>
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{DIABETIC_LBL}:</label>
                    <RadioButton
                      id={DIABETIC_ID}
                      name={DIABETIC_ID}
                      classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                      }}
                      validator={register}
                      radioOption={['Yes', 'No']}
                      defaultValue={patientData && PATIENT_DIABETIC[patientData.moreInfo.diabetic]}
                    />
                  </div>

                  <MultiSelect
                    id={COMORBID_CONDITIONS_ID}
                    name={COMORBID_CONDITIONS_ID}
                    label={`${COMORBID_CONDITIONS_LBL}:`}
                    classes={{
                      column: `col-6 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-6 col-form-label ${styles.label_spec} pt-0 pb-0`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`,
                    }}
                    optionValue={comorbidConditionsData}
                    validator={comorbidRef}
                    showCheckbox={true}
                    displayValue="value"
                    onSelect={handleComorbidSelect}
                    onRemove={handleComorbidSelect}
                    defaultValue={patientData?.moreInfo?.comorbidConditions}
                    labelSubText={'Select all that apply'}
                  />

                  {/* Smoking status */}

                  <div className={`col-6 ${styles.col_spec}`}>
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{SMOKING_STATUS_LBL}:</label>
                    <RadioButton
                      id={SMOKING_STATUS_ID}
                      name={SMOKING_STATUS_ID}
                      classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                      }}
                      validator={register}
                      radioOption={['Yes', 'No']}
                      defaultValue={patientData && PATIENT_SMOKING_STATUS[patientData.moreInfo.smokingStatus]}
                    />
                  </div>

                  {/* Insurance payer  */}

                  <SelectBox
                    id={INSURANCE_PAYER_ID}
                    name={INSURANCE_PAYER_ID}
                    label={`${INSURANCE_PAYER_LBL}:`}
                    classes={{
                      column: `col-6 ${styles.col_spec}`,
                      inputClassName: `${styles.basic_select} ${styles.first_child}`,
                      labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={insurancePayerData}
                    validator={register}
                    placeholder={"None"}
                    defaultValue={patientData && patientData.moreInfo.insurancePayer}
                  />

                  {/* NE1 status */}

                  <div className={`col-6 ${styles.col_spec}`}>
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>{NE1_WMS_STATUS_LBL}:</label>
                    <RadioButton
                      id={NE1_WMS_STATUS_ID}
                      name={NE1_WMS_STATUS_ID}
                      classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                      }}
                      validator={register}
                      radioOption={['Active', 'Inactive']}
                      defaultValue={patientData && PATIENT_NE1_WMS_STATUS[patientData.moreInfo.ne1WMSStatus]}
                    />
                  </div>

                  <div className={`row ${styles.form_btn}`}>
                    <div className="col-sm-12">
                      <div className="text-center pb-4">
                        <br />
                        {/* Save Button */}
                        <Button
                          classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                          type="submit"
                          id={SUBMIT_ID}
                        >
                          {SAVE_BTN}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                          classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                          id={CANCEL_ID}
                          name="cancel"
                          onClick={handleEdit}
                        >
                          {CANCEL_BTN}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>

              </div>


            }


          </div>
        </div>
      </div>
      </>
}
    </>
  )
}

MoreInfo.propTypes = {
  heading: PropTypes.object,
  patientData: PropTypes.object
}
