import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, SelectBox } from '../../primary';
import { woundAssessmentUpdateAsync, patientAssessmentAsyncOverride, getwoundprogressAsync,ne1updateassessmentprogressAsync } from '../../../app/patientSlice';
import style from '../Patients/characteristic.module.scss';
import { useForm } from 'react-hook-form';
import { CloseWarning } from '../../secondary'
import editIcon from '../../../static/images/edit-icon.png'
import {
  CANCEL_BTN,
  SAVE_BTN
} from '../../../constants';
import { useSelector } from 'react-redux';
import { signedLockedAccess } from '../../../utils';

export function CharacteristicProgress({ index, dispatch, updateDone, selectedIndex, styles, patientId, assessmentId, assessmentDetails,
  getCharDetails, hideIt, overrideToggle, isCompleted, characteristicTabsData }) {
      /* Hooks form */
  const { register, handleSubmit, reset:reset } = useForm();
  const [assessmentDetailsNew, setAssessmentDetailsNew] = useState('');
  //call the API for Update the data
  useEffect(()=>{
    setAssessmentDetailsNew('')
    toast.dismiss('success1')
    reset({})
    let payload = { patientId, assessmentId, type: 'tissuetypes'} 
    dispatch(patientAssessmentAsyncOverride(payload))
    dispatch(getwoundprogressAsync(payload))    
},[dispatch])
    const woundprogressData = useSelector((state) => state.patient.woundprogressDataByAssessID);
    const ne1getWounAssessmentDetailData = useSelector(state => state.patient.ne1getWounAssessmentDetail)
    const ne1configsignwithoutmindatasetData = useSelector(state => state.patient.ne1configsignwithoutmindataset)
          /* get user role*/ 
          const getUserRole = useSelector(state => state.user);

    useEffect(() => {
        if(woundprogressData){
           
            setAssessmentDetailsNew({'woundStage':woundprogressData?.woundProgress})
        }
    
    }, [woundprogressData])  

  // Edit state
  const [editable, setEditable] = useState(false)
  /* Warning state for closing modal */
  const [closeWarning, setCloseWarning] = useState(false)

  const [isProgressResolve, setIsProgressResolve] = useState(false)
  const [yesResolve, setYesResolve] = useState(false)

  useEffect(() => {
    if (isCompleted === 0) {
      setAssessmentDetailsNew('')
      setEditable(true)
    }
  }, [assessmentDetailsNew,isCompleted])

  const woundProgressResolve = () => {
    setIsProgressResolve(false)
  }
  //Progress
   /* Warning functions Yes and no */
   const handleYesProgress = (isNo) => {
    const navigate = () => {
      updateDone(index)
      if(Array.isArray(charStatus)&&charStatus[index]===1){
        setEditable(false)
      }else{
        selectedIndex(0) 
      }
    }
    let tempAssessment = {
      woundStage: 'resolve',
    }
  if(isNo===1||isNo===0){
        let tempMeasurement={...assessmentDetails?.measurement}
        if(isNo===1){
        tempMeasurement.surfaceArea=0
        tempMeasurement.length=0
        tempMeasurement.width=0
        tempMeasurement.depth=0
        tempMeasurement.undermining=0
        tempMeasurement.tunneling=0
        }
        tempMeasurement.isMeasurementResolved=isNo===1?1:0// isMeasurementResolved case yes 1 or no 0
        tempAssessment={...tempAssessment,...{measurement:tempMeasurement}}
        //measurement		
      }
      const assessment = { ...assessmentDetails, ...tempAssessment }
      const payload = {
        patientId,
        assessmentId,
        assessment,
        navigate,
        getCharDetails,
        overrideenable : overrideToggle ? true : false,
        isMeasurementResolved:isNo===1?1:0
      }
      //dispatch(woundAssessmentUpdateAsync(payload))
      dispatch(ne1updateassessmentprogressAsync(payload))
      setIsProgressResolve(false)
      setYesResolve(false)
  }
  /* No fn */
  const handleNoProgress = () => {
    handleYesProgress(0)
  }
  /* Warning functions Yes and no */
  const handleYes = () => {
    setCloseWarning(false)
    setEditable(false)
  }
  /* No fn */
  const handleNo = () => {
    setCloseWarning(false)
  }



  // Getting wound progress list options
  const woundProgressOptions = useSelector(state => state.patient.woundProgress);
  const charStatus = useSelector(state => state.patient.charStatus)



  const doSubmit = (data) => {
    const navigate = () => {
      updateDone(index)
      if(Array.isArray(charStatus)&&charStatus[index]===1){
        setEditable(false)
      }else{
        selectedIndex(0) 
      }
    }
    // Temp assessment with submitted data
    let tempAssessment = {
      woundStage: data.progress
    }
    if(data.progress==='resolve'){
      setIsProgressResolve(true)
      setYesResolve(true)
    
    }else{
      setIsProgressResolve(false)
      setYesResolve(false)
    // Merging our temp assessment with assessment details and dispatching
    const assessment = { ...assessmentDetails, ...tempAssessment }
    const payload = {
      patientId,
      assessmentId,
      assessment,
      navigate,
      getCharDetails,
      overrideenable : overrideToggle ? true : false
    }
    //dispatch(woundAssessmentUpdateAsync(payload))
    dispatch(ne1updateassessmentprogressAsync(payload))
    //  setYesResolve(false)
    }
  
  }

  return (
    <div className={`${styles.mesurement_blk} pt-1 pb-0`}>
      <div>
        {!editable ?

          <>
            {/* Edit button   */}
            {signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={() => setEditable(true)}>
              <img src={editIcon} alt="edit" />Edit
            </div>}
           

            <p><label>Wound Progress:</label><span>{assessmentDetailsNew?.woundStage ?
              woundProgressOptions && woundProgressOptions.length > 0 && woundProgressOptions?.map((data) => {
                if (data.key === assessmentDetailsNew?.woundStage?.toLowerCase()) {
                  return data.value
                }
              })
              :
              '-'
            }</span></p>
          </>
          :
          /* Close warning */
          <>
            {closeWarning && <CloseWarning
              message="All progress in this section will be lost. Are you sure want to cancel?"
              yesFn={handleYes}
              noFn={handleNo}
            />
            }
            {getUserRole?.role==='ExternalStandardUser'&&ne1getWounAssessmentDetailData?.isProgressDetailDone===0?
 <><p>Set progress is not selected for this wound</p></>
 :
 Array.isArray(characteristicTabsData)&&characteristicTabsData[0]['category']==="History/Cause"&&characteristicTabsData[0]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select History/Cause before selecting Progress'}
                                </div>  
 
 </>
 
 :Array.isArray(characteristicTabsData)&&characteristicTabsData[1]['category']==="Tissue Type"&&characteristicTabsData[1]['isCompleted']===0?<>
 
 <div className={`${styles.errorTissueNull} col-11 text-danger text-center`}>
                                    {'Please select Tissue Type(s) before selecting Progress'}
                                </div>  
 
 </>
 :<>
        {isProgressResolve&& <CloseWarning
                      message="Do you want to set all the measurement to 0?"
                      yesFn={()=>handleYesProgress(1)}
                      noFn={handleNoProgress}
                    />}
            <div className="container-fluid p-0">
              <div className={`${styles.patient_content} p-0`}>
                <Form  className={'col-12 p-0'} onSubmit={handleSubmit(doSubmit)}>
                  <SelectBox
                    id={'progress'}
                    name={'progress'}
                    label={'Wound Progress: '}
                    classes={{
                      column: `col-12 ${styles.col_spec}`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={woundProgressOptions}
                    validator={register}
                    placeholder='Select'
                    defaultValue={assessmentDetailsNew?.woundStage ? assessmentDetails.woundStage : assessmentDetails?.reEvalSequence === 0 ? 'new' : null}
                    onChange={()=>woundProgressResolve()}
                 />
                  {signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <div className="col-12 p-0 mt-4">
                    <div className="offset-4">
                      <Button
                        type="submit"
                        value={SAVE_BTN}
                        classes={{ className: `btn pl-1 mr-4 ${style.btn_primary}` }}
                      />
                      <Button
                        type="button"
                        value={CANCEL_BTN}
                        classes={{ className: `btn ml-2 ${style.btn_scondary}` }}
                        onClick={() => {
                          window.scrollTo(0, 0)
                          setIsProgressResolve(false)
                          setCloseWarning(true)
                        }}
                      />
                    </div>
                  </div>}
                </Form>
              </div>
            </div>

            </>}
          </>
        }

      </div>
    </div>
  )
}
