import React, { useEffect, useState } from 'react';
import { Accordion } from '../../secondary/Accordion/accordion';
import LogoImage from '../../../static/images/measurmenttest.jpg';
import deleteIcon from '../../../static/images/delete-icon.png';
import warning from '../../../static/images/icon-check-danger.png';
import { useSelector } from 'react-redux';
import style from './woundAssessment.module.scss';
import { Button } from '../../primary';
import { Confirmation } from '../../secondary/Confirmation/confirmation';
import { useParams } from 'react-router-dom';
import { daysAgo, fullMonthFormatDate, getLabel, getSubLabel } from '../../../utils';
//import { CreateWoundPhotoAssessment } from './createWoundPhotoAssessment';
import { CreateWoundSubmittedPhotoAssessment } from './createWoundSubmittedPhotoAssessment';
import {
  SUCCESS_STATUS, UPDATE_SUCCESS,
  UPDATE_FAILURE, ERROR_STATUS
} from '../../../constants';
import { setWoundAssessment, getPatientSubmittedWoundPhotoAsync, deleteWoundPhotoAsync, reAdmitPatientAsync, setEditState } from '../../../app/patientSlice';
import { Modal } from '../../secondary/Modal/modal';
import infoIcon from '../../../static/images/info.svg';
import { format } from 'date-fns';
export const WoundPhotoAssessment = ({ patientData, dispatch, getAssessments }) => {
  const [offlineData, setOfflineData] = useState('');
  const [reeval, setReeval] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [reAdmitModal, setReAdmitModal] = useState(false)
  const [currentWoundPhoto, setCurrentWoundPhoto] = useState('')
  const [existingAssessment, setExistingAssessment] = useState('')

  // Role
  const userRole = useSelector(state => state.user);

  const closeInfoConfirm = () => {
    setInfoModal(false)
    setReAdmitModal(false)

  }
  const openInfoModal = (e) => {
    setInfoModal(true);
    e.stopPropagation();

  }
  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const { patientId } = useParams();
  const updateStatus = useSelector(state => state.patient.editState);
  const identifyUsername = useSelector(state => state.user.username);

  useEffect(() => {
   // dispatch(setWoundAssessment('reset'))

    //getAssessments(patientId);
    dispatch(setEditState({}))
    setCurrentWoundPhoto('')
    setExistingAssessment('')
  }, [])

  useEffect(() => {
    if ((updateStatus === "success") || (updateStatus === "error")) {
      closeInfoConfirm();
      setTimeout(() => {
        dispatch(setEditState({}))
      }, 5000)
    }
    //setOfflineData(woundAssessments && woundAssessments.offlineInfo && woundAssessments.offlineInfo.offline === 1 ? woundAssessments.offlineInfo : {})
  }, [updateStatus]);

  //const woundAssessments = useSelector(state => state.patient.woundAssessments);
  const woundAssessments = useSelector(state => state.patient.woundAssessments);
  const pastWoundPhotoData = useSelector(state => state.patient.pastWoundPhotoData);
  //const pastWoundPhotoData = woundAssessments && woundAssessments.assessments && Array.isArray(woundAssessments.assessments) ? woundAssessments.assessments : []
  const woundPhotoData = woundAssessments && woundAssessments.patientWounds && Array.isArray(woundAssessments.patientWounds) ? woundAssessments.patientWounds : []



  let currentTitle = '';
  // options API
  const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
  const historyCause = useSelector(state => state.patient.historyCause);
  const clousreControl = '100';
  const statusBadg = {
    'pendingassessment': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_assess}`}>Pending Assessment</span>,
    'reviewed': <span className={`pt-2 pb-2  ${style.assess_status} ${style.reviewed}`}>Reviewed</span>,
    'pendingreview': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_review}`}>Pending Review</span>,
    'signedlocked': <span className={`pt-2 pb-2 ${style.assess_status} ${style.signed_locked}`}>Signed &#38; Locked</span>
  }
  // State for modal open / close
  const [assessmentModal, setAssessmentModal] = useState(false)
  const [createWoundPhotoModal, setCreateWoundPhotoModal] = useState(false)
  const [assessId, setAssessId] = useState()
  const [modalShow, setmodalShow] = useState(false);
  const createWoundPhotoModalOpen = (reeval, assessment) => {
    if (reeval === 'reeval') {
      setReeval(true)
      setExistingAssessment(assessment)
    } else {
      setReeval(false)
      setExistingAssessment('')
    }
    setCreateWoundPhotoModal(true);
    setmodalShow(false);

  }
  const modalOpen = (items) => {
    dispatch(getPatientSubmittedWoundPhotoAsync(patientId));
    if (patientData && patientData?.statusInfo?.status === 'discharged') {
      setReAdmitModal(true)
      setCurrentWoundPhoto(items)
    } else {
      setCurrentWoundPhoto(items)
      setmodalShow(true);
    }

  }
  const submitReadmit = () => {
    const reAdmitPayload = {
      "ne1Id": patientId,
      "status": "readmitted",
      "admissionDate": new Date().toISOString(),
      "dischargeDate": new Date().toISOString(),
      "facility": patientData && patientData?.facilityNumber
    }
    dispatch(reAdmitPatientAsync(reAdmitPayload))
    setTimeout(() => {
      closeInfoConfirm();
    }, 2000)
    setTimeout(() => {
      setmodalShow(true);
    }, 3000)
  }


  const [showDelConWoundPhoto, setShowDelConWoundPhoto] = useState(false)
  const [woundPhotoId, setWoundPhotoId] = useState(false)
  const openDelConWoundPhoto = (id) => {
    setWoundPhotoId(id);
    setShowDelConWoundPhoto(true)
  }
  const handleDeleteWoundPhoto = () => {
    dispatch(deleteWoundPhotoAsync(patientId, woundPhotoId, 0))
    setWoundPhotoId('')
    setShowDelConWoundPhoto(false)
  }
  const modalRefresh = () => {
    setmodalShow(false);
    setCurrentWoundPhoto('')
    setExistingAssessment('setExistingAssessment')
    setReeval(false)
  }
  //handleDeleteWoundPhoto
  useEffect(() => {
    setOfflineData(woundAssessments && woundAssessments.offlineInfo && woundAssessments.offlineInfo.offline === 1 ? woundAssessments.offlineInfo : {})
  }, [woundAssessments]);

  return (<div>
    {
      <>
        {/* Edit success */}
        {updateStatus === SUCCESS_STATUS && <div className={`alert alert-success d-inline ${style.form_alert}`}>{UPDATE_SUCCESS}</div>}
        {/* Edit failure */}
        {updateStatus === ERROR_STATUS && <div className={`alert alert-danger ${style.form_alert}`}>{UPDATE_FAILURE}</div>}
        {
           woundPhotoData && woundPhotoData.length > 0&& <Accordion
           idControl={0}
           heading={
             <div>
               Patient Submitted Photos
               {
                 offlineData && offlineData.offline === 1 ?
                   <>
                     <span className={`ml-2 ${style.info_icon} openInfo`} onClick={(e) => openInfoModal(e)}><img src={infoIcon} alt="info" /></span>
                   </>
                   :
                   ''
               }
             </div>
           }
           content={<div>
             {
               woundPhotoData && woundPhotoData.length > 0 ? woundPhotoData.map((items, index) => {
                 return <div key={index} className={`row m-0 ${style.accordion_panel}`}>
                   <div className="col-lg-2 col-md-3 col-sm-3 pl-0">
                     <div className={style.img_parent}>
                       {userRole.role === 'ExternalStandardUser' ? <img src={items.picture || LogoImage} alt="wound" className={style.dis_patient_sub_img}
 
                       /> : <img src={items.picture || LogoImage} alt="wound" className={offlineData && offlineData.offline === 1 && userRole.role !== 'ExternalStandardUser' ? style.dis_patient_sub_img : style.patient_sub_img}
                         onClick={() => offlineData && offlineData.offline === 1 && userRole.role !== 'ExternalStandardUser' ? false : modalOpen(items)}
                       />}
 
                     </div>
                   </div>
                   <div className="col-lg-8 col-md-7 col-sm-6 pl-0">
                     <div className={`mb-3 ${style.detaile}`}>
                       <div className={style.assess_content}>
                         <span className={style.assess_label}>Taken By: </span>
                         {
                           items.createdBy
                         }
                       </div>
                       <div className={style.assess_content}>
                         <span className={style.assess_label}>Taken On: </span>
                         {
                           items.createdDate ? format(new Date(items.createdDate), 'dd MMMM yyyy') : ''
                         }
                       </div>
                     </div>
 
                   </div>
                   <div className="col-lg-2 col-md-2">
                     <div className={`row float-right ${style.full_height}`}>
                       <div className={`text-right`}>
                         {userRole.role === 'ExternalStandardUser' ? ''
                           :
                           <img src={deleteIcon} alt="delete icon" className={`${style.del_icon} ${ offlineData && offlineData.offline === 1 && userRole.role !== 'ExternalStandardUser'?style.del_icon_desable:''}`}
                             onClick={() => offlineData && offlineData.offline === 1 && userRole.role !== 'ExternalStandardUser' ? false : openDelConWoundPhoto(items.woundPhotoId)}
                           />
                         }
 
 
                       </div>
                     </div>
                   </div>
                 </div>
               }) : <div className={`pt-4 pb-4 text-center ${style.no_data_content}`}>There are no patient submitted photos</div>
             }
           </div>
           }
         />
        }
       

        {
          modalShow && <>
            <Modal
              modalOpen={modalOpen}
              heading={<div>
                <div className={`${style.modal_heading}  ml-n2`}>{"Current Wound"}</div>
              </div>}
              modalClose={modalRefresh}
              modalSize={style.cust_current_photo_modal_size}
              noScroll={true}
              custom_heading={style.custom_heading}
            >
              {<>
                <div className={style.parent}>
                  <div className={style.admission_status_space}>
                    <div className={`row m-0 ${style.accordion_panel} ${style.current_wound_panel}`}>
                      <div className="col-lg-2 col-md-3 col-sm-3 pl-0">
                        <div className={style.img_parent}>
                          <img src={currentWoundPhoto.picture || LogoImage} alt="wound" className={style.patient_sub_img} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-7 col-sm-6 pl-0">
                        <div className={`mb-3 ${style.detaile}`}>
                          <div className={style.assess_content}>
                            <span className={style.assess_label}>Taken By: </span>
                            {
                              currentWoundPhoto && currentWoundPhoto?.createdBy
                            }
                          </div>
                          <div className={style.assess_content}>
                            <span className={style.assess_label}>Identified By:  </span>
                            {
                              identifyUsername
                            }
                          </div>
                          <div className={`${style.assess_content} ${style.caplize_name}`}>
                            <span className={style.assess_label}>Notes:</span><br />
                            {
                              currentWoundPhoto && currentWoundPhoto?.notes
                            }
                          </div>
                          <div className={`mt-2 ${style.assess_content}`}>
                            <Button
                              value="Document as New Wound"
                              classes={{ className: `btn mr-2 pb-2 pt-2 ${style.btn_wound}` }}

                              onClick={() => createWoundPhotoModalOpen()}
                            />
                          </div>

                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className={`row align-items-center ${style.full_height}`}>
                          <div className={`col-lg-6 col-md-12 ${style.closure_ctr}`}>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${style.parent}`}>
                  <div className={`${style.modal_heading} pl-3 pt-0 mt-0 pb-0`}> Past Wounds</div>
                  <div className={`${style.admission_status_space} ${style.pastwound_scroll} mt-n1`}>
                    {
                      patientData.ne1Id === patientId && pastWoundPhotoData && Array.isArray(pastWoundPhotoData) && pastWoundPhotoData.length > 0 ? pastWoundPhotoData.map((assessment, index) => {
                        currentTitle = assessment.title;
                        return <div key={index} className={`row m-0 ${style.pastwound_panel}`}>
                          <div className="col-lg-2 col-md-3 col-sm-3 pl-0">
                            <div className={style.img_parent}>
                              <img src={assessment.picture || LogoImage} alt="wound" className={style.patient_sub_img} />
                              {
                                !isAdminView && assessment?.woundStage && <span className={`${style.img_stage} pt-1 pb-1`}>
                                  {assessment && assessment.woundStage}
                                </span>
                              }
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-7 col-sm-6 pl-0">
                            <div className={`${style.panel_heading} ${style.body_heading} mb-2`}>

                              <div className="mr-3">
                                {
                                  assessment && assessment?.title
                                }
                              </div>

                              <div className={style.assess_stage}>
                                {
                                  !isAdminView && statusBadg[assessment.assessmentStage]
                                }
                              </div>
                            </div>
                            <div className={`mb-3 ${style.detaile}`}>
                              <div className={style.assess_content}>
                                <span className={style.assess_label}>History/Cause: </span>
                                {
                                  getSubLabel(assessment?.cause, historyCause)
                                }
                              </div>
                              <div className={style.assess_content}>
                                <span className={style.assess_label}>Anatomy: </span>
                                {
                                  getLabel(assessment?.anatomy, humanAnatomy)
                                }
                              </div>
                              <div className={`${style.assess_content} ${style.caplize_name}`}>
                                <span className={style.assess_label}>Wound Identified By:</span> {assessment.identifiedBy}
                              </div>
                              <div className={style.assess_content}>
                                <span className={style.assess_label}>Wound Identified:</span> {fullMonthFormatDate(assessment?.identifiedDate)}
                              </div>
                              <div className={style.assess_content}>
                                <span className={style.assess_label}>Last Assessed:&nbsp;</span>
                                {index !== 0 ?
                                  assessment.lastAssessedDate ? fullMonthFormatDate(assessment?.lastAssessedDate) : 'NA'
                                  :
                                  assessment.lastAssessedDate ? daysAgo(assessment?.lastAssessedDate) : 'NA'}
                              </div>
                              <div className={style.assess_content}>
                                <span className={style.assess_label}>Wound Acquired:</span> {assessment.woundAcquired ? assessment.woundAcquired : 'NA'}
                              </div>
                              <div className={`mt-2 ${style.assess_content}`}>
                                <Button
                                  value="Same as the Current Wound"
                                  classes={{ className: `btn mr-2 pb-2 pt-2 ${style.no_btn_wound}` }}
                                  onClick={() => createWoundPhotoModalOpen('reeval', assessment)}
                                />
                              </div>
                            </div>

                          </div>
                          <div className="col-lg-4 col-md-2">
                            <div className={`row align-items-center ${style.full_height}`}>
                              <div className={`col-lg-6 col-md-12 ${style.closure_ctr}`}>
                                {
                                  !isAdminView && <div>
                                    <div className={style.closure_container}>
                                      <div className={style.clousre_text}>% Closure</div>
                                      <div className={`${style.clousre_percentage} ${clousreControl === assessment?.closurePercentage ? style.green : ''}`}>
                                        {assessment?.closurePercentage ? assessment.closurePercentage : '-'}
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      }) : <h5 className={`pb-4 text-center ${style.pastwound_panel}`}>No Previous wounds</h5>
                    }
                  </div>
                </div>
              </>
              }
            </Modal>
          </>
        }
        {/* Delete confirmation */}
        {
          showDelConWoundPhoto && <Confirmation
            onClose={() => setShowDelConWoundPhoto(false)}
            title={" "}
          >
            <div className="text-center pr-4 pl-5">
              {/* <p>
                <img src={warning} alt="warnning-icon" />
              </p> */}
              <p className="pb-3">Are you sure you want to discard this wound photo?</p>
              <div className="mb-2">

                <Button
                  value="Discard"
                  classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn}` }}
                  onClick={handleDeleteWoundPhoto}
                />

                <Button
                  value="Cancel"
                  classes={{ className: `btn mr-3 ml-2 pb-2 pt-2 ${style.no_btn}` }}
                  onClick={() => setShowDelConWoundPhoto(false)}
                />
              </div>
            </div>
          </Confirmation>
        }

        {
          (infoModal || reAdmitModal) && <Confirmation
            onClose={() => closeInfoConfirm()}
            title={" "}
            customSize={style.customSize}
          >{infoModal && <>
            <p className="pr-2 pl-2 pb-3">This patient is locked because another clinician
              <strong> {(offlineData && offlineData.addedBy) ? offlineData.addedBy : ''}</strong> added
              this patient to  their offline list. Please have clinican
              <strong> {(offlineData && offlineData.addedBy) ? offlineData.addedBy : ''}</strong> remove
              this patient from their offline list
            </p>
            <div className="text-center pr-5 pl-5">
              <div className="mb-2">
                <Button
                  value="Close"
                  classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn} closeInfo` }}
                  onClick={() => closeInfoConfirm()}
                />
              </div>
            </div>
          </>
            }
            {reAdmitModal && <>
              <p className="pr-2 pl-2 pb-3 text-center">
                Patient was discharged and needs to be re-admitted before processsing the wound photo.
              </p>
              <div className="text-center pr-5 pl-5">
                <div className="mb-2">
                  <Button
                    value="Re-Admit"
                    classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn}` }}
                    onClick={() => submitReadmit()}
                  />
                  <Button
                    value="Cancel"
                    classes={{ className: `btn mr-3 ml-2 pb-2 pt-2 ${style.no_btn}` }}
                    onClick={() => closeInfoConfirm()}
                  />
                </div>
              </div>
            </>
            }
          </Confirmation>
        }

{createWoundPhotoModal && <CreateWoundSubmittedPhotoAssessment modalClose={setCreateWoundPhotoModal} reEval={reeval} assessmentId={assessId} currentWoundPhoto={currentWoundPhoto} existingAssessment={existingAssessment} patientId={patientId} />}
        {/* {createWoundPhotoModal && <CreateWoundPhotoAssessment modalClose={setCreateWoundPhotoModal} reEval={reeval} assessmentId={assessId} currentWoundPhoto={currentWoundPhoto} existingAssessment={existingAssessment} patientId={patientId} />} */}
      </>
    }
  </div>)
}
