import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { Button, TextArea, Form, FancyDropDown ,RadioButton ,TextField } from '../../primary'
import { useForm } from 'react-hook-form';
import { useParams } from "react-router-dom";
import { Accordion } from "../../secondary/Accordion/accordion";
import {
    createAssessmentAsync,
    createAssessMentWithoutPhoto,
    createAssessMentWithoutPhotoAnatomyCheck,
    optionTypeAsync
  } from "../../../app/patientSlice";
 export function CreateAssessmentHistory({ setCause, setAnatomy, styles, dispatch, historyCauseData, setCreateAssessmentData, showCom, currentWoundPhoto,modalClose,isWoundWithoutPhoto,history,setAnatomyPopUPList }) {
    const { patientId } = useParams();
    /* Hook form */
    const {  register, errors, handleSubmit, watch } = useForm()
    const loading = useSelector((state) => state.app.loading);
    /* State for catgory */
    const [category, setCategory] = useState('')
    const [selectedCause, setSelectedCause] = useState('')
    const [pi, setPi] = useState('')
    const [errChk, setErrChk] = useState(false)
    const piHistory = useSelector(state => state.patient.piHistory);
    const woundAge = useSelector(state => state.patient.woundAge);
    const photoType = useSelector(state => state.patient.photoType);
    const documentProvidedBy = useSelector(state => state.patient.documentProvidedBy);
    const createAssessmentData = useSelector(state => state.patient.createAssessmentData)
 /* question form check */
    const [checkquestion, setCheckquestion] = useState(false)
        /* PDocumentation provided by: other */
        const [otherDocType, setOtherDocType] = useState(false)
        const [otherDocTypeVal, setOtherDocTypeVal] = useState('')
        const [viewDocType, setViewDocType] = useState('')
        const [viewDocTypeSelect, setViewDocTypeSelect] = useState('')
    /* Handle submit */
    const onSubmit = (data) => {
        /* If data exists */
        if(checkquestion===false){
            if ((pi) && (selectedCause)) {
                //update history cause options details updateHistoryCauseDetailsOption
           if(data?.otherNamecause){
               let chckExitOtheropt=false
               historyCauseData && historyCauseData.length > 0 && historyCauseData.map((histDataOpt) => {
                 if(histDataOpt.key==='other'){
                        return chckExitOtheropt =Array.isArray(histDataOpt.options)&&histDataOpt.options.some((val) => val.key.toLowerCase() === data?.otherNamecause.toLowerCase())
                 }
               })
               if(!chckExitOtheropt){
                   const payloadOptUpdate = {
                       ne1ID:patientId,
                       assessmentId:createAssessmentData?.assessmentId?createAssessmentData?.assessmentId:0,
                       historyCauseType: "other",
                       historyCauseTypeValuse: data?.otherNamecause,
                       KeyUpdated: null 
                       }
               }
           }
               setErrChk(false)
               /* Templ payload */
               const payload = {
                assessmentId:createAssessmentData?.assessmentId?createAssessmentData?.assessmentId:0,
                   /* cause: data.cause,
                    causeCategory: category ? category : null,*/
                   cause: selectedCause ? selectedCause : '',
                   causeCategory: pi ? pi : '',
                   notes: data.notes
               }
               /* Dispatch it */
               dispatch(setCreateAssessmentData(payload))
               setCheckquestion(true)
               /* Hide current screen */
               //setCause(false)
               /* Show next screen */
               //setAnatomy(true)
              
   
           } else {
               setErrChk(true)
           }
        }else if(checkquestion===true){
 /* Templ payload */
 let documentProvidedByPost= data?.documentProvidedBy ? data?.documentProvidedBy :'';
 if(data?.documentProvidedBy){
     if(data?.documentProvidedBy&&data?.documentProvidedBy.replace(/\s/g, '').toLowerCase()==='other'&&data?.documentProvidedByOther){
         documentProvidedByPost=data?.documentProvidedByOther
     }
 }
 if(data?.documentProvidedBy===''){
     documentProvidedByPost=''
 }  

 let selPhotoType=''
 if(data?.photoType){
    Array.isArray(photoType) && photoType.map((optionVal) => {
        if(optionVal.value===data?.photoType){
            selPhotoType=optionVal.key
        }

     });
 }
 const Tempayload = {
    /* cause: data.cause,
     causeCategory: category ? category : null,*/
     woundAge: data?.woundAge ? data?.woundAge.replace(/\s/g, '').toLowerCase() : null,
     conditionPOA:  data?.conditionPOA === 'Yes' ? 1 : 0,
     photoType: data?.photoType&&selPhotoType?selPhotoType: '',
     documentProvidedBy: documentProvidedByPost?documentProvidedByPost.replace(/\s/g, '').toLowerCase() : null,
     piHistory:createAssessmentData?.causeCategory==="pi" ? data?.piHistory ? data?.piHistory.replace(/\s/g, '').toLowerCase() : null : null,
}
const payload = { ...createAssessmentData, ...Tempayload }
/* Dispatch it */
dispatch(setCreateAssessmentData(payload))

if (isWoundWithoutPhoto && isWoundWithoutPhoto === 1) {
    // dispatch(createAssessMentWithoutPhoto(patientId, history,setAnatomyPopUPList));
    dispatch(createAssessMentWithoutPhotoAnatomyCheck(patientId, history,setAnatomyPopUPList));
     //createAssessMentWithoutPhotoAnatomyCheck
   } else {
     dispatch(createAssessmentAsync(patientId, history, currentWoundPhoto));
     setTimeout(() => {
       if (!loading) {
         modalClose();
       }
     }, 2000);
   }
        }
        

    }

    const getHSData = (cat, value) => {
        setPi(cat)
        setSelectedCause(value)
    }

    const allowEnter = (e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      }
      const documentProvidedByChange = (val) => {
        setOtherDocType(false);
        setViewDocTypeSelect(val?val.replace(/\s/g, '').toLowerCase():'');
        if (val !== "" && val.replace(/\s/g, '').toLowerCase() === "other") {
          setOtherDocType(true);
        }
      };

      useEffect(() => {
        dispatch(optionTypeAsync('humananatomy'));
        dispatch(optionTypeAsync('historycause'));
        dispatch(optionTypeAsync('pihistory'));
        dispatch(optionTypeAsync('woundage'));
        dispatch(optionTypeAsync('phototype'));
        dispatch(optionTypeAsync('documentationtype'));
    }, [dispatch])
    return (
        <div className={styles.history_cause}>

            <Form formChanges={true} onSubmit={handleSubmit(onSubmit)}>
                {/* History Cause form */}
                <div className={styles.history_form}>
                {checkquestion===false&&
                <>
                    {/* History cause */}
                    <div className={`${styles.col_spec}`}>
                        {/* Label */}
                        {errChk && <span className={`mt-n1 ${styles.hist_cause_err}`}>Please select the history/cause of the wound from the list or name it something else</span>}
                        <div className={`col-12 p-0`}>
                        <label htmlFor="cause" className={`pl-0 col-form-label ${styles.label_spec}`}>History/Cause</label>
                            <FancyDropDown
                                id={"cause"}
                                name={"cause"}
                                classes={{ column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select, inputColumn: `col-12 p-0 mt-4` }}
                                listOptions={historyCauseData}
                                defaultValue={currentWoundPhoto}
                                validator={register}
                                getData={getHSData}
                                showCom={showCom}
                            />
                        </div>
                    </div>
                    {/* Notes */}
                    <div className={`${styles.col_spec} mt-n3`}>
                    <div className={`col-12 p-0`}>
                        <label
                            htmlFor={"notes"}
                            className={`pl-0 col-form-label ${styles.label_spec} ${styles.notes_label}`}>
                            Notes <div className="d-inline">(Optional)</div>
                        </label>
                        <TextArea
                            classes={{ inputClassName: styles.basic_textarea, column: '' }}
                            name="notes"
                            id="notes"
                            placeholder="Add additional notes here..."
                            validator={register}
                            onKeyPress={(e) => allowEnter(e)}
                        />
                        </div>
                    </div>
                   
                      </>}


{checkquestion===true&&<>
                    <>
                {/* Condition of POA radio         */}
                {errors && (
                  <span className={`mt-1 ml-2 ${styles.hist_cause_err}`}>
                    {(errors['conditionPOA']) ? errors['conditionPOA'].message:''}
                  </span>
                )}
                <Accordion
                 histCause={true}
                  defaultShow={1}
                  classes={{
                    accBodyCust: `${styles.acc_body_cust} mb-1`,
                    accHeadCust: `${styles.acc_head_cust}`,
                    accBtnCust: `${styles.acc_btn_cust}`,
                  }}
                  idControl={2}
                  heading={
                    <div className='mt-3'>
                      {
                        <>
                          <b>
                            Condition present on admission?{" "}
                            <span
                              style={{ color: "#de350b"}}
                            >
                              *
                            </span>
                          </b>
                        </>
                      }
                    </div>
                  }
                  content={
                    <div>
                      <RadioButton
                        id="conditionPOA"
                        name="conditionPOA"
                        classes={{
                          column: `ml-4 mt-2 mb-2`,
                          labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                          inputClassName: `custom-control-input ${styles.custom_control_input}`,
                          inputColumn:
                            "custom-control custom-radio custom-control-inline",
                        }}
                        radioOption={["Yes", "No"]}
                        validator={register({
                          required: "Please select Yes or No",
                        })}
                      // defaultValue={conditionPOADyn===1?'Yes':conditionPOADyn===0?'No':''}
                      />
                   
                    </div>
                  }
                />
                  
              </>

              {/* Has PI */}
            {createAssessmentData?.causeCategory==="pi" && (
              <>

                {/* PI history */}
                <span className={`mt-n3 ml-2 ${styles.hist_cause_err}`}>
                    {(errors['piHistory']) ? errors['piHistory'].message:''}
                  </span>
                <Accordion
                   histCause={true}
                   defaultShow={1}
                  classes={{
                    accBodyCust: `${styles.acc_body_cust}`,
                    accHeadCust: `${styles.acc_head_cust}`,
                    accBtnCust: `${styles.acc_btn_cust} mt-3`,
                  }}
                  idControl={4}
                  heading={
                    <div>
                      {
                        <>
                          <b>
                            Does the patient has a past history of Pressure
                            Injury/Ulcer for the same wound?{" "}
                            <span
                              style={{ color: "#de350b" }}
                            >
                              *
                            </span>
                          </b>
                        </>
                      }
                    </div>
                  }
                  content={
                    <div>

{
                   Array.isArray(piHistory) && piHistory.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                    id={optionVal?.key}
                                    name="piHistory"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register({
                                        required: "Please select an option",
                                      })}
                                    // errors={errors}
                               //  defaultValue={piHistoryDyn&&piHistoryDyn.replace(/\s/g, '').toLowerCase()===optionVal?.key?optionVal?.value:''}
                                />
                      </div>

</div>
                    }) 
                  }
                  
                    </div>
                  }
                />
               
              </>
            )}

               {/* Wound age dropdown */}
            <Accordion
              defaultShow={0}
              classes={{
                accBodyCust: `${styles.acc_body_cust}`,
                accHeadCust: `${styles.acc_head_cust}`,
                accBtnCust: `${styles.acc_btn_cust} mt-3`,
              }}
              idControl={1}
              heading={
                <div>
                  {
                    <>
                      <b>
                        Wound age{" "}
                        <span
                          style={{ color: "#de350b" }}
                        ></span>
                      </b>
                    </>
                  }
                </div>
              }
              content={
                <div>

                  {
                   Array.isArray(woundAge) && woundAge.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         
                         <RadioButton
                                     id={optionVal?.key}
                                    name="woundAge"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register}
                                    //defaultValue={woundAgeDyn&&woundAgeDyn.replace(/\s/g, '').toLowerCase()===optionVal?.key?optionVal?.value:''}
                                    unCheck={1}
                                />
                      </div>

</div>
                    }) 
                  }
                </div>
              }
            />
             

             <>
                    <Accordion
                      defaultShow={0}
                      classes={{
                        accBodyCust: `${styles.acc_body_cust}`,
                        accHeadCust: `${styles.acc_head_cust}`,
                        accBtnCust: `${styles.acc_btn_cust}`,
                      }}
                      idControl={2}
                      heading={
                        <div>
                          {
                            <>
                              <b>
                                Photo type{" "}
                                <span
                                  style={{ color: "#de350b", fontSize: "31px" }}
                                ></span>
                              </b>
                            </>
                          }
                        </div>
                      }
                      content={
                        <div>


{
                   Array.isArray(photoType) && photoType.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                     id={optionVal?.key}
                                    name="photoType"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register}
                                  //  defaultValue={photoTypeDyn&&photoTypeDyn.replace(/\s/g, '').toLowerCase()===optionVal?.key?optionVal?.value:''}
                                  unCheck={1}
                                />
                      </div>

</div>
                    }) 
                  }
                        </div>
                      }
                    />

                   
                    <Accordion
                      defaultShow={0}
                      classes={{
                        accBodyCust: `${styles.acc_body_cust}`,
                        accHeadCust: `${styles.acc_head_cust}`,
                        accBtnCust: `${styles.acc_btn_cust}`,
                      }}
                      idControl={3}
                      heading={
                        <div>
                          {
                            <>
                              <b>
                                Documentation type{" "}
                                <span
                                  style={{ color: "#de350b", fontSize: "31px" }}
                                ></span>
                              </b>
                            </>
                          }
                        </div>
                      }
                      content={
                        <div>

                          {
                   Array.isArray(documentProvidedBy) && documentProvidedBy.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ml-4 ${styles.accordion_panel}`}>

                      <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                     id={optionVal?.key}
                                    name="documentProvidedBy"
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={register}
                                    onChange={(e) =>
                                      documentProvidedByChange(e.target.value)
                                    }
                                   // defaultValue={viewDocTypeSelect}
                                   // defaultValue={viewDocTypeSelect&&viewDocTypeSelect===optionVal?.key?optionVal?.value:''}
                                  unCheck={1}
                                />
                      </div>

</div>
                    }) 
                  }
                          {otherDocType && (
                            <>
                              <TextField
                                id="documentProvidedByOther"
                                name="documentProvidedByOther"
                                label={``}
                                classes={{
                                  column: `ml-4 ${styles.col_spec}`,
                                  labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                  inputColumn: "col-11 p-0",
                                  inputClassName: `${styles.basic_input}`,
                                }}
                                type="text"
                                validator={register}
                                // defaultValue={
                                //   otherDocTypeVal && otherDocTypeVal.replace(/\s/g, '').toLowerCase() !== "other"
                                //     ? otherDocTypeVal
                                //     : ""
                                // }
                              />
                            </>
                          )}
                        </div>
                      }
                    />
                  </>

</>}

                    {/* Next Button */}
                    <div className="text-center mt-3">
                        <Button
                            type={"submit"}
                            classes={{ className: `btn ${styles.btn_add}` }}
                            id="next"
                        >
                            Next
                        </Button>
                        
                        {checkquestion===true&&<><Button
                        type="button"
                        value="Cancel"
                        classes={{
                          className: `btn btn-small ml-4 ${styles.btn_small_cancel} ${styles.btn_custom}`,
                        }}
                        id="cancel"
                        onClick={() => {
                          modalClose()
                        }}
                      />
                      </>}
                    </div>

                </div>
            </Form>
        </div>
    )
}
