import React, { useState, useEffect} from "react";
import ReactImageAnnotate from "react-wound-image-annotate";
import { Modal } from "../../secondary/Modal";
import styles from "./createAssessment.module.scss";
import "./measurement.css";
import { CreateAssessmentHistory } from "./createAssessmentHistory";
import { CreateAssessmentAnatomy } from "./createAssessmentAnatomy";
import { AnatomyListPopUp } from "./anatomyListPopUp";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setCreateAssessmentData,
  deleteWoundPhotoAsync,
  preWoundPhotoEvalAsync,
  reEvalWoundPhotoAssessmentAsync,
} from "../../../app/patientSlice";
import { withRouter } from "react-router-dom";
import { Button } from "../../primary";
import { Confirmation } from "../../secondary/Confirmation/confirmation";
export let CreateWoundPhotoAssessment = ({
  modalClose,
  history,
  reEval,
  assessmentId,
  currentWoundPhoto,
  existingAssessment,
  patientId,
  isWoundWithoutPhoto
}) => {

  const createAssessmentData = useSelector(state => state.patient.createAssessmentData)
  /* States for navigating */
  const [viewWoundimg, setViewWoundimg] = useState(true);
  const [discardModal, setDiscardModal] = useState(false);
  const [anatomyPopUPList, setAnatomyPopUPList] = useState(false);
  const [cause, setCause] = useState(false);
  const [anatomy, setAnatomy] = useState(false);

  // Get history cause and anatomy data from redux
  const humanAnatomy = useSelector((state) => state.patient.humanAnatomy);
  const historyCause = useSelector((state) => state.patient.historyCause);

  /* Dispatch */
  const dispatch = useDispatch();

  const modalCloseViewImg = () => {
    setViewWoundimg(false);
    if (reEval) {
      modalClose();
    } else {
      //setCause(true);
      setAnatomy(true)
    }
  };
  const discardViewImg = () => {
    setDiscardModal(true);
  };

  const discardConfirm = () => {
    dispatch(
      deleteWoundPhotoAsync(patientId, currentWoundPhoto.woundPhotoId, 1)
    );
    modalClose();
    setDiscardModal(true);
    setDiscardModal(false);
  };
  const closeConfirm = () => {
    setDiscardModal(false);
  };

  let evalWound = (annotation) => {
    let woundSvgElement = document
      .getElementById("wound")
      .querySelector("canvas")
      .parentElement.querySelector("svg");
    const { pixelSize, regions } = annotation.images[0];
    if (pixelSize && pixelSize.w && pixelSize.h) {
      let canvas = document.createElement("canvas");
      canvas.width = pixelSize.w;
      canvas.height = pixelSize.h;
      let context = canvas.getContext("2d");
      let woundImage = new Image();
      woundImage.crossOrigin = "anonymous";
      let picture = "";
      woundImage.onload = function() {
        try {
          context.drawImage(woundImage, 0, 0, pixelSize.w, pixelSize.h);
          picture = canvas.toDataURL("image/jpeg");
          const payloadPrewound = {
            id: assessmentId,
            picture: picture.replace("data:image/jpeg;base64,", ""),
            tracing: "auto",
            coordinates: regions[0]?.points,
            channel: "ios",
            isStickerAvailable: currentWoundPhoto?.isStickerAvailable,
            stickerArea: currentWoundPhoto?.stickerArea,
            stickerLength: currentWoundPhoto?.stickerLength,
            uuId: currentWoundPhoto?.uuId,
          };

          const payloadreEval = {
            patientId: patientId,
            woundPhotoId: currentWoundPhoto?.woundPhotoId,
            assessmentId: existingAssessment?.id,
            uuId: currentWoundPhoto?.uuId,
            picture: picture.replace("data:image/jpeg;base64,", ""),
          };
          if (currentWoundPhoto?.isStickerAvailable === 1) {
            dispatch(preWoundPhotoEvalAsync(payloadPrewound));
          }

          if (reEval) {
            dispatch(reEvalWoundPhotoAssessmentAsync(payloadreEval));
            setTimeout(() => {
              modalCloseViewImg();
            }, 5000);
          } else {
            dispatch(
              setCreateAssessmentData({
                uuId: currentWoundPhoto?.uuId,
                woundPhotoId: currentWoundPhoto?.woundPhotoId,
                picture: currentWoundPhoto?.picture,
              })
            );
            modalCloseViewImg();
          }
        } catch (e) {
          // console.log(e);
        }
      };
      woundImage.src = currentWoundPhoto?.picture;
    }
  };

  const noStickerCall = () => {
    const payloadreEval = {
      patientId: patientId,
      woundPhotoId: currentWoundPhoto?.woundPhotoId,
      assessmentId: existingAssessment?.id,
      uuId: currentWoundPhoto?.uuId,
      picture: currentWoundPhoto?.picture,
    };
    if (reEval) {
      dispatch(reEvalWoundPhotoAssessmentAsync(payloadreEval));
      setTimeout(() => {
        modalCloseViewImg();
      }, 5000);
    } else {
      dispatch(
        setCreateAssessmentData({
          uuId: currentWoundPhoto?.uuId,
          woundPhotoId: currentWoundPhoto?.woundPhotoId,
          picture: currentWoundPhoto?.picture,
        })
      );
      modalCloseViewImg();
    }
  };

  const nextDrawCall = () => {
    if (document?.querySelector(".next_draw")) {
      document.querySelector(".next_draw").click();
    }
  };
  const clearDrawCall = () => {
    if (document?.querySelector(".clear_draw")) {
      document.querySelector(".clear_draw").click();
    }
  };

  useEffect(() => {
    if(isWoundWithoutPhoto&&isWoundWithoutPhoto===1){
      setViewWoundimg(false)
      //setCause(true);
      dispatch(setCreateAssessmentData('reset'))
      setAnatomy(true)
    }
  }, [isWoundWithoutPhoto,dispatch])
  return (
    <>
      {/* Modal */}
      {anatomyPopUPList ?
      <AnatomyListPopUp 
      patientId={patientId}
      history={history}
      setAnatomyPopUPList={setAnatomyPopUPList}
      modalClose={modalClose} 
      dispatch={dispatch}/>
      :
      <Modal
        modalOpen={true}
        modalClose={modalClose}
        heading={isWoundWithoutPhoto&&isWoundWithoutPhoto===1?"Wound assessment without photo":"New Wound Assessment"}
        className={styles.assessment_modal}
        modalSize={styles.custom_modal_size}
      >
        {/* Upload image componet */}
        {viewWoundimg && isWoundWithoutPhoto!==1&&(
          <>

            {/* <div
              className={`col-12 justify-content-center mb-3 main_next_clear`}
            >
              <Button
                value={"Next"}
                classes={{ className: `btn mr-4 ${styles.btn_add} nextstep` }}
                onClick={() => nextDrawCall()}
              />
              <Button
                value="Clear"
                classes={{ className: `btn  ml-4 pb-2 pt-2 ${styles.no_btn} clearbutton` }}
                onClick={() => clearDrawCall()}
              />
            </div> */}


            {/* added for measurement git animation loader */}
            <div  className={`${styles.button_Right} col-3 align-items-center  main_next_clear`} >
                <div  className={`${styles.button_Right_Block}`} >
                <Button
                  value={"Next"}
                  classes={{ className: `btn ml-4 mb-4 ${styles.btn_add_trace} nextstep`}}
                  onClick={() => nextDrawCall()}
                />
                <Button
                  value="Clear"
                  classes={{ className: `btn  ml-4 pt-2 ${styles.no_btn_clr} clearbutton`}}
                  onClick={() => clearDrawCall()}
                />
                </div>
            </div>
            {/* added for measurement git animation loader */}

            {currentWoundPhoto &&
            currentWoundPhoto.isStickerAvailable === 1? (
              <div className={`${styles.img_alert} text-center mb-2 sub_next_clear`}>
                <p>Do a high level trace along the wound edges</p>{" "}
              </div>
            ) : (
              <div className={`d-flex ${styles.img_alert} mb-2`}>
                <p>
                  Photo was submitted without the sticker, hence the
                  measurements can't be calculated. Tap{" "}
                  <span className={styles.next_or_label}>'Next'</span> to
                  continue <span className={styles.next_or_label}>OR</span>
                  <Button
                  id="discardPhoto"
                    value="Discard this photo"
                    classes={{ className: `btn ml-1 ${styles.no_btn_discard} discardPhoto` }}
                    onClick={() => discardViewImg()}
                  />
                </p>
              </div>
            )}

            {currentWoundPhoto &&
            currentWoundPhoto.isStickerAvailable === 1 ? (
              <div
                className={`d-flex ${styles.annotate_container}`}
                id={"wound"}
              >
                <ReactImageAnnotate
                  selectedTool="create-polygon"
                  onExit={(annotation) => evalWound(annotation)}
                  hideHeaderText
                  hideNext
                  hidePrev
                  showTags={false}
                  images={[
                    {
                      src: currentWoundPhoto?.picture,
                      regions: [],
                    },
                  ]}
                />
              </div>
            ) : (
              <>
                <div className="text-center mb-3 mt-3">
                  <Button
                    value={"Next"}
                    classes={{ className: `btn mr-4 ${styles.btn_add} nosticker` }}
                    onClick={() => noStickerCall()}
                  />
                  <Button
                    value="Cancel"
                    classes={{
                      className: `btn  ml-4 pb-2 pt-2 ${styles.no_btn} closemodal`,
                    }}
                    onClick={() => modalClose()}
                  />
                </div>
                <div className={`d-flex ${styles.annotate_container}`}>
                  <img
                    src={currentWoundPhoto.picture}
                    alt="wound"
                    className={styles.view_wound_img}
                  />
                </div>
              </>
            )}
          </>
        )}
        {/* History & Cause */}
        {cause &&  (
          <div className={`${styles.spacing} mt-n4`}>
            <CreateAssessmentHistory
              setCause={setCause}
              setAnatomy={setAnatomy}
              styles={styles}
              dispatch={dispatch}
              historyCauseData={historyCause}
              setCreateAssessmentData={setCreateAssessmentData}
              currentWoundPhoto={currentWoundPhoto}
              modalClose={modalClose}
              //showCom={isWoundWithoutPhoto===1?true:false}
              showCom={true}
              history={history}
              isWoundWithoutPhoto={isWoundWithoutPhoto}
              setAnatomyPopUPList={setAnatomyPopUPList}
            />
          </div>
        )}
        {/* History & Cause */}
        {anatomy && (
          <div className={styles.spacing}>
            <CreateAssessmentAnatomy
              styles={styles}
              dispatch={dispatch}
              setCreateAssessmentData={setCreateAssessmentData}
              history={history}
              humanAnatomyData={humanAnatomy}
              currentWoundPhoto={true}
              modalClose={modalClose}
              isWoundWithoutPhoto={isWoundWithoutPhoto}
              setCause={setCause}
              setAnatomy={setAnatomy}
            />
          </div>
        )}
      </Modal>}



      {discardModal && (
        <Confirmation
          onClose={() => closeConfirm()}
          title={" "}
          customSize={styles.customSize}
        >
          <p className="pr-2 pl-2 pb-3 text-center">
            Are you sure you want to discard this wound photo
          </p>
          <div className="text-center pr-5 pl-5">
            <div className="mb-2">
              <Button
                value="Discard"
                classes={{ className: `btn mr-2 pb-2 pt-2 ${styles.yes_btn} disclose` }}
                onClick={() => discardConfirm()}
              />
              <Button
                value="Keep"
                classes={{
                  className: `btn mr-3 ml-2 pb-2 pt-2 ${styles.no_btn} diskeep`,
                }}
                onClick={() => closeConfirm()}
              />
            </div>
          </div>
        </Confirmation>
      )}
    </>
  );
};

CreateWoundPhotoAssessment = connect()(withRouter(CreateWoundPhotoAssessment));
