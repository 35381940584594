import React, { useState } from 'react'
import { Button, TextArea, Form, FancyDropDown } from '../../primary'
import { useForm } from 'react-hook-form';
import { useParams } from "react-router-dom";
export function CreateAssessmentHistorySubmittedPhoto({ setCause, setAnatomy, styles, dispatch, historyCauseData, setCreateAssessmentData, showCom, currentWoundPhoto }) {
    const { patientId } = useParams();
    /* Hook form */
    const { register, handleSubmit } = useForm()

    /* State for catgory */
    const [category, setCategory] = useState('')
    const [selectedCause, setSelectedCause] = useState('')
    const [pi, setPi] = useState('')
    const [errChk, setErrChk] = useState(false)

    /* Handle submit */
    const onSubmit = (data) => {
       // console.log('daTA',data)
       // return false;
        /* If data exist */
        if ((pi) && (selectedCause)) {
             //update history cause options details updateHistoryCauseDetailsOption
        if(data?.otherNamecause){
            let chckExitOtheropt=false
            historyCauseData && historyCauseData.length > 0 && historyCauseData.map((histDataOpt) => {
              if(histDataOpt.key==='other'){
                     return chckExitOtheropt =Array.isArray(histDataOpt.options)&&histDataOpt.options.some((val) => val.key.toLowerCase() === data?.otherNamecause.toLowerCase())
              }
            })
            if(!chckExitOtheropt){
                const payloadOptUpdate = {
                    ne1ID:patientId,
                    assessmentId:0,
                    historyCauseType: "other",
                    historyCauseTypeValuse: data?.otherNamecause,
                    KeyUpdated: null 
                    }
            }
        }
            setErrChk(false)
            /* Templ payload */
            const payload = {
                /* cause: data.cause,
                 causeCategory: category ? category : null,*/
                cause: selectedCause ? selectedCause : '',
                causeCategory: pi ? pi : '',
                notes: data.notes
            }
            /* Dispatch it */
            dispatch(setCreateAssessmentData(payload))
            /* Hide current screen */
            setCause(false)
            /* Show next screen */
            setAnatomy(true)
        } else {
            setErrChk(true)
        }

    }

    const getHSData = (cat, value) => {
        setPi(cat)
        setSelectedCause(value)
    }

    const allowEnter = (e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      }
    return (
        <div className={styles.history_cause}>

            <Form onSubmit={handleSubmit(onSubmit)}>
                {/* History Cause form */}
                <div className={styles.history_form}>
                    {/* History cause */}
                    <div className={`${styles.col_spec}`}>
                        {/* Label */}
                        {errChk && <span className={`mt-n1 ${styles.hist_cause_err}`}>Please select the history/cause of the wound from the list or name it something else</span>}
                        <div className={`col-12 p-0`}>
                        <label htmlFor="cause" className={`pl-0 col-form-label ${styles.label_spec}`}>History/Cause</label>
                            <FancyDropDown
                                id={"cause"}
                                name={"cause"}
                                classes={{ column: `col-12 ${styles.col_spec} mb-2`, inputClassName: styles.basic_select, inputColumn: `col-12 p-0 mt-4` }}
                                listOptions={historyCauseData}
                                defaultValue={currentWoundPhoto}
                                validator={register}
                                getData={getHSData}
                                showCom={showCom}
                            />
                        </div>
                    </div>
                    {/* Notes */}
                    <div className={`${styles.col_spec} mt-n3`}>
                    <div className={`col-12 p-0`}>
                        <label
                            htmlFor={"notes"}
                            className={`pl-0 col-form-label ${styles.label_spec} ${styles.notes_label}`}>
                            Notes <div className="d-inline">(Optional)</div>
                        </label>
                        <TextArea
                            classes={{ inputClassName: styles.basic_textarea, column: '' }}
                            name="notes"
                            id="notes"
                            placeholder="Add additional notes here..."
                            validator={register}
                            onKeyPress={(e) => allowEnter(e)}
                        />
                        </div>
                    </div>

                    {/* Next Button */}
                    <div className="text-center">
                        <Button
                            type={"submit"}
                            classes={{ className: `btn ${styles.btn_add}` }}
                            id="next"
                        >
                            Next
                        </Button>
                    </div>

                </div>
            </Form>
        </div>
    )
}
