import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  BROWSE_LBL,
  CANCEL_BTN,
  CANCEL_ID,
  EDIT,
  SAVE_BTN,
  SUBMIT_ID,
  AI_TISSUE_TYPE_STATUS,
  WOUND_EDGE_AUTO_TRACING_STATUS,
  NE1_WOUND_MEASUREMENT_OPT,
  // UPDATE_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
  SESSION_TIMEOUT_OPTIONS,
  POA_TYPE_OPTIONS,
} from "../../../constants";
import editIcon from "../../../static/images/edit-icon-blue.png";
import DefaultImg from "../../../static/images/picture-icon-default.png";
import {
  checkDomain,
  poaRevalValidator,
  checkSessionType,
  checkSessionHours,
  checkSessionMinutes,
  checkPoaType,
} from "../../../utils";
import { Button, Form, RadioButton, TextField, SelectBox } from "../../primary";
import { PictureIcon, Modal } from "../../secondary";
import styles from "./ne1.module.scss";
import PropTypes from "prop-types";
import { setEditState } from "../../../app/customerSlice";
import { AccessErrorModal } from "../../primary";
import { Confirmation } from "../../secondary/Confirmation/confirmation";
import warning from "../../../static/images/icon-check-danger.png";
import closeIcon from "../../../static/images/close-icon-gray.png";
import Loader from '../../../static/images/spinner.svg';
export function Ne1({ customerData, updateCustomerData, history  }) {
  /* Use hook form */
  const { register, handleSubmit, watch, clearErrors, errors,reset,getValues} = useForm();

  /* State to check editable forms */
  const [editable, setEditable] = useState(false);
  const [disable, setDisable] = useState(false);
  const accessError = useSelector((state) => state.app.accessError);

  /* DefaultTimeout POA Type */
  const [defaultPOAType, setDefaultPOAType] = useState("");
  const resMsgState = useSelector(state => state.user.resMsgState);

  /* Set reEval or set default value */
  const reEvalHours = customerData?.settings
    ? customerData?.settings?.reEvalWithinHrs
    : "";

  /* Function to toggle edit */
  const handleEdit = () => {
    reset()
    setEditable(!editable);
    setDisable(!disable);
    setDefaultPOAType(
      customerData && customerData?.settings?.poaType
        ? customerData?.settings?.poaType
        : "hours"
    );
    setPopupSave(false)
    setNe1State(customerData?.settings?.ne1Wms)
    setMakeShiftSold(true)
    setShowConfirmPopup(false)
  };

  /* Set default image to a state if there is any */
  useEffect(() => {
    if (customerData?.settings?.companyLogo) { 
      setDefaultPOAType(
        customerData && customerData?.settings?.poaType
          ? customerData?.settings?.poaType
          : "hours"
      );
    }
  }, [customerData]);

  // submit data
  const onSubmit = (data) => {   
    const tempData = {
      settings: {
        companyLogo: customerData?.settings?.companyLogo,
        customerStatus: customerData?.settings?.customerStatus,
        domain:  customerData?.settings?.domain,
        sessionTimeout: customerData?.settings?.sessionTimeout,
        sessionTimeoutType: customerData?.settings?.sessionTimeoutType,        
        pdfAutoGen : customerData?.settings?.pdfAutoGen,
        ne1Wms: ne1State === 1 ? 1 : 0, //check Ne1 status
        poaWithinHrs: data.poa,
        poaType: data.poaType,
        reEvalWithinHrs: data.reEval,    
        woundEdgeAutoTracing: data.woundEdgeAutoTracing === "Enable" ? 1 : 0,
        measureType: data.measureType === "Longest length" ? "longestlength" : "headtotoe",
        riskAssessmentScale: data.riskAssessmentScale === "Braden" ? "braden" : "norton",
        woundAssessmentScore: data.woundAssessmentScore === "PUSH" ? "push" : "bwat",
        aiTissueType: data.aiTissueType === "Enable" ? 1 : 0, // aiTissueType
        historyCause: data.aihistorycause === "Enable" ? 1 : 0,
        soldToShipToForZ002: popupSave === true ? 1 : 0,
        copyDataFromPreviousAssessment: data.copyDataFromPreviousAssessment === 'Enable' ? 1 : 0,
        signWithOutMinimumDataset: data.signWithOutMinimumDataset === 'Enable' ? 1 : 0,
        skipPhoto: data.skipPhoto === 'Enable' ? 1 : 0
      },
    };
    /* Merging default object with new data */
    const result = { ...customerData, ...tempData };
    /* Dispatching */
     updateCustomerData(result);
  };

  /* Edit success */
  const editState = useSelector((state) => state.customer.editState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editState === "success") {
      setEditable(!editable);
      reset()
    }
    setTimeout(() => {
      dispatch(setEditState(false));
    }, 5000);
  }, [editState, dispatch]);


  const getPoaTimeType = (value) => {
    if (value !== "") {
      setDefaultPOAType(value);
      if (document.getElementById("poa")) {
        document.getElementById("poa").value = "";
      }
    }
    watch("poa");
    clearErrors(["poa"]);
  };  
  
  //ne1 toggle
  const [ne1State, setNe1State] = useState(0)
  const [makeShiftSold, setMakeShiftSold] = useState(true)

  const handleClickStatus = () => {
    // if(ne1State===0 && customerData?.settings?.ne1Wms===0){
    //   setNe1State(1)
    //   setMakeShiftSold(true)
    // }
    // if(ne1State===1 && customerData?.settings?.ne1Wms===0){
    //   setNe1State(0)
    //   setMakeShiftSold(false)
    // }
    // if(ne1State===0 && customerData?.settings?.ne1Wms===1){
    //   setMakeShiftSold(false)
    //   setNe1State(1)
    // }
    // if(ne1State===1 && customerData?.settings?.ne1Wms===1){
    //   setMakeShiftSold(true)
    //   setNe1State(0)
    // }
    setMakeShiftSold(true)
    if(ne1State===0){
      setNe1State(1)      
    }
    if(ne1State===1){
      setNe1State(0)
    }
  }

  useEffect(()=>{
    if(customerData?.settings?.ne1Wms===1){
      setNe1State(1)
      setMakeShiftSold(true)
    }
    else{
      setNe1State(0)
      setMakeShiftSold(true)
    }
  },[customerData])

  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  
  const modalOpen = () => {
    setShowConfirmPopup(true);
  }
  const modalRefresh = () => {
    setShowConfirmPopup(false)
  }

  const [popupSave, setPopupSave] = useState(false)
  const handleSavePopup = () => {
    setPopupSave(true)
    setShowConfirmPopup(false)
  }
  const handleCancelPopup = () =>{
    setShowConfirmPopup(false)
    setMakeShiftSold(true)
    // setPopupSave(false)
  }
/*loader*/
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  
  return (
    <>
      {/* Modal Pop Screen */}
      {showConfirmPopup && customerData?.accountGroup==="Z002" && <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
        heading={"NE1 Settings"}
        className={"modal_box"}
        modalSize={styles.custom_modal_size}
        modalScroll={false}
        reset={!resMsgState?.errorMessage && reset}
        custom_content={styles.custom_content}
        custom_heading={styles.custom_heading_ne1}
        modal_title={styles.modal_title_modal}
        close={styles.close_button_modal}
        changeNe1={true}
      >
        <div className="">
            <div className={`${styles.headerSetting} pt-3`}>
              <div className="mr-4">
                <i class="fa fa-cog" style={{color:'rgb(137 137 137)', fontSize:'34px'}} aria-hidden="true"></i>
              </div>
              <div className="pt-2">
                <p style={{fontSize:'21px', color:'rgb(137 137 137)', fontWeight:'500'}}>Apply to all Sold To’s & Ship To’s</p>
              </div>              
            </div>
            <div className={`${styles.content_modal} text-center pt-3`}>
              {ne1State===1 ? <h1 style={{fontSize:'60px', color:'rgb(164 163 163)', fontWeight:'500'}}>Enable NE1</h1> : <h1 style={{fontSize:'60px', color:'rgb(164 163 163)', fontWeight:'500'}}>Disable NE1</h1>}
              <p style={{fontSize:'20px', color:'rgb(137 137 137)', fontWeight:'500',marginTop:'32px', padding:'0 60px'}}>Please click “Yes” if you wish to apply these parameters to the entire org hierarchy or “No” to cancel</p>
            </div>

            <div className={`row ${styles.form_btn} ml-0`}>
              <div className="col-sm-12">
                <div className="text-center pb-4">
                  <br />
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small} settingsSave rounded`,
                    }}
                    type="submit"
                    id={SUBMIT_ID}
                    onClick={handleSavePopup}
                  >
                    {'Yes'}
                  </Button>
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} ${styles.btn_small_cancel_modal} rounded`,
                    }}
                    id={'no_popup'}
                    onClick={handleCancelPopup}
                  >
                    {'No'}
                  </Button>
                </div>
              </div>
            </div>
            
        </div>
      </Modal>
      }

      <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          {/* Edit success */}
          {editState === SUCCESS_STATUS && (
            <div className={`alert alert-success ${styles.form_alert}`}>
              {CUSTOMER_UPDATE_SUCCESS}
            </div>
          )}

          {/* Edit failure */}
          {editState === ERROR_STATUS && (
            <div className={`alert alert-danger ${styles.form_alert}`}>
              {UPDATE_FAILURE}
            </div>
          )}
          
          
          {!tinyDelay ?<div className={`loader col-12`}>
				<img src={Loader} alt="loading" />
			  </div>: <div className={` ${styles.patient_content}`}>
            {!editable ? (
              <>
                {/* Ne1 Status */}
                <div className={`${styles.tab_content_top} col-12`}>
                  <h2 className={`${styles.tab_content_hdg} pl-0`}>
                    <div className={`${styles.tab_content_ne1Status}`}>
                    {`NE1 - Paid: `}<span className="" style={{fontSize:'22px', color:'#737373'}}>{customerData?.settings?.ne1Wms === 1 ? "Enable" : "Disable"}</span>
                    </div>
                    {!editable && (
                      <span
                        className={`float-sm-right ${styles.tab_edit}`}
                        onClick={handleEdit}
                      >
                        <img src={editIcon} alt="edit" />
                        {EDIT}
                      </span>
                    )}
                  </h2>
                </div>

                {/*Wound Assessment​ Heading */}
                <div className={`col-sm-12 ${styles.col_spec} pt-2`}>
                  <p className={`col-6 ${styles.subheading_2}`}>
                    Wound Assessment​
                  </p>
                  <p className={`col-6 ${styles.subheading_2}`}>
                    Wound Photo
                  </p>
                </div>
              
                {/* POA  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    POA:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.settings?.poaWithinHrs}{" "}
                      {customerData && customerData?.settings?.poaType
                        ? customerData?.settings?.poaType[0]?.toUpperCase() +
                          customerData?.settings?.poaType?.slice(1)
                        : "Hours"}
                    </span>
                  </div>
                </div>

                {/* Skip photo  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Assessment without photo:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.settings?.skipPhoto
                        ]
                      }
                    </span>
                  </div>
                </div>


                {/* Re Eval  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Re-eval:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.settings?.reEvalWithinHrs !== "" ? customerData?.settings?.reEvalWithinHrs : "-"} {customerData?.settings?.reEvalWithinHrs !== "" && "Hours"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Wound score  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Wound score:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.settings?.woundAssessmentScore !== null
                        ? customerData?.settings?.woundAssessmentScore[0]?.toUpperCase() +
                        customerData?.settings?.woundAssessmentScore?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>                

                {/* Risk score  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Risk score:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.settings?.riskAssessmentScale !== null
                        ? customerData?.settings?.riskAssessmentScale[0]?.toUpperCase() +
                        customerData?.settings?.riskAssessmentScale?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* AI based Tissue Type  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    AI tissue type:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.settings?.aiTissueType
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* AI based History/Cause  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    AI history/cause:
                  </label>  
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.settings?.historyCause
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Copy data from previous assessment  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Copy data from previous assessment:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.settings?.copyDataFromPreviousAssessment
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Sign without minimum dataset  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Sign without minimum dataset:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.settings?.signWithOutMinimumDataset
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>                

                {/*Wound Measurements Heading */}
                <div className={`col-sm-12 ${styles.col_spec} pt-2`}>
                  <p className={`col-12 ${styles.subheading_2}`}>
                    Wound Measurements
                  </p>
                </div>

                {/* Ne1 Method  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Method:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.settings?.measureType &&
                      customerData?.settings?.measureType === "longestlength"
                        ? "Longest length"
                        : "Head to toe"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/*Auto Tracing  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec}`}
                  >
                    Auto trace:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        WOUND_EDGE_AUTO_TRACING_STATUS[
                          customerData?.settings?.woundEdgeAutoTracing
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>    
              </>
            ) : 
            
            (
              <Form
                formChanges={true}
                className={`p-0 ${styles.patient_content}`}
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* Heading Section */}
                <div className={`${styles.tab_content_top} col-12 pt-3 pb-4 d-flex align-items-center`}> 
                  {/* Ne1 Status */}
                  <div className={`${!popupSave ? 'col-3' : 'col-6'} ${styles.ne1_toggleSwitch} custom-control custom-switch pl-0`}>                      
                    <h2 className={`${!popupSave ? 'col-7 px-0' : 'col-3 px-0'}`} style={{fontSize:'24px', fontWeight:'normal'}}>
                      NE1 - Paid: 
                    </h2>
                    {!popupSave && <div className={`${styles.toggle_shells}`}>                                      
                    <input 
                        type="checkbox"
                        name="isStatus" 
                        className="custom-control-input"
                        id={"isStatus"}
                        data-testid={"toggleSwitch"}
                        // defaultValue = {ne1State}
                        checked = {ne1State === 1 ? true : false}                                                    
                        onChange={handleClickStatus}                          
                    />
                    <label className={`custom-control-label ${styles.toggle_switch}`} for="isStatus"></label> 
                    </div>}
                    {popupSave && <div className={`${styles.confrimationPopupButton}`}>
                      <p>{ne1State===1 ? "Enabled" : "Disabled"}</p>
                    </div>}
                    {popupSave && <div className={`col-7 ${styles.confrimationPopupTick} d-flex justify-content-center pl-0`}>
                      <i class="fa fa-check" aria-hidden="true" style={{color:"#2680eb",paddingTop:"8px", paddingRight:'10px'}}></i>
                      <p style={{color: "#2680eb"}}>Applied to all Sold To’s & Ship To’s</p>
                    </div>}
                  </div>
                  
                  {/* Vertical code */}
                  {!popupSave && makeShiftSold && customerData?.accountGroup==="Z002" && <div className={`verticalCode  ${styles.vertical_Code_role}`}></div>} 
                  {/* Shift and Sold button */}
                  {!popupSave && makeShiftSold && customerData?.accountGroup==="Z002" &&
                  <div className="btnClass">
                    <Button
                        classes={{
                          className: `btn ${styles.shif_sold_button}`,
                        }}
                        id="ShiftSold"
                        type="button"
                        // id={CANCEL_ID}
                        onClick={modalOpen}
                      >
                        {"Apply to all Sold To’s and Ship To’s​"}
                      </Button>
                  </div>}

                </div>

              {/*Wound Assessment Heading */}
              <div className={`col-6 ${styles.col_spec} ${styles.ne1_left} d-block`}>
                <p className={`col-12 ${styles.subheading_2}`}>
                  Wound Assessment
                </p>
                
              <div className="col-12 d-flex px-0">
              {/* POA */}
              <TextField
                  id={"poa"}
                  name={"poa"}
                  label={"POA:"}
                  classes={{
                    column: `col-9 ${styles.col_spec} mt-3`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: "col-6 p-0 ml-4 pl-1 pr-3",
                    inputClassName: styles.basic_input,
                  }}
                  type="text"
                  validator={register(poaRevalValidator)}
                  defaultValue={
                    customerData && customerData?.settings?.poaWithinHrs
                  }
                  required={true}
                  errors={errors}
                />

                <SelectBox
                  id={"poaType"}
                  name={"poaType"}
                  //  label={`${'sessionTimeoutType'}:`}
                  classes={{
                    column: `col-3 ${styles.col_spec} ml-2 pt-3`,
                    inputClassName: styles.basic_select,
                    // labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                  }}
                  optionValue={POA_TYPE_OPTIONS}
                  validator={register(checkPoaType)}
                  defaultValue={defaultPOAType}
                  errors={errors}
                  required={true}
                  onChange={(e) => getPoaTimeType(e.target.value)}
                />
                </div>                      

                {/* Re Eval  */}
                <TextField
                  id={"reEval"}
                  name={"reEval"}
                  label={"Re-eval:"}
                  classes={{
                    column: `col-10 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: `col-6 p-0 pl-0 ${styles.basic_input_column}`,
                    inputClassName: styles.basic_input,
                  }}
                  type="text"
                  // validator={register(poaRevalValidator)}
                  validator={register}
                  defaultValue={reEvalHours ? reEvalHours : 168}
                  note={
                    <label
                      className={`col-2 col-form-label ${styles.label_spec}`}
                    >
                      Hours
                    </label>
                  }
                />
                

                {/* Re-Eval Error Message */}
                {errors && errors.reEval && (
                  <div className={`col-10  p-0 ${styles.col_spec} mt-n3`}>
                    <div className="col-6 p-0"></div>
                    <div className="col-6 p-0 text-danger ml-n2">
                      {errors.reEval.message}
                    </div>
                  </div>
                )}
                {errors && errors.reEval && (
                  <div className={`col-10 ${styles.col_spec}`}></div>
                )}

                {/* Wound Score */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Wound score:
                  </label>
                  <RadioButton
                    id={"woundAssessmentScore"}
                    name={"woundAssessmentScore"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-control custom-radio custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["PUSH", "BWAT"]}
                    defaultValue={
                      customerData?.settings?.woundAssessmentScore === "push"
                        ? "PUSH"
                        : "BWAT"
                    }
                  />
                </div>
                

                {/* Risk Score */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Risk score:
                  </label>
                  <RadioButton
                    id={"riskAssessmentScale"}
                    name={"riskAssessmentScale"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-control custom-radio custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Braden", "Norton"]}
                    defaultValue={
                      customerData?.settings?.riskAssessmentScale === "braden"
                        ? "Braden"
                        : "Norton"
                    }
                  />
                </div>
               

                {/* AI based Tissue Type */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    AI tissue type:
                  </label>
                  <RadioButton
                    id={"aiTissueType"}
                    name={"aiTissueType"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn: `custom-control custom-radio custom-control-inline`,
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.settings?.aiTissueType === 1
                        ? "Enable"
                        : "Disable"
                    }
                    //defaultValue={customerData && AI_TISSUE_TYPE_STATUS[customerData?.settings?.aiTissueType]}
                  />
                </div>
               

                {/* AI based History / Cause Type */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    AI history/cause:
                  </label>
                  <RadioButton
                    id={"aihistorycause"}
                    name={"aihistorycause"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn: `custom-control custom-radio custom-control-inline`,
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.settings?.historyCause === 1
                        ? "Enable"
                        : "Disable"
                    }
                    //defaultValue={customerData && AI_TISSUE_TYPE_STATUS[customerData?.settings?.aiTissueType]}
                  />
                </div>
               

                {/* Copy data from previous Assessment */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Copy data from previous assessment:
                  </label>
                  <RadioButton
                    id={"copyDataFromPreviousAssessment"}
                    name={"copyDataFromPreviousAssessment"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn: `custom-control custom-radio custom-control-inline`,
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.settings?.copyDataFromPreviousAssessment === 1
                        ? "Enable"
                        : "Disable"
                    }
                  />
                </div>
               

                {/* Sign without minimum Dataset */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Sign without minimum dataset:
                  </label>
                  <RadioButton
                    id={"signWithOutMinimumDataset"}
                    name={"signWithOutMinimumDataset"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn: `custom-control custom-radio custom-control-inline`,
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.settings?.signWithOutMinimumDataset === 1
                        ? "Enable"
                        : "Disable"
                    }
                  />
                </div>
                


                {/*Wound Measurements Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>
                    Wound Measurements
                  </p>
                </div>


                {/* Wound type Method */}
                <div className={`col-11 ${styles.col_spec}`}>
                  <label
                    className={`col-5 col-form-label ${styles.label_spec} mr-3`}
                  >
                    Method:
                  </label>
                  <RadioButton
                    id={"measureType"}
                    name={"measureType"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label} ml-1`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-control custom-radio custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Head to toe", "Longest length"]}
                    defaultValue={
                      customerData?.settings?.measureType &&
                      customerData?.settings?.measureType === "longestlength"
                        ? "Longest length"
                        : "Head to toe"
                    }
                    // defaultValue={customerData && WOUND_EDGE_AUTO_TRACING_STATUS[customerData?.settings?.woundAssessmentScore]}
                  />
                </div>
               
                              
                {/* Wound auto tracing */}
                <div className={`col-10 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Auto trace:
                  </label>
                  <RadioButton
                    id={"woundEdgeAutoTracing"}
                    name={"woundEdgeAutoTracing"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-control custom-radio custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.settings?.woundEdgeAutoTracing === 1
                        ? "Enable"
                        : "Disable"
                    }
                    // defaultValue={customerData && WOUND_EDGE_AUTO_TRACING_STATUS[customerData?.settings?.woundAssessmentScore]}
                  />
                </div>
               

              </div>

              <div className={`col-6 ${styles.col_spec} d-block pl-2`}>
                <p className={`col-12 ${styles.subheading_2}`}>
                  Wound Photo
                </p>

                {/* Skip Photo */}
                <div className={`col-10 ${styles.col_spec} pt-3`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Assessment without photo:
                  </label>
                  <RadioButton
                    id={"skipPhoto"}
                    name={"skipPhoto"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-control custom-radio custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.settings?.skipPhoto === 1
                        ? "Enable"
                        : "Disable"
                    }
                    // defaultValue={customerData && WOUND_EDGE_AUTO_TRACING_STATUS[customerData?.settings?.woundAssessmentScore]}
                  />
                </div>
              </div> 
                

                <div className={`row ${styles.form_btn}`}>
                  <div className="col-sm-12">
                    <div className="text-center pb-4">
                      <br />
                      {/* Save Button */}
                      <Button
                        classes={{
                          className: `btn ${styles.btn_custom} ${styles.btn_small} settingsSave`,
                        }}
                        type="submit"
                        id={SUBMIT_ID}
                      >
                        {SAVE_BTN}
                      </Button>

                      {/* Cancel button  */}
                      <Button
                        classes={{
                          className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}`,
                        }}
                        id={CANCEL_ID}
                        onClick={handleEdit}
                      >
                        {CANCEL_BTN}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </div>}
        </div>
      </div>

      {accessError && <AccessErrorModal />}      
    </>
  );
}

Ne1.propTypes = {
  customerData: PropTypes.object,
};
