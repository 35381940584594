import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux'
import {
  EMAIL_ID,
  EMAIL_LBL,
  EMAIL_PHDR,
  FORGOT_PASSWORD_LBL,
  PASSWORD_ID,
  PASSWORD_LBL,
  PASSWORD_PHDR,
  LOGIN_ID,
  LOGIN_LBL,
  WELCOME_LBL,
  NO_ACCOUNT_LBL,
  REGISTER_LBL,
  EMAIL_REGEX,
  EMAIL_INVALID,
  PASSWORD_REQUIRED,
  BASE_URL,
  LOGREG_LBL
} from "../../../constants";

import { checkEmail, checkPassword } from "../../../utils";
import { TextField, Button, Form } from "../../primary";
import { Link , useLocation, useParams } from "react-router-dom";
import { Banner } from "../../secondary/Banner";
import { Logo } from "../../secondary/Logo";
import { ServiceError } from "../../../utils";
import {
  link_style,
  subheading,
  form_shadow,
  title,
  form_group,
  medline_block,
  link_style2,
  service_error,
  close_btn,
  link_stylegray,
  textField_Enter_Error
} from "../../../styles/common.module.scss";
import { custom_btn, close_password, modal_size,custom_heading,custom_body,custom_content,sso_p, custom_fade, sso_privacy } from "./loginForm.module.scss";
import { useDispatch } from "react-redux";
import { setResMsgState, setEditState, setSSOAuthData, getSSOTokaen, setIsSSOLoading} from "../../../app/userSlice";
import { setEditState as setEditStateCustomer } from "../../../app/customerSlice";
import { setAccessError, setError, clearError, setLoading } from "../../../app/appSlice";
import { setLastVal,setPatientListLastVal } from '../../../app/patientSlice';
import { setWoundReportPayload } from '../../../app/woundReportSlice';

import closeErrorIcon from "./../../../static/images/Close_Normal.svg";
import close_icon_gray from '../../../static/images/close-icon-gray.png';
import sso_icon from '../../../static/images/SSO.png';
import {
  loginAsync,
  loginEmailAsync,
  setUserDetail,
  setuserDetails
} from '../../../app/userSlice'; 
import { Modal } from "../../secondary";
export function LoginForm({ doLogin , doLoginEmail,isEmailValid,setIsEmailValid, history, localIP}) {
  
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const sSOAuthData = useSelector(state => state.user.sSOAuthData);
  const isSSOLoading = useSelector(state => state.user.isSSOLoading);
 //setSSOAuthData
  const { register, handleSubmit, errors,setValue,getValues:getValuesEmail } = useForm();
  const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset: reset2,getValues} = useForm();

  const errorMessage = ServiceError();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLastVal({}))
    dispatch(setPatientListLastVal({}))
    dispatch(setWoundReportPayload({}))
    dispatch(setUserDetail({}))
    dispatch(setuserDetails({}))
    if(code&&sSOAuthData?.email){
      if(document?.querySelector("body")){
        document.querySelector("body").style.display = "none";
      }
      const payload={
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: `${window.location.origin}/login`,
        code_verifier:sSOAuthData?.verifier,
        email:sSOAuthData?.email
        //sSOAuthData verifier
      }
      dispatch(getSSOTokaen({payload,history}))
    }
    dispatch(setIsSSOLoading({}));
      // dispatch(setSSOAuthData({}));
    dispatch(setResMsgState({}));
    dispatch(setEditState(false));
    dispatch(setEditStateCustomer(false));
    dispatch(setLoading(false));
  }, [dispatch]);
  useEffect(() => {
   if(getValues('email')){
    setValue('email',getValues('email'))
    if(document.getElementById('email')){
      document.getElementById('email').value=getValues('email');     
      setValue('email',getValues('email'))
    }
  }
  }, [getValues('email')]);

  //if Using 'Emter' key move next step
  const [validationCheck, setValidationCheck] = useState(false)
  const [validationPassword, setValidationPassword] = useState(false)

  const getEmailValue = data =>{   
    let Emailcheck = /^(([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validcheck = Emailcheck.test(data.email) ? true : false;
    if(validcheck===false){
      setValidationCheck(true)
    }
    if(validcheck===true){
      setValidationCheck(false)
      if(setIsEmailValid){
      }
      dispatch(setSSOAuthData({}))
      dispatch(loginEmailAsync(data, history,setIsEmailValid)); 
    }
        
  }


  const getPasswordValue = data => {
    if(data.password===''){
      setValidationPassword(true)
    }
    if(data.password!==''){
      setValidationPassword(false)
      dispatch(loginAsync(data, history, localIP));
    }
  } 
//if Using 'Emter' key move next step
//isSSOLoading
/*  if(isSSOLoading&&isSSOLoading?.renderQueryURL){
    //renderQueryURL
    setTimeout(() => {
      window.location = isSSOLoading?.renderQueryURL
      dispatch(setIsSSOLoading({}));
    }, 20000)
    
  }
 }, [isSSOLoading]);*/

  return (<>
  <Modal
          modalOpen={isSSOLoading?.renderQueryURL?true:false}
          modalClose={()=>{dispatch(setIsSSOLoading({}));}}
          heading={'Single Sign On'}
          className={"modal_box"}
          modalSize={`${modal_size}`}
          custom_heading={`${custom_heading}`}
          custom_body={`${custom_body}`}
          custom_content={`${custom_content}`}
          custom_fade={`${custom_fade}`}
        >
          <div className={`pl-2`}>
            <p className={`pl-1 pr-1  text-center mt-0 ${sso_p}`}>
              <img src={sso_icon} alt="sso icon" /> <br/>
              Your organization uses Single Sign On (SSO) with the Skin Health app. Please click ‘Continue’ and sign in using your SSO credentials.
              </p>
                <div className="form-group flex-fill d-flex align-items-center mt-n4">
                    <Button
                      type={"button"}
                      value={"Continue"}
                    // id={LOGIN_ID}
                    onClick={
                      ()=>{
                      if(document?.querySelector("body")){
                        document.querySelector("body").style.display = "none";
                      }
                    window.location = isSSOLoading?.renderQueryURL;
                    dispatch(setIsSSOLoading({}));}
                  }
                      classes={{ className: `btn btn-block ${custom_btn} col-2 container-fluid text-center rounded` }}
                    />
                </div>
                <div className="form-group flex-fill  align-items-center mt-4">
                    <p className={sso_privacy}>By continuing, you acknowledge that you have read, understood,</p>
                    <p className={sso_privacy}>and agree to 

                          <Link to={{  pathname: "https://cms.medlinesh.com/node/263"}} target="_blank" className={link_style}>
                          {' Privacy Policy '}
                        </Link>    
                          and  
                        <Link to={{  pathname: "https://cms.medlinesh.com/node/264"}} target="_blank" className={link_style}>
                          {' Terms and Conditions '}
                        </Link></p>
                      </div>
          </div>
        </Modal>
    <div className="form-login">
      <div className={`container-fluid ${medline_block}`}>
        <div className="row vh-100">
          <div className="col-sm-12 col-lg-6">
            <Logo />
            <div className="container pt-3">
              <div className="row">
                <div
                  className={`col-12 col-sm-12  mx-auto pt-5 pb-5 ${form_shadow}`}
                >
                  <div className=" col-12 ">
                  <>
                  {isEmailValid&&
                         <button className={`${close_password}`} id={'close_password'} onClick={()=>{dispatch(clearError());setIsEmailValid(false)}} >
                         
                          {/* <img src={close_icon_gray} alt="Close Password" /> */}
                        </button>
                      }
                    </>
                    <h1 className={title}> {!isEmailValid?<>{LOGREG_LBL}</>:'Login to your account'}</h1>
                    <p>
                      <span className={subheading}>
                      {!isEmailValid?<>Enter your work email to get started</>:''}
                        {/* {NO_ACCOUNT_LBL}&#160;
                        <Link to="/registration" className={link_style}>
                          {REGISTER_LBL}
                        </Link> */}
                      </span>
                    </p>
                  </div>
                  {errorMessage && (
                    <div className="row">
                    <div
                      data-testid="service_error"
                      className={service_error}
                      dangerouslySetInnerHTML={{
                        __html:
                          errorMessage
                      }}
                    ></div>
                    <>
                      {
                         <button className={`${close_btn}`} id={'close_btn'} onClick={()=>{dispatch(clearError())}}>
                          <img src={closeErrorIcon} alt="Close" />
                        </button>
                      }
                    </>
                  </div>
                  )}
                 
                  {!isEmailValid&&<Form className={"col-12"} onSubmit={handleSubmit2(doLoginEmail)}>
                    {/* Email */}
                    <TextField
                      classes={{
                        column: `form-group no-gutters ${form_group}`,
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={EMAIL_LBL}
                      type={"text"}
                      name={EMAIL_ID}
                      id={EMAIL_ID}
                      placeholder={EMAIL_PHDR}
                      validator={register2(checkEmail)}
                      required={true}
                      errors={validationCheck===false && errors2}
                      autoComplete={'off'}
                      onKeyUp={(e)=>{ if (e.key === 'Enter') {
                        
                        let data = {email : ''}
                        data.email = e.target.value.toLowerCase().trim()
                        getEmailValue(data);
                        dispatch(clearError())   
                        }
                      }}
                      onChange={(e)=>{
                        if(e.target.value !== ''){
                          if(document.getElementById('email')){
                            document.getElementById('email').value=e.target.value.toLowerCase().trim();     
                            setValue('email',e.target.value.toLowerCase().trim())
                          }
                          setValidationCheck(false)
                        }
                      }}
                    />
                    
                    {validationCheck===true && <div className={textField_Enter_Error}>{EMAIL_INVALID}</div>}

                    <div className="form-group">
                      <Button
                        type={"submit"}
                        value={"Next"}
                        id={LOGIN_ID}
                        classes={{ className: `btn btn-block ${custom_btn}` }}
                      />
                    </div>
                
                  </Form>}

                  {isEmailValid&&<Form className={"col-12"} onSubmit={handleSubmit(doLogin)}>
                    {/* Email */}
                    <div className={'mt-3 mb-3'}>
                     {getValuesEmail('email')?`${getValuesEmail('email')}`:''} 
                         <button id={'change_link'} onClick={()=>{dispatch(clearError());setIsEmailValid(false)}} className={link_style}>
                          {'Change'}
                        </button>
                    </div>
                    <TextField
                      classes={{
                        column: `form-group no-gutters ${form_group} d-none`,
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={EMAIL_LBL}
                      type={"text"}
                      name={EMAIL_ID}
                      id={EMAIL_ID}
                      placeholder={EMAIL_PHDR}
                      validator={register(checkEmail)}
                      required={true}
                      errors={errors}
                      defaultValue={getValues('email')?getValues('email').toLowerCase():''}
                      autoComplete={'off'}
                    />
                    <TextField
                      classes={{
                        column: `form-group no-gutters ${form_group}`,
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={PASSWORD_LBL}
                      type={"password"}
                      name={PASSWORD_ID}
                      id={PASSWORD_ID}
                      placeholder={PASSWORD_PHDR}
                      validator={register(checkPassword)}
                      required={true}
                      errors={validationPassword===false && errors}
                      onKeyUp={(e)=>{ if (e.key === 'Enter') {                        
                        let data = {email: '', password:''}
                        data.email = document.getElementById('email').value;
                        data.password = e.target.value;
                        getPasswordValue(data);  
                        }
                      }}
                      onChange={(e)=>{
                        if(e.target.value !== ''){
                          setValidationPassword(false)
                        }
                      }}
                    />

                    {validationPassword===true && <div className={textField_Enter_Error}>{PASSWORD_REQUIRED}</div>}

                    <div className="form-group  no-gutters">
                      <div className="col text-right">
                        <Link to="/forgotpassword" className={link_stylegray}>
                          {FORGOT_PASSWORD_LBL}
                        </Link>
                      </div>
                    </div>
                    <div className="form-group">
                      <Button
                        type={"submit"}
                        value={"Login"}
                        id={LOGIN_ID}
                        classes={{ className: `btn btn-block ${custom_btn}` }}
                      />
                    </div>
                    
                  </Form>}
                </div>
              </div>
            </div>
          </div>
          <Banner />
        </div>
      </div>
    </div>
 </> );
}
